import React, {
  useState,
  useCallback,
  useEffect,
} from 'react'
import { observer } from 'mobx-react'
import { computed } from 'mobx'
import makeStyles from '@mui/styles/makeStyles'
import {
  Tabs,
  Tab,
  Box,
  Button,
  Typography,
} from '@mui/material'

import FilterListIcon from '@mui/icons-material/FilterList'

import { useStores } from 'admin/hooks'
import Page from 'admin/components/page'
import { formatDate } from 'admin/utils/helper'

import PosDatagridCustomer from './datagrid-customer'
import PosDatagridDriver from './datagrid-driver'
import PosDatagridInvoice from './datagrid-invoice'
import PosDatagridInvoiceItem from './datagrid-invoice-item'
import PosDatagridItemAccessory from './datagrid-item-accessory'
import PosCustomerInfo from './info/customer'
import PosDriverInfo from './info/driver'
import PosInvoiceInfo from './info/invoice'
import PosInvoiceItemInfo from './info/invoice-item'
import PosItemAccessoryInfo from './info/item-accessory'
import PosFilter from './filter'

import styles from './styles'

const useStyles = makeStyles(styles)

const FilterAndAction = observer(({
  activeTab,
}) => {
  const classes = useStyles()
  const { posStore } = useStores()

  const filterLength = computed(() => {
    const object = {
      id: posStore.query.id,
      name: posStore.query.name,
      phoneNumber: posStore.query.phoneNumber,
      paymentTypeId: posStore.query.paymentTypeId,
      customerId: posStore.query.customerId,
      statusTypeId: posStore.query.statusTypeId,
      date: posStore.query.startDate,
      orderId: posStore.query.orderId,
    }
    const objArr = Object.values(object)
    const filterArr = objArr.filter(item => item)
    return filterArr.length
  }).get()

  const selectedDateFilter = computed(() => {
    const { startDate, endDate } = posStore.query
    if (!startDate) return '-'
    return `${formatDate(startDate)} - ${formatDate(endDate)}`
  }).get()

  const renderIcon = () => {
    return (
      <>
        {filterLength > 0 ? (
          <div className={classes.filterNumber}>
            {filterLength}
          </div>
        ) : (
          <FilterListIcon />
        )}
        <span>
          Filter
        </span>
      </>
    )
  }

  return (
    <div className={classes.filter}>
      <Button
        variant="outlined"
        color="secondary"
        onClick={() => {
          posStore.setOpenDialog(true)
        }}
      >
        {renderIcon()}
      </Button>
      {activeTab !== 1 && (
        <div style={{ marginLeft: '0.5rem' }}>
          <Typography variant="h6">
            {activeTab === 2 ? 'Dropoff Time' : 'Created At'}
          </Typography>
          <Typography variant="body2">
            {selectedDateFilter}
          </Typography>
        </div>
      )}
    </div>
  )
})

const POS = observer(() => {
  const { posStore } = useStores()
  const [activeTab, setActiveTab] = useState(0)

  const handleChangeTab = useCallback((event, value) => {
    posStore.resetList()
    setActiveTab(value)
  }, [])

  useEffect(() => {
    posStore.resetList()
  }, [])

  return (
    <Page>
      <Box sx={{
        borderBottom: 1,
        borderColor: 'divider',
        display: 'flex',
        alignItems: 'center',
      }}
      >
        <Tabs
          value={activeTab}
          onChange={handleChangeTab}
          textColor="secondary"
          indicatorColor="secondary"
        >
          {[
            'Customer',
            'Driver',
            'Invoice',
            'Invoice Item',
            'Item Accessory',
          ].map((option) => (
            <Tab
              key={option}
              label={option}
              sx={(theme) => ({
                textTransform: 'none',
                color: theme.palette.secondary.main,
                fontWeight: 600,
              })}
            />
          ))}
        </Tabs>
      </Box>
      <FilterAndAction activeTab={activeTab} />
      <PosFilter activeTab={activeTab} />
      {activeTab === 0 && (
        <>
          <PosDatagridCustomer />
          <PosCustomerInfo />
        </>
      )}
      {activeTab === 1 && (
        <>
          <PosDatagridDriver />
          <PosDriverInfo />
        </>
      )}
      {activeTab === 2 && (
        <>
          <PosDatagridInvoice />
          <PosInvoiceInfo />
        </>
      )}
      {activeTab === 3 && (
        <>
          <PosDatagridInvoiceItem />
          <PosInvoiceItemInfo />
        </>
      )}
      {activeTab === 4 && (
        <>
          <PosDatagridItemAccessory />
          <PosItemAccessoryInfo />
        </>
      )}
    </Page>
  )
})

export default POS
