import React from 'react'
import {
  CircularProgress,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import clx from 'clsx'

import CustomizedDialogTitle from 'admin/components/dialog-title'
import styles from './styles'

const useStyles = makeStyles(styles)

const CustomDialog = ({
  size = 'sm',
  open = false,
  title,
  content,
  onClose,
  onAccept,
  primaryActionLabel = 'OK',
  cancelActionLabel = 'Cancel',
  loading = false,
  noButton,
  fullWidth = false,
}) => {
  const classes = useStyles()

  return (
    <Dialog
      maxWidth={size}
      open={open}
      onClose={(event, reason) => {
        event.preventDefault()
        onClose(reason)
      }}
      fullWidth={fullWidth}
    >
      <CustomizedDialogTitle>
        {title}
      </CustomizedDialogTitle>
      <DialogContent
        sx={{ marginBottom: '1.5rem' }}
      >
        {content}
      </DialogContent>
      {
        !noButton && (
          <DialogActions>
            <Button
              variant="outlined"
              color="secondary"
              onClick={onClose}
            >
              {cancelActionLabel}
            </Button>
            {onAccept && (
              <Button
                variant="contained"
                color="secondary"
                onClick={(event) => {
                  event.preventDefault()
                  onAccept()
                }}
                className={clx({
                  [classes.bottonProgress]: loading,
                })}
                endIcon={loading && (
                  <CircularProgress
                    color="secondary"
                    size={15}
                    disableShrink
                    style={{ color: '#FFFFFF' }}
                  />
                )}
              >
                {primaryActionLabel}
              </Button>
            )}
          </DialogActions>
        )
      }
    </Dialog>
  )
}

export default CustomDialog
