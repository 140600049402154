import React, { useEffect, useCallback } from 'react'
import { observer } from 'mobx-react'
import makeStyles from '@mui/styles/makeStyles'
import {
  Button,
} from '@mui/material'

import { useApiRequest, useStores } from 'admin/hooks'
import * as srv from 'admin/services'

import DeleteDialog from 'admin/components/dialog'
import Page from 'admin/components/page'
import Protected from 'admin/components/protected'

import AdminForm from './form'
import AdminInfo from './info'
import AdminDataGrid from './datagrid'
import styles from './styles'

const useStyles = makeStyles(styles)

const FilterAndAction = observer(() => {
  const classes = useStyles()
  const { adminStore } = useStores()

  return (
    <div className={classes.headSection}>
      <Protected
        level="create"
        category="admin"
      >
        <Button
          onClick={() => {
            adminStore.setOpenDialog(null, 'form')
          }}
          variant="contained"
          color="secondary"
        >
          Add New Admin
        </Button>
      </Protected>
    </div>
  )
})

const Admin = observer(() => {
  const {
    notificationStore,
    adminStore,
  } = useStores()

  if (!adminStore.adminPageAccess) return null

  const {
    request: deleteAdmin,
  } = useApiRequest(srv.deleteAdmin, { blocking: true })

  const onDelete = useCallback(async () => {
    if (
      !adminStore.selected ||
      !adminStore.selected.id
    ) return
    const response = await deleteAdmin(adminStore.selected.id)
    if (!response) return
    notificationStore.setSuccess('Deleted')
    adminStore.setCloseDialog('delete')
    adminStore.reload(true)
  }, [adminStore.selected])

  useEffect(() => {
    adminStore.fetch()
  }, [adminStore.queryParams])

  useEffect(() => {
    return () => {
      adminStore.resetQuery()
    }
  }, [])

  return (
    <Page>
      <FilterAndAction />
      <AdminForm />
      <AdminDataGrid />
      <AdminInfo />
      <DeleteDialog
        open={adminStore.dialog.delete}
        title={`Do you want to delete admin ${adminStore.selected?.name} account? `}
        content="Admin data that deleted cannot be recovered. Make sure you know what you are doing."
        primaryActionLabel="Delete"
        onAccept={onDelete}
        onClose={() => { adminStore.setCloseDialog('delete') }}
      />
    </Page>
  )
})
export default Admin
