import React from 'react'
import { observer } from 'mobx-react'

import { useStores } from 'admin/hooks'

import CardItem from 'admin/components/dashboard-card/upper-content/overview'

const DriverLowest = () => {
  const { bookingStore } = useStores()
  const { countLowestDriver } = bookingStore

  return (
    <CardItem
      title="Lowest No. Pick Up"
      driverName={countLowestDriver?.name || '-'}
      total={countLowestDriver?.total || 0}
      isDriver
    />
  )
}

export default observer(DriverLowest)
