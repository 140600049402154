import { makeAutoObservable, flow } from 'mobx'

import * as srv from 'admin/services'
import handleError from 'admin/services/error-handler'

export default class ShortLinkStore {
  loading = false
  dialog = false

  list = []
  total = 0
  query = {
    sortBy: '',
    sortDirection: '',
  }
  currentPage = 0
  rowsPerPage = 10

  DEFAULT = {
    title: '',
    slashtag: '',
    destination: '',
    expiredAt: null,
  }

  constructor() {
    makeAutoObservable(this, {
      reload: flow,
      fetch: flow,
    })
  }

  setOpenDialog = (value) => {
    this.dialog = value
  }

  setCloseDialog = (value) => {
    this.dialog = value
  }

  updatePage = (page) => {
    this.currentPage = page
  }

  updateRowsPerPage = (perPage) => {
    this.rowsPerPage = perPage
  }

  resetQuery = () => {
    this.query = {
      sortBy: '',
      sortDirection: '',
    }
    this.currentPage = 0
  }

  get queryParams() {
    const params = {
      limit: this.rowsPerPage,
      offset: this.currentPage * this.rowsPerPage,
    }
    if (this.query.sortBy) params.sortBy = this.query.sortBy
    if (this.query.sortDirection) params.sortDirection = this.query.sortDirection
    return params
  }

  * reload() {
    yield this.fetch()
  }

  * fetch(params) {
    try {
      this.loading = true
      const response = yield srv.fetchShortLink({
        ...this.queryParams,
        ...params,
      })
      if (response.ok) {
        this.list = response.data.list
        this.total = response.data.total
        return response.data
      }
      throw handleError(response)
    } catch (error) {
      return null
    } finally {
      this.loading = false
    }
  }
}
