export default () => ({
  listItemTextPadding: {
    paddingLeft: '1rem',
    textTransform: 'uppercase',
  },
  listContainer: {
    width: '100%',
  },
  latestStatusText: {
    marginTop: '0.25rem',
    marginLeft: '1rem',
  },
})
