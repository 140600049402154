import React, { useCallback, useState, useEffect } from 'react'
import { observer } from 'mobx-react'
import {
  Dialog,
  Button,
  DialogContent,
  Grid,
  Tooltip,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

import DoneIcon from '@mui/icons-material/Done'

import CustomizedDialogTitle from 'admin/components/dialog-title'
import TextField from 'admin/components/textfield'

import { useStores, useApiRequest } from 'admin/hooks'
import * as srv from 'admin/services'

import styles from '../styles'

const useStyles = makeStyles(styles)

const WARNING_MESSAGE = 'Please remember that if you change the barcode value, it will affect to the process of syncing POS orders to dms bookings. so understand what you are doing.'

const BookingTagItemForm = observer((props) => {
  const classes = useStyles()
  const { bookingTagStore, notificationStore } = useStores()
  const [tag, setTag] = useState({
    name: '',
    posCode: '',
  })

  const {
    request: updateTag,
    isLoading: updateProcessing,
  } = useApiRequest(srv.updateTag, { blocking: true })

  const handleChange = useCallback((key, value) => {
    setTag({
      ...tag,
      [key]: value,
    })
  }, [tag])

  useEffect(() => {
    setTag(props.item)
  }, [props.item])

  const handleSubmit = useCallback(async (event) => {
    event.preventDefault()
    if (updateProcessing) return

    const request = updateTag
    const response = await request(tag)
    if (!response) return

    notificationStore.setSuccess('Update tag success')

    bookingTagStore.reload(true)
  }, [tag])

  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      spacing={1}
      className={classes.grid}
    >
      <Grid
        item
        md={3}
      >
        <span>
          {tag.name}
        </span>
      </Grid>
      <Grid
        item
        md={8}
        style={{ position: 'relative' }}
      >
        <div>
          <TextField
            fullWidth
            label="Barcode"
            name="posCode"
            size="small"
            value={tag.posCode}
            onChange={(event) => {
              handleChange(event.target.name, event.target.value)
            }}
            color="secondary"
          />
        </div>
      </Grid>
      <Grid
        item
        xs={1}
      >
        <Tooltip title="Save">
          <Button
            variant="outlined"
            color="secondary"
            className={classes.button}
            onClick={handleSubmit}
            disabled={updateProcessing}
          >
            <DoneIcon />
          </Button>
        </Tooltip>
      </Grid>
    </Grid>
  )
})

const BarcodeForm = () => {
  const classes = useStyles()
  const { bookingTagStore } = useStores()

  const handleClose = useCallback(() => {
    bookingTagStore.setCloseDialog('barcode')
  }, [])

  return (
    <Dialog
      open={bookingTagStore.dialog.barcode}
      onClose={handleClose}
      maxWidth="md"
      fullWidth
    >
      <CustomizedDialogTitle onClose={handleClose}>
        Barcode
      </CustomizedDialogTitle>
      <DialogContent className={classes.dialogContent}>
        <div className={classes.warningMessage}>
          <span>
            {WARNING_MESSAGE}
          </span>
        </div>
        {(bookingTagStore.list.length !== 0) && bookingTagStore.list.map((tag) => (
          <BookingTagItemForm
            key={tag.id}
            item={tag}
          />
        ))}
      </DialogContent>
    </Dialog>
  )
}

export default observer(BarcodeForm)
