import React, { useEffect, useCallback, useState } from 'react'
import { observer } from 'mobx-react'
import { makeStyles } from '@mui/styles'
import {
  Typography,
  Stack,
} from '@mui/material'

import { useStores } from 'admin/hooks'
import CustomDataGrid from 'admin/components/data-grid'
import DriverType from 'admin/components/driver-type'
import ServiceIcon from 'admin/components/service-icon'
import {
  getTags,
  formatDate,
  formatTimeOnly,
  slotDisplay,
  channelDisplay,
} from 'admin/utils/helper'
import { APP_PAYMENT_METHOD, REASON_BOOKING, CUSTOMER_CREATED } from 'admin/config'

import styles from './styles'

const useStyles = makeStyles(styles)

const statusColor = (value) => {
  if (value === 'completed') return '#00A424'
  if (value === 'on the way') return '#E3AE24'
  if (value === 'paid') return '#00A424'
  if (value === 'unpaid') return '#E4627B'
  if (value === 'failed') return '#E01205'
  if (value === 'deleted') return '#251500'
  return null
}

const reasonBooking = (reason) => {
  return REASON_BOOKING.find((item) => item === reason)
}

const COL_CREATED_DATE = {
  field: 'createdAt',
  headerName: 'Date Created',
  width: 120,
  renderCell: (params) => (
    <Stack direction="column">
      <Typography variant="h5">
        {formatDate(params.row.createdAt)}
      </Typography>
      <Typography variant="h5">
        {formatTimeOnly(params.row.createdAt)}
      </Typography>
    </Stack>
  ),
}

const COL_BOOKING_CODE = {
  field: 'code',
  sortable: false,
  headerName: 'Booking Code',
  minWidth: 120,
  renderCell: (params) => {
    return params.row.code
  },
}

const COL_CUSTOMER = {
  field: 'customer.name',
  sortable: false,
  headerName: 'Customer',
  flex: 0.5,
  minWidth: 160,
  renderCell: (params) => (
    <Stack direction="column">
      <Typography variant="h5">
        {params.row.customer?.name || params.row.customer?.originalData.name}
      </Typography>
      <Typography variant="h5">
        {params.row.customer?.phoneNumber || params.row.customer?.originalData.mobileNo}
      </Typography>
    </Stack>
  ),
}

const COL_ADDRESS = {
  field: 'address',
  headerName: 'Address',
  sortable: false,
  flex: 1,
  minWidth: 250,
  renderCell: (params) => (
    <Typography
      style={{
        lineHeight: 'normal',
        overflow: 'hidden',
        whiteSpace: 'normal',
      }}
      variant="h5"
    >
      {params.row.address || params.row.customer?.address}
    </Typography>
  ),
}

const COL_CHANNEL = {
  field: 'channel.name',
  headerName: 'Channel',
  sortable: false,
  minWidth: 120,
  renderCell: (params) => {
    return params.row.channel ? channelDisplay(params.row.channel.name) : '-'
  },
}

const COL_SERVICES = {
  field: 'services',
  headerName: 'Services',
  minWidth: 160,
  flex: 0.5,
  sortable: false,
  renderCell: (params) => {
    const {
      pickup,
      delivery,
    } = getTags(params.row.bookingTags)
    return (
      <Stack
        direction="column"
        style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
      >
        {pickup.length > 0 && (
          <ServiceIcon
            iconName="pickup"
            data={pickup}
          />
        )}
        {delivery.length > 0 && (
          <ServiceIcon
            iconName="delivery"
            data={delivery}
          />
        )}
      </Stack>
    )
  },
}

const COL_CODE = {
  field: 'driver.code',
  headerName: 'Driver',
  minWidth: 120,
  sortable: false,
  renderCell: (params) => (
    params.row.driver ? (
      <DriverType
        driver={params.row.driver}
        label={params.row.driver.code}
      />
    ) : '-'
  ),
}

const COL_SCHEDULE = {
  field: 'date',
  headerName: 'Schedule',
  minWidth: 120,
  renderCell: (params) => (
    <Stack direction="column">
      <Typography variant="h5">
        {formatDate(params.row.date)}
      </Typography>
      <Typography variant="h5">
        ({slotDisplay(params.row.slot.start, params.row.slot.end)})
      </Typography>
    </Stack>
  ),
}

const COL_STATUS = {
  field: 'status',
  headerName: 'Status',
  minWidth: 160,
  renderCell: (params) => (
    <Stack direction="column">
      <Typography
        color="secondary"
        variant="caption"
        style={{
          textTransform: 'capitalize',
          color: statusColor(params.value),
          fontWeight: 'bold',
        }}
      >
        {params.value}
      </Typography>
      <Typography variant="h6">
        {reasonBooking(params.row.reason)}
      </Typography>
    </Stack>
  ),
}

const COL_INVOICES = {
  field: 'invoice',
  headerName: 'Invoice',
  width: 160,
  sortable: false,
  renderCell: (params) => (
    <Typography
      style={{
        lineHeight: 'normal',
        overflow: 'hidden',
        whiteSpace: 'normal',
      }}
      variant="h5"
    >
      {params.row.invoices.length > 0 ? (params.row.invoices.map(item => (
        <div key={item.prestoId}>
          {item.prestoId}
        </div>
      ))) : '-'}
    </Typography>
  ),
}

const COL_PAYMENT_TYPE = {
  field: 'paymentType',
  headerName: 'Payment Method',
  width: 160,
  sortable: false,
  renderCell: (params) => (
    <Typography
      style={{
        lineHeight: 'normal',
        overflow: 'hidden',
        whiteSpace: 'normal',
      }}
      variant="h5"
    >
      {params.row.invoices.length > 0 ? (params.row.invoices.map(item => (
        <div key={item.prestoId}>
          {item.paymentMethod === APP_PAYMENT_METHOD.value ? APP_PAYMENT_METHOD.display : item.paymentMethod}
        </div>
      ))) : '-'}
    </Typography>
  ),
}

const COL_PAYMENT_STATUS = {
  field: 'status',
  headerName: 'Status',
  renderCell: (params) => (
    <div>
      {params.row.invoices.length > 0 ? (params.row.invoices.map(item => (
        <Typography
          color="secondary"
          variant="caption"
          style={{
            textTransform: 'capitalize',
            color: statusColor(item.paymentStatus),
            marginBottom: '0.5rem',
          }}
          key={item.prestoId}
        >
          <div>{item.paymentStatus}</div>
        </Typography>
      ))) : '-'}
    </div>
  ),
}

const COL_CREATED_BY = {
  field: 'createdBy',
  headerName: 'Created By',
  minWidth: 150,
  renderCell: (params) => {
    return params.value ? params.value : '-'
  },
}

const defaultColumns = [COL_CREATED_DATE, COL_BOOKING_CODE, COL_CUSTOMER]

const BookingDataGrid = ({
  viewMode = 'booking', // bookings or invoice
}) => {
  const classes = useStyles()
  const { bookingStore, infoSectionStore } = useStores()
  const [columns, setColumns] = useState([])
  const [bookingList, setBookingList] = useState([])

  const handlePageChange = useCallback((params) => {
    bookingStore.updatePage(params)
  }, [])

  const handlePageSizeChange = useCallback((param) => {
    bookingStore.updateRowsPerPage(param)
  }, [])

  const handleRowClick = useCallback((rowData) => {
    infoSectionStore.select(rowData.row)
  }, [])

  const isChannel = useCallback((params) => {
    return !params.row.hasReviewed && CUSTOMER_CREATED.includes(params.row.channel?.name) ? 'customer-create' : ''
  }, [])

  useEffect(() => {
    return () => {
      bookingStore.resetQuery()
      bookingStore.setDefaultFilter(true)
    }
  }, [])

  useEffect(() => {
    const defaultParams = {
      default: bookingStore.defaultFilter || '',
    }
    bookingStore.fetch(defaultParams)
    bookingStore.setListingQuery(defaultParams)
  }, [
    bookingStore.queryParams,
    bookingStore.defaultFilter,
    bookingStore.rebookDialog,
  ])

  useEffect(() => {
    if (viewMode === 'booking') {
      setColumns([
        ...defaultColumns,
        COL_ADDRESS,
        COL_CHANNEL,
        COL_SERVICES,
        COL_CODE,
        COL_SCHEDULE,
        COL_STATUS,
        COL_CREATED_BY,
      ])
    } else if (viewMode === 'invoice') {
      setColumns([
        ...defaultColumns,
        COL_INVOICES,
        COL_PAYMENT_TYPE,
        COL_PAYMENT_STATUS,
      ])
    } else {
      setColumns(defaultColumns)
    }
  }, [viewMode])

  const onSortModelChange = (param) => {
    if (param.length === 1) {
      bookingStore.updateQuery('sortBy', param[0].field, true)
      bookingStore.updateQuery('sortDirection', param[0].sort, true)
      return
    }
    bookingStore.resetSort()
  }

  useEffect(() => {
    if (!bookingStore.list || bookingStore.list.length === 0) {
      setBookingList([])
      return
    }
    setBookingList(bookingStore.list.map((booking) => {
      return {
        id: booking.id,
        createdAt: booking.createdAt,
        customer: booking.customer,
        address: booking.address,
        channel: booking.channel,
        bookingTags: booking.bookingTags,
        driver: booking.driver,
        date: booking.date,
        slot: booking.slot,
        status: booking.status,
        invoices: booking.invoices || [],
        createdBy: booking.createdBy,
        images: booking.images,
        channelId: booking.channelId,
        code: booking.code,
        completedTime: booking.completedTime,
        customerId: booking.customerId,
        deletedAt: booking.deletedAt,
        deletedBy: booking.deletedBy,
        driverId: booking.driverId,
        log: booking.log,
        reason: booking.reason,
        remarks: booking.remarks,
        timeslotId: booking.timeslotId,
        updatedAt: booking.updatedAt,
        userId: booking.userId,
        userType: booking.userType,
        payments: booking.payments,
        hasReviewed: booking.hasReviewed,
        additionalRemarks: booking.additionalRemarks,
      }
    }))
  }, [bookingStore.list, bookingStore.rebookDialog])

  return (
    <CustomDataGrid
      rows={bookingList}
      columns={columns}
      handleRowClick={handleRowClick}
      handlePageChange={handlePageChange}
      rowCount={bookingStore.total}
      pageSize={bookingStore.rowsPerPage}
      page={bookingStore.currentPage}
      handlePageSizeChange={handlePageSizeChange}
      loading={bookingStore.loading}
      onSortModelChange={onSortModelChange}
      overrideLayout={classes.overrideLayout}
      getCellClassName={isChannel}
    />
  )
}

export default observer(BookingDataGrid)
