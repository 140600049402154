export default (theme) => ({
  parentContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  infoContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1',
    overflow: 'auto',
  },
  titleSection: {
    minHeight: '0.4rem',
    color: theme.color.lightBlack,
    background: theme.color.darkGrey,
    padding: '1.2rem 1rem',
  },
  tab: {
    textTransform: 'none',
    border: `0.5px solid ${theme.palette.secondary.main}`,
    color: theme.palette.secondary.main,
    background: theme.color.white,
  },
  tabSelected: {
    borderBottomWidth: 0,
    background: theme.palette.secondary.main,
    color: `${theme.color.white} !important`,
  },
  label: {
    fontSize: '1rem',
    fontWeight: '600',
    width: '2.2rem',
  },
  quolent: {
    fontSize: '0.8rem',
    padding: '0 0.5rem',
  },
  value: {
    fontSize: '1rem',
  },
  infoItem: {
    paddingRight: '1rem',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
})
