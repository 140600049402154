export const ENV = `${__ENV__}`.toUpperCase()
export const { ASSET_URL } = process.env

export const SENTRY_DSN = process.env.SENTRY_DSN_FRONTEND
export const { SENTRY_ENV } = process.env

export const DAYS = [
  'default',
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
]

export const days = [
  {
    day: 'monday',
    short: 'mon',
  },
  {
    day: 'tuesday',
    short: 'tue',
  },
  {
    day: 'wednesday',
    short: 'wed',
  },
  {
    day: 'thursday',
    short: 'thu',
  },
  {
    day: 'friday',
    short: 'fri',
  },
  {
    day: 'saturday',
    short: 'sat',
  },
  {
    day: 'sunday',
    short: 'sun',
  },
]

export const paymentMethod = [
  {
    id: 1,
    name: 'Cash',
  },
  {
    id: 2,
    name: 'NETS',
  },
  {
    id: 3,
    name: 'VISA / MASTER',
  },
  {
    id: 4,
    name: 'Presto Vouchers',
  },
  {
    id: 5,
    name: 'Membership Card',
  },
  {
    id: 6,
    name: 'Cheque',
  },
  {
    id: 7,
    name: 'On Credit',
  },
  {
    id: 8,
    name: 'Vouchers',
  },
  {
    id: 9,
    name: 'Cash / Vouchers',
  },
  {
    id: 10,
    name: 'NETS / Vouchers',
  },
  {
    id: 11,
    name: 'VISA/MASTER / Vouchers',
  },
  {
    id: 12,
    name: 'Cheque / Vouchers',
  },
  {
    id: 13,
    name: 'Giro',
  },
  {
    id: 14,
    name: 'Cash Refund',
  },
  {
    id: 15,
    name: 'Credits',
  },
  {
    id: 16,
    name: 'Giro Payment',
  },
  {
    id: 17,
    name: 'Discount',
  },
  {
    id: 18,
    name: 'COD',
  },
  {
    id: 19,
    name: 'PayPal',
  },
  {
    id: 20,
    name: 'PayPal Payment',
  },
  {
    id: 21,
    name: 'PayNow',
  },
  {
    id: 22,
    name: 'Cash / Credits',
  },
  {
    id: 23,
    name: 'NETS / Credits',
  },
  {
    id: 24,
    name: 'VISA/MASTER / Credits',
  },
  {
    id: 25,
    name: 'Cheque / Credits',
  },
  {
    id: 26,
    name: 'AMEX',
  },
  {
    id: 27,
    name: 'AMEX / Vouchers',
  },
  {
    id: 28,
    name: 'AMEX / Credits',
  },
  {
    id: 29,
    name: 'PayNow / Vouchers',
  },
  {
    id: 30,
    name: 'PayNow / Credits',
  },
]

export const BOOKING_STATUS = {
  SCHEDULED: 'scheduled',
  ONTHEWAY: 'on the way',
  COMPLETED: 'completed',
  FAILED: 'failed',
}

export const STATUS_COLOR = {
  [BOOKING_STATUS.SCHEDULED]: '#00A424',
  [BOOKING_STATUS.ONTHEWAY]: '#E3AE24',
  paid: '#00A424',
  unpaid: '#E4627B',
  [BOOKING_STATUS.FAILED]: '#E01205',
}

export const JOB_ROLE = {
  ADMIN: 'admin',
  COUNTER_STAFF: 'Counter Staff',
  OFFICE_USER: 'Office User',
}

export const DRIVER_TYPE = {
  HOME_PICKUP: 'homePickupDriver',
  OUTLET: 'outletDriver',
}

export const HIDDEN_CHANNEL = [
  'driver app',
  'system',
  'online web booking',
  'calendly',
  'customer app',
]

export const CUSTOMER_CREATED = [
  'Customer App',
  'Website',
]

export const REASON_BOOKING = [
  'Payment not met',
]

export const APP_PAYMENT_METHOD = {
  value: 'APP',
  display: 'Stripe',
}

export const BOOKING_LOG_DETAILS = [
  {
    label: 'Address',
    code: 'address',
  },
  {
    label: 'Channel',
    code: 'channel',
  },
  {
    label: 'Channel Id',
    code: 'channelId',
  },
  {
    label: 'Code',
    code: 'code',
  },
  {
    label: 'Completed Time',
    code: 'completedTime',
  },
  {
    label: 'Created By',
    code: 'createdBy',
  },
  {
    label: 'Customer',
    code: 'customer',
  },
  {
    label: 'Customer Id',
    code: 'customerId',
  },
  {
    label: 'Date',
    code: 'date',
  },
  {
    label: 'Date Created',
    code: 'createdAt',
  },
  {
    label: 'Deleted At',
    code: 'deletedAt',
  },
  {
    label: 'Deleted By',
    code: 'deletedBy',
  },
  {
    label: 'Driver',
    code: 'driver',
  },
  {
    label: 'Driver Id',
    code: 'driverId',
  },
  {
    label: 'Failed Reason',
    code: 'reason',
  },
  {
    label: 'Invoices',
    code: 'invoices',
  },
  {
    label: 'Invoice Ids',
    code: 'invoiceIds',
  },
  {
    label: 'Remark',
    code: 'remarks',
  },
  {
    label: 'Additional Remarks',
    code: 'additionalRemarks',
  },
  {
    label: 'Service',
    code: 'tags',
  },
  {
    label: 'Tags',
    code: 'bookingTags',
  },
  {
    label: 'Pickup Booking Tags',
    code: 'bookingTags',
  },
  {
    label: 'Minimum Spend',
    code: 'minimumSpend',
  },
  {
    label: 'Slot',
    code: 'slot',
  },
  {
    label: 'Slot Id',
    code: 'timeslotId',
  },
  {
    label: 'Status',
    code: 'status',
  },
  {
    label: 'Mark as reviewed',
    code: 'hasReviewed',
  },
]

export const TAG_CODE = {
  CURTAIN_WITH_DISMANTLING: 'Curtain',
}

export const DEFECT_LIST = [
  'Stains',
  'Pulled Threads/Scratches',
  'Chipped Buttons',
  'Weakening Fabric',
  'Balling/pilling fabric',
  'Color Bleed/ Color Run',
  'Cracked Prints',
  'Fuzzy Spandex',
  'Peeling Leather',
  'Texture Distorted',
  'Crease',
  'Chalky Marks',
  'Shining Marks',
  'Mouldy',
  'Holes',
  'Tear',
  'Opening Seam',
  'Inner lining torn',
  'Discoloration/2 Tone/Uneven color',
  'Missing Attachment/ Sequin',
  'Missing button',
  'Open Pleats',
  'Fraying',
]

export const DRIVER_HOLIDAY_DIALOG = {
  add: 'add',
  edit: 'edit',
  delete: 'delete',
}

export const BOOKING_PAYMENT_STATUS = {
  FAILED: 'failed',
  SUCCEEDED: 'succeeded',
}

export const TRANSACTION_TYPE = {
  TOPUP: 'topup',
  SPEND: 'spend',
}

export const DRIVER_HOLIDAY_TYPE = {
  HOLIDAY: 'holiday',
  BLOCKED: 'blocked_day',
}

export const BOOKING_TAG = {
  LAUNDRY: 'laundry',
  CURTAIN_WITH_DISMANTLING: 'curtain (dismantling)',
}
