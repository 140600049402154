import React from 'react'
import {
  Typography,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

import styles from './styles'

const useStyles = makeStyles(styles)

export default function ServiceIcon(props) {
  const classes = useStyles()
  return (
    <div className={classes.container}>
      <img
        src={props.iconName === 'pickup' ? (
          '/images/service-pick-up.svg') : (
          '/images/service-delivery.svg'
        )}
        alt={props.iconName}
        width="20px"
        height="20px"
      />
      <Typography
        variant={props.variant || 'h5'}
        className={props.className}
      >
        {props.data.map((tag) => tag).join(', ')}
      </Typography>
    </div>
  )
}
