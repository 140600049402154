import moment from 'moment'

export default (
  name,
  phoneNumber,
  address,
  bookingDate,
  slot,
  tagPriority,
  bookingCreatedAt,
  pickupTemplate,
) => {
  let message = ''

  if (!pickupTemplate) {
    return `
Hi there, thank you for using Presto Drycleaners.

We are pleased to inform you that the delivery of your items is scheduled on *${bookingDate}, ${slot}* to your registered address *${address}*. Please let us know if any of the information is incorrect.
If no one is at home, we will securely leave the items at your doorstep, gate, shoe rack, riser compartment, or another designated location.

At Presto, we recommend removing the plastic cover and checking your items once delivered. For any feedback about our service, please do share it with us via WhatsApp. Thank you!`
  }

  let minSpend = 30
  if (moment(bookingCreatedAt).isSameOrAfter('2021-12-01')) {
    minSpend = 50
  }

  if (tagPriority === 1) { // Garment care
    message = `
*Exclusive to PRESTO App Users: Pay with your Credit Card for your latest pick-up booking* - Download our App via https://prestodrycleaners.com.sg/app today!

*Booking Conﬁrmation*
Thank you for booking a pick-up with PRESTO! We have scheduled your pick up on *${bookingDate}, ${slot}*.

*Service Selected: Laundry/Dryclean at per piece price*
Min Spend: S$${minSpend}
Processing Speed: 6 Working Days

Please verify your details below:

1. Name: ${name}
2. Contact No: ${phoneNumber}
3. Full Address: ${address}
If your pickup location is within the CBD/Sentosa area, there is a additional $30 surcharge

*For non-app users*: Payment is required by Cash/Paynow upon pickup by our driver. Please do provide us with your payment acknowledgment via Paynow once payment is made.`
  }
  if (tagPriority === 2) { // Loadwash - Wash & Fold only
    message = `
*Exclusive to PRESTO App Users: Pay with your Credit Card for your pick-ups* - Download our App via https://prestodrycleaners.com.sg/app today!

*Booking Conﬁrmation*
Thank you for booking a pick-up with PRESTO! We have scheduled your pick up on *${bookingDate}, ${slot}*.

*Service Selected: Loadwash $9/per kg, (min 3kg per bag)*
Min Spend:S$${minSpend}
Processing Speed: 6 Working Days

Our loadwash service includes wash, dry and fold only. This is not available for drycleaning items. We will not be responsible for any missing items and any defect or color run to the items.

Please verify your details below:
1. Name: ${name}
2. Contact No: ${phoneNumber}
3. Full Address: ${address}
If your pickup location is within CBD/Sentosa, there is an additional $30 surcharge.

*For non-app users*: Payment is required by Cash/Paynow upon pickup by our driver. Please do provide us with your payment acknowledgment via Paynow once payment is made.`
  }
  if (tagPriority === 3) { // Carpet
    message = `
*Exclusive to PRESTO App Users: Pay with your Credit Card for your latest pick-up booking* - Download our App via https://prestodrycleaners.com.sg/app today!

*Booking Conﬁrmation*
Thank you for booking a pick-up with PRESTO! We have scheduled your pick up on *${bookingDate}, ${slot}*.

*Service Selected: Carpet Cleaning ($4.30/psf)*
Min Spend: S$60 per piece
Processing Speed: 4 Weeks

Please:
- Remove all furnitures placed on top of carpet as we will not be responsible for the moving or any damage to furniture/fittings.
Or
- Rolled up the carpet

Please verify your details below:
1. Name: ${name}
2. Contact No: ${phoneNumber}
3. Full Address: ${address}
If your pick up location is within the CBD/Sentosa area, there is an additional $30 surcharge.

*For non-app users*: Payment is required by Cash/Paynow upon pickup by our driver. Please do provide us with your payment acknowledgment via Paynow once payment is made.`
  }

  if (tagPriority === 4) { // Sofa (with dismantling)
    message = `
*Exclusive to PRESTO App Users: Pay with your Credit Card for your latest pick-up booking* - Download our App via https://prestodrycleaners.com.sg/app today!

*Booking Conﬁrmation*
Thank you for booking a pick-up with PRESTO! We have scheduled your pick up on *${bookingDate}, ${slot}*.

*Service Selected: Sofa Cover(With Dismantling) per piece rate*
Min Spend: S$200
Processing Speed: 6 Working Days

There is a risk of shrinkage (i.e. 3-5 Percent) and due to prolonged sun exposure weakening the covers, uneven discoloration and tears may develop on the covers after processing.

Please verify your details below:

1. Name: ${name}
2. Contact No: ${phoneNumber}
3. Full Address: ${address}
If your pick up location is within the CBD/Sentosa area, there is an additional $30 surcharge.

*For non-app users*: Payment is required by Cash/Paynow upon pickup by our driver. Please do provide us with your payment acknowledgment via Paynow once payment is made.`
  }

  if (tagPriority === 5) { // Sofa (without dismantling)
    message = `
*Exclusive to PRESTO App Users: Pay with your Credit Card for your latest pick-up booking* - Download our App via https://prestodrycleaners.com.sg/app today!

*Booking Conﬁrmation*
Thank you for booking a pick-up with PRESTO! We have scheduled your pick up on *${bookingDate}, ${slot}*.

*Service Selected: Sofa Cover (No Dismantling) per piece rate*
Min Spend: S$${minSpend}
Processing Speed: 6 Working Days

There is a risk of shrinkage (i.e. 3-5 Percent) and due to prolonged sun exposure weakening the covers, uneven discoloration and tears may develop on the covers after processing.

Please verify your details below:

1. Name: ${name}
2. Contact No: ${phoneNumber}
3. Full Address: ${address}
If your pick up location is within the CBD/Sentosa area, there is an additional $30 surcharge.

*For non-app users*: Payment is required by Cash/Paynow upon pickup by our driver. Please do provide us with your payment acknowledgment via Paynow once payment is made.`
  }

  if (tagPriority === 6) { // Curtain without Dismantling
    message = `
*Exclusive to PRESTO App Users: Pay with your Credit Card for your latest pick-up booking* - Download our App via https://prestodrycleaners.com.sg/app today!

*Booking Conﬁrmation*
Thank you for booking a pick-up with PRESTO! We have scheduled your pick up on *${bookingDate}, ${slot}*.

*Service Selected: Curtains (No Dismantling) at $14.3/kg*
Min Spend: S$${minSpend}
Processing Speed: 6 Working Days

There are risks of shrinkage (3-5 Percent) and due to prolonged sun exposure weakening the curtains, tears may develop on the curtains during or after the cleaning process. Due to such risk involved, no compensation will be rendered should your curtains be found with tears or shrinkage after processing.

Please verify your details below:

1. Name: ${name}
2. Contact No: ${phoneNumber}
3. Full Address: ${address}
If your pick up location is within the CBD/Sentosa area, there is an additional $30 surcharge.

*For non-app users*: Payment is required by Cash/Paynow upon pickup by our driver. Please do provide us with your payment acknowledgment via Paynow once payment is made.`
  }
  if (tagPriority === 7) { // Curtain With Dismantling
    message = `
*Exclusive to PRESTO App Users: Pay with your Credit Card for your latest pick-up booking* - Download our App via https://prestodrycleaners.com.sg/app today!

*Booking Conﬁrmation*
Thank you for booking a pick-up with PRESTO! We have scheduled your pick up on *${bookingDate}, ${slot}*.

*Service Selected: Curtains (with dismantle and install) at $15.3/kg*
Min Spend: S$200
Processing Speed: 6 Working Days

There are risks of shrinkage (3-5 Percent) and due to prolonged sun exposure weakening the curtains, tears may develop on the curtains during or after the cleaning process. Due to such risk involved, no compensation will be rendered should your curtains be found with tears or shrinkage after processing.

Please verify your details & read our highlighted terms for curtain cleaning.

1. Name: ${name}
2. Contact No: ${phoneNumber}
3. Full Address: ${address}
If your pick up location is within the CBD/Sentosa area, there is an additional $30 surcharge

*For non-app users*: Payment is required by Cash/Paynow upon pickup by our driver. Please do provide us with your payment acknowledgment via Paynow once payment is made.`
  }

  return message
}
