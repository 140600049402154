import { makeAutoObservable, flow } from 'mobx'

import * as srv from 'admin/services'
import handleError from 'admin/services/error-handler'

export default class BranchStore {
  loading = false

  list = []
  total = 0

  constructor(rootStore) {
    makeAutoObservable(this, {
      reload: flow,
      fetch: flow,
    })
    this.rootStore = rootStore
  }

  * reload() {
    yield this.fetch()
  }

  * fetch() {
    try {
      this.loading = true
      const response = yield srv.fetchBranch()
      if (response.ok) {
        this.list = response.data.list
        this.total = response.data.total
        return response.data
      }
      throw handleError(response)
    } catch (error) {
      return null
    } finally {
      this.loading = false
    }
  }
}
