import React, { useMemo, useEffect } from 'react'
import { observer } from 'mobx-react'
import {
  Stack,
  Typography,
} from '@mui/material'

import { useStores } from 'admin/hooks'

import CardChart from 'admin/components/dashboard-card/upper-content/chart'

import Chart from 'admin/components/chart'

const BookingChart = ({ rangeDate }) => {
  const { bookingStore } = useStores()
  const { percentByChannel } = bookingStore

  const { startDate, endDate } = rangeDate

  useEffect(() => {
    bookingStore.fetchPercentage({
      startDate,
      endDate,
      isChannel: true,
    })
  }, [rangeDate])

  const data = useMemo(() => {
    if (!percentByChannel && percentByChannel.length <= 0) return {}
    return {
      labels: percentByChannel.map(item => {
        return [item.total, item.name]
      }),
      datasets: [
        {
          label: '',
          data: percentByChannel.map(item => item.total),
          backgroundColor: [
            'rgba(255,46,0,1)',
            'rgba(255,245,0,1)',
            'rgba(255,153,0,1)',
            'rgba(252,0,227,1)',
            'rgba(2,194,255,1)',
            'rgba(136,136,136,1)',
            'rgba(136,136,136,1)',
            'rgba(136,136,136,1)',
          ],
          borderColor: [
            'rgba(255,46,0,2)',
            'rgba(255,245,0,2)',
            'rgba(255,153,0,2)',
            'rgba(252,0,227,2)',
            'rgba(2,194,255,2)',
            'rgba(136,136,136,2)',
            'rgba(136,136,136,2)',
            'rgba(136,136,136,2)',
          ],
          borderWidth: 1,
          afterLabel: percentByChannel.map(item => item.name),
        },
      ],
    }
  }, [percentByChannel])

  return (
    <CardChart
      title="Bookings Created By Channel"
    >
      {percentByChannel.length > 0 ? (
        <Chart
          data={data}
          customLegend
          customTooltip
        />
      ) : (
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          style={{ minHeight: '15rem' }}
        >
          <Typography>
            No Percentage
          </Typography>
        </Stack>
      )}
    </CardChart>
  )
}

export default observer(BookingChart)
