import React, { useEffect, useCallback } from 'react'
import { observer } from 'mobx-react'
import { useStores } from 'admin/hooks'
import CustomDataGrid from 'admin/components/data-grid'

const columns = [
  {
    field: 'name',
    headerName: 'Name',
    width: 170,
  },
  {
    field: 'username',
    headerName: 'Username',
    width: 170,
  },
  {
    field: 'email',
    headerName: 'Email',
    width: 320,
  },
  {
    field: 'phoneNumber',
    headerName: 'Phone Number',
    width: 170,
  },
  {
    field: 'outletCode',
    headerName: 'Outlet Code',
    width: 200,
    renderCell: (params) => params.row.branches.map((item) => item.code).join(),
  },
  {
    field: 'role',
    headerName: 'Role',
    width: 150,
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 120,
  },
]

const AdminDataGrid = () => {
  const { adminStore, infoSectionStore } = useStores()

  useEffect(() => {
    adminStore.fetch()
  }, [adminStore.queryParams])

  const handlePageChange = useCallback((params) => {
    adminStore.updatePage(params)
  }, [])

  const handlePageSizeChange = useCallback((param) => {
    adminStore.updateRowsPerPage(param)
  }, [])

  const handleRowClick = useCallback((rowData) => {
    infoSectionStore.select(rowData.row)
  }, [])

  const onSortModelChange = (param) => {
    if (param.length === 0) {
      adminStore.resetQuery()
    }
    if (param.length === 1) {
      adminStore.updateQuery('sortBy', param[0].field, true)
      adminStore.updateQuery('sortDirection', param[0].sort, true)
    }
    return null
  }

  return (
    <CustomDataGrid
      rows={adminStore.list}
      columns={columns}
      handleRowClick={handleRowClick}
      handlePageChange={handlePageChange}
      rowCount={adminStore.total}
      pageSize={adminStore.rowsPerPage}
      page={adminStore.currentPage}
      handlePageSizeChange={handlePageSizeChange}
      loading={adminStore.loading}
      onSortModelChange={onSortModelChange}
    />
  )
}

export default observer(AdminDataGrid)
