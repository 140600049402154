export default () => ({

  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  detailButton: {
    margin: '0.5rem',
  },
})
