import React from 'react'
import { observer } from 'mobx-react'
import { useStores } from 'admin/hooks'
import InformationTab from 'admin/components/information-tab'
import {
  formatDate,
  slotDisplay,
  formatDateHourAmPm,
} from 'admin/utils/helper'
import { APP_PAYMENT_METHOD } from 'admin/config'

const InvoicePaymentInfo = () => {
  const { infoSectionStore } = useStores()

  const { selected: payment } = infoSectionStore
  const { invoice } = payment

  return payment && (
    <InformationTab
      data={[
        {
          label: 'Name',
          value: invoice?.customer?.originalData?.name || invoice?.customer?.name,
        },
        {
          label: 'Phone',
          value: invoice.customer?.originalData?.mobileNo || invoice.customer?.phoneNumber,
        },
        {
          label: 'Address',
          value: invoice.booking?.address || invoice.customer?.address,
        },
        {
          label: 'Invoice Date',
          value: formatDate(invoice.originalResponse?.droppedOffTime),
        },
        {
          label: 'Invoice Number',
          value: invoice?.prestoId,
        },
        {
          label: 'Date Updated',
          value: formatDateHourAmPm(payment.updatedAt),
        },
        {
          label: 'Driver',
          value: invoice.booking?.driver ? `${invoice.booking.driver?.code} - ${invoice.booking.driver?.name}` : '-',
        },
        {
          label: 'Booking Date',
          value: invoice.booking?.date ? formatDate(invoice.booking.date) : '-',
        },
        {
          label: 'Slot',
          value: invoice.booking?.slot ? slotDisplay(invoice.booking.slot?.start, invoice?.booking.slot?.end) : '-',
        },
        {
          label: 'Booking channel',
          value: invoice.booking?.channel?.name || '-',
        },
        {
          label: 'Payment Receive Date',
          value: payment.paymentTime ? formatDate(payment.paymentTime) : '-',
        },
        {
          label: 'Payment Method',
          value: (invoice.paymentMethod === APP_PAYMENT_METHOD.value ? APP_PAYMENT_METHOD.display : invoice.paymentMethod) || '-',
        },
        {
          label: 'Total Quantity',
          value: `${invoice.items?.length} + ${invoice.accesoriesCount || 0}`,
        },
        {
          label: 'Sub Total',
          value: `$${Number(invoice.amount).toFixed(2) || Number(invoice.originalResponse?.revenue).toFixed(2) || 0}`,
        },
      ]}
    />
  )
}

export default observer(InvoicePaymentInfo)
