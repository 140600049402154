import React from 'react'
import {
  Box,
} from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import { formatDate } from 'admin/utils/helper'

export default function BasicDatePicker(props) {
  return (
    <DatePicker
      label="Custom input"
      value={props.value}
      onChange={props.onChange}
      renderInput={({ inputRef, inputProps, InputProps }) => (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {InputProps?.endAdornment}
          <div
            ref={inputRef}
            {...inputProps}
            style={{ fontSize: 'medium', paddingLeft: '1rem' }}
          >
            {formatDate(props.value)}
          </div>
        </Box>
      )}
    />
  )
}
