import React, { useState, useCallback, useEffect } from 'react'
import { observer } from 'mobx-react'
import {
  Divider,
  Typography,
  Switch,
  Button,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

import { useStores, useApiRequest } from 'admin/hooks'
import * as srv from 'admin/services'
import { days } from 'admin/config'

import styles from './styles'

const useStyles = makeStyles(styles)

const WorkingDays = () => {
  const classes = useStyles()

  const {
    driverStore,
    infoSectionStore,
    notificationStore,
  } = useStores()

  const [isUpdate, setIsUpdate] = useState(false)

  const [workDays, setWorkDays] = useState({ ...driverStore.workDays })

  const {
    request: addDriverWorkDays,
    isLoading: addProcessing,
  } = useApiRequest(srv.addDriverWorkDays, { blocking: true })

  const {
    request: updateDriverWorkDays,
    isLoading: updateProcessing,
  } = useApiRequest(srv.updateDriverWorkDays, { blocking: true })

  const handleChange = useCallback((event) => {
    setWorkDays({
      ...workDays,
      [event.target.name]: event.target.checked,
    })
  }, [workDays])

  const handleSubmit = useCallback(async (event) => {
    event.preventDefault()
    if (addProcessing || updateProcessing) return

    const request = isUpdate ? updateDriverWorkDays : addDriverWorkDays
    const response = await request(infoSectionStore.selected, workDays)
    if (!response) return

    if (isUpdate) {
      notificationStore.setSuccess('Driver Working Days has been updated successfully')
    } else {
      notificationStore.setSuccess('Driver Working Days has been created successfully')
    }
    driverStore.reload(true)
  }, [infoSectionStore.selected, workDays])

  useEffect(() => {
    const { workday } = infoSectionStore.selected

    if (workday) {
      setWorkDays({ ...workday })

      setIsUpdate(true)
    }
  }, [infoSectionStore.selected])

  return (
    <form
      noValidate
      onSubmit={handleSubmit}
      autoComplete="off"
    >
      {
        days.map((d) => (
          <div key={d.day}>
            <div className={classes.list}>
              <Typography
                variant="body2"
                className={classes.days}
              >
                {d.day}
              </Typography>
              <Switch
                checked={workDays[d.short]}
                onChange={handleChange}
                name={d.short}
                color="primary"
                inputProps={{ 'aria-label': `${d.day} Checkbox` }}
              />
            </div>
            <Divider />
          </div>
        ))
      }
      <div className={classes.btnWrapper}>
        <Button
          type="submit"
          variant="contained"
          color="secondary"
          size="medium"
          disabled={addProcessing || updateProcessing}
        >
          Save
        </Button>
      </div>
    </form>
  )
}

export default observer(WorkingDays)
