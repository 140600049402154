import { startOfDay, endOfDay, addDays, subDays } from 'date-fns'
import { makeAutoObservable, flow } from 'mobx'
import moment from 'moment'

import { TODAY } from 'admin/utils/helper'
import * as srv from 'admin/services'
import handleError from 'admin/services/error-handler'

export default class InvoicePaymentStore {
  loading = false
  processing = false
  dialog = {
    form: false,
    filter: false,
    export: false,
    updatePayment: false,
    loading: false,
    delete: false,
  }
  selected = null
  list = []
  total = 0
  query = {
    paymentRef: '',
    paymentStatus: '',
    invoiceNumber: '',
    columnIndex: -1,
    sortBy: 'updatedAt',
    sortDirection: 'desc',
  }
  currentPage = 0
  rowsPerPage = 10
  startDate = startOfDay(subDays(TODAY(), 7))
  endDate = endOfDay(addDays(TODAY(), 7))
  fileName = ''
  DEFAULT = {
    invoiceId: '',
    paymentTime: moment(),
    paymentChannel: '',
    amount: 0,
    referenceNumber: '',
  }

  constructor(rootStore) {
    makeAutoObservable(this, {
      reload: flow,
      fetch: flow,
      checkSmsReminder: flow,
    })
    this.rootStore = rootStore
  }

  select = (id) => {
    this.selected = this.list.find((item) => item.id === id)
  }

  setSelected = (value) => {
    this.selected = value
  }

  resetSelected = () => {
    this.selected = { ...this.DEFAULT }
  }

  updatePage = (page) => {
    this.currentPage = page
  }

  setFileName = (fileName) => {
    this.fileName = fileName
  }

  updateRowsPerPage = (perPage) => {
    this.rowsPerPage = perPage
  }

  updateStartDate = (value) => {
    this.startDate = value
  }

  updateEndDate = (value) => {
    this.endDate = value
  }

  updateQuery = (key, value, resetPage = false) => {
    this.query[key] = value
    if (resetPage) this.currentPage = 0
  }

  setOpenDialog = (selected, type) => {
    this.dialog[type] = true
    if (selected) {
      this.selected = { ...this.DEFAULT, ...selected }
    } else {
      this.selected = { ...this.DEFAULT }
    }
  }

  setCloseDialog = (type, reset = true) => {
    this.dialog[type] = false
    if (reset) setTimeout(this.resetSelected, 50)
  }

  // open or close dialog without changing this.selected value
  setDialog = (key, value) => {
    this.dialog[key] = value
  }

  get filterLength() {
    const object = {
      paymentRef: this.query.paymentRef,
      paymentStatus: this.query.paymentStatus,
      driverId: this.query.driverId,
      invoiceNumber: this.query.invoiceNumber,
    }
    const objArr = Object.values(object)
    const filterArr = objArr.filter((item) => item)
    return filterArr.length
  }

  resetQuery = (sDate, eDate) => {
    this.query = {
      paymentRef: '',
      paymentStatus: '',
      columnIndex: -1,
      sortBy: 'id',
      sortDirection: 'desc',
    }
    this.currentPage = 0
    this.rowsPerPage = 10
    this.startDate = sDate || startOfDay(subDays(TODAY(), 7))
    this.endDate = eDate || endOfDay(addDays(TODAY(), 7))
  }

  get queryParams() {
    const sDate = moment(this.startDate).startOf('day').toISOString()
    const eDate = moment(this.endDate).endOf('day').toISOString()
    const params = {
      sortBy: this.query.sortBy,
      sortDirection: this.query.sortDirection,
      limit: this.rowsPerPage,
      offset: this.currentPage * this.rowsPerPage,
      startDate: sDate,
      endDate: eDate,
    }
    if (this.query.paymentRef) params.paymentRef = this.query.paymentRef
    if (this.query.paymentStatus) params.paymentStatus = this.query.paymentStatus
    if (this.query.driverId) params.driverId = this.query.driverId
    if (this.query.invoiceNumber) params.invoiceNumber = this.query.invoiceNumber
    return params
  }

  * reload(reset = false) {
    yield this.fetch()
    if (reset) {
      this.selected = { ...this.DEFAULT }
      this.rootStore.infoSectionStore.reset()
    } else if (this.rootStore.infoSectionStore.selected?.id) {
      // reselect after fetch
      this.select(this.rootStore.infoSectionStore.selected.id)

      // update the selected object in infoSectionStore
      this.rootStore.infoSectionStore.replace(this.selected)
    }
  }

  * fetch(params) {
    try {
      this.loading = true
      const response = yield srv.fetchInvoicePayment({
        ...this.queryParams,
        ...params,
      })
      if (response.ok) {
        this.list = response.data.list
        this.total = response.data.total
        return response.data
      }
      throw handleError(response)
    } catch (error) {
      return null
    } finally {
      this.loading = false
    }
  }

  * checkSmsReminder(params) {
    if (this.processing) return null
    this.processing = true
    const { isChecked } = params
    try {
      const response = yield srv.setSMSCache({ isChecked })
      if (response.ok) {
        return response.ok
      }
      throw handleError(response)
    } finally {
      this.processing = false
    }
  }
}
