import React, { Suspense } from 'react'
import { observer } from 'mobx-react'
import moment from 'moment'

import Page from 'admin/components/page'
import BasicDatePicker from 'admin/components/date-picker'

import { useStores } from 'admin/hooks'
import InvoicePaymentInfo from './info'
import SmsLogDataGrid from './datagrid'

const SmsLog = () => {
  const { actionLogStore } = useStores()

  const handleChange = (newDateValue) => {
    actionLogStore.setDate(moment(newDateValue).startOf('day').format())
  }

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Page>
        <div style={{ marginBottom: 10 }}>
          <BasicDatePicker
            value={actionLogStore.date}
            onChange={handleChange}
          />
        </div>
        <InvoicePaymentInfo />
        <SmsLogDataGrid />
      </Page>
    </Suspense>
  )
}

export default observer(SmsLog)
