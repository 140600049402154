import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { Typography } from '@mui/material'
import styles from './styles'

const useStyles = makeStyles(styles)

const StatusText = ({ status, variant }) => {
  const classes = useStyles({ status })

  return (
    <Typography
      variant={variant}
      className={classes.status}
    >
      {status}
    </Typography>
  )
}

export default StatusText
