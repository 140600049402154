import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react'
import { computed } from 'mobx'
import {
  Button,
  FormControl,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

import { useStores } from 'admin/hooks'
import { HIDDEN_CHANNEL } from 'admin/config'
import { formatDate, slotDisplay, getTags } from 'admin/utils/helper'

import InvoiceAndIcon from 'admin/components/invoice-icon'

import styles from './styles'

const useStyles = makeStyles(styles)

const isChannelHidden = (lowerCaseName) => {
  return HIDDEN_CHANNEL.some((item) => item === lowerCaseName)
}

function filterChannel(value) {
  return !isChannelHidden(value.name.toLowerCase())
}

const Channel = observer(() => {
  const classes = useStyles()
  const { channelStore, bookingStore } = useStores()
  const { selected } = bookingStore
  const [channel, setChannel] = useState(selected.channelId)

  const handleChange = (event) => {
    setChannel(event.target.value)
    bookingStore.updateSelected(event.target.name, event.target.value)

    const selectedChannelData = channelStore.list
      .find(channelList => channelList.id === event.target.value)
    bookingStore.updateSelected('channel', selectedChannelData)
  }

  useEffect(() => {
    channelStore.fetch()
  }, [])

  if (
    !bookingStore.newBooking &&
    isChannelHidden(selected?.channel?.name.toLowerCase())
  ) {
    return (
      <div className={classes.selectedChannel}>
        <Typography variant="h4">
          Channel
        </Typography>
        <Typography variant="h4">
          {selected?.channel?.name}
        </Typography>
      </div>
    )
  }
  if (channelStore.list.length > 0) {
    return (
      <FormControl
        className={classes.formControl}
        margin="normal"
      >
        <TextField
          fullWidth
          name="channelId"
          label="Channel"
          value={channel}
          onChange={handleChange}
          select
          InputLabelProps={{ shrink: true }}
          required
        >
          {channelStore.list.filter(filterChannel).map((list) => {
            return (
              <MenuItem key={list.id} value={list.id}>
                {list.name}
              </MenuItem>
            )
          })}
        </TextField>
      </FormControl>
    )
  }
  return (
    <Typography variant="caption">
      Please set channel first at setting page
    </Typography>
  )
})

const Invoices = ({ invoices }) => {
  if (!invoices || invoices.length === 0) return ''
  return invoices.map((invoice) => (
    <InvoiceAndIcon
      key={invoice.prestoId}
      invoice={invoice}
    />
  ))
}

const Summary = observer(() => {
  const classes = useStyles()
  const {
    bookingStore,
  } = useStores()
  const { selected } = bookingStore
  const [remarks, setRemarks] = useState(selected.remarks)

  const handleChange = (event) => {
    setRemarks(event.target.value)
    bookingStore.updateSelected(event.target.name, event.target.value)
  }

  const dataSummary = computed(() => {
    if (selected) {
      const invoices = Array.isArray(selected.invoices) && selected.invoices?.length > 0 ? (
        selected.invoices) : ''
      const slot = slotDisplay(selected.slot?.start, selected.slot?.end)

      const {
        pickup,
        delivery,
      } = getTags(selected?.bookingTags)

      const pickupService = pickup.map((tag) => tag).join(', ')
      const deliveryService = delivery.map((tag) => tag).join(', ')

      return ([
        {
          label: 'Customer',
          activeStep: 0,
          info: [
            {
              sublabel: 'Name',
              value: selected.customer?.name,
            },
            {
              sublabel: 'Phone',
              value: selected.customer?.phoneNumber,
            },
            {
              sublabel: 'Alternate Phone',
              value: '-',
            },
            {
              sublabel: 'Booking Address',
              value: selected.address,
            },
          ],
        },
        {
          label: 'Service',
          activeStep: 1,
          info: [
            {
              sublabel: 'Pick-up',
              icon: 'pickup',
              value: pickupService,
            },
            {
              sublabel: 'Delivery',
              icon: 'delivery',
              value: deliveryService,
            },
            {
              sublabel: 'Invoice',
              value: invoices,
            },
          ],
        },
        {
          label: 'Schedule',
          activeStep: 2,
          info: [
            {
              sublabel: 'Driver',
              value: selected.driver?.name,
            },
            {
              sublabel: 'Date',
              value: formatDate(selected.date),
            },
            {
              sublabel: 'Timeslot',
              value: slot,
            },
          ],
        },
      ])
    }
    return null
  }).get()

  return (
    <>
      <Typography variant="h3">Summary</Typography>
      <div className={classes.container}>
        {selected && dataSummary.map((data) => (
          <div
            className={classes.flex}
            key={data.activeStep}
            style={{ marginBottom: '2rem' }}
          >
            <div className={classes.mainLabel}>
              <Typography variant="h4">
                {data.label}
              </Typography>
              <Button
                component="button"
                variant="body2"
                onClick={() => {
                  if (data.activeStep === 0) {
                    bookingStore.setOpenWarningDialog(true)
                  } else {
                    bookingStore.updateActiveStep(data.activeStep)
                  }
                }}
                className={classes.link}
              >
                Edit
              </Button>
            </div>
            <div className={classes.flex}>
              {data.info.map((info) => (
                <div className={classes.subInfo} key={info.sublabel}>
                  <div className={classes.flex}>
                    {info.icon && (
                      <img
                        src={info.icon === 'pickup' ? (
                          '/images/service-pick-up.svg') : (
                          '/images/service-delivery.svg'
                        )}
                        alt={info.icon}
                        width="20px"
                        height="20px"
                        className={classes.icon}
                      />
                    )}
                    <Typography variant="h5">
                      {info.sublabel}
                    </Typography>
                  </div>
                  {info.sublabel === 'Invoice' ? (
                    <Invoices
                      invoices={info.value}
                    />
                  ) : (
                    <Typography variant="body2">
                      {info.value}
                    </Typography>
                  )}
                </div>
              ))}
            </div>
          </div>
        ))}
        <FormControl
          className={classes.formControl}
          margin="normal"
        >
          <TextField
            label="Booking Remarks"
            name="remarks"
            InputLabelProps={{ shrink: true }}
            fullWidth
            value={remarks}
            onChange={handleChange}
          />
        </FormControl>
        <Channel />
      </div>
    </>
  )
})

export default Summary
