import React, { useCallback } from 'react'
import { observer } from 'mobx-react'
import { useStores, useApiRequest } from 'admin/hooks'
import * as srv from 'admin/services'
import {
  Typography,
} from '@mui/material'
import CustomDialog from 'admin/components/dialog'

const DeleteDialog = () => {
  const { channelStore, notificationStore } = useStores()
  const {
    request: deleteChannel,
    isLoading: deleteProcessing,
  } = useApiRequest(srv.deleteChannel, { blocking: true })

  const onDelete = useCallback(async () => {
    if (deleteProcessing) return
    await deleteChannel(channelStore.deleteDialog)
    notificationStore.setSuccess('Delete channel success')
    channelStore.setDeletedDialog(null)
    channelStore.reload(true)
  }, [])

  return (
    <CustomDialog
      open={channelStore.deleteDialog}
      size="sm"
      title="Delete Channel"
      primaryActionLabel="Delete"
      content={(
        <Typography variant="body2">
          Please note that removing channel will also remove the channel info from existing bookings. The effect is not reversible.
        </Typography>
      )}
      loading={deleteProcessing}
      onAccept={onDelete}
      onClose={() => channelStore.setDeletedDialog(null)}
    />
  )
}

export default observer(DeleteDialog)
