import React from 'react'
import clsx from 'clsx'

import makeStyles from '@mui/styles/makeStyles'
import { Button } from '@mui/material'

import { TODAY_MOMENT } from 'admin/utils/helper'

import styles from './styles'

const useStyles = makeStyles(styles)

const ButtonJumpToToday = ({
  setDate,
  style,
}) => {
  const classes = useStyles()

  return (
    <Button
      variant="outlined"
      className={clsx(classes.jumpToToday, style)}
      onClick={() => {
        setDate(TODAY_MOMENT.format())
      }}
    >
      Jump to Today
    </Button>
  )
}

export default ButtonJumpToToday
