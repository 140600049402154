import { makeAutoObservable, flow } from 'mobx'
import moment from 'moment'

import { lastSunday, lastSaturday } from 'admin/utils/helper'
import * as srv from 'admin/services'
import handleError from 'admin/services/error-handler'

const QUERY = {
  sortBy: 'createdAt',
  sortDirection: 'desc',
  barcode: '',
  customerId: '',
  status: '',
  prestoId: '',
  adminId: '',
  defect1: undefined,
  defect2: undefined,
}

const DEFAULT = {
  name: '',
  url: '',
}

const ACTIVE_TAB = {
  main: 0, // value between 0 and 1
  sub: 0, // sub tab (analytics & listing)
}

export default class InvoiceItemStore {
  loading = false
  processing = false
  dialog = false
  defectImageDialog = false
  selectedDefectImageUrl = null
  selectedDefect = DEFAULT
  selectedAdmin = null
  list = []
  total = 0
  query = QUERY
  // last sunday-saturday
  startDate = lastSunday
  endDate = lastSaturday

  selected = null
  currentPage = 0
  rowsPerPage = 10

  activeTab = ACTIVE_TAB

  constructor(rootStore) {
    makeAutoObservable(this, {
      reload: flow,
      fetch: flow,
    })
    this.rootStore = rootStore
  }

  setActiveTab = (key, value) => {
    this.activeTab[key] = value
  }

  setOpenFilterDialog = (value) => {
    this.dialog = value
  }

  openDefectImageDialog = (selected, value) => {
    this.defectImageDialog = value
    if (selected) {
      this.selectedDefect = { ...DEFAULT, ...selected }
    } else {
      this.selectedDefect = { ...DEFAULT }
    }
  }

  setSelectedAdmin = (value) => {
    this.selectedAdmin = value
  }

  updateStartDate = (value) => {
    this.startDate = value
  }

  updateEndDate = (value) => {
    this.endDate = value
  }

  select = (id) => {
    this.selected = this.list.find((item) => item.id === id)
  }

  updatePage = (page) => {
    this.currentPage = page
  }

  updateRowsPerPage = (perPage) => {
    this.rowsPerPage = perPage
  }

  updateQuery = (key, value, resetPage = false) => {
    this.query[key] = value
    if (resetPage) this.currentPage = 0
  }

  resetQuery = (stDate, enDate) => {
    this.query = QUERY
    this.currentPage = 0
    this.rowsPerPage = 10
    this.startDate = stDate || lastSunday
    this.endDate = enDate || lastSaturday
    this.selectedAdmin = null
  }

  resetActiveTab = () => {
    this.activeTab = ACTIVE_TAB
  }

  get queryParams() {
    const sDate = moment(this.startDate).startOf('day').toISOString()
    const eDate = moment(this.endDate).endOf('day').toISOString()
    const params = {
      sortBy: this.query.sortBy,
      sortDirection: this.query.sortDirection,
      limit: this.rowsPerPage,
      offset: this.currentPage * this.rowsPerPage,
      startDate: sDate,
      endDate: eDate,
    }
    if (this.query.customerId) params.customerId = this.query.customerId
    if (this.query.status) params.status = this.query.status
    if (this.query.barcode) params.barcode = this.query.barcode
    if (this.query.prestoId) params.prestoId = this.query.prestoId
    if (this.query.adminId) params.adminId = this.query.adminId
    if (this.query.defect1) params.defect1 = this.query.defect1
    if (this.query.defect2) params.defect2 = this.query.defect2
    if (this.query.startCreatedAt) params.startCreatedAt = this.query.startCreatedAt
    if (this.query.endCreatedAt) params.endCreatedAt = this.query.endCreatedAt
    return params
  }

  * reload(reset = false) {
    yield this.fetch()
    if (reset) {
      this.rootStore.infoSectionStore.reset()
    } else if (this.rootStore.infoSectionStore.selected?.id) {
      // reselect after fetch
      this.select(this.rootStore.infoSectionStore.selected.id)

      // update the selected object in infoSectionStore
      this.rootStore.infoSectionStore.replace(this.selected)
    }
  }

  * fetch(params) {
    try {
      this.loading = true
      const response = yield srv.fetchInvoiceItem({
        ...this.queryParams,
        ...params,
      })
      if (response.ok) {
        this.list = response.data.list
        this.total = response.data.total
        return response.data
      }
      throw handleError(response)
    } catch (error) {
      return null
    } finally {
      this.loading = false
    }
  }
}
