export const phoneRegex = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/

export const nameRegex = /^(?![\s.]+$)[a-zA-Z\s.]*$/

export const regexCodeArea = /\d{6}/

export const regeUnitNumber = /[0-9]{2,5}-[0-9]{2,5}/

export const unitNumberCodeRegexGlobal = /#|[0-9]{2,5}-[0-9]{2,5}/g

export const hexColorRegex = /^#(?:[0-9a-fA-F]{3}){1,2}$/
