export default class ManualChargeStore {
  DEFAULT = {
    phoneNumber: null,
    invoiceId: '',
    amount: 0,
    description: '',
    stripeChargeId: '',
  }

  constructor(rootStore) {
    this.rootStore = rootStore
  }
}
