export default (theme) => ({
  datePickerStartEnd: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  capitalize: {
    display: 'block',
    textTransform: 'capitalize',
    fontWeight: 'bold',
  },
  paidStatus: {
    fontWeight: 'bold',
    color: theme.color.primary,
  },
  unpaidStatus: {
    fontWeight: 'bold',
    color: theme.color.redBerry,
  },
  textfield: {
    marginBottom: '0.5rem',
  },
  autocomplete: {
    marginBottom: '0.25rem',
  },
  filterWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
  },
  searchField: {
    display: 'flex',
    flexDirection: 'row',
    margin: '0.5rem 0.2rem 0.5rem',
  },
  filterNumber: {
    color: theme.color.white,
    backgroundColor: theme.color.paarl,
    borderRadius: '50px',
    padding: '0 9px',
    marginRight: '5px',
  },
  syncButton: {
    margin: '0 0 0 auto',
    alignSelf: 'center',
  },
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: 'none',
  },
  uploadButton: {
    margin: '0 1rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  remark: {
    fontSize: '0.8rem',
  },
  tab: {
    textTransform: 'none',
    color: theme.palette.secondary.main,
    fontWeight: 600,
  },
  dateRange: {
    marginBottom: '0.75rem',
  },
  divider: {
    color: theme.color.brown,
  },
  indicator: {
    width: '160px !important',
  },
})
