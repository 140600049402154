import React, { useState, useCallback } from 'react'
import { observer } from 'mobx-react'
import makeStyles from '@mui/styles/makeStyles'
import {
  FormControl,
  Button,
  Container,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
} from '@mui/material'

import {
  Visibility,
  VisibilityOff,
} from '@mui/icons-material'

import { useStores, useApiRequest } from 'admin/hooks'
import * as srv from 'admin/services'

import Protected from 'admin/components/protected'
import TextField from 'admin/components/textfield'

import styles from './styles'

const useStyles = makeStyles(styles)

const UpdatePassword = observer(() => {
  const classes = useStyles()
  const { infoSectionStore, notificationStore } = useStores()
  const [password, setPassword] = useState('')
  const [showPassword, setShowPassword] = useState(false)

  const {
    request: updateAdminPassword,
    isLoading: updateProcessing,
  } = useApiRequest(srv.updateAdminPassword, { blocking: true })

  const handleChangePassword = useCallback((event) => {
    setPassword(event.target.value)
  }, [])

  const handleShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const handleMouseDownPassword = (event) => {
    event.preventDefault()
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    await updateAdminPassword(infoSectionStore.selected, password)
    notificationStore.setSuccess('Admin password has been updated successfully')
  }

  return (
    <Container>
      <form
        noValidate
        onSubmit={handleSubmit}
        autoComplete="off"
      >
        <FormControl
          fullWidth
          margin="normal"
          variant="outlined"
        >
          <InputLabel htmlFor="input-password">
            Update Password
          </InputLabel>
          <OutlinedInput
            label="input-password"
            type={showPassword ? 'text' : 'password'}
            value={password}
            onChange={handleChangePassword}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                helperText={null}
              />
            )}
            required
            labelWidth={144}
            endAdornment={(
              <InputAdornment position="end">
                <IconButton
                  onClick={handleShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                  size="large"
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            )}
          />
        </FormControl>
        <div className={classes.button}>
          <Protected
            level="update"
            category="admin"
          >
            <Button
              variant="contained"
              color="secondary"
              type="submit"
              disabled={updateProcessing}
            >
              Save
            </Button>
          </Protected>
        </div>
      </form>
    </Container>
  )
})

export default UpdatePassword
