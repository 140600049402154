import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { useLocation, useHistory } from 'react-router-dom'
import {
  Drawer,
  List,
  ListItem,
  ListItemText,
  Icon,
  useMediaQuery,
  Toolbar,
  Typography,
} from '@mui/material'

import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import ExitToAppIcon from '@mui/icons-material/ExitToApp'

import { useStores } from 'admin/hooks'
import Protected from 'admin/components/protected'

import styles from './styles'

const useStyles = makeStyles(styles)

const Menu = () => {
  const location = useLocation()
  const route = useHistory()
  const { infoSectionStore, adminStore } = useStores()
  const classes = useStyles()
  const {
    isCounterStaff,
    adminPageAccess,
  } = adminStore

  const isActive = (path) => (
    (location.pathname.length === 1 && path.length === 1) ||
    (location.pathname.indexOf(path) >= 0 && path.length !== 1)
  )

  const routing = (link) => {
    route.push(link)
    infoSectionStore.reset()
  }

  if (isCounterStaff) {
    return (
      <List disablePadding>
        <ListItem
          button
          selected={isActive('/booking-list')}
          classes={{
            root: classes.listItem,
            selected: classes.selected,
          }}
          onClick={() => routing('/booking-list')}
        >
          <Icon
            classes={{
              root: classes.icon,
            }}
          >schedule
          </Icon>
          <ListItemText
            classes={{ root: classes.listItemText }}
            primary="Booking"
          />
        </ListItem>
      </List>
    )
  }

  return (
    <List
      className={classes.listContainer}
      disablePadding
    >
      <ListItem
        button
        selected={isActive('/')}
        classes={{
          root: classes.listItem,
          selected: classes.selected,
        }}
        onClick={() => routing('/')}
      >
        <Icon
          classes={{
            root: classes.icon,
          }}
        >home
        </Icon>
        <ListItemText
          classes={{ root: classes.listItemText }}
          primary="Dashboard"
        />
      </ListItem>
      <Protected
        level="read"
        category="driver"
        silent
      >
        <ListItem
          button
          selected={isActive('/driver')}
          classes={{
            root: classes.listItem,
            selected: classes.selected,
          }}
          onClick={() => routing('/driver')}
        >
          <Icon
            classes={{
              root: classes.icon,
            }}
          >drive_eta
          </Icon>
          <ListItemText
            classes={{ root: classes.listItemText }}
            primary="Driver"
          />
        </ListItem>
      </Protected>
      <Protected
        level="read"
        category="booking"
        silent
      >
        <ListItem
          button
          selected={isActive('/booking-list')}
          classes={{
            root: classes.listItem,
            selected: classes.selected,
          }}
          onClick={() => routing('/booking-list')}
        >
          <Icon
            classes={{
              root: classes.icon,
            }}
          >schedule
          </Icon>
          <ListItemText
            classes={{ root: classes.listItemText }}
            primary="Booking"
          />
        </ListItem>
      </Protected>
      <Protected
        level="read"
        category="customer"
        silent
      >
        <ListItem
          button
          selected={isActive('/customer')}
          classes={{
            root: classes.listItem,
            selected: classes.selected,
          }}
          onClick={() => routing('/customer')}
        >
          <Icon
            classes={{
              root: classes.icon,
            }}
          >face
          </Icon>
          <ListItemText
            classes={{ root: classes.listItemText }}
            primary="Customers"
          />
        </ListItem>
      </Protected>
      {adminPageAccess && (
        <Protected
          level="read"
          category="admin"
          silent
        >
          <ListItem
            button
            selected={isActive('/admin')}
            classes={{
              root: classes.listItem,
              selected: classes.selected,
            }}
            onClick={() => routing('/admin')}
          >
            <Icon
              classes={{
                root: classes.icon,
              }}
            >person
            </Icon>
            <ListItemText
              classes={{ root: classes.listItemText }}
              primary="Admin"
            />
          </ListItem>
        </Protected>
      )}
      <Protected
        level="read"
        category="invoice"
        silent
      >
        <ListItem
          button
          selected={isActive('/invoice')}
          classes={{
            root: classes.listItem,
            selected: classes.selected,
          }}
          onClick={() => routing('/invoice')}
        >
          <Icon
            classes={{
              root: classes.icon,
            }}
          >receipt
          </Icon>
          <ListItemText
            classes={{ root: classes.listItemText }}
            primary="Invoice"
          />
        </ListItem>
      </Protected>
      <Protected
        level="read"
        category="payment"
        silent
      >
        <ListItem
          button
          selected={isActive('/payment')}
          classes={{
            root: classes.listItem,
            selected: classes.selected,
          }}
          onClick={() => routing('/payment')}
        >
          <Icon
            classes={{
              root: classes.icon,
            }}
          >local_atm
          </Icon>
          <ListItemText
            classes={{ root: classes.listItemText }}
            primary="Payment"
          />
        </ListItem>
      </Protected>
      <Protected
        level="read"
        category="charge"
        silent
      >
        <ListItem
          button
          selected={isActive('/app-charge')}
          classes={{
            root: classes.listItem,
            selected: classes.selected,
          }}
          onClick={() => routing('/app-charge')}
        >
          <Icon
            classes={{
              root: classes.icon,
            }}
          >credit_card
          </Icon>
          <ListItemText
            classes={{ root: classes.listItemText }}
            primary="App Charge"
          />
        </ListItem>
      </Protected>
      <Protected
        level="read"
        category="wallet"
        silent
      >
        <ListItem
          button
          selected={isActive('/wallet')}
          classes={{
            root: classes.listItem,
            selected: classes.selected,
          }}
          onClick={() => routing('/wallet')}
        >
          <Icon
            classes={{
              root: classes.icon,
            }}
          >account_balance_wallet
          </Icon>
          <ListItemText
            classes={{ root: classes.listItemText }}
            primary="Wallet"
          />
        </ListItem>
      </Protected>
      <Protected
        level="read"
        category="defect"
        silent
      >
        <ListItem
          button
          selected={isActive('/defect')}
          classes={{
            root: classes.listItem,
            selected: classes.selected,
          }}
          onClick={() => routing('/defect')}
        >
          <div className={classes.icon}>
            <img
              src={isActive('/defect') ?
                '/images/defect_menu_active.png' :
                '/images/defect_menu.png'}
              alt="icon"
              width="24px"
              height="24px"
            />
          </div>
          <ListItemText
            classes={{ root: classes.listItemText }}
            primary="Defect"
          />
        </ListItem>
      </Protected>
      <Protected
        level="read"
        category="pos"
        silent
      >
        <ListItem
          button
          selected={isActive('/pos')}
          classes={{
            root: classes.listItem,
            selected: classes.selected,
          }}
          onClick={() => routing('/pos')}
        >
          <Icon
            classes={{
              root: classes.icon,
            }}
          >point_of_sale
          </Icon>
          <ListItemText
            classes={{ root: classes.listItemText }}
            primary="POS"
          />
        </ListItem>
      </Protected>
      <Protected
        level="read"
        category="setting"
        silent
      >
        <ListItem
          button
          selected={isActive('/setting')}
          classes={{
            root: classes.listItem,
            selected: classes.selected,
          }}
          onClick={() => routing('/setting')}
        >
          <Icon
            classes={{
              root: classes.icon,
            }}
          >settings
          </Icon>
          <ListItemText
            classes={{ root: classes.listItemText }}
            primary="Setting"
          />
        </ListItem>
      </Protected>
    </List>
  )
}

const UserAccount = () => {
  const classes = useStyles()
  const route = useHistory()
  const { adminStore } = useStores()
  const session = adminStore.admin
  const isMd = useMediaQuery(theme => theme.breakpoints.down('lg'))

  const handleClickProfile = () => {
    if (
      adminStore.isAdmin ||
      adminStore.isOfficeUser
    ) route.push('/profile')
  }

  return (
    <Toolbar
      disableGutters
      className={classes.toolbar}
      onClick={handleClickProfile}
    >
      <div className={classes.iconDrawer}>
        <AccountCircleIcon className={classes.accountIcon} />
        {!isMd && (
          <div className={classes.sessionInfo}>
            <Typography variant="h3">
              {session.name}
            </Typography>
            {session.role === 'user' && (
              <Typography variant="body2">
                {session.jobRole.name}
              </Typography>
            )}
          </div>
        )}
      </div>
    </Toolbar>
  )
}

const SignOut = () => {
  const classes = useStyles()
  const { adminStore } = useStores()

  const doLogout = async () => {
    await adminStore.logout()
  }

  return (
    <List disablePadding className={classes.logoutDrawer}>
      <ListItem
        button
        classes={{
          root: classes.listItem,
          selected: classes.selected,
        }}
        onClick={doLogout}
      >
        <ExitToAppIcon className={classes.logoutIcon} />
        <ListItemText
          classes={{ root: classes.listItemText }}
          primary="Logout"
        />
      </ListItem>
    </List>
  )
}

const SideMenu = () => {
  const classes = useStyles()
  const {
    adminStore: {
      isAdmin,
      isOfficeUser,
    },
  } = useStores()

  return (
    <Drawer
      className={classes.drawer}
      variant="permanent"
      classes={{
        paper: classes.drawerPaper,
      }}
      anchor="left"
    >
      <UserAccount />
      <Menu />
      {(isAdmin || isOfficeUser) && <SignOut />}
    </Drawer>
  )
}

export default SideMenu
