import { api } from './api'

export const fetchBooking = (query) => api.get('/booking', query)
export const fetchBookingCounts = (query) => api.get('/booking/counts', query)
export const fetchBookingInfo = (id) => api.get(`/booking/${id}`)
export const addBooking = (booking) => api.post('/booking', booking)
export const updateBooking = (booking) => api.put(`/booking/${booking.id}`, booking)
export const updateBookingFromSidemenu = (booking, data) => api.put(`/booking/${booking.id}/sidemenu-update`, data)

export const deleteBooking = (id) => api.delete(`/booking/${id}`)

export const fetchBookingImage = (bookingImage) => api.get(`/booking/${bookingImage.bookingId}/image`, bookingImage)
export const addBookingImage = (bookingImage) => api.post(`/booking/${bookingImage.bookingId}/image`, bookingImage)
export const updateBookingImage = (bookingImage) => api.put(`/booking/${bookingImage.bookingId}/image/${bookingImage.id}`, bookingImage)
export const deleteBookingImage = (bookingImage) => api.delete(`/booking/${bookingImage.bookingId}/image/${bookingImage.id}`)
export const fetchTotalBooking = (query) => api.get('/booking/count', query)
export const fetchAddress = (postalCode) => api.get('/booking/address', { postalCode })

export const fetchBookingPayments = (query) => api.get(`/booking/${query.id}/payments`, query)

export const fetchBookingHistory = (query) => api.get('/booking/history', query)

export const fetchPercentageBooking = (query) => api.get('/booking/percentage', query)
export const fetchPercentageInvoice = (query) => api.get('/booking/percentage-invoice', query)
export const fetchCountAverageBooking = (query) => api.get('/booking/count-average', query)
export const fetchCountHeightLowBooking = (query) => api.get('/booking/count-highest-lowest', query)
