export default (theme) => {
  const {
    color,
  } = theme

  return {
    jumpToToday: {
      color: color.paarl,
      border: `1px solid ${color.paarl}`,
      '&:hover': {
        border: `1px solid ${color.paarl}`,
      },
    },
  }
}
