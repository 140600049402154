import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
} from 'react'
import { observer } from 'mobx-react'
import makeStyles from '@mui/styles/makeStyles'
import moment from 'moment'

import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Stack,
  MenuItem,
} from '@mui/material'

import DateRangeIcon from '@mui/icons-material/DateRange'

import { useStores, useApiRequest } from 'admin/hooks'
import * as srv from 'admin/services'
import { DRIVER_HOLIDAY_DIALOG, DRIVER_HOLIDAY_TYPE } from 'admin/config'

import CustomizedDialogTitle from 'admin/components/dialog-title'
import TextField from 'admin/components/textfield'

import {
  formatDate,
  dateOnlyFormat,
} from 'admin/utils/helper'

import styles from './styles'

const useStyles = makeStyles(styles)

const ListToDelete = observer(({
  setHoliday,
}) => {
  const {
    driverHolidayStore,
  } = useStores()
  const { list } = driverHolidayStore
  const [state, setState] = useState(list.reduce((acc, cur) => ({ ...acc, [cur.id]: !!cur.id }), {}))

  const handleChange = (event) => {
    setState(prev => ({
      ...prev,
      [event.target.name]: event.target.checked,
    }))
  }

  useEffect(() => {
    const selectedIds = Object.keys(state).filter(el => !!state[el])
    setHoliday(prev => ({
      ...prev,
      deletes: selectedIds,
    }))
  }, [state])

  return (
    <FormControl
      sx={{ m: 3 }}
      component="fieldset"
      variant="standard"
    >
      <FormLabel component="legend">
        List to Delete
      </FormLabel>
      <FormGroup>
        {list.map((holidayData) => (
          <FormControlLabel
            key={holidayData.id}
            control={(
              <Checkbox
                checked={state[holidayData.id]}
                onChange={handleChange}
                name={holidayData.id}
              />
            )}
            label={`${formatDate(holidayData.date)} | ${holidayData.remarks} | ${holidayData.type === DRIVER_HOLIDAY_TYPE.BLOCKED ? 'Blocked' : 'Holiday'}`}
          />
        ))}
      </FormGroup>
    </FormControl>
  )
})

const DriverHolidayForm = () => {
  const classes = useStyles()
  const {
    driverHolidayStore,
    infoSectionStore,
    notificationStore,
  } = useStores()

  const { selected: driverHoliday } = driverHolidayStore
  const { selected: driver } = infoSectionStore

  const [holiday, setHoliday] = useState({
    id: '',
    driverId: '',
    date: null,
    remarks: '',
    type: 'holiday',
    deletes: [],
    startDate: null,
    endDate: null,
  })

  const remarkRef = useRef()

  const {
    request: bulkAddDriverHoliday,
    isLoading: bulkAddProcessing,
  } = useApiRequest(srv.bulkAddDriverHoliday, { blocking: true })
  const {
    request: updateDriverHoliday,
    isLoading: updateProcessing,
  } = useApiRequest(srv.updateDriverHoliday, { blocking: true })

  const {
    request: bulkDeleteDriverHoliday,
    isLoading: bulkDeleteProcessing,
  } = useApiRequest(srv.bulkDeleteDriverHoliday, { blocking: true })

  useEffect(() => {
    setHoliday(prev => ({
      ...prev,
      driverId: driver?.id,
    }))
  }, [
    driver?.id,
  ])

  useEffect(() => {
    const range = {}
    if (driverHolidayStore.activeDialog !== DRIVER_HOLIDAY_DIALOG.edit) {
      range.startDate = driverHolidayStore.startDate
      range.endDate = driverHolidayStore.endDate
      setHoliday(prev => ({
        ...prev,
        ...range,
      }))
    }
  }, [
    driverHolidayStore.activeDialog,
    driverHolidayStore.startDate,
    driverHolidayStore.endDate,
  ])

  useEffect(() => {
    const data = {}
    if (driverHolidayStore.activeDialog === DRIVER_HOLIDAY_DIALOG.edit) {
      data.id = driverHoliday?.id
      data.remarks = driverHoliday?.remarks
      data.type = driverHoliday?.type
      data.date = driverHoliday?.date
      setHoliday(prev => ({
        ...prev,
        ...data,
      }))
    }
  }, [
    driverHoliday,
    driverHolidayStore.activeDialog,
  ])

  const dateRangeDisplay = useMemo(() => {
    let start = null
    let end = null
    if (
      !driverHolidayStore.isDateRangeActive &&
      driverHolidayStore.activeDialog === DRIVER_HOLIDAY_DIALOG.delete
    ) {
      return 'All time'
    }
    if (driverHolidayStore.activeDialog === DRIVER_HOLIDAY_DIALOG.edit) {
      start = holiday.date
    } else {
      start = holiday.startDate
      end = holiday.endDate
    }
    return `${formatDate(start)} ${end ? `- ${formatDate(end)}` : ''}`
  }, [
    holiday,
    driverHolidayStore.isDateRangeActive,
    driverHolidayStore.activeDialog,
  ])

  const resetHoliday = useCallback(() => {
    setHoliday({
      id: '',
      driverId: driver?.id,
      date: null,
      remarks: '',
      type: 'holiday',
      deletes: [],
      startDate: driverHolidayStore.startDate,
      endDate: driverHolidayStore.endDate,
    })
  }, [
    driver?.id,
    driverHolidayStore.startDate,
    driverHolidayStore.endDate,
  ])

  const handleClose = useCallback(() => {
    if (bulkAddProcessing || updateProcessing || bulkDeleteProcessing) return
    driverHolidayStore.setCloseActiveDialog()
    resetHoliday()
  }, [resetHoliday])

  const handleChange = useCallback((key, value) => {
    setHoliday(prev => ({
      ...prev,
      [key]: value,
    }))
  }, [])

  // Bulk Add / Bulk Delete / Single Edit
  const handleSubmit = useCallback(async (event) => {
    event.preventDefault()
    if (bulkAddProcessing || updateProcessing || bulkDeleteProcessing) return

    let response = null

    const params = {
      driverId: holiday.driverId,
    }

    if (driverHolidayStore.activeDialog === DRIVER_HOLIDAY_DIALOG.delete) {
      if (!holiday.deletes || !holiday.deletes.length) {
        notificationStore.setError('Please select list')
        return
      }

      params.deletes = holiday.deletes

      response = await bulkDeleteDriverHoliday(params)
    } else if (driverHolidayStore.activeDialog === DRIVER_HOLIDAY_DIALOG.edit && holiday.id) {
      if (!holiday.remarks) {
        notificationStore.setError('Please enter remarks')
        return
      }

      params.id = holiday.id
      params.remarks = holiday.remarks
      params.type = holiday.type

      response = await updateDriverHoliday(params)
    } else if (driverHolidayStore.activeDialog === DRIVER_HOLIDAY_DIALOG.add) {
      if (!holiday.remarks) {
        notificationStore.setError('Please enter remarks')
        return
      }
      if (!holiday.startDate || !holiday.endDate) {
        notificationStore.setError('Please select date range')
        return
      }

      const range = moment(holiday.endDate).diff(holiday.startDate, 'days') + 1

      // Limit the number of data to be created in one go
      // under 2 months (62 days)
      if (range > 62) {
        notificationStore.setError('Date range is too large')
        return
      }

      params.startDate = dateOnlyFormat(holiday.startDate)
      params.endDate = dateOnlyFormat(holiday.endDate)
      params.remarks = holiday.remarks
      params.type = holiday.type

      response = await bulkAddDriverHoliday(params)
    }
    if (!response) return

    notificationStore.setSuccess('Success')

    remarkRef?.current?.blur()

    driverHolidayStore.setCloseActiveDialog()

    driverHolidayStore.fetch({ id: holiday.driverId })
    resetHoliday()
  }, [driverHolidayStore.activeDialog, holiday, resetHoliday])

  if (!driverHolidayStore.activeDialog) return null

  return (
    <Dialog
      open={!!driverHolidayStore.activeDialog}
      onClose={handleClose}
      aria-labelledby="Holidays/Leave Dialog"
    >
      <CustomizedDialogTitle onClose={handleClose}>
        {driverHolidayStore.activeDialog} Holidays / Leave
      </CustomizedDialogTitle>
      <form
        noValidate
        onSubmit={handleSubmit}
        autoComplete="off"
      >
        <DialogContent>
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            className={classes.dateRange}
          >
            <DateRangeIcon />
            <span>
              {dateRangeDisplay}
            </span>
          </Stack>
          {driverHolidayStore.activeDialog !== DRIVER_HOLIDAY_DIALOG.delete && (
            <Stack>
              <TextField
                fullWidth
                label="Remarks"
                name="remarks"
                color="secondary"
                size="small"
                className={classes.remarksField}
                inputRef={remarkRef}
                value={holiday.remarks}
                onChange={(event) => {
                  handleChange(event.target.name, event.target.value)
                }}
              />
              <TextField
                name="type"
                color="secondary"
                type="text"
                variant="outlined"
                size="small"
                fullWidth
                select
                label="Type"
                value={holiday.type}
                onChange={(e) => {
                  handleChange(e.target.name, e.target.value)
                }}
                className={classes.textfield}
              >
                <MenuItem value={DRIVER_HOLIDAY_TYPE.HOLIDAY}>Holiday</MenuItem>
                <MenuItem value={DRIVER_HOLIDAY_TYPE.BLOCKED}>Blocked</MenuItem>
              </TextField>
            </Stack>
          )}
          {(
            driverHolidayStore.activeDialog === DRIVER_HOLIDAY_DIALOG.delete &&
            !!driverHolidayStore.list.length
          ) && (
            <ListToDelete setHoliday={setHoliday} />
          )}
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            color="primary"
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={(
              bulkAddProcessing ||
              updateProcessing ||
              bulkDeleteProcessing
            )}
            className={classes.capitalize}
          >
            Confirm {driverHolidayStore.activeDialog}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default observer(DriverHolidayForm)
