import React, {
  useState,
  useCallback,
  useEffect,
} from 'react'
import { observer } from 'mobx-react'
import makeStyles from '@mui/styles/makeStyles'
import {
  Tabs,
  Tab,
} from '@mui/material'
import { DAYS } from 'admin/config'
import {
  useStores,
} from 'admin/hooks'

import Default from './default'
import styles from './styles'

const useStyles = makeStyles(styles)

const isDisabled = (day, driver, disableDaysOfTheWeekTabs) => {
  if (disableDaysOfTheWeekTabs) return true
  if (day !== 'default' && driver && driver.workday) return !driver.workday[day.substring(0, 3)]
  if (day !== 'default') return true
  return false
}

const ServiceAreaTab = () => {
  const classes = useStyles()
  const [activeTab, setActiveTab] = useState(0)

  const [override, setOverride] = useState({})
  const [disableDaysOfTheWeekTabs, setDisableDaysOfTheWeekTabs] = useState(true)

  const {
    infoSectionStore,
    driverStore,
    slotStore,
  } = useStores()

  const { selected: driver } = infoSectionStore

  const tabs = DAYS.map(label => {
    if (override[label] && label !== 'default') return `${label}*`
    return label
  })

  const handleChangeTab = useCallback((event, value) => {
    setActiveTab(value)
  }, [])

  useEffect(() => {
    async function fetchData() {
      await slotStore.fetch()
      const responseDriverSlots = await driverStore.fetchDriverSlot({ id: driver.id })
      if (!responseDriverSlots) return null

      if (responseDriverSlots.length === 0) {
        setDisableDaysOfTheWeekTabs(true)
      } else {
        setDisableDaysOfTheWeekTabs(false)
      }

      const defaultSlot = responseDriverSlots.filter(resp => resp.day === 'default')
      setOverride({})
      slotStore.list.map(slotSetup => {
        DAYS.map(day => {
          const found = responseDriverSlots.find(element => element.day === day && element.slotId === slotSetup.id)
          const foundDefault = defaultSlot.find(element => element.slotId === slotSetup.id)
          if (found && foundDefault) {
            if (
              found.serviceArea.length !== foundDefault.serviceArea.length ||
              found.isActive !== foundDefault.isActive
            ) {
              setOverride(prev => ({
                ...prev,
                [day]: true,
              }))
            } else if (
              found.isActive === foundDefault.isActive &&
              found.serviceArea.length === foundDefault.serviceArea.length
            ) {
              if (found.serviceArea.length > 0) {
                found.serviceArea.map((serviceArea) => {
                  if (!foundDefault.serviceArea.includes(serviceArea)) {
                    setOverride(prev => ({
                      ...prev,
                      [day]: true,
                    }))
                  }
                  return null
                })
              }
            }
          }
          return null
        })
        return null
      })
      return null
    }
    fetchData()
  }, [driver])

  return (
    <>
      <Tabs
        classes={{
          root: classes.tabsRoot,
          indicator: classes.tabsIndicator,
          scrollButtons: classes.scrollButtons,
        }}
        variant="scrollable"
        scrollButtons
        value={activeTab}
        onChange={handleChangeTab}
      >
        {tabs.map((day) => (
          <Tab
            key={day}
            label={day}
            classes={{
              root: classes.tab,
              selected: classes.tabSelected,
              wrapper: classes.wrapper,
            }}
            disabled={isDisabled(day, driver, disableDaysOfTheWeekTabs)}
          />
        ))}
      </Tabs>
      <div className={classes.infoContainer}>
        {activeTab === 0 && <Default day="default" />}
        {activeTab === 1 && <Default day="monday" />}
        {activeTab === 2 && <Default day="tuesday" />}
        {activeTab === 3 && <Default day="wednesday" />}
        {activeTab === 4 && <Default day="thursday" />}
        {activeTab === 5 && <Default day="friday" />}
        {activeTab === 6 && <Default day="saturday" />}
        {activeTab === 7 && <Default day="sunday" />}
      </div>
    </>
  )
}

export default observer(ServiceAreaTab)
