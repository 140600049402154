import React, {
  useCallback,
  useState,
  useEffect,
} from 'react'
import { observer } from 'mobx-react'
import { computed } from 'mobx'

import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  MenuItem,
  IconButton,
  Typography,
  Switch,
} from '@mui/material'
import { DateRange } from '@mui/icons-material/'
import makeStyles from '@mui/styles/makeStyles'

import DateRangePicker from 'admin/components/date-range-picker'
import CustomizedDialogTitle from 'admin/components/dialog-title'
import TextField from 'admin/components/textfield'

import { useStores, useRequest, useDebounce } from 'admin/hooks'
import * as srv from 'admin/services'
import { replaceToNumbers, replaceToString, formatDate } from 'admin/utils/helper'

import CustomizedAutocomplete from 'admin/components/autocomplete'

import { DEFECT_LIST } from 'admin/config'

import styles from './styles'

const useStyles = makeStyles(styles)

const DEFECT_LIST_INIT = DEFECT_LIST.map(defectName => ({
  key: defectName,
  label: defectName,
}))

const DateFilter = ({
  checked,
  onChange,
  name,
  label,
  handleSelect,
  selectionRange,
}) => {
  const classes = useStyles()

  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleCloseCalendar = () => {
    setAnchorEl(null)
  }

  const date = computed(() => {
    const { startDate, endDate } = selectionRange
    if (!startDate) return '-'
    if (formatDate(startDate) !== formatDate(endDate)) {
      return `${formatDate(startDate)} - ${formatDate(endDate)}`
    }
    return formatDate(startDate)
  }).get()

  return (
    <>
      <Typography variant="h4">
        {label}
      </Typography>
      <div className={classes.dateOption}>
        <Switch
          checked={checked}
          onChange={onChange}
          name={name}
        />
        <div className={classes.datePicker}>
          <span>
            {date}
          </span>
          <IconButton
            onClick={handleClick}
            size="large"
          >
            <DateRange />
          </IconButton>
        </div>
        <DateRangePicker
          anchorEl={anchorEl}
          handleClose={handleCloseCalendar}
          handleSelect={handleSelect}
          selectionRange={selectionRange}
          onDialog
        />
      </div>
    </>
  )
}

const Filter = () => {
  const classes = useStyles()
  const {
    invoiceItemStore,
  } = useStores()

  const [invoice, setInvoice] = useState('')
  const [barcode, setBarcode] = useState('')

  const [customerPhoneNumber, setCustomerPhoneNumber] = useState('')
  const [customerOptions, setCustomerOptions] = useState([])
  const [selectedCustomer, setSelectedCustomer] = useState(null)

  const [adminUsername, setAdminUsername] = useState('')
  const debounceAdminUsername = useDebounce(adminUsername, 300)
  const [adminUsernameOptions, setAdminUsernameOptions] = useState([])
  const [selectedAdmin, setSelectedAdmin] = useState(null)

  const [status, setStatus] = useState('')

  const [defect1, setDefect1] = useState([])
  const [defect2, setDefect2] = useState([])
  const [defectOption] = useState(DEFECT_LIST_INIT)
  const [isChecked, setisChecked] = useState(false)
  const [rangeCreatedAtDefect, setRangeCreatedAtDefect] = useState({
    key: 'selection',
    startDate: null,
    endDate: new Date(''),
  })

  const {
    request: fetchCustomer,
    loading: customerLoading,
  } = useRequest(srv.fetchCustomer, {
    concurrent: true,
    initialData: { list: [], total: 0 },
    transformData: (data) => {
      if (data && data.list.length > 0) {
        setCustomerOptions(data.list.map(item => ({
          key: item.id,
          label: item.phoneNumber || item.originalData.mobileNo,
        })))
      }
    },
  })

  const {
    request: fetchAdminUsername,
    loading: adminUsernameLoading,
  } = useRequest(srv.fetchAdminName, {
    concurrent: true,
    initialData: { list: [], total: 0 },
    transformData: (data) => {
      if (data && data.list.length > 0) {
        setAdminUsernameOptions(data.list.map(item => ({
          ...item,
          key: item.id,
          label: item.username,
        })))
      }
    },
  })

  useEffect(() => {
    if (debounceAdminUsername) {
      fetchAdminUsername({
        username: debounceAdminUsername,
        limit: 5,
      })
    }
  }, [debounceAdminUsername])

  useEffect(() => {
    if (customerPhoneNumber) {
      fetchCustomer({
        phoneNumber: customerPhoneNumber,
        limit: 5,
      })
    }
  }, [customerPhoneNumber])

  const handleReset = useCallback(() => {
    setInvoice('')
    setBarcode('')
    setStatus('')
    setCustomerPhoneNumber('')
    setSelectedCustomer(null)
    setAdminUsername('')
    setSelectedAdmin(null)
    setDefect1([]) // must be an empty array
    setDefect2([]) // must be an empty array
    setisChecked(false)
    setRangeCreatedAtDefect(prev => ({
      ...prev,
      startDate: null,
      endDate: new Date(''),
    }))
  }, [])

  useEffect(() => {
    handleReset()
  }, [invoiceItemStore.activeTab.sub])

  const handleSelectRangeDate = useCallback(values => {
    const { startDate, endDate } = values.selection
    setRangeCreatedAtDefect(prev => ({
      ...prev,
      startDate,
      endDate,
    }))
    setisChecked(true)
  }, [])

  const handleChangeSwitch = (event) => {
    setisChecked(event.target.checked)
    setRangeCreatedAtDefect(prev => ({
      ...prev,
      startDate: null,
      endDate: new Date(''),
    }))
  }

  const handleAdminUsernameInputChange = useCallback((event, newValue) => {
    setAdminUsername(replaceToString(newValue).trim())
  }, [])

  const handleCustomerPhoneNumberInputChange = useCallback((event, newValue) => {
    setCustomerPhoneNumber(replaceToNumbers(newValue))
  }, [])

  const handleChangeSearchCustomer = useCallback((_, newValue) => {
    setSelectedCustomer(newValue)
  }, [])

  const handleChangeSearchAdminUsername = useCallback((_, newValue) => {
    setSelectedAdmin(newValue)
  }, [])

  const handleClose = useCallback(() => {
    invoiceItemStore.setOpenFilterDialog(false)
  }, [])

  const handleSubmit = useCallback(() => {
    invoiceItemStore.updateQuery('prestoId', invoice.trim(), true)
    invoiceItemStore.updateQuery('barcode', barcode.trim(), true)
    invoiceItemStore.updateQuery('status', status, true)
    invoiceItemStore.updateQuery('customerId', selectedCustomer?.key, true)
    invoiceItemStore.updateQuery('adminId', selectedAdmin?.key, true)
    invoiceItemStore.updateQuery('startCreatedAt', isChecked ? rangeCreatedAtDefect.startDate : null, true)
    invoiceItemStore.updateQuery('endCreatedAt', isChecked ? rangeCreatedAtDefect.endDate : null, true)

    const selectedDefect1 = Array.isArray(defect1) && defect1.length > 0
    const selectedDefect2 = Array.isArray(defect2) && defect2.length > 0
    invoiceItemStore.updateQuery('defect1', selectedDefect1 ? defect1.map(d => d.key) : null, true)
    invoiceItemStore.updateQuery('defect2', selectedDefect2 ? defect2.map(d => d.key) : null, true)

    invoiceItemStore.setOpenFilterDialog(false)
    invoiceItemStore.setSelectedAdmin(selectedAdmin)
  }, [
    status,
    invoice,
    barcode,
    selectedCustomer,
    selectedAdmin,
    defect1,
    defect2,
    rangeCreatedAtDefect,
  ])

  if (!invoiceItemStore.dialog) return null

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      open={invoiceItemStore.dialog}
      onClose={handleClose}
    >
      <CustomizedDialogTitle onReset={handleReset}>
        Defect Filter
      </CustomizedDialogTitle>
      <DialogContent>
        {invoiceItemStore.activeTab.sub === 0 && (
          <DateFilter
            checked={isChecked}
            onChange={handleChangeSwitch}
            name="date"
            label="Defect Date Created"
            handleSelect={handleSelectRangeDate}
            selectionRange={rangeCreatedAtDefect}
          />
        )}
        <div style={{ marginTop: '0.5rem' }}>
          <CustomizedAutocomplete
            inputValue={adminUsername}
            handleInputChange={handleAdminUsernameInputChange}
            handleChange={handleChangeSearchAdminUsername}
            options={adminUsernameOptions}
            loading={adminUsernameLoading}
            value={selectedAdmin}
            label="Invoice Created by"
            size="small"
            className={classes.autocomplete}
          />
        </div>
        {invoiceItemStore.activeTab.sub === 0 && (
          <>
            <div className={classes.textfield}>
              <CustomizedAutocomplete
                label="Defect 1 (Captured by Counter)"
                multiple
                handleChange={(e, newValue) => {
                  setDefect1(newValue)
                }}
                options={defectOption}
                value={defect1}
              />
            </div>
            <div className={classes.textfield}>
              <CustomizedAutocomplete
                label="Defect 2 (Captured by HQ/Office)"
                multiple
                handleChange={(e, newValue) => {
                  setDefect2(newValue)
                }}
                options={defectOption}
                value={defect2}
              />
            </div>
            <div className={classes.textfield}>
              <TextField
                name="status"
                type="text"
                variant="outlined"
                size="small"
                fullWidth
                select
                label="Status"
                value={status}
                onChange={(e) => {
                  setStatus(e.target.value)
                }}
              >
                <MenuItem value="">All</MenuItem>
                <MenuItem value="reported">Reported</MenuItem>
                <MenuItem value="unreported">Unreported</MenuItem>
              </TextField>
            </div>
            <TextField
              name="prestoId"
              type="text"
              variant="outlined"
              size="small"
              fullWidth
              label="Search Invoice"
              value={invoice}
              onChange={(e) => {
                setInvoice(e.target.value)
              }}
              className={classes.textfield}
            />
            <TextField
              name="barcode"
              type="text"
              variant="outlined"
              size="small"
              fullWidth
              label="Search Barcode"
              value={barcode}
              onChange={(e) => {
                setBarcode(e.target.value)
              }}
              className={classes.textfield}
            />
            <CustomizedAutocomplete
              inputValue={customerPhoneNumber}
              handleInputChange={handleCustomerPhoneNumberInputChange}
              handleChange={handleChangeSearchCustomer}
              options={customerOptions}
              loading={customerLoading}
              value={selectedCustomer}
              label="Customer Phone No."
              size="small"
              className={classes.autocomplete}
            />
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={handleClose}
          color="secondary"
        >
          Cancel
        </Button>
        <Button
          type="submit"
          variant="contained"
          onClick={handleSubmit}
          color="secondary"
        >
          Apply
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default observer(Filter)
