export default (theme) => ({
  headbar: {
    display: 'flex',
  },
  value: {
    textTransform: 'capitalize',
  },
  title: {
    padding: '1rem',
    backgroundColor: theme.color.seashell,
  },
  noDataText: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '10rem',
  },
  infoItem: {
    padding: '1rem 1rem 0',
  },
})
