export default (theme) => ({
  status: ({ status }) => {
    let color = ''
    if (status === 'paid') {
      color = theme.color.indochine
    } else if (
      status === 'unpaid' ||
      status === 'failed' ||
      status === 'unreported'
    ) {
      color = theme.color.redCherry
    } else if (status === 'partial') {
      color = theme.color.cinnabar
    } else if (status === 'received') {
      color = theme.color.camarone
    } else if (
      status === 'Completed' ||
      status === 'succeeded' ||
      status === 'reported'
    ) {
      color = theme.color.camarone
    } else {
      color = theme.color.black
    }

    return {
      textTransform: 'capitalize',
      fontWeight: 'bold',
      color,
    }
  },
})
