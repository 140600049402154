import React, { useEffect, useCallback } from 'react'
import { observer } from 'mobx-react'
import {
  Typography,
  Stack,
} from '@mui/material'

import { useStores } from 'admin/hooks'
import CustomDataGrid from 'admin/components/data-grid'
import {
  formatDate,
  formatTimeOnly,
} from 'admin/utils/helper'

const columns = [
  {
    field: 'createdAt',
    headerName: 'Date Created',
    width: 145,
    renderCell: (params) => (
      <Stack direction="column">
        <Typography variant="h5">
          {formatDate(params.row.createdAt)}
        </Typography>
        <Typography variant="h5">
          {formatTimeOnly(params.row.createdAt)}
        </Typography>
      </Stack>
    ),
  },
  {
    field: 'updatedAt',
    headerName: 'Date Updated',
    width: 145,
    renderCell: (params) => (
      <Stack direction="column">
        <Typography variant="h5">
          {formatDate(params.row.updatedAt)}
        </Typography>
        <Typography variant="h5">
          {formatTimeOnly(params.row.updatedAt)}
        </Typography>
      </Stack>
    ),
  },
  {
    field: 'name',
    headerName: 'Name',
    width: 275,
  },
  {
    field: 'phoneNumber',
    headerName: 'Phone Number',
    width: 250,
  },
  {
    field: 'address',
    headerName: 'Address',
    width: 300,
  },
]

const CustomerDataGrid = () => {
  const { customerStore, infoSectionStore } = useStores()

  const handlePageChange = useCallback((params) => {
    customerStore.updatePage(params)
  }, [])

  const handlePageSizeChange = useCallback((param) => {
    customerStore.updateRowsPerPage(param)
  }, [])

  const handleRowClick = useCallback((rowData) => {
    infoSectionStore.select(rowData.row)
  }, [])

  const onSortModelChange = (param) => {
    if (param.length === 1) {
      customerStore.updateQuery('sortBy', param[0].field, true)
      customerStore.updateQuery('sortDirection', param[0].sort, true)
      return
    }
    customerStore.resetQuery()
  }

  useEffect(() => {
    customerStore.fetch()
  }, [customerStore.queryParams])

  return (
    <CustomDataGrid
      rows={customerStore.list}
      columns={columns}
      handleRowClick={handleRowClick}
      handlePageChange={handlePageChange}
      rowCount={customerStore.total}
      pageSize={customerStore.rowsPerPage}
      page={customerStore.currentPage}
      handlePageSizeChange={handlePageSizeChange}
      onSortModelChange={onSortModelChange}
      loading={customerStore.loading}
      rowHeight={80}
    />
  )
}

export default observer(CustomerDataGrid)
