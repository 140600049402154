import { startOfDay, endOfDay, addDays, subDays } from 'date-fns'
import { makeAutoObservable, flow } from 'mobx'
import moment from 'moment'

import { TODAY } from 'admin/utils/helper'
import * as srv from 'admin/services'
import handleError from 'admin/services/error-handler'

export default class WalletStore {
  loading = false

  dialog = false

  selected = null
  list = []
  query = {
    sortBy: 'createdAt',
    sortDirection: 'desc',
    action: null,
  }
  total = 0
  currentPage = 0
  rowsPerPage = 10

  DEFAULT = {
    transactionType: 'topup',
    phoneNumber: null,
    transactionDate: moment(),
    paymentAmount: '',
    creditAmount: '',
    notes: '',
    reference: '',
  }

  activeTab = null
  startDate = startOfDay(subDays(TODAY(), 7))
  endDate = endOfDay(addDays(TODAY(), 7))
  isDateRangeActive = true
  defaultDatePickerSelectionRange = {
    key: 'selection',
    startDate: this.startDate,
    endDate: this.endDate,
  }
  datePickerSelectionRange = this.defaultDatePickerSelectionRange

  constructor(rootStore) {
    makeAutoObservable(this, {
      fetch: flow,
      fetchWalletByCustomerId: flow,
    })
    this.rootStore = rootStore
  }

  setOpenCreateDialog = (value) => {
    this.dialog = value
  }

  setCloseDialog = (reset = true) => {
    this.dialog = false
    if (reset) setTimeout(this.resetSelected, 50)
  }

  setActiveTab = (tab) => {
    this.activeTab = tab
  }

  updateDatePickerSelectionRange = (startDate, endDate) => {
    this.datePickerSelectionRange = {
      key: 'selection',
      startDate,
      endDate,
    }
  }

  updateQuery = (key, value, resetPage = false) => {
    this.query[key] = value
    if (resetPage) this.currentPage = 0
  }

  resetQuery = () => {
    this.query = {
      action: '',
      sortBy: 'createdAt',
      sortDirection: 'desc',
    }
  }

  select = (id) => {
    this.selected = this.list.find((item) => item.id === id)
  }

  setSelected = (value) => {
    this.selected = value
  }

  resetSelected = () => {
    this.selected = { ...this.DEFAULT }
  }

  updatedPage = (page) => {
    this.currentPage = page
  }

  updateRowsPerPage = (perPage) => {
    this.rowsPerPage = perPage
  }

  activateDateRange = (value, reset = false) => {
    this.isDateRangeActive = value
    if (reset) this.datePickerSelectionRange = this.defaultDatePickerSelectionRange
  }

  get queryParams() {
    const sDate = moment(this.datePickerSelectionRange.startDate).startOf('day').toISOString()
    const eDate = moment(this.datePickerSelectionRange.endDate).endOf('day').toISOString()
    const params = {
      sortBy: this.query.sortBy,
      sortDirection: this.query.sortDirection,
      limit: this.rowsPerPage,
      offset: this.currentPage * this.rowsPerPage,
      startDate: sDate,
      endDate: eDate,
    }

    if (this.query.startDate) params.startDate = this.query.startDate
    if (this.query.endDate) params.endDate = this.query.endDate
    if (this.query.action) params.action = this.query.action
    return params
  }

  * fetch(params) {
    try {
      this.loading = true
      const response = yield srv.fetchWallet({
        ...this.queryParams,
        ...params,
      })
      if (response.ok) {
        this.list = response.data.list
        this.total = response.data.total
        return response.data
      }
      throw handleError(response)
    } catch (error) {
      throw new Error(error.message)
    } finally {
      this.loading = false
    }
  }

  * fetchWalletByCustomerId(customerId) {
    try {
      this.loading = true
      const response = yield srv.fetchWalletByCustomerId(customerId)
      if (response.ok) {
        this.list = response.data.list
        this.total = response.data.total
        return response.data
      }
      throw handleError(response)
    } catch (error) {
      throw new Error(error.message)
    } finally {
      this.loading = false
    }
  }
}
