import React from 'react'
import clx from 'clsx'
import {
  FormControl,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Typography,
  Checkbox,
  IconButton,
} from '@mui/material'

import AddBoxIcon from '@mui/icons-material/AddBox'
import IndeterminateCheckBoxOutlinedIcon from '@mui/icons-material/IndeterminateCheckBoxOutlined'

import { TAG_CODE } from 'admin/config'

import makeStyles from '@mui/styles/makeStyles'
import styles from '../styles'

const useStyles = makeStyles(styles)

const NumOfRoom = ({
  item,
  setTags,
}) => {
  const classes = useStyles()

  const plusItem = () => {
    setTags(prev => {
      const newArray = prev.map((object) => {
        if (object.tagId === item.tagId) {
          object.additionalInfo.numberOfRoom = Number(object.additionalInfo.numberOfRoom) + 1
        }
        return object
      })
      return newArray
    })
  }

  const minusItem = () => {
    setTags(prev => {
      const newArray = prev.map((object) => {
        if (object.tagId === item.tagId) {
          object.additionalInfo.numberOfRoom = Number(object.additionalInfo.numberOfRoom) - 1
        }
        return object
      })
      return newArray
    })
  }

  return (
    <div className={clx(classes.numberRooms, {
      [classes.disableText]: !item.isChecked,
    })}
    >
      Num. Rooms
      <div className={classes.icons}>
        <IconButton
          color="primary"
          variant="contained"
          size="small"
          disabled={item.additionalInfo.numberOfRoom === 0 || !item.isChecked}
          onClick={minusItem}
        >
          <IndeterminateCheckBoxOutlinedIcon />
        </IconButton>
        <span>
          {item.additionalInfo.numberOfRoom}
        </span>
        <IconButton
          color="primary"
          size="small"
          onClick={plusItem}
          disabled={!item.isChecked}
        >
          <AddBoxIcon />
        </IconButton>
      </div>
    </div>
  )
}

const CheckboxGroup = ({
  icon,
  title,
  data,
  setTags,
}) => {
  const classes = useStyles()

  const handleChangeCheckbox = (event) => {
    const newTags = [...data].map((object) => {
      if (object.tag.name === event.target.name) {
        return {
          ...object,
          isChecked: event.target.checked,
        }
      }
      return object
    })
    setTags(newTags)
  }

  return (
    <FormControl
      style={{ marginRight: '3rem' }}
      component="fieldset"
      className={classes.formControl}
    >
      <FormLabel component="legend">
        <div className={classes.checkboxLabel}>
          <img
            src={icon === 'pickup' ? (
              '/images/service-pick-up.svg') : (
              '/images/service-delivery.svg'
            )}
            alt={icon}
            width="25px"
            height="25px"
            className={classes.icon}
          />
          <Typography variant="h4">
            {title}
          </Typography>
        </div>
      </FormLabel>
      <FormGroup>
        {data && data.length > 0 && (data.map((bookingTag) => {
          return (
            <React.Fragment key={bookingTag.tagId}>
              <FormControlLabel
                control={(
                  <Checkbox
                    checked={bookingTag.isChecked}
                    onChange={handleChangeCheckbox}
                    name={bookingTag.tag.name}
                  />
                )}
                label={bookingTag.tag.name}
              />
              {bookingTag.tag.posCode === TAG_CODE.CURTAIN_WITH_DISMANTLING && (
                <NumOfRoom item={bookingTag} setTags={setTags} />
              )}
            </React.Fragment>
          )
        }))}
        {data && data.length === 0 && (
          <Typography variant="caption">
            Please set tag first at setting page
          </Typography>
        )}
      </FormGroup>
    </FormControl>
  )
}

export default CheckboxGroup
