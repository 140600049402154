import React, {
  useEffect,
  useState,
} from 'react'
import { observer } from 'mobx-react'
import {
  Stack,
  TableBody,
  TableRow,
  TableCell,
  Typography,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

import { useStores, useApiRequest } from 'admin/hooks'
import * as srv from 'admin/services'

import {
  formatDayName,
  getTags,
} from 'admin/utils/helper'

import InfoSectionTable from 'admin/components/info-section-table'
import ServiceIcon from 'admin/components/service-icon'

import styles from './styles'

const useStyles = makeStyles(styles)

const BookingHistory = () => {
  const classes = useStyles()
  const { infoSectionStore } = useStores()

  const { selected: booking } = infoSectionStore

  const [bookingHistoryList, setBookingHistoryList] = useState([])

  const {
    request: fetchBookingHistory,
  } = useApiRequest(srv.fetchBookingHistory, { blocking: false })

  useEffect(() => {
    const fetchBookingHistoryList = async () => {
      setBookingHistoryList([])
      if (!booking) return
      const params = {
        customerId: booking.customerId,
        limit: 10,
        offset: 0,
        sortBy: 'date',
        sortDirection: 'desc',
      }
      const response = await fetchBookingHistory(params)

      if (response?.ok) {
        setBookingHistoryList(response.data.list)
      }
    }
    fetchBookingHistoryList()
  }, [booking?.id])

  return (
    <InfoSectionTable
      columns={['Date', 'Services', 'Driver']}
      rows={(
        <TableBody>
          {bookingHistoryList.map((row) => {
            const {
              pickup,
              delivery,
            } = getTags(row.bookingTags)
            return (
              <TableRow
                key={`history-${row.id}`}
                className={(booking?.id === row.id) ? classes.row : null}
              >
                <TableCell>
                  <Stack>
                    <Typography variant="caption">
                      {formatDayName(row.date)}
                    </Typography>
                    <Typography variant="caption">
                      ({row.slot.start}-{row.slot.end})
                    </Typography>
                  </Stack>
                </TableCell>
                <TableCell>
                  {pickup.length > 0 && (
                    <ServiceIcon
                      iconName="pickup"
                      data={pickup}
                      variant="caption"
                    />
                  )}
                  {delivery.length > 0 && (
                    <ServiceIcon
                      iconName="delivery"
                      data={delivery}
                      variant="caption"
                    />
                  )}
                </TableCell>
                <TableCell>
                  <Typography variant="caption">
                    {row.driver?.code}
                  </Typography>
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      )}
    />

  )
}

export default observer(BookingHistory)
