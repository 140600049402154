export default () => ({
  grid: {
    marginTop: '0.7rem',
  },
  button: {
    padding: '5px',
    minWidth: 0,
  },
  filterWrapper: {
    display: 'flex',
    alignContent: 'center',
    alignItems: 'center',
    margin: '1.5rem',
  },
  buttons: {
    textAlign: 'right',
    display: 'flex',
    flexDirection: 'column',
  },
  datePicker: {
    flex: 2,
  },
  buttonAdd: {
    marginBottom: '0.5rem',
  },
  remarksField: {
    width: '25rem',
    marginTop: '1rem',
    marginBottom: '1rem',
  },
  dateRange: {
    margin: '0.5rem 0',
    width: '25rem',
  },
  buttonEdit: {
    width: '24px',
    height: '24px',
    marginRight: '12px',
  },
  hideButtons: {
    visibility: 'hidden',
  },
  capitalize: {
    textTransform: 'capitalize',
  },
})
