import React, { useEffect, useCallback } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { observer } from 'mobx-react'
import moment from 'moment'
import { DatePicker } from '@mui/x-date-pickers'
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  FormControl,
  InputAdornment,
  TextField as MuiTextField,
  Switch,
  Stack,
} from '@mui/material'

import { useStores, useApiRequest } from 'admin/hooks'
import * as srv from 'admin/services'
import CustomizedDialogTitle from 'admin/components/dialog-title'
import TextField from 'admin/components/textfield'

const PaymentForm = () => {
  const {
    invoicePaymentStore,
    notificationStore,
  } = useStores()

  const { control, handleSubmit: handleSubmitForm, reset, watch } = useForm({
    defaultValues: {
      id: '',
      paymentTime: invoicePaymentStore.DEFAULT.paymentTime,
      paymentChannel: invoicePaymentStore.DEFAULT.paymentChannel,
      amount: invoicePaymentStore.DEFAULT.amount,
      referenceNumber: invoicePaymentStore.DEFAULT.referenceNumber,
      editPaymentTime: false,
      editPaymentChannel: false,
      editAmount: false,
      editReferenceNumber: false,
    },
  })

  const watchFields = watch([
    'editPaymentTime',
    'editAmount',
    'editPaymentChannel',
    'editReferenceNumber',
  ])

  const {
    request: updatePayment,
    isLoading: updateProcessing,
  } = useApiRequest(srv.updatePayment, { blocking: true })

  const handleClose = useCallback(() => {
    return invoicePaymentStore.setCloseDialog('form')
  }, [])

  const handleSubmit = useCallback(async (value) => {
    if (
      !value.editPaymentTime &&
      !value.editAmount &&
      !value.editPaymentChannel &&
      !value.editReferenceNumber
    ) {
      notificationStore.setError('Please insert new data')
      return
    }
    const request = updatePayment
    const params = { id: value.id }
    if (value.editAmount) params.amount = value.amount
    if (value.editPaymentChannel) params.paymentChannel = value.paymentChannel
    if (value.editPaymentTime) params.paymentTime = moment(value.paymentTime).startOf('day').format()
    if (value.editReferenceNumber) params.referenceNumber = value.referenceNumber

    const res = await request(params)
    if (!res) return

    invoicePaymentStore.updatePage(0)
    if (value.editPaymentTime) {
      invoicePaymentStore.updateStartDate(params.paymentTime)
      invoicePaymentStore.updateEndDate(params.paymentTime)
    }
    await invoicePaymentStore.reload()
    notificationStore.setSuccess('Update payment success')

    invoicePaymentStore.setCloseDialog('form')
  }, [])

  useEffect(() => {
    const editFields = {
      editPaymentTime: false,
      editAmount: false,
      editPaymentChannel: false,
      editReferenceNumber: false,
    }
    if (!invoicePaymentStore.selected) {
      const {
        paymentTime,
        paymentChannel,
        amount,
        referenceNumber,
      } = invoicePaymentStore.DEFAULT
      reset({
        ...editFields,
        id: '',
        paymentTime,
        paymentChannel,
        amount,
        referenceNumber,
      })
    } else {
      const {
        paymentTime,
        paymentChannel,
        amount,
        referenceNumber,
        id,
      } = invoicePaymentStore.selected
      reset({
        ...editFields,
        id,
        paymentTime,
        paymentChannel,
        amount,
        referenceNumber,
      })
    }
  }, [invoicePaymentStore.selected])

  if (!invoicePaymentStore.dialog.form) return null

  return (
    <Dialog
      open={invoicePaymentStore.dialog.form}
      onClose={handleClose}
      aria-labelledby="Payment Form"
    >
      <CustomizedDialogTitle onClose={handleClose}>
        Update Payment
      </CustomizedDialogTitle>

      <form
        noValidate
        onSubmit={handleSubmitForm(handleSubmit)}
        autoComplete="off"
      >
        <DialogContent>
          <FormControl
            fullWidth
            margin="normal"
          >
            <Stack
              direction="row"
              spacing={2}
            >
              <Controller
                name="editPaymentTime"
                control={control}
                render={({ field }) => (
                  <Switch
                    onChange={(e) => field.onChange(e.target.checked)}
                    checked={field.value}
                  />
                )}
              />
              <Controller
                name="paymentTime"
                control={control}
                render={({ field }) => {
                  return (
                    <DatePicker
                      {...field}
                      label="Payment Receipt Date"
                      mask="__/__/____"
                      inputFormat="dd/MM/yyyy"
                      onChange={(e) => field.onChange(e)}
                      selected={field.value}
                      disabled={!watchFields[0]}
                      renderInput={(params) => {
                        return (
                          <MuiTextField
                            {...params}
                            size="small"
                            style={{ width: '80%' }}
                            helperText={null}
                          />
                        )
                      }}
                    />
                  )
                }}
              />
            </Stack>
          </FormControl>
          <FormControl
            fullWidth
            margin="normal"
          >
            <Stack
              direction="row"
              spacing={2}
            >
              <Controller
                name="editAmount"
                control={control}
                render={({ field }) => (
                  <Switch
                    onChange={(e) => field.onChange(e.target.checked)}
                    checked={field.value}
                  />
                )}
              />
              <Controller
                control={control}
                name="amount"
                render={({
                  field: { ref, ...fieldProps },
                  fieldState: { error },
                }) => (
                  <TextField
                    label="Payment Amount"
                    InputLabelProps={{ shrink: true }}
                    error={!!error?.message}
                    type="number"
                    disabled={!watchFields[1]}
                    style={{ width: '80%' }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          $
                        </InputAdornment>
                      ),
                    }}
                    helperText={error?.message}
                    inputRef={ref}
                    {...fieldProps}
                  />
                )}
              />
            </Stack>
          </FormControl>
          <FormControl
            fullWidth
            margin="normal"
          >
            <Stack
              direction="row"
              spacing={2}
            >
              <Controller
                name="editPaymentChannel"
                control={control}
                render={({ field }) => (
                  <Switch
                    onChange={(e) => field.onChange(e.target.checked)}
                    checked={field.value}
                  />
                )}
              />
              <Controller
                control={control}
                name="paymentChannel"
                render={({
                  field: { ref, ...fieldProps },
                  fieldState: { error },
                }) => (
                  <TextField
                    label="Payment Method"
                    InputLabelProps={{ shrink: true }}
                    error={!!error?.message}
                    helperText={error?.message}
                    inputRef={ref}
                    disabled={!watchFields[2]}
                    style={{ width: '80%' }}
                    {...fieldProps}
                  />
                )}
              />
            </Stack>
          </FormControl>
          <FormControl
            fullWidth
            margin="normal"
          >
            <Stack
              direction="row"
              spacing={2}
            >
              <Controller
                name="editReferenceNumber"
                control={control}
                render={({ field }) => (
                  <Switch
                    onChange={(e) => field.onChange(e.target.checked)}
                    checked={field.value}
                  />
                )}
              />
              <Controller
                control={control}
                name="referenceNumber"
                render={({
                  field: { ref, ...fieldProps },
                  fieldState: { error },
                }) => (
                  <TextField
                    label="Payment Ref #"
                    InputLabelProps={{ shrink: true }}
                    error={!!error?.message}
                    helperText={error?.message}
                    inputRef={ref}
                    disabled={!watchFields[3]}
                    style={{ width: '80%' }}
                    {...fieldProps}
                  />
                )}
              />
            </Stack>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            color="primary"
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={updateProcessing}
          >
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default observer(PaymentForm)
