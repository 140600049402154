import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { Typography } from '@mui/material'
import styles from './styles'

const useStyles = makeStyles(styles)

const Status = ({
  status,
  variant,
  text,
  reasonFailed,
}) => {
  const classes = useStyles({ status, text })

  return (
    <>
      <Typography
        variant={variant}
        className={classes.status}
      >
        {status}
      </Typography>
      {reasonFailed && (
        <Typography
          variant="h6"
          className={classes.reason}
        >
          {reasonFailed}
        </Typography>
      )}
    </>
  )
}

export default Status
