export default (theme) => ({
  sectionContainer: {
    display: 'flex',
    flexDirection: 'row',
    paddingLeft: '1rem',
  },
  label: {
    flex: 1,
  },
  description: {
    flex: 2,
  },
  bookingContainer: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: theme.color.seashell,
    padding: '0.5rem 0 0.5rem 1rem',
  },
  bookingTitle: {
    flex: 1,
  },
  bookingSubtitle: {
    flex: 2,
  },
  divider: {
    margin: '1rem 0 0',
    background: theme.color.wheat,
  },
  message: {
    paddingLeft: '1rem',
    paddingTop: '1rem',
  },
  infoItem: {
    marginTop: '0.3rem',
  },
})
