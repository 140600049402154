import { makeAutoObservable, flow } from 'mobx'

import {
  TODAY_MOMENT,
  dateOnlyFormat,
} from 'admin/utils/helper'
import * as srv from 'admin/services'
import handleError from 'admin/services/error-handler'

export default class ActionLogStore {
  loading = false
  date = dateOnlyFormat(TODAY_MOMENT)
  list = []
  booking = null
  total = 0

  query = {
    columnIndex: -1,
    sortBy: 'createdAt',
    sortDirection: 'desc',
  }

  currentPage = 0
  rowsPerPage = 10

  constructor() {
    makeAutoObservable(this, {
      fetch: flow,
    })
  }

  updatePage = (page) => {
    this.currentPage = page
  }

  updateRowsPerPage = (perPage) => {
    this.rowsPerPage = perPage
  }

  updateQuery = (key, value, resetPage = false) => {
    this.query[key] = value
    if (resetPage) this.currentPage = 0
  }

  resetSort = () => {
    this.query.sortBy = 'createdAt'
    this.query.sortDirection = 'desc'
  }

  get queryParams() {
    const params = {
      sortBy: this.query.sortBy,
      sortDirection: this.query.sortDirection,
      limit: this.rowsPerPage,
      offset: this.currentPage * this.rowsPerPage,
    }
    return params
  }

  setDate = (value) => {
    this.date = value
  }

  resetQuery = () => {
    this.query = {
      columnIndex: -1,
      sortBy: 'id',
      sortDirection: 'asc',
    }
    this.currentPage = 0
  }

  * fetch(params) {
    try {
      this.loading = true
      const response = yield srv.fetchAction({
        ...this.queryParams,
        ...params,
      })
      if (response.ok) {
        this.list = response.data.list
        this.booking = response.data.booking
        this.total = response.data.total
        return response.data
      }
      throw handleError(response)
    } catch (error) {
      return null
    } finally {
      this.loading = false
    }
  }
}
