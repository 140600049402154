export default function styles(theme) {
  return {
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
    },
    formContainer: {
      width: '100%',
      maxWidth: '30rem',
      padding: '1.63rem 0.5rem 0.4rem 0.5rem',
    },
    notificationImage: {
      height: '6.9rem',
      width: '7.12rem',
      marginBottom: '1.8rem',
    },
    mainText: {
      fontWeight: 'bold',
      marginBottom: '0.44rem',
    },
    submainText: {
      textAlign: 'center',
    },
    button: {
      marginTop: '2.19rem',
      color: theme.color.white,
      fontWeight: '600',
      textTransform: 'none',
    },
    formImage: {
      height: '5.63rem',
      width: '5.63rem',
      marginBottom: '2.3rem',
    },
  }
}
