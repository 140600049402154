import React, { useState, useEffect, useCallback } from 'react'
import { observer } from 'mobx-react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm, Controller } from 'react-hook-form'
import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

import CustomizedDialogTitle from 'admin/components/dialog-title'
import TextField from 'admin/components/textfield'

import { useStores, useApiRequest } from 'admin/hooks'
import * as srv from 'admin/services'

import schema from './schema'

import styles from '../styles'

const useStyles = makeStyles(styles)

const RoleForm = () => {
  const classes = useStyles()
  const { roleStore, notificationStore } = useStores()

  const [permissions, setPermissions] = useState([])
  const [rolePermissions, setRolePermissions] = useState(null)

  const {
    control,
    watch,
    handleSubmit: handleSubmitForm,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: { ...roleStore.DEFAULT },
  })
  const watchRole = watch()
  const {
    request: fetchPermissions,
    isLoading: loadingPermissions,
  } = useApiRequest(srv.fetchPermissions, { blocking: false })

  const {
    request: addRole,
    isLoading: addProcessing,
  } = useApiRequest(srv.addRole, { blocking: true })
  const {
    request: updateRole,
    isLoading: updateProcessing,
  } = useApiRequest(srv.updateRole, { blocking: true })

  const handleClose = useCallback(() => {
    if (addProcessing || updateProcessing) return
    roleStore.setCloseDialog('form')
  }, [])

  const handleSubmit = useCallback(async (value) => {
    const isUpdate = !!value.id
    const request = (isUpdate) ? updateRole : addRole
    const res = await request({ ...value, permissions: rolePermissions })
    if (!res) return

    await roleStore.reload(true)
    if (isUpdate) {
      notificationStore.setSuccess('Update role success')
    } else {
      notificationStore.setSuccess('Create role success')
    }

    roleStore.setCloseDialog('form')
  }, [rolePermissions])

  useEffect(() => {
    if (!roleStore.selected) {
      reset({ ...roleStore.DEFAULT })
      return
    }
    reset({ ...roleStore.selected })
  }, [roleStore.selected])

  const init = useCallback(async () => {
    const response = await fetchPermissions()
    setPermissions(response.data.list)
  }, [])

  useEffect(() => {
    init()
  }, [])

  useEffect(() => {
    if (!permissions || permissions.length === 0) return
    // Init role permissions
    const rps = permissions.reduce((obj, p) => {
      obj[p.id] = {
        read: false, create: false, update: false, delete: false,
      }
      if (roleStore.selected && roleStore.selected.permissions && roleStore.selected.permissions.length > 0) {
        const roleP = roleStore.selected.permissions.find((rp) => rp.id === p.id)
        if (roleP && roleP.RolePermission) {
          const rp = roleP.RolePermission
          obj[p.id] = {
            read: rp.read, create: rp.create, update: rp.update, delete: rp.delete,
          }
        }
      }
      return obj
    }, {})
    setRolePermissions(rps)
  }, [permissions, roleStore.selected])

  if (loadingPermissions || rolePermissions === null) {
    return (
      <div className={classes.loadingProgress}>
        <CircularProgress size="5rem" />
      </div>
    )
  }

  return (
    <Dialog
      open={roleStore.dialog.form}
      onClose={handleClose}
      maxWidth="sm"
      fullWidth
    >
      <CustomizedDialogTitle onClose={handleClose}>
        {watchRole.id ? 'Edit Role' : 'Create Role'}
      </CustomizedDialogTitle>
      <form
        noValidate
        onSubmit={handleSubmitForm(handleSubmit)}
        autoComplete="off"
      >
        <DialogContent>
          <FormControl
            fullWidth
            margin="normal"
          >
            <Controller
              control={control}
              name="name"
              render={({
                field,
                fieldState: { error },
              }) => (
                <TextField
                  label="Role"
                  InputLabelProps={{ shrink: true }}
                  error={!!error?.message}
                  helperText={error?.message}
                  {...field}
                />
              )}
            />
          </FormControl>
          {permissions && permissions.map((permission) => (
            <div
              key={permission.id}
              className={classes.permissionItem}
            >
              <div className={classes.permissionLabel}>
                {permission.name}
              </div>
              <div className={classes.permissionCheckboxes}>
                {['read', 'create', 'update', 'delete'].map((action) => (
                  <FormControlLabel
                    key={`permission-${permission.id}-${action}`}
                    label={action}
                    labelPlacement="top"
                    classes={{ label: classes.checkboxLabel }}
                    control={(
                      <Checkbox
                        color="default"
                        checked={rolePermissions[permission.id] && rolePermissions[permission.id][action]}
                        onChange={(event) => {
                          const rp = rolePermissions[permission.id]
                          const newRolePermissions = {
                            ...rolePermissions,
                            [permission.id]: {
                              ...rp, [action]: event.target.checked,
                            },
                          }
                          setRolePermissions(newRolePermissions)
                        }}
                      />
                    )}
                  />
                ))}
              </div>
            </div>
          ))}
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            color="primary"
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={addProcessing || updateProcessing}
          >
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default observer(RoleForm)
