import React from 'react'
import { Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import styles from './styles'

const useStyles = makeStyles(styles)

const InformationTab = ({ data }) => {
  const classes = useStyles()

  return (
    <div className={classes.titleSection}>
      {data && data.map((info) => {
        if (!info.value) return null
        return (
          <div className={classes.infoItem} key={info.label}>
            <Typography variant="h4" className={classes.label}>
              {info.label}
            </Typography>
            <Typography variant="h4" className={classes.value}>
              {info.value}
            </Typography>
          </div>
        )
      })}
    </div>
  )
}

export default InformationTab
