export default (theme) => ({
  headbar: {
    display: 'flex',
  },
  title: {
    padding: '1rem',
  },
  details: {
    padding: '0 1rem 0',
  },
  info: {
    display: 'table-row',
  },
  titleLabel: {
    fontWeight: 500,
    display: 'table-cell',
    padding: '0 3rem 1.2rem 0',
    color: theme.color.dustyGray,
    lineHeight: '1rem',
    letterSpacing: '1px',
  },
})
