export default function styles() {
  return {
    button: {
      marginRight: '1rem',
    },
    range: {
      marginBottom: '1rem',
    },
  }
}
