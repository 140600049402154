export default () => {
  return {
    driver: {
      display: 'flex',
    },
    label: {
      marginLeft: '0.5rem',
      textTransform: 'capitalize',
    },
    preferred: {
      marginLeft: '0.2rem',
    },
  }
}
