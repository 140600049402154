export default function styles(theme) {
  return {
    container: {
      display: 'flex',
      flexWrap: 'wrap',
      width: '100%',
      marginBottom: '0.5rem',
    },
    wrapper: {
      display: 'flex',
      flexDirection: 'row',
      margin: '0.5rem 0.2rem 0.5rem',
    },
    tabsRoot: {
      borderRadius: '5px',
      marginRight: '1rem',
    },
    tabsIndicator: {
      display: 'none',
    },
    tabListing: {
      textTransform: 'capitalize',
      border: `0.5px solid ${theme.color.secondary}`,
    },
    tabSelected: {
      borderBottomWidth: 0,
      background: theme.color.secondary,
      color: `${theme.color.white} !important`,
      fontWeight: 600,
    },
    datePickerStartEnd: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
    },
    wrapperButton: {
      margin: '0 0 0 auto',
      alignSelf: 'center',
    },
    autocomplete: {
      margin: '0 0.25rem',
    },
    optionCustomer: {
      display: 'flex',
      justifyContent: 'flex-end',
      color: theme.color.dustyGray,
      marginRight: '1rem',
    },
    optionPhoneNumber: {
      marginLeft: '1rem',
    },
    upperSection: {
      display: 'flex',
      flexDirection: 'row',
      marginBottom: '0.5rem',
      alignItems: 'center',
      justifyContent: 'space-between',
      zIndex: '11',
    },
  }
}
