export default (theme) => ({
  drawer: {
    zIndex: 9,
    width: theme.global.menuWidth,
    flexShrink: 0,
    '@media print': {
      display: 'none',
    },
    [theme.breakpoints.down('lg')]: {
      width: theme.global.responsiveMenuWidth,
    },
  },
  drawerPaper: {
    width: theme.global.menuWidth,
    [theme.breakpoints.down('lg')]: {
      width: theme.global.responsiveMenuWidth,
    },
  },
  listItem: {
    '&$selected, &$selected:hover': {
      color: theme.color.white,
      backgroundColor: theme.palette.primary.main,
    },
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
    [theme.breakpoints.down('lg')]: {
      display: 'block',
      padding: '0.5rem',
    },
  },
  selected: {
    '&$selected': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  iconDrawer: {
    color: theme.color.white,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'nowrap',
  },
  listItemText: {
    fontSize: '1rem',
    letterSpacing: '1px',
    marginLeft: '0.5rem',
    [theme.breakpoints.down('lg')]: {
      textAlign: 'center',
      marginLeft: '0',
      fontSize: '0.625rem',
      marginTop: '0',
    },
  },
  icon: {
    [theme.breakpoints.down('lg')]: {
      width: '100%',
      textAlign: 'center',
    },
  },
  containerLogo: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '0.5rem',
    minHeight: '64px',
  },
  toolbar: {
    paddingLeft: '16px',
    paddingRight: '16px',
    backgroundColor: theme.color.primary,
    height: '60px',
    borderBottom: '1px solid #fff',
    cursor: 'pointer',
    [theme.breakpoints.down('lg')]: {
      minHeight: '60px',
      justifyContent: 'center',
    },
  },
  accountIcon: {
    marginRight: '0.5rem',
    [theme.breakpoints.down('lg')]: {
      marginRight: 0,
    },
    width: '1.5rem',
    height: '1.5rem',
  },
  logoutDrawer: {
    [theme.breakpoints.up('lg')]: {
      backgroundColor: theme.color.white,
      position: 'absolute',
      left: 0,
      bottom: 10,
    },
    width: '100%',
  },
  logoutIcon: {
    [theme.breakpoints.down('lg')]: {
      width: '100%',
      textAlign: 'center',
    },
  },
  sessionInfo: {
    display: 'flex',
    flexDirection: 'column',
  },
  listContainer: {
    overflow: 'auto',
    maxHeight: '80%',
  },
})
