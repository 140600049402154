import React, { useEffect } from 'react'
import { observer } from 'mobx-react'
import {
  TableBody,
  TableCell,
  TableRow,
} from '@mui/material'
import { useStores } from 'admin/hooks'
import { formatDate, formatTimeOnly } from 'admin/utils/helper'
import InfoSectionTable from '../info-section-table'

const LogTable = ({
  requestParams,
}) => {
  const { actionLogStore } = useStores()

  const user = (row) => {
    if (Number(row.subjectId) === 0) return 'Admin (System)'
    return row?.subjectDetail?.name
  }

  useEffect(() => {
    actionLogStore.fetch(requestParams)
  }, [requestParams])

  return (
    <InfoSectionTable
      columns={['Date', 'Action', 'Updated By']}
      rows={(
        <TableBody>
          {actionLogStore.list.map((row) => (
            <TableRow key={`log-${row.id}`}>
              <TableCell component="th" scope="row">
                <div>
                  {formatDate(row.createdAt)}
                </div>
                <div>
                  {formatTimeOnly(row.createdAt)}
                </div>
              </TableCell>
              <TableCell>{row.action}</TableCell>
              <TableCell>{user(row)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      )}
    />
  )
}

export default observer(LogTable)
