export default (theme) => ({
  layout: {
    height: '223px',
  },
  table: {
    minWidth: 700,
    fontWeight: 600,
  },
  headBooking: {
    backgroundColor: theme.palette.secondary.main,
    color: `${theme.color.white} !important`,
    fontWeight: 600,
  },
  body: {
    fontSize: 14,
    fontWeight: 600,
  },
  tableRow: {
    '&:nth-of-type(even)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  },
  button: {
    textTransform: 'none',
    borderRadius: '10px',
    fontSize: '0.8rem',
    '&:active': {
      boxShadow: 'none',
      backgroundColor: theme.palette.secondary.main,
      color: theme.color.white,
    },
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: theme.palette.secondary.main,
      color: theme.color.white,
    },
  },
  searchWrapper: {
    margin: '2rem 0',
    display: 'flex',
  },
  searchButton: {
    height: '100%',
    marginLeft: '14px',
  },
  tableContainer: {
    marginBottom: '1rem',
  },
})
