import React, { useState, useCallback } from 'react'
import { observer } from 'mobx-react'
import { computed } from 'mobx'
import makeStyles from '@mui/styles/makeStyles'
import { useStores } from 'admin/hooks'
import { useHistory } from 'react-router-dom'

import { Stack, Button } from '@mui/material'

import { APP_PAYMENT_METHOD } from 'admin/config'

import CustomizedTabs from 'admin/components/tabs'
import InfoSection from 'admin/components/info-section'
import HeadBar from 'admin/components/head-bar'
import Information from './information'
import History from './history'
import Invoice from './invoice'
import App from './app'

import styles from './styles'

const useStyles = makeStyles(styles)

const BookingInfo = () => {
  const classes = useStyles()
  const route = useHistory()
  const [activeTab, setActiveTab] = useState(0)
  const {
    infoSectionStore,
  } = useStores()

  const { selected: booking } = infoSectionStore

  const handleChangeTab = useCallback((event, value) => {
    setActiveTab(value)
  }, [])

  const invoiceAPP = computed(() => {
    const invoices = (booking && Array.isArray(booking.invoices)) ? booking.invoices : []
    return invoices.some(invoice => {
      const findInvoice = (
        invoice.paymentMethod === APP_PAYMENT_METHOD.value ||
        !!invoice.paymentRemark
      )
      return findInvoice
    })
  }).get()

  const label = [
    'Booking',
    'History',
    'Invoice',
  ]

  if (invoiceAPP) {
    label.push(APP_PAYMENT_METHOD.display)
  } else if (activeTab === 3) {
    setActiveTab(2)
  }

  const actionLog = useCallback(() => {
    infoSectionStore.reset()
    if (booking?.id) route.push(`/booking-log/${booking.id}`)
  }, [booking?.id])

  return (
    <InfoSection
      title="Booking"
    >
      <div className={classes.parentContainer}>
        {booking && (
          <>
            <Stack
              direction="row"
              justifyContent="space-between"
            >
              <HeadBar
                data={[
                  {
                    label: 'Phone Number',
                    value: booking.customer?.phoneNumber || booking.customer?.originalData?.mobileNo,
                  },
                  {
                    label: 'Name',
                    value: booking.customer?.name || booking.customer?.originalData?.name,
                  },
                  {
                    label: 'Booking Code',
                    value: booking.code,
                  },
                ]}
              />
              <Button
                variant="outlined"
                className={classes.logButton}
                onClick={actionLog}
              >
                Action Log
              </Button>
            </Stack>
            <CustomizedTabs
              activeTab={activeTab}
              handleChangeTab={handleChangeTab}
              labels={label}
            />
            <div className={classes.infoContainer}>
              {activeTab === 0 && <Information />}
              {activeTab === 1 && <History />}
              {activeTab === 2 && <Invoice />}
              {(activeTab === 3 && invoiceAPP) && <App />}
            </div>
          </>
        )}
      </div>
    </InfoSection>
  )
}

export default observer(BookingInfo)
