export default (theme) => ({
  titleSection: {
    color: theme.palette.text.primary,
    padding: '1rem',
    listStyle: 'none',
  },
  infoItem: {
    paddingBottom: '1rem',
  },
  label: {
    fontSize: '0.7rem',
    fontWeight: 600,
    paddingRight: '1rem',
    lineHeight: 'inherit',
  },
  value: {
    wordWrap: 'break-word',
  },
})
