import { createTheme } from '@mui/material/styles'

// Custom global variables
const global = {
  // Maximum content width when resolution is large
  maxContentWidth: 1120,
  // Width of logo
  logoWidth: 110,
  menuWidth: '200px',
  responsiveMenuWidth: '120px',
}

// Browser width breakpoints
const breakpoints = {
  values: {
    xs: 0,
    sm: 478,
    md: 992,
    lg: global.maxContentWidth,
    xl: 1920,
  },
}

// Our color options
const color = {
  red: '#E4627B',
  green: '#4CD964',
  brown: '#FACA6C',
  purple: '#9784E8',
  cyan: '#70FFE9',
  transparent: 'rgba(0, 0, 0, 0)',
  opaque: 'rgba(255, 255, 255, 0.94)',
  white: '#fff',
  floralWhite: '#F9F6F2',
  seashell: '#FBF9F7',
  cinnabar: '#E3AC24',
  lightBrown: '#C4A37A',
  black: '#251500',
  lightBlack: 'rgba(37, 21, 0, 0.6)',
  gray: '#f0f0f0',
  dustyGray: '#969696',
  lightGray: '#868686',
  pearlBush: '#E6E0CF',
  shadow: 'rgba(50, 68, 82, 0.2)',
  camel: 'rgba(196, 163, 122, 0.6)',
  paarl: '#B74826',
  coffee: '#726753',
  redBerry: '#880000',
  camarone: '#075C00',
  darkGreen: '#00A424',
  redCherry: '#E01205',
  darkSilver: '#7C7366',
  linen: '#F2EEE1',
  alpine: '#b08d2d',
  indochine: '#B26B00',
  wheat: '#F5E1AD',
  whatsapp: '#25D366',
  candyAppleRed: '#F20F00',
  pippin: '#FFE2E2',
  limeGreen: '#21A700',
  orange: '#CE6403',
}

// https://material.io/resources/color
color.primary = color.cinnabar
color.primaryLight = '#ffdd5a'
color.primaryDark = '#ad7d00'
color.secondary = color.lightBrown
color.secondaryLight = '#f8d4a9'
color.secondaryDark = '#92744e'
color.error = color.redCherry
color.textPrimary = color.black
color.textSecondary = color.lightBlack

// Material UI default color palettes
const palette = {
  primary: {
    main: color.primary,
  },
  secondary: {
    main: color.secondary,
  },
  error: {
    main: color.error,
  },
  text: {
    primary: color.textPrimary,
    secondary: color.textSecondary,
  },
  action: {
    hover: color.floralWhite,
  },
  whatsapp: {
    main: color.whatsapp,
  },
}

// Material UI default font typography
const typography = {
  fontFamily: 'Open Sans',
  htmlFontSize: 16,
  fontSize: 16,
  color: 'textPrimary',
  h1: {
    fontSize: '2.125rem',
    fontWeight: 600,
  },
  h2: {
    fontSize: '1.5rem',
    fontWeight: 600,
  },
  h3: {
    fontSize: '1.25rem',
    fontWeight: 600,
  },
  h4: {
    fontSize: '1rem',
    fontWeight: 600,
  },
  h5: {
    fontSize: '0.875rem',
    fontWeight: 600,
  },
  h6: {
    fontSize: '0.75rem',
    fontWeight: 600,
  },
  body1: {
    fontWeight: 400,
    whiteSpace: 'pre-wrap',
  },
  body2: {
    fontWeight: 400,
    whiteSpace: 'pre-wrap',
  },
  subtitle1: {
    fontWeight: 300,
  },
  subtitle2: {
    fontWeight: 300,
  },
  caption: {
    fontWeight: 400,
  },
}

// Material UI component overrides
// https://next.material-ui.com/customization/theme-components/
const components = {
  MuiButtonBase: {
    defaultProps: {
      disableRipple: false, // Set to true for no more ripple, on the whole application 💣!
    },
  },
  MuiListItemText: {
    styleOverrides: {
      inset: {
        marginLeft: 'unset',
        paddingLeft: 15,
      },
    },
  },
  MuiButton: {
    styleOverrides: {
      root: {
        textTransform: 'none',
        fontWeight: 600,
      },
      containedPrimary: {
        color: '#ffffff',
      },
      containedSecondary: {
        color: '#ffffff',
      },
    },
  },
  MuiInput: {
    defaultProps: {
      autoComplete: 'off',
    },
  },
  MuiCheckbox: {
    defaultProps: {
      color: 'primary',
    },
  },
  MuiPaper: {
    defaultProps: {
      square: true,
    },
  },
  MuiRadio: {
    defaultProps: {
      color: 'primary',
    },
  },
  MuiSwitch: {
    defaultProps: {
      color: 'primary',
    },
  },
  MuiTextField: {
    defaultProps: {
      size: 'small',
    },
  },
  MuiLink: {
    defaultProps: {
      color: 'primary',
      underline: 'none',
    },
  },
  MuiFormHelperText: {
    styleOverrides: {
      root: {
        marginLeft: 0,
      },
    },
  },
}

const muiTheme = {
  global,
  breakpoints,
  color,
  palette,
  typography,
  components,
}

export default createTheme(muiTheme)
