import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
} from 'react'
import { observer } from 'mobx-react'
import makeStyles from '@mui/styles/makeStyles'
import {
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  Button,
} from '@mui/material'

import Big from 'big.js'

import { getTags } from 'admin/utils/helper'

import { useStores, useApiRequest } from 'admin/hooks'
import * as srv from 'admin/services'
import { APP_PAYMENT_METHOD, BOOKING_STATUS } from 'admin/config'
import InvoiceAndIcon from 'admin/components/invoice-icon'
import BookingImages from '../booking-image'

import styles from './styles'

const useStyles = makeStyles(styles)

const statusColor = (value) => {
  if (value === 'paid') return '#B26B00'
  if (value === 'unpaid') return '#E01205'
  if (value === 'partial') return '#E3AC24'
  if (value === 'received') return '#075C00'
  return null
}

const InvoicesTable = ({ rows }) => {
  const totalRevenue = useMemo(() => {
    return rows.reduce((accum, invoice) => {
      if (!invoice.amount) return accum
      return accum.plus(invoice.amount)
    }, new Big(0))
  }, [rows])

  return (
    <Table
      aria-label="invoices-table"
      style={{ marginBottom: '1rem' }}
    >
      <TableBody>
        {rows.map((row) => (
          <TableRow key={row.prestoId}>
            <TableCell>
              <InvoiceAndIcon
                key={row.prestoId}
                invoice={row}
              />
            </TableCell>
            <TableCell>
              {row?.paymentMethod === APP_PAYMENT_METHOD.value ? APP_PAYMENT_METHOD.display : row?.paymentMethod}
            </TableCell>
            <TableCell
              style={{
                textTransform: 'capitalize',
                color: statusColor(row.paymentStatus),
              }}
            >
              <Stack>
                <Typography variant="h4">
                  {row.paymentStatus}
                </Typography>
                {row.orderStatus === 'Cancelled' && (
                  <Typography variant="h4">
                    {row.orderStatus}
                  </Typography>
                )}
              </Stack>
            </TableCell>
            <TableCell>
              ${Number(row.amount).toFixed(2)}
            </TableCell>
          </TableRow>
        ))}
        <TableRow>
          <TableCell
            colSpan={3}
            style={{ fontWeight: 600 }}
          >
            Total Revenue
          </TableCell>
          <TableCell>
            ${totalRevenue.toFixed(2)}
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  )
}

const Invoice = () => {
  const classes = useStyles()
  const {
    infoSectionStore,
    bookingStore,
    notificationStore,
  } = useStores()
  const { selected } = infoSectionStore

  const [invoiceList, setInvoiceList] = useState([])
  const [deliveryBookingId, setDeliveryBookingId] = useState('')

  const {
    request: searchInvoice,
  } = useApiRequest(srv.searchInvoice, { blocking: false })

  const {
    request: chargeCCManual,
    isLoading: chargeProcessing,
  } = useApiRequest(srv.chargeCCManual, { blocking: true })

  useEffect(() => {
    const fetchInvoiceList = async () => {
      if (
        Array.isArray(selected.invoices) &&
        !!selected.invoices.length
      ) {
        setInvoiceList(selected.invoices)
        setDeliveryBookingId(selected.id)
      } else if (
        !selected?.bookingTags.find(itemTag => itemTag.type === 'delivery')
      ) {
        const params = {
          pickupBookingId: selected.id,
        }
        const response = await searchInvoice(params)
        if (response?.ok) {
          setInvoiceList(response.data.list)
          if (response.data.list.length > 0) {
            setDeliveryBookingId(response.data.list[0].bookingId)
          }
        }
      } else {
        setInvoiceList([])
      }
    }
    fetchInvoiceList()
  }, [selected])

  const handleSubmit = useCallback(async () => {
    if (!selected.customer.stripeId) {
      notificationStore.setError('Customer does not have an account')
      return
    }
    if (!deliveryBookingId) return
    const response = await chargeCCManual({
      customerId: selected.customerId,
      id: deliveryBookingId,
    })
    if (!response) return

    notificationStore.setSuccess('Charge invoice successfully')
    bookingStore.reload()
  }, [deliveryBookingId, selected])

  const isPayNow = useMemo(() => {
    return invoiceList.some(invoice => (invoice.paymentMethod || invoice.originalResponse.paymentTypes?.name) === 'PayNow')
  }, [invoiceList])

  const isCharge = useMemo(() => {
    const { delivery } = getTags(selected.bookingTags)
    let bookingStatus = true // default for pickup booking status
    if (delivery.length < 0) bookingStatus = selected.status !== BOOKING_STATUS.COMPLETED
    return (
      bookingStatus &&
      invoiceList.some(invoice => (
        invoice.paymentMethod === APP_PAYMENT_METHOD.value &&
        // dont need to check for invoice.originalResponse.paymentTypeId === 31
        invoice.paymentStatus === 'unpaid' &&
        (invoice.orderStatus !== 'Completed' || invoice.orderStatus !== 'Cancelled')
      ))
    )
  }, [invoiceList, selected])

  return (
    <div>
      {invoiceList.length === 0 ? (
        <div className={classes.center}>
          <Typography variant="h4">
            No Invoice For This Booking
          </Typography>
        </div>
      ) : (
        <div className={classes.titleSection}>
          <div className={classes.infoItem}>
            <div className={classes.titleAndAction}>
              <Typography
                variant="h6"
                className={classes.labelDelivery}
              >
                Delivery Invoices
              </Typography>
              {isCharge && (
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={handleSubmit}
                  disabled={chargeProcessing}
                >
                  {chargeProcessing ? 'Charging Invoice..' : 'Charge Invoice'}
                </Button>
              )}
            </div>
            <InvoicesTable rows={invoiceList} />
          </div>
          {isPayNow && (
            <BookingImages
              type="receipt"
              title="Upload booking image, e.g.: receipt image"
            />
          )}
        </div>
      )}
    </div>
  )
}

export default observer(Invoice)
