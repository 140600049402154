import React, { useCallback, useState, useEffect, Suspense } from 'react'
import { observer } from 'mobx-react'
import { useLocation } from 'react-router-dom'
import makeStyles from '@mui/styles/makeStyles'
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  LinearProgress,
} from '@mui/material'
import { formatDate, isDisableCheck } from 'admin/utils/helper'

import DateRangePicker from 'admin/components/date-range-picker'

import DateRangeIcon from '@mui/icons-material/DateRange'
import FilterListIcon from '@mui/icons-material/FilterList'

import { useStores, useApiRequest } from 'admin/hooks'
import Page from 'admin/components/page'
import ExportCSV from 'admin/components/export-csv'
import * as srv from 'admin/services'

import DeleteDialog from 'admin/components/dialog'
import InvoicePaymentForm from './invoice-payment/form'
import InvoicePaymentInfo from './info'
import InvoicePaymentDataGrid from './datagrid'
import styles from './styles'
import Filter from './filter'

const DialogExportCSV = React.lazy(() => import('admin/components/export-csv'))
const DialogImportPreview = React.lazy(() => import('./preview'))

const useStyles = makeStyles(styles)

const Filters = observer(({ setCsvList }) => {
  const classes = useStyles()
  const location = useLocation()
  const { invoicePaymentStore, notificationStore } = useStores()
  const [anchorEl, setAnchorEl] = useState(null)
  const [isChecked, setIsChecked] = useState(false)

  const [date, setDate] = useState({
    key: 'selection',
    startDate: invoicePaymentStore.startDate,
    endDate: invoicePaymentStore.endDate,
  })

  const {
    request: fetchSMSCache,
  } = useApiRequest(srv.fetchSMSCache, { blocking: false })

  useEffect(() => {
    const fetchSMS = async () => {
      try {
        const response = await fetchSMSCache()
        setIsChecked(Boolean(Number(response.data.isChecked)))
      } catch (error) {
        notificationStore.setError(error)
      }
    }
    fetchSMS()
  }, [])

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleSelect = useCallback(value => {
    const { startDate, endDate } = value.selection
    setDate(prev => ({
      ...prev,
      startDate,
      endDate,
    }))
    invoicePaymentStore.updateStartDate(startDate)
    invoicePaymentStore.updateEndDate(endDate)
  }, [])

  const handleChangeUpload = async (e) => {
    e.preventDefault()
    try {
      const formData = new FormData()
      formData.append('file', e.target.files[0])
      const results = await srv.uploadExcel(formData, e.target.files[0])
      if (!Array.isArray(results.data?.groups)) throw new Error('Data not valid')
      invoicePaymentStore.setFileName(e.target.files[0]?.name)
      setCsvList(results.data)
      if (!!results.data && !!results.data.groups.length) invoicePaymentStore.setDialog('updatePayment', true)
    } catch (err) {
      notificationStore.setError(err)
    }
  }

  const handleOpenCSVFilter = useCallback(() => {
    invoicePaymentStore.setDialog('export', true)
  }, [])

  const renderIcon = () => {
    return (
      <>
        {invoicePaymentStore.filterLength > 0 ? (
          <div className={classes.filterNumber}>
            {invoicePaymentStore.filterLength}
          </div>
        ) : (
          <FilterListIcon />
        )}
        <span>
          Filter
        </span>
      </>
    )
  }

  const handleChangeCheckBox = async (e) => {
    try {
      setIsChecked(e.target.checked)
      await invoicePaymentStore.checkSmsReminder({
        isChecked: Number(e.target.checked),
      })
    } catch (error) {
      notificationStore.setError(error)
    }
  }

  return (
    <Box sx={{
      borderBottom: 1,
      borderColor: 'divider',
      display: 'flex',
      alignItems: 'center',
      marginBottom: '1rem',
    }}
    >
      <div className={classes.filterWrapper}>
        <div className={classes.searchField}>
          <Button
            onClick={() => {
              invoicePaymentStore.setOpenDialog(null, 'filter')
            }}
            variant="outlined"
            color="secondary"
          >
            {renderIcon()}
          </Button>
        </div>
        <div className={classes.uploadButton}>
          <label htmlFor="contained-button-file">
            <input
              className={classes.input}
              id="contained-button-file"
              multiple
              onChange={handleChangeUpload}
              type="file"
            />
            <Button
              variant="contained"
              color="secondary"
              component="span"
            >
              Update Invoice Payment
            </Button>
          </label>
        </div>
        <div className={classes.searchField}>
          <Button
            variant="contained"
            color="secondary"
            component="span"
            onClick={handleOpenCSVFilter}
          >
            Export
          </Button>
        </div>
        <div className={classes.uploadButton}>
          <ExportCSV />
        </div>
        <div className={classes.datePickerStartEnd}>
          <IconButton onClick={handleClick} size="large">
            <DateRangeIcon />
          </IconButton>
          <span>
            {formatDate(date.startDate)} - {formatDate(date.endDate)}
          </span>
        </div>
        <DateRangePicker
          anchorEl={anchorEl}
          handleClose={handleClose}
          handleSelect={handleSelect}
          selectionRange={date}
          editableDateInputs={location.pathname === '/invoice-payment'}
        />
      </div>
      <div className={classes.checkBoxContainer}>
        <FormControlLabel
          classes={{
            root: classes.formControlLabel,
          }}
          control={(
            <Checkbox
              checked={isChecked}
              onChange={handleChangeCheckBox}
              color="primary"
              disabled={isDisableCheck()}
            />
          )}
          label="Send SMS Notification"
        />
      </div>
    </Box>
  )
})

const InvoicePayment = observer(() => {
  const location = useLocation()
  const classes = useStyles()

  const [summary, setSummary] = useState(null)
  const [list, setList] = useState(null)
  const {
    invoicePaymentStore,
    notificationStore,
  } = useStores()

  const {
    request: bulkUpdateInvoice,
    isLoading: loading,
  } = useApiRequest(srv.bulkUpdateInvoice, { blocking: true })

  const {
    request: deletePayment,
  } = useApiRequest(srv.deletePayment, { blocking: true })

  useEffect(() => {
    invoicePaymentStore.fetch()
  }, [invoicePaymentStore.queryParams])

  useEffect(() => {
    return () => {
      invoicePaymentStore.resetQuery()
    }
  }, [])

  const handleClose = (reason) => {
    if (reason !== 'backdropClick') {
      invoicePaymentStore.setDialog('updatePayment', false)
      setList([])
    }
  }

  const renderLoading = () => {
    return (
      <LinearProgress
        classes={{
          root: classes.root,
          colorPrimary: classes.colorPrimary,
          barColorPrimary: classes.barColorPrimary,
        }}
      />
    )
  }

  const handleAccept = async () => {
    invoicePaymentStore.setDialog('updatePayment', false)
    invoicePaymentStore.setDialog('loading', true)
    const result = await bulkUpdateInvoice({ invoices: list })
    invoicePaymentStore.setDialog('loading', false)
    if (!result) {
      notificationStore.setError('Something went wrong')
      return
    }
    notificationStore.setSuccess('Update invoice payment success')
    await invoicePaymentStore.fetch()
    setList(result.groups)
  }

  const handleSetCsvList = (result) => {
    if (!result?.groups?.length) {
      notificationStore.setError('Data is empty or not valid format')
      return
    }
    setSummary(result.summary)
    setList(result.groups)
  }

  const onDelete = useCallback(async () => {
    if (
      !invoicePaymentStore.selected ||
      !invoicePaymentStore.selected.id
    ) return
    const response = await deletePayment(invoicePaymentStore.selected.id)
    if (!response) return
    notificationStore.setSuccess('Deleted')
    invoicePaymentStore.setCloseDialog('delete')
    invoicePaymentStore.reload(true)
  }, [invoicePaymentStore.selected])

  return (
    <Page>
      <Filters
        setCsvList={handleSetCsvList}
      />
      <Filter />
      {invoicePaymentStore.dialog.loading && renderLoading()}
      <Suspense fallback={<div>Loading...</div>}>
        <DialogImportPreview
          groups={list}
          fileName={invoicePaymentStore.fileName}
          summary={summary}
          open={invoicePaymentStore.dialog.updatePayment}
          onAccept={handleAccept}
          onClose={handleClose}
          loading={loading}
        />
        <DialogExportCSV />
      </Suspense>
      {location.pathname === '/invoice-payment' && <InvoicePaymentForm />}
      <InvoicePaymentDataGrid />
      <InvoicePaymentInfo />
      <DeleteDialog
        open={invoicePaymentStore.dialog.delete}
        title={`Do you want to delete ${invoicePaymentStore.selected?.referenceNumber} payment`}
        content="Payment data that deleted cannot be recovered. Make sure you know what you are doing"
        primaryActionLabel="Delete"
        onAccept={onDelete}
        onClose={() => { invoicePaymentStore.setCloseDialog('delete') }}
      />
    </Page>
  )
})

export default InvoicePayment
