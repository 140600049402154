import React, { useCallback } from 'react'
import { observer } from 'mobx-react'
import makeStyles from '@mui/styles/makeStyles'

import { Typography } from '@mui/material'

import CustomDataGrid from 'admin/components/data-grid'
import { formatDate } from 'admin/utils/helper'
import { useStores } from 'admin/hooks'

import styles from '../styles'

const useStyles = makeStyles(styles)

const columns = [
  {
    field: 'date',
    headerName: 'Date',
    width: 220,
    renderCell: (params) => (
      <Typography
        variant="body"
      >
        {formatDate(params.value)}
      </Typography>
    ),
  },
  {
    field: 'remarks',
    sortable: false,
    headerName: 'Remarks',
    flex: 0.7,
    minWidth: 150,
  },
  {
    field: 'type',
    sortable: false,
    headerName: 'Type',
    flex: 0.3,
    minWidth: 100,
    renderCell: (params) => (
      params.row.type === 'blocked_day' ? 'Blocked' : params.row.type
    ),
  },
]

const HolidayDataGrid = () => {
  const classes = useStyles()
  const { holidayStore } = useStores()

  const handleRowClick = useCallback((rowData) => {
    holidayStore.setOpenDialog(rowData.row, 'form')
  }, [])

  const handlePageChange = useCallback((params) => {
    holidayStore.updatePage(params)
  }, [])

  const handlePageSizeChange = useCallback((param) => {
    holidayStore.updateRowsPerPage(param)
  })

  const onSortModelChange = (param) => {
    if (param.length === 0) {
      holidayStore.resetQuery(holidayStore.query.year)
    }
    if (param.length === 1) {
      holidayStore.updateQuery('sortBy', param[0].field, true)
      holidayStore.updateQuery('sortDirection', param[0].sort, true)
    }
    return null
  }

  return (
    <CustomDataGrid
      rows={holidayStore.list}
      columns={columns}
      handleRowClick={handleRowClick}
      handlePageChange={handlePageChange}
      rowCount={holidayStore.total}
      pageSize={holidayStore.rowsPerPage}
      page={holidayStore.currentPage}
      handlePageSizeChange={handlePageSizeChange}
      loading={holidayStore.loading}
      onSortModelChange={onSortModelChange}
      rowHeight={75}
      overrideLayout={classes.overrideLayout}
      className={classes.dataGridHoliday}
    />
  )
}

export default observer(HolidayDataGrid)
