import React from 'react'
import { SvgIcon } from '@mui/material'

const CancelIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <path d="M23,12,20.56,9.22l.34-3.68-3.61-.82L15.4,1.54,12,3,8.6,1.54,6.71,4.72,3.1,5.53l.34,3.68L1,12l2.44,2.78L3.1,18.47l3.61.82L8.6,22.47,12,21l3.4,1.46,1.89-3.18,3.61-.82-.34-3.68Zm-7,2.59L14.59,16,12,13.41,9.41,16,8,14.59,10.59,12,8,9.41,9.41,8,12,10.59,14.59,8,16,9.41,13.41,12Z" />
    </SvgIcon>
  )
}

export default CancelIcon
