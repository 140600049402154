import { api } from './api'

export const fetchHoliday = (query) => api.get('/holiday', query)
export const searchHoliday = (query) => api.get('/holiday/search', query)

export const addHoliday = (holiday) => api.post('/holiday', holiday)

export const updateHoliday = (holiday) => api.put(`/holiday/${holiday.id}`, holiday)

export const deleteHoliday = (holiday) => api.delete(`/holiday/${holiday.id}`)
