import React, { useCallback } from 'react'
import { observer } from 'mobx-react'
import {
  Stack,
  Typography,
} from '@mui/material'

import { useStores } from 'admin/hooks'
import CustomDataGrid from 'admin/components/data-grid'

const columns = [
  {
    field: 'transactionDate',
    headerName: 'Payment Date',
    width: 300,
    renderCell: (params) => params.row.transactionDate,
  },
  {
    field: 'wallet.customer.name',
    headerName: 'Customer',
    width: 300,
    renderCell: (params) => (
      <Stack direction="column">
        <Typography variant="body">
          { params.row.wallet.customer.name }
        </Typography>
        <Typography variant="body">
          { params.row.wallet.customer.phoneNumber }
        </Typography>
      </Stack>
    ),
  },
  {
    field: 'action',
    headerName: 'Type',
    width: 300,
    renderCell: (params) => (
      <Typography
        variant="body"
        style={{
          color: params.row.action !== 'topup' ? '#CE6403' : '#21A700',
        }}
      >
        {params.row.action}
      </Typography>
    ),
  },
  {
    field: 'amount',
    headerName: 'Credit Amount',
    width: 200,
    renderCell: (params) => (
      <Stack direction="column" alignItems="center">
        {params.row.action === 'topup' && (
          <Typography
            variant="body"
          >
            { `$${Number(params.row.paymentAmount).toFixed(2)}` }
          </Typography>
        )}
        <Typography
          variant="body"
          style={{
            color: params.row.action !== 'topup' ? '#CE6403' : '#21A700',
          }}
        >
          { params.row.action === 'topup' ? `+ $${Number(params.row.amount).toFixed(2)}` : `- $${Number(params.row.amount).toFixed(2)}` }
        </Typography>
      </Stack>
    ),
  },
]

const walletDataGrid = () => {
  const { walletStore, infoSectionStore } = useStores()

  const handlePageChange = useCallback((params) => {
    walletStore.updatedPage(params)
  }, [])

  const handlePageSizeChange = useCallback((params) => {
    walletStore.updateRowsPerPage(params)
  }, [])

  const handleRowClick = useCallback((rowData) => {
    infoSectionStore.select(rowData.row)
  }, [])

  return (
    <CustomDataGrid
      rows={walletStore.list}
      columns={columns}
      handleRowClick={handleRowClick}
      handlePageChange={handlePageChange}
      rowCount={walletStore.total}
      pageSize={walletStore.rowsPerPage}
      page={walletStore.currentPage}
      handlePageSizeChange={handlePageSizeChange}
      loading={walletStore.loading}
      rowHeight={100}
    />
  )
}

export default observer(walletDataGrid)
