import React from 'react'
import { observer } from 'mobx-react'
import { useStores } from 'admin/hooks'
import InformationTab from 'admin/components/information-tab'
import {
  formatDate,
  slotDisplay,
  formatDateHourAmPm,
} from 'admin/utils/helper'
import { APP_PAYMENT_METHOD } from 'admin/config'

const remarkText = (invoice) => {
  const invoiceAmount = Number(invoice.amount) || Number(invoice.originalResponse?.revenue) || 0
  const paymentAmount = Number(invoice.totalPayment)
  const total = paymentAmount - invoiceAmount

  return `${invoice.paymentRemark} $${total.toFixed(2)}`
}

const InvoiceInfo = () => {
  const { infoSectionStore } = useStores()

  const { selected: invoice } = infoSectionStore

  return invoice && (
    <InformationTab
      data={[
        {
          label: 'Name',
          value: invoice.customer?.originalData?.name,
        },
        {
          label: 'Phone',
          value: invoice.customer?.originalData?.mobileNo,
        },
        {
          label: 'Address',
          value: invoice.booking?.address || invoice.customer?.address,
        },
        {
          label: 'Invoice Date',
          value: formatDate(invoice.originalResponse?.droppedOffTime),
        },
        {
          label: 'Date Updated',
          value: formatDateHourAmPm(invoice.updatedAt),
        },
        {
          label: 'Ready Time',
          value: formatDate(invoice.originalResponse?.readyTime),
        },
        {
          label: 'Delivery Driver',
          value: invoice.booking?.driver?.code || '-',
        },
        {
          label: 'Booking Code',
          value: invoice.booking?.code || '-',
        },
        {
          label: 'Booking Date',
          value: invoice.booking?.date ? formatDate(invoice.booking.date) : '-',
        },
        {
          label: 'Slot',
          value: invoice.booking?.slot ? slotDisplay(invoice.booking.slot.start, invoice.booking.slot.end) : '-',
        },
        {
          label: 'Order Status',
          value: invoice.orderStatus || invoice.originalResponse?.statusTypes?.name || '-',
        },
        {
          label: 'Payment Method',
          value: invoice?.paymentMethod === APP_PAYMENT_METHOD.value ? APP_PAYMENT_METHOD.display : invoice?.paymentMethod,
        },
        {
          label: 'Items',
          value: invoice.originalResponse?.items,
        },
        {
          label: 'Total Quantity',
          value: `${invoice.items?.length} + ${invoice.accesoriesCount || 0}`,
        },
        {
          label: 'Sub Total',
          value: `$${Number(invoice.amount).toFixed(2) || Number(invoice.originalResponse?.revenue).toFixed(2) || 0}`,
        },
        {
          label: 'Payment amount',
          value: invoice.totalPayment ? `$${invoice.totalPayment.toFixed(2)}` : '-',
        },
        {
          label: 'Payment remarks',
          value: invoice.paymentRemark ? remarkText(invoice) : '-',
        },
      ]}
    />
  )
}

export default observer(InvoiceInfo)
