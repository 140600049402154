import React from 'react'
import makeStyles from '@mui/styles/makeStyles'

import styles from './styles'

const useStyles = makeStyles(styles)

const Page = ({ children, lockScroll = false }) => {
  const classes = useStyles({ lockScroll })
  return (
    <div
      className={classes.page}
    >
      {children}
    </div>
  )
}

export default Page
