import React, { useCallback, useEffect } from 'react'
import { observer } from 'mobx-react'
import { useParams } from 'react-router-dom'

import {
  Typography,
  Stack,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

import { useStores } from 'admin/hooks'
import Page from 'admin/components/page'
import HeadBar from 'admin/components/head-bar'
import CustomDataGrid from 'admin/components/data-grid'
import {
  formatDate,
  formatTimeOnly,
} from 'admin/utils/helper'

import Info from './info'
import styles from './styles'

const useStyles = makeStyles(styles)

const LogDataGrid = () => {
  const { id } = useParams()
  const classes = useStyles()
  const { infoSectionStore, actionLogStore } = useStores()

  const handlePageChange = useCallback((params) => {
    actionLogStore.updatePage(params)
  }, [])

  const handlePageSizeChange = useCallback((param) => {
    actionLogStore.updateRowsPerPage(param)
  }, [])

  const handleRowClick = useCallback((rowData) => {
    infoSectionStore.select(rowData.row)
  }, [])

  const onSortModelChange = (param) => {
    if (param.length === 1) {
      actionLogStore.updateQuery('sortBy', param[0].field, true)
      actionLogStore.updateQuery('sortDirection', param[0].sort, true)
      return
    }
    actionLogStore.resetSort()
  }

  useEffect(() => {
    const params = {
      objectId: id,
      object: 'booking',
    }
    actionLogStore.fetch(params)
  }, [id, actionLogStore.queryParams])

  useEffect(() => {
    return () => {
      infoSectionStore.reset()
    }
  }, [])

  const columns = [
    {
      field: 'createdAt',
      headerName: 'Action Date',
      width: 160,
      renderCell: (params) => (
        <Stack direction="column">
          <Typography variant="h5">
            {formatDate(params.row.createdAt)}
          </Typography>
          <Typography variant="h5">
            {formatTimeOnly(params.row.createdAt)}
          </Typography>
        </Stack>
      ),
    },
    {
      field: 'subject',
      headerName: 'Subject',
      sortable: false,
      width: 275,
      renderCell: (params) => {
        if (params.row.subjectId !== '0') {
          if (params.row.subject === 'admin') {
            return (
              <Typography
                variant="h5"
                className={classes.column}
              >
                {params.row.subject} {params.row.subjectDetail?.name}
              </Typography>
            )
          }
          if (params.row.subject === 'driver') {
            return (
              <Typography
                variant="h5"
                className={classes.column}
              >
                {params.row.subject} {params.row.subjectDetail?.name}
              </Typography>
            )
          }
          if (params.row.subject === 'customer') {
            return (
              <Typography
                variant="h5"
                className={classes.column}
              >
                {params.row.subject} {params.row.subjectDetail?.name}
              </Typography>
            )
          }
          return `${params.row.subject} System`
        }
        return null
      },
    },
    {
      field: 'action',
      headerName: 'Action',
      sortable: false,
      width: 275,
      renderCell: (params) => (
        <Typography
          variant="h5"
          className={classes.column}
        >
          {params.row.action}
        </Typography>
      ),
    },
    {
      field: 'status',
      headerName: 'Status',
      sortable: false,
      width: 275,
      renderCell: (params) => (
        <Typography
          variant="h5"
          className={classes.column}
        >
          {params.row.value.status}
        </Typography>
      ),
    },
  ]

  return (
    <Page>
      {actionLogStore.booking && (
        <HeadBar
          data={[
            {
              label: 'Name',
              value: actionLogStore.booking.customer.name,
            },
            {
              label: 'Phone Number',
              value: actionLogStore.booking.customer.phoneNumber,
            },
            {
              label: 'Booking Code',
              value: actionLogStore.booking.code,
            },
          ]}
          padding
        />
      )}
      <CustomDataGrid
        rows={actionLogStore.list}
        columns={columns}
        handleRowClick={handleRowClick}
        handlePageChange={handlePageChange}
        rowCount={actionLogStore.total}
        pageSize={actionLogStore.rowsPerPage}
        page={actionLogStore.currentPage}
        handlePageSizeChange={handlePageSizeChange}
        onSortModelChange={onSortModelChange}
        loading={actionLogStore.loading}
        rowHeight={80}
      />
      <Info />
    </Page>
  )
}

export default observer(LogDataGrid)
