export default function styles(theme) {
  return {
    tab: {
      textTransform: 'none',
      color: theme.palette.secondary.main,
      fontWeight: 600,
    },
    // listing - data-grid
    field: {
      margin: '0.5rem 0',
    },
    searchField: {
      display: 'flex',
      flexDirection: 'row',
      margin: '0.5rem 0.2rem 0.5rem',
    },
    textField: {
      marginBottom: '0.5rem',
    },
    autocomplete: {
      marginBottom: '0.25rem',
    },
    // listing
    filterWrapper: {
      marginBottom: '1rem',
      display: 'flex',
      flexWrap: 'wrap',
    },
    selectedDateFilter: {
      display: 'flex',
      flexDirection: 'column',
      marginLeft: '0.7rem',
      justifyContent: 'center',
    },
    dateOption: {
      display: 'flex',
      marginBottom: '0.5rem',
      alignItems: 'center',
    },
    dateType: {
      flex: 1,
      marginRight: '0.5rem',
    },
    date: {
      flex: 1,
    },
    img: {
      width: '390px',
      height: '256px',
    },
    tabsIndicator: {
      display: 'none',
    },
    tabsRoot: {
      borderRadius: '5px',
      marginRight: '1rem',
    },
    tabListing: {
      textTransform: 'capitalize',
      border: `0.5px solid ${theme.color.secondary}`,
    },
    tabSelected: {
      borderBottomWidth: 0,
      background: theme.color.secondary,
      color: `${theme.color.white} !important`,
      fontWeight: 600,
    },
    filterNumber: {
      color: theme.color.white,
      backgroundColor: theme.color.paarl,
      borderRadius: '50px',
      padding: '0 9px',
      marginRight: '5px',
    },
    imgDialog: {
      minHeight: '200px',
      maxWidth: '100%',
      objectFit: 'contain',
      borderRadius: 4,
    },
    formControlLabel: {
      minWidth: '11rem',
    },
    showDelete: {
      marginLeft: '1px',
      marginBottom: '0.5rem',
    },
    datePicker: {
      display: 'flex',
      flex: 1,
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    actionExport: {
      display: 'flex',
      flexDirection: 'column',
      marginLeft: '0.8rem',
      marginRight: '0.8rem',
      justifyContent: 'center',
    },
    buttonExport: {
      paddingTop: '0.5rem',
      paddingBottom: '0.5rem',
    },
  }
}
