import React, { useCallback, useState, useEffect } from 'react'
import { observer } from 'mobx-react'
import makeStyles from '@mui/styles/makeStyles'
import { TimePicker } from '@mui/x-date-pickers'
import {
  Dialog,
  Button,
  DialogContent,
  Grid,
  Typography,
  Tooltip,
  TextField as MuiTextField,
} from '@mui/material'

import AddIcon from '@mui/icons-material/Add'
import CloseIcon from '@mui/icons-material/Close'
import DoneIcon from '@mui/icons-material/Done'
import DeleteIcon from '@mui/icons-material/Delete'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'

import Protected from 'admin/components/protected'
import CustomizedDialogTitle from 'admin/components/dialog-title'
import TextField from 'admin/components/textfield'

import { useStores, useApiRequest } from 'admin/hooks'
import { formatTimeOnlyHour, createDateFromTextValue } from 'admin/utils/helper'
import * as srv from 'admin/services'

import styles from '../styles'

const useStyles = makeStyles(styles)

const SlotItemForm = observer((props) => {
  const classes = useStyles()
  const { slotStore, notificationStore } = useStores()
  const [slot, setSlot] = useState({
    start: '',
    end: '',
  })
  const [isEdit, setIsEdit] = useState(false)

  const {
    request: addSlot,
    isLoading: addProcessing,
  } = useApiRequest(srv.addSlot, { blocking: true })

  const {
    request: updateSlot,
    isLoading: updateProcessing,
  } = useApiRequest(srv.updateSlot, { blocking: true })

  const {
    request: deleteChannel,
    isLoading: deleteProcessing,
  } = useApiRequest(srv.deleteSlot, { blocking: true })

  const handleChange = useCallback((key, value) => {
    setSlot({
      ...slot,
      [key]: value,
    })
  }, [slot])

  const handleSubmit = useCallback(async (event) => {
    event.preventDefault()
    if (addProcessing || updateProcessing) return

    const request = (isEdit) ? updateSlot : addSlot
    const response = await request({ ...slot, capacity: slotStore.selected?.capacity })
    if (!response) return

    if (isEdit) {
      notificationStore.setSuccess('Update slots success')
    } else {
      setSlot({
        start: '',
        end: '',
      })
      notificationStore.setSuccess('Save slots success')
    }
    slotStore.reload()
  }, [slot, slotStore.selected, slotStore.capacitySlot])

  useEffect(() => {
    if (!props.item) return
    setSlot(props.item)
    setIsEdit(!!props.item.id)
  }, [props.item])

  const onDelete = useCallback(async (item) => {
    if (deleteProcessing) return
    await deleteChannel(item)
    notificationStore.setSuccess('Delete slots success')
    slotStore.reload()
  }, [slotStore.list])

  return (
    <Grid
      container
      direction="column"
      className={classes.grid}
    >
      {!isEdit && (
        <Grid
          item
          className={classes.gridSlot}
        >
          <Typography>
            Slots
          </Typography>
        </Grid>
      )}
      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        className={classes.gridSlot}
      >
        <Grid
          item
          sm={4}
        >
          <TimePicker
            ampm={false}
            openTo="hours"
            views={['hours']}
            inputFormat="HH"
            mask="__:__"
            label="Time start"
            value={createDateFromTextValue(slot.start)}
            onChange={(newValue) => {
              handleChange('start', formatTimeOnlyHour(newValue))
            }}
            renderInput={(params) => (
              <MuiTextField
                {...params}
                size="small"
                helperText={null}
              />
            )}
          />
        </Grid>
        <Grid
          item
        >
          <ArrowForwardIosIcon fontSize="small" />
        </Grid>
        <Grid
          sm={4}
          item
        >
          <TimePicker
            ampm={false}
            openTo="hours"
            views={['hours']}
            inputFormat="HH"
            label="Time end"
            mask="__:__"
            value={createDateFromTextValue(slot.end)}
            onChange={(newValue) => {
              handleChange('end', formatTimeOnlyHour(newValue))
            }}
            renderInput={(params) => (
              <MuiTextField
                {...params}
                size="small"
                helperText={null}
              />
            )}
          />
        </Grid>
        <Grid
          sm={1}
          item
        >
          <Tooltip title="Save">
            <Button
              variant="outlined"
              color="secondary"
              onClick={handleSubmit}
              className={classes.button}
              disabled={addProcessing || updateProcessing}
            >
              {isEdit ? <DoneIcon /> : <AddIcon />}
            </Button>
          </Tooltip>
        </Grid>
        <Grid
          sm={1}
          item
        >
          {isEdit ? (
            <Protected
              level="delete"
              category="slot"
            >
              <Tooltip title="Delete">
                <Button
                  variant="outlined"
                  color="secondary"
                  className={classes.button}
                  onClick={() => onDelete(slot)}
                  disabled={deleteProcessing}
                >
                  <DeleteIcon />
                </Button>
              </Tooltip>
            </Protected>
          ) : (
            <Tooltip title="Cancel">
              <Button
                variant="outlined"
                color="secondary"
                className={classes.button}
                onClick={() => {
                  setSlot({
                    start: '',
                    end: '',
                  })
                }}
              >
                <CloseIcon />
              </Button>
            </Tooltip>
          )}
        </Grid>
      </Grid>
    </Grid>
  )
})

const SlotForm = () => {
  const classes = useStyles()
  const { slotStore, notificationStore } = useStores()
  const isSlotExist = slotStore.allIdSlot.length > 0

  const {
    request: updateCapacitySlot,
    isLoading: updateProcessing,
  } = useApiRequest(srv.updateCapacitySlot, { blocking: true })

  const handleClose = useCallback(() => {
    slotStore.setOpenDialog(false)
    slotStore.reload(true)
  }, [])

  const handleSubmit = useCallback(async (event) => {
    event.preventDefault()
    if (updateProcessing) return

    const request = updateCapacitySlot
    const allId = slotStore.allIdSlot
    const res = await request({
      ids: allId,
      capacity: slotStore.selected?.capacity,
    })
    if (!res) return

    notificationStore.setSuccess('Update capacity slots success')
    slotStore.reload()
  }, [slotStore.capacitySlot])

  useEffect(() => {
    if (!slotStore.list.length) return
    slotStore.updateSelected('capacity', slotStore.capacitySlot[0])
  }, [slotStore.capacitySlot])

  return (
    <Dialog
      open={slotStore.openDialog}
      onClose={handleClose}
      maxWidth="sm"
      fullWidth
    >
      <CustomizedDialogTitle onClose={handleClose}>
        Slots Setting
      </CustomizedDialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Grid
          container
          direction="column"
          spacing={1}
          item
          sm={4}
          className={classes.gridSlot}
        >
          <Grid
            item
            className={classes.gridSlot}
          >
            <Typography>
              Capacity
            </Typography>
          </Grid>
          <Grid
            item
            style={{
              display: 'grid',
              gridTemplateColumns: '135px 40px',
              gridColumnGap: 10,
            }}
          >

            <TextField
              fullWidth
              label="Capacity per slot"
              name="capacity"
              size="small"
              value={slotStore.selected?.capacity || ''}
              onChange={(event) => {
                slotStore.updateSelected('capacity', event.target.value)
              }}
              InputLabelProps={{ shrink: true }}
            />
            {
              isSlotExist && (
                <Tooltip title="Update">
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={handleSubmit}
                    className={classes.button}
                    disabled={updateProcessing}
                  >
                    <DoneIcon />
                  </Button>
                </Tooltip>
              )
            }
          </Grid>
        </Grid>
        <SlotItemForm item={null} />
        {(slotStore.list.length !== 0) && slotStore.list.map((slot) => (
          <SlotItemForm
            key={slot.id}
            item={slot}
          />
        ))}
      </DialogContent>
    </Dialog>
  )
}

export default observer(SlotForm)
