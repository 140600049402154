import React, { useEffect } from 'react'
import { observer } from 'mobx-react'

import { useStores } from 'admin/hooks'
import { BOOKING_STATUS } from 'admin/config'

import CardItem from 'admin/components/dashboard-card/upper-content/overview'

const BookingFailed = ({ rangeDate }) => {
  const { bookingStore } = useStores()
  const { percentByStatus } = bookingStore

  const { startDate, endDate } = rangeDate

  useEffect(() => {
    bookingStore.fetchPercentage({
      startDate,
      endDate,
      status: BOOKING_STATUS.FAILED,
    })
  }, [rangeDate])

  return (
    <CardItem
      title="Failed Booking"
      status={BOOKING_STATUS.FAILED}
      total={percentByStatus.failed || 0}
    />
  )
}

export default observer(BookingFailed)
