import React, { useEffect, useCallback } from 'react'
import { observer } from 'mobx-react'
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  FormControl,
} from '@mui/material'
import { useForm, Controller } from 'react-hook-form'

import { useStores, useApiRequest } from 'admin/hooks'
import * as srv from 'admin/services'

import CustomizedDialogTitle from 'admin/components/dialog-title'
import TextField from 'admin/components/textfield'

const RemarksDialog = () => {
  const { bookingStore, notificationStore } = useStores()

  const {
    control,
    handleSubmit: handleSubmitForm,
    reset,
  } = useForm({
    defaultValues: {
      remarks: '',
    },
  })

  const { request: updateBookingRemarks, isLoading } = useApiRequest(
    srv.updateBookingFromSidemenu,
    { blocking: true },
  )

  useEffect(() => {
    if (bookingStore.selected && bookingStore.selected.remarks) {
      reset({
        remarks: bookingStore.selected.remarks,
      })
    } else {
      reset({ remarks: '' })
    }
  }, [bookingStore.selected])

  const handleClose = useCallback(() => {
    if (isLoading) return
    bookingStore.setCloseDialog('remarks')
  }, [isLoading])

  const handleSubmit = async (value) => {
    if (bookingStore.selected.id === undefined) {
      notificationStore.setError('Booking id is undefined')
      return
    }

    if (isLoading) return
    const res = await updateBookingRemarks(
      {
        id: bookingStore.selected.id,
      },
      {
        remarks: value.remarks,
      },
    )
    if (!res) return

    notificationStore.setSuccess(
      'Booking remarks has been updated successfully',
    )
    bookingStore.setCloseDialog('remarks')
    bookingStore.reload()
  }

  return (
    <Dialog
      open={bookingStore.dialog.remarks}
      onClose={handleClose}
      aria-labelledby="Update Booking Remarks"
      maxWidth="sm"
      fullWidth
    >
      <CustomizedDialogTitle onClose={handleClose}>
        Update Booking Remarks
      </CustomizedDialogTitle>

      <form
        noValidate
        onSubmit={handleSubmitForm(handleSubmit)}
        autoComplete="off"
      >
        <DialogContent>
          <FormControl fullWidth margin="normal">
            <Controller
              control={control}
              name="remarks"
              render={({
                field: { ref, ...fieldProps },
                fieldState: { error },
              }) => (
                <TextField
                  label="Booking Remarks"
                  color="secondary"
                  InputLabelProps={{ shrink: true }}
                  autoFocus
                  error={!!error?.message}
                  helperText={error?.message}
                  inputRef={ref}
                  {...fieldProps}
                />
              )}
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="secondary"
            disabled={isLoading}
          >
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default observer(RemarksDialog)
