import React, {
  useState,
} from 'react'
import { observer } from 'mobx-react'
import {
  Button,
  Menu,
  MenuItem,
} from '@mui/material'

import { useStores } from 'admin/hooks'

import Setting from 'admin/components/setting'
import InformationTab from 'admin/components/information-tab'
import EditProfileForm from './profile-form'
import ChangePasswordForm from './password-form'

const ProfileMenu = observer(() => {
  const {
    profileStore,
    adminStore: { admin },
  } = useStores()
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const handleEditProfile = () => {
    profileStore.setOpenDialog(admin, 'profile')
    handleClose()
  }
  const handleChangePassword = () => {
    profileStore.setOpenDialog(null, 'password')
    handleClose()
  }
  return (
    <div>
      <Button
        variant="contained"
        color="secondary"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        Edit
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={handleEditProfile}>
          Edit Profile
        </MenuItem>
        <MenuItem onClick={handleChangePassword}>
          Change Password
        </MenuItem>
      </Menu>
    </div>
  )
})

const ProfileData = observer(() => {
  const {
    adminStore: { admin },
  } = useStores()

  if (!admin) return null

  return (
    <InformationTab
      data={[
        {
          label: 'Name',
          value: admin.name,
        },
        {
          label: 'Username',
          value: admin.username,
        },
        {
          label: 'Phone Number',
          value: admin.phoneNumber,
        },
        {
          label: 'Email',
          value: admin.email,
        },
        {
          label: 'Outlet',
          value: admin.branches?.map((item) => item.code).join(', ') || '-',
        },
        {
          label: 'Role',
          value: admin.jobRole ? `${admin.jobRole.name} (${admin.role})` : admin.role,
        },
        {
          label: 'Status',
          value: admin.status,
        },
      ]}
    />
  )
})

const ProfileInfo = () => {
  const {
    adminStore: {
      isAdmin,
      isOfficeUser,
    },
  } = useStores()

  if (!isAdmin && !isOfficeUser) return null

  return (
    <Setting
      title="Profile Information"
      action={(
        <>
          <ProfileMenu />
          <EditProfileForm />
          <ChangePasswordForm />
        </>
      )}
      table={<ProfileData />}
    />
  )
}

export default observer(ProfileInfo)
