import React, {
  useCallback,
} from 'react'
import { observer } from 'mobx-react'

import makeStyles from '@mui/styles/makeStyles'
import {
  Typography,
  Stack,
} from '@mui/material'
import { useStores } from 'admin/hooks'

import CustomDataGrid from 'admin/components/data-grid'

import {
  formatDate,
} from 'admin/utils/helper'

import styles from './styles'

const useStyles = makeStyles(styles)

const DefectDisplay = ({
  data,
  numbering,
}) => {
  return data.length ? (data.map(defectData => (
    <Typography
      variant="h5"
      key={defectData.id}
      style={{
        minWidth: '300px',
        color: defectData.status !== 'reported' ? '#E01205' : null,
      }}
    >
      {`${numbering} ${defectData.name}`}
    </Typography>
  ))) : (
    <Typography
      variant="h5"
      style={{ minWidth: '300px' }}
    >
      {numbering} -
    </Typography>
  )
}

const columns = [
  {
    field: 'defectsList',
    headerName: 'Defects',
    sortable: false,
    width: 400,
    renderCell: (params) => {
      const defect1 = []
      const defect2 = []
      params.row.defectsList.map(row => {
        if (row.jobRole?.name === 'Counter Staff') {
          defect1.push(row)
        } else {
          defect2.push(row)
        }
        return null
      })
      return (
        <Stack direction="column">
          <DefectDisplay data={defect1} numbering="(1)" />
          <DefectDisplay data={defect2} numbering="(2)" />
        </Stack>
      )
    },
  },
  {
    field: 'defectsList.created',
    headerName: 'Defects Date Created',
    sortable: false,
    width: 180,
    renderCell: (params) => {
      let defectDate1 = '-'
      let defectDate2 = '-'
      params.row.defectsList.map(row => {
        if (row.jobRole?.name === 'Counter Staff') {
          defectDate1 = formatDate(row.createdAt)
        } else {
          defectDate2 = formatDate(row.createdAt)
        }
        return null
      })
      return (
        <Stack direction="column">
          <Typography variant="h5">
            {`(1) ${defectDate1}`}
          </Typography>
          <Typography variant="h5">
            {`(2) ${defectDate2}`}
          </Typography>
        </Stack>
      )
    },
  },
  {
    field: 'barcode',
    headerName: 'Barcode',
    width: 100,
    renderCell: (params) => params.value || '-',
  },
  {
    field: 'customer',
    sortable: false,
    headerName: 'Customer',
    width: 160,
    renderCell: (params) => (
      <Stack direction="column">
        <Typography variant="h5">
          {params.row.invoice?.customer?.name}
        </Typography>
        <Typography variant="h5">
          {params.row.invoice?.customer?.phoneNumber}
        </Typography>
      </Stack>
    ),
  },
  {
    field: 'invoice',
    headerName: 'Invoice',
    width: 120,
    renderCell: (params) => params.row.invoice?.prestoId,
  },
  {
    field: 'invoiceDate',
    headerName: 'Invoice Date', // created date
    width: 120,
    renderCell: (params) => `${formatDate(params.row.invoice?.originalResponse?.droppedOffTime)}`,
  },
  {
    field: 'invoiceCreatedBy',
    sortable: false,
    headerName: 'Invoice Created By',
    width: 180,
    renderCell: (params) => {
      let createdBy = '-'
      if (params.row.invoice?.admin) {
        createdBy = `Admin ${params.row.invoice.admin.name}`
      } else if (params.row.invoice?.driver) {
        createdBy = `Driver ${params.row.invoice.driver.code}`
      }
      return (
        <Typography variant="h5">
          {createdBy}
        </Typography>
      )
    },
  },
]

const InvoiceItemDataGrid = () => {
  const classes = useStyles()
  const { invoiceItemStore, infoSectionStore } = useStores()

  const handlePageChange = useCallback((params) => {
    invoiceItemStore.updatePage(params)
  }, [])

  const handlePageSizeChange = useCallback((param) => {
    invoiceItemStore.updateRowsPerPage(param)
  }, [])

  const handleRowClick = useCallback((rowData) => {
    infoSectionStore.select(rowData.row)
  }, [])

  const onSortModelChange = (param) => {
    if (param.length === 0) {
      invoiceItemStore.resetQuery()
    }
    if (param.length === 1) {
      invoiceItemStore.updateQuery('sortBy', param[0].field, true)
      invoiceItemStore.updateQuery('sortDirection', param[0].sort, true)
    }
    return null
  }

  return (
    <CustomDataGrid
      rows={invoiceItemStore.list}
      columns={columns}
      handleRowClick={handleRowClick}
      handlePageChange={handlePageChange}
      rowCount={invoiceItemStore.total}
      pageSize={invoiceItemStore.rowsPerPage}
      page={invoiceItemStore.currentPage}
      handlePageSizeChange={handlePageSizeChange}
      loading={invoiceItemStore.loading}
      onSortModelChange={onSortModelChange}
      rowHeight={100}
      overrideLayout={classes.overrideLayout}
    />
  )
}

export default observer(InvoiceItemDataGrid)
