import React, {
  useState,
  useCallback,
  useEffect,
} from 'react'
import moment from 'moment'
import {
  startOfDay, addDays, subDays,
} from 'date-fns'
import { observer } from 'mobx-react'
import clsx from 'clsx'
import makeStyles from '@mui/styles/makeStyles'
import { DatePicker } from '@mui/x-date-pickers'
import {
  Typography,
  Icon,
  IconButton,
  Box,
} from '@mui/material'

import { useStores } from 'admin/hooks'
import {
  dateOnlyFormat,
  formatDayOnly,
  getDateList,
  isTodayDate,
  getSelectedDate,
} from 'admin/utils/helper'

import styles from './styles'

const useStyles = makeStyles(styles)

const DateList = observer(({
  item,
  index,
  setSelectedDateIndex,
}) => {
  const {
    label,
    selected,
    isDisabled,
  } = item
  const {
    isToday,
    monthAndDate,
    dayOnly,
  } = label
  const classes = useStyles({ selected })
  const { bookingStore } = useStores()
  const handleClick = async () => {
    setSelectedDateIndex(index)
    bookingStore.resetSelectedSchedule()
  }

  return (
    <div
      className={clsx(classes.slotDate, {
        [classes.slotDateSelected]: selected,
        [classes.slotDateDisable]: isDisabled,
        [classes.slotDateSelectedDisable]: isDisabled && selected,
      })}
      onClick={handleClick}
    >
      {isToday && (
        <Icon
          className={classes.slotDateToday}
        >
          circle
        </Icon>
      )}
      <Typography
        className={clsx(classes.slotDayOnlyLabel, {
          [classes.slotDateLabelSelected]: selected,
          [classes.slotDateLabelDisable]: isDisabled,
        })}
      >
        {dayOnly}
      </Typography>
      <Typography
        className={clsx(classes.slotMonthDateLabel, {
          [classes.slotDateLabelSelected]: selected,
          [classes.slotDateLabelDisable]: isDisabled,
        })}
      >
        {monthAndDate}
      </Typography>
    </div>
  )
})

const Calendar = () => {
  const classes = useStyles()
  const { bookingStore, holidayStore } = useStores()

  const [dates, setDates] = useState([])
  const [selectedDateIndex, setSelectedDateIndex] = useState(0)

  const [
    startDate,
    setStartDate,
  ] = useState(bookingStore.selected.date)

  // set failed booking selected date to tomorrow
  useEffect(() => {
    if (bookingStore.rebookDialog) {
      const date = getSelectedDate({
        dayToSkip: 1,
        holidayStoreList: holidayStore.list,
      })
      setStartDate(date)
    }
  }, [bookingStore.rebookDialog])

  useEffect(() => {
    const dateList = getDateList(startDate, 7)
    const items = dateList.map((date, index) => {
      const formatedFullDate = date.format()
      const selected = selectedDateIndex === index
      if (selected) {
        bookingStore.updateSelected('date', dateOnlyFormat(formatedFullDate))
        bookingStore.fetch({
          date: dateOnlyFormat(formatedFullDate),
          limit: 1000,
          offset: 0,
        })
        const seventDaysAgo = moment(dateOnlyFormat(formatedFullDate)).subtract(7, 'days').startOf('day')
        bookingStore.fetchPickupAWeekAgo({
          limit: 1000,
          offset: 0,
          date: dateOnlyFormat(seventDaysAgo),
        })
      }

      // disable sunday or holidays
      let isDisabled
      if (formatDayOnly(date) === 'Sunday') {
        isDisabled = true
      } else if (
        Array.isArray(holidayStore.list) &&
        holidayStore.list.length
      ) {
        const isCurrentDateHoliday = holidayStore.list
          .find(holiday => dateOnlyFormat(formatedFullDate) === dateOnlyFormat(holiday.date))
        isDisabled = !!isCurrentDateHoliday
      } else {
        isDisabled = false
      }

      return {
        label: {
          monthAndDate: date.format('MMM D'),
          dayOnly: date.format('ddd'),
          isToday: isTodayDate(date),
        },
        selected,
        isDisabled,
      }
    })
    setDates(items)
  }, [selectedDateIndex, startDate, holidayStore.list])

  const handleClickPrevDate = useCallback(async () => {
    setStartDate(s => {
      return getSelectedDate({
        start: startOfDay(subDays(new Date(s), 1)),
        holidayStoreList: holidayStore.list,
        type: 'prev',
      })
    })
    bookingStore.resetSelectedSchedule()
  }, [holidayStore.list])

  const handleClickNextDate = useCallback(() => {
    setStartDate(s => {
      return getSelectedDate({
        start: startOfDay(addDays(new Date(s), 1)),
        holidayStoreList: holidayStore.list,
        type: 'next',
      })
    })
    bookingStore.resetSelectedSchedule()
  }, [holidayStore.list])

  const handleChangeDatePicker = useCallback((newValue) => {
    setStartDate(newValue)
    setSelectedDateIndex(0)
    bookingStore.resetSelectedSchedule()
  }, [])

  return (
    <div className={classes.calendar}>
      <IconButton
        aria-label="prev"
        color="primary"
        onClick={handleClickPrevDate}
        size="large"
      >
        <Icon>chevron_left</Icon>
      </IconButton>
      {dates.map((item, index) => (
        <DateList
          key={`key-${index}`}
          item={item}
          index={index}
          setSelectedDateIndex={setSelectedDateIndex}
        />
      ))}
      <IconButton
        aria-label="next"
        color="primary"
        onClick={handleClickNextDate}
        size="large"
      >
        <Icon>chevron_right</Icon>
      </IconButton>
      <DatePicker
        label="Custom input"
        value={startDate}
        onChange={handleChangeDatePicker}
        minDate={new Date()}
        maxDate={new Date(moment().add(30, 'days'))}
        renderInput={({ inputRef, inputProps, InputProps }) => (
          <Box
            sx={{ display: 'block', alignItems: 'center' }}
          >
            <div ref={inputRef} {...inputProps}>
              {InputProps?.endAdornment}
            </div>
          </Box>
        )}
      />
    </div>
  )
}

export default observer(Calendar)
