import React, {
  useEffect,
  useCallback,
} from 'react'
import { observer } from 'mobx-react'
import {
  Typography,
  Stack,
} from '@mui/material'

import { STATUS_TYPE } from 'admin/config/status-type'
import { formatDate, formatTimeOnly } from 'admin/utils/helper'
import { useStores } from 'admin/hooks'
import CustomDataGrid from 'admin/components/data-grid'

const columns = [
  {
    field: 'id',
    headerName: 'ID',
    width: 200,
  },
  {
    field: 'orderId',
    headerName: 'Invoice ID',
    width: 160,
  },
  {
    field: 'name',
    headerName: 'Item',
    width: 240,
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 240,
    renderCell: (params) => (
      <Typography variant="h5">
        {params.row.statusTypeId ?
          STATUS_TYPE.find((status) => status.id === params.row.statusTypeId)?.name : '-'}
      </Typography>
    ),
  },
  {
    field: 'barcode',
    headerName: 'Barcode',
    width: 180,
    renderCell: (params) => (
      <Typography variant="h5">
        {params.row.barcode || '-'}
      </Typography>
    ),
  },
  {
    field: 'createdTime',
    headerName: 'Created at',
    width: 145,
    renderCell: (params) => (
      <Stack direction="column">
        <Typography variant="h5">
          {formatDate(params.row.createdTime)}
        </Typography>
        <Typography variant="h5">
          {formatTimeOnly(params.row.createdTime)}
        </Typography>
      </Stack>
    ),
  },
  {
    field: 'lastUpdatedTime',
    headerName: 'Last updated at',
    width: 145,
    renderCell: (params) => (
      <Stack direction="column">
        <Typography variant="h5">
          {formatDate(params.row.lastUpdatedTime)}
        </Typography>
        <Typography variant="h5">
          {formatTimeOnly(params.row.lastUpdatedTime)}
        </Typography>
      </Stack>
    ),
  },
]

const PosDatagridInvoiceItem = () => {
  const { posStore, infoSectionStore } = useStores()

  const handlePageChange = useCallback((param) => {
    posStore.updatePage(param)
  }, [])

  const handlePageSizeChange = useCallback((param) => {
    posStore.updateRowsPerPage(param)
  }, [])

  const onSortModelChange = (param) => {
    if (param.length === 1) {
      posStore.updateQuery('sortBy', param[0].field, true)
      posStore.updateQuery('sortDirection', param[0].sort, true)
      return
    }
    posStore.resetQuery()
  }

  const handleRowClick = useCallback((rowData) => {
    infoSectionStore.select(rowData.row)
  }, [])

  useEffect(() => {
    posStore.fetchInvoiceItem()
  }, [posStore.queryParams])

  return (
    <CustomDataGrid
      rows={posStore.list}
      columns={columns}
      handleRowClick={handleRowClick}
      handlePageChange={handlePageChange}
      rowCount={posStore.total}
      pageSize={posStore.rowsPerPage}
      page={posStore.currentPage}
      handlePageSizeChange={handlePageSizeChange}
      onSortModelChange={onSortModelChange}
      loading={posStore.loading}
      rowsPerPageOptions={[20, 50, 100]}
    />
  )
}

export default observer(PosDatagridInvoiceItem)
