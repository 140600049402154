import React from 'react'
import { observer } from 'mobx-react'
import {
  FormControlLabel,
  Switch,
} from '@mui/material'

import { useStores } from 'admin/hooks'
import Setting from 'admin/components/setting'

const ActivateKeyboardSetting = () => {
  const { keyboardStore } = useStores()

  const handleChange = (event) => {
    keyboardStore.setUseVirtual(event.target.checked)
  }

  return (
    <Setting
      title="Virtual Keyboard"
      action={(
        <FormControlLabel
          label="Active"
          control={(
            <Switch
              checked={keyboardStore.useVirtual}
              onChange={handleChange}
              inputProps={{ 'aria-label': 'controlled' }}
            />
          )}
        />
      )}
    />
  )
}

export default observer(ActivateKeyboardSetting)
