import React, { useCallback } from 'react'
import { observer } from 'mobx-react'
import {
  Typography,
  Stack,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  formatDate,
  formatTimeOnly,
} from 'admin/utils/helper'
import { useStores } from 'admin/hooks'
import CustomDataGrid from 'admin/components/data-grid'
import StatusText from 'admin/components/status-text'

import { APP_PAYMENT_METHOD } from 'admin/config'

import styles from './styles'

const useStyles = makeStyles(styles)

const SpanDivider = () => {
  const classes = useStyles()
  return (
    <span className={classes.divider}>|</span>
  )
}

const columns = [
  {
    field: 'prestoId',
    headerName: 'Invoice',
    width: 200,
    renderCell: (params) => params.row.prestoId,
  },
  {
    field: 'createdAt',
    headerName: 'Invoice Date',
    width: 150,
    renderCell: (params) => `${formatDate(params.row.originalResponse.droppedOffTime)}`,
  },
  {
    field: 'updatedAt',
    headerName: 'Date Updated',
    width: 150,
    renderCell: (params) => (
      <Stack direction="column">
        <Typography variant="h5">
          {formatDate(params.row.updatedAt)}
        </Typography>
        <Typography variant="h5">
          {formatTimeOnly(params.row.updatedAt)}
        </Typography>
      </Stack>
    ),
  },
  {
    field: 'customer',
    headerName: 'Customer',
    width: 250,
    sortable: false,
    renderCell: (params) => (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <Typography variant="h5">
          {params.row.customer?.originalData?.name || '-'}
        </Typography>
        <Typography variant="h5">
          {params.row.customer?.originalData?.mobileNo}
        </Typography>
      </div>
    ),
  },
  {
    field: 'amount',
    headerName: 'Sub Total',
    width: 120,
    renderCell: (params) => {
      const amount = Number(params.row.amount) || Number(params.row.originalResponse?.revenue)
      return (
        <Typography variant="h5">
          {String(amount) ? `$${amount.toFixed(2)}` : '-'}
        </Typography>
      )
    },
  },
  {
    field: 'paymentMethod',
    headerName: 'Payment Method',
    width: 200,
    renderCell: (params) => (params.row?.paymentMethod === APP_PAYMENT_METHOD.value ? APP_PAYMENT_METHOD.display : params.row?.paymentMethod),
  },
  {
    field: 'paymentStatus',
    headerName: 'Payment Status',
    width: 200,
    renderCell: (params) => {
      const { paymentStatus } = params.row
      return (
        <Stack direction="column">
          <StatusText
            variant="caption"
            status={paymentStatus}
          />
          {params.row?.paymentRemark && (
            <StatusText
              variant="caption"
              status={params.row.paymentRemark}
            />
          )}
        </Stack>
      )
    },
  },
  {
    field: 'unpaidamount',
    headerName: 'Total All Unpaid',
    width: 190,
    sortable: false,
    renderCell: (params) => {
      return (
        <Typography variant="h5" color="red" style={{ fontWeight: 'bold' }}>
          {Number(params.row.unpaidamount) ? `$${params.row.unpaidamount.toFixed(2)}` : '-'}
        </Typography>
      )
    },
  },
  {
    field: 'payments',
    headerName: 'Payments',
    width: 420,
    sortable: false,
    renderCell: (params) => {
      if (params.value?.length) {
        return (
          <Stack
            direction="column"
            style={{
              marginTop: '1rem',
              marginBottom: '0.8rem',
            }}
          >
            {params.value.map(value => (
              <Stack
                direction="row"
                key={value.id}
                style={{ marginBottom: '0.2rem' }}
              >
                <Typography
                  variant="h5"
                  style={{ minWidth: '5rem' }}
                >
                  {formatDate(value.paymentTime)}
                </Typography>
                <Typography
                  variant="h5"
                  style={{ minWidth: '10.2rem' }}
                >
                  <SpanDivider /> {value.referenceNumber}
                </Typography>
                <Typography
                  variant="h5"
                >
                  <SpanDivider /> ${Number(value.amount).toFixed(2)}
                </Typography>
              </Stack>
            ))}
          </Stack>
        )
      }
      return <Typography variant="h5">-</Typography>
    },
  },
]

const InvoiceDataGrid = () => {
  const { invoiceStore, infoSectionStore } = useStores()

  const handlePageChange = useCallback((params) => {
    invoiceStore.updatePage(params)
  }, [])

  const handlePageSizeChange = useCallback((param) => {
    invoiceStore.updateRowsPerPage(param)
  }, [])

  const handleRowClick = useCallback((rowData) => {
    infoSectionStore.select(rowData.row)
  }, [])

  const onSortModelChange = (param) => {
    if (param.length === 1) {
      invoiceStore.updateQuery('sortBy', param[0].field, true)
      invoiceStore.updateQuery('sortDirection', param[0].sort, true)
    }
  }

  return (
    <CustomDataGrid
      rows={invoiceStore.list}
      columns={columns}
      handleRowClick={handleRowClick}
      handlePageChange={handlePageChange}
      rowCount={invoiceStore.total}
      pageSize={invoiceStore.rowsPerPage}
      page={invoiceStore.currentPage}
      handlePageSizeChange={handlePageSizeChange}
      loading={invoiceStore.loading}
      onSortModelChange={onSortModelChange}
      rowHeight={100}
    />
  )
}

export default observer(InvoiceDataGrid)
