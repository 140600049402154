import React, {
  useCallback,
  useEffect,
  useState,
} from 'react'
import { observer } from 'mobx-react'
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  MenuItem,
  Typography,
  Switch,
  Stack,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

import { useStores, useRequest } from 'admin/hooks'
import { replaceToNumbers } from 'admin/utils/helper'

import * as srv from 'admin/services'

import CustomizedAutocomplete from 'admin/components/autocomplete'
import CustomizedDialogTitle from 'admin/components/dialog-title'
import TextField from 'admin/components/textfield'
import { APP_PAYMENT_METHOD } from 'admin/config'

import styles from './styles'

const useStyles = makeStyles(styles)

const Filter = () => {
  const classes = useStyles()
  const {
    invoiceStore,
  } = useStores()

  const [status, setStatus] = useState('')
  const [invoice, setInvoice] = useState('')
  const [customers, setCustomers] = useState(null)
  const [customerOptions, setCustomerOptions] = useState([])
  const [driverOptions, setDriverOptions] = useState([])
  const [drivers, setDrivers] = useState(null)
  const [isDateRangeActive, setIsDateRangeActive] = useState(invoiceStore.isDateRangeActive)
  const [paymentMethod, setPaymentMethod] = useState('')
  const [customerPhoneNumber, setCustomerPhoneNumber] = useState('')

  const {
    request: fetchCustomer,
    loading: customerLoading,
  } = useRequest(srv.fetchCustomer, {
    concurrent: true,
    initialData: { list: [], total: 0 },
    transformData: (data) => {
      if (data && data.list.length > 0) {
        setCustomerOptions(data.list.map(item => ({
          key: item.id,
          label: item.originalData.mobileNo,
        })))
      }
    },
  })

  const {
    request: fetchDriver,
    loading: driverLoading,
  } = useRequest(srv.fetchDriver, {
    concurrent: true,
    initialData: { list: [], total: 0 },
    transformData: (data) => {
      if (data && data.list.length > 0) {
        setDriverOptions(data.list.map(item => ({
          key: item.id,
          label: item.code,
        })))
      }
    },
  })

  useEffect(() => {
    if (customerPhoneNumber) {
      fetchCustomer({
        phoneNumber: customerPhoneNumber,
      })
    }
  }, [customerPhoneNumber])

  useEffect(() => {
    setIsDateRangeActive(invoiceStore.isDateRangeActive)
  }, [invoiceStore.isDateRangeActive])

  const handleCustomerPhoneNumberInputChange = useCallback((event, newValue) => {
    setCustomerPhoneNumber(replaceToNumbers(newValue))
  }, [])

  const handleOpenSearchDriver = useCallback(() => {
    fetchDriver()
  }, [])

  const handleSearchDriverInputChange = useCallback((event, newValue) => {
    if (!newValue) {
      setDriverOptions([])
      return
    }
    fetchDriver({ code: newValue })
  }, [])

  const handleChangeSearchCustomer = useCallback((_, newValue) => {
    setCustomers(newValue)
  }, [])

  const handleChangeSearchDriver = useCallback((_, newValue) => {
    setDrivers(newValue)
  }, [])

  const handleClose = useCallback(() => {
    invoiceStore.setCloseDialog('filter')
  }, [])

  const handleSubmit = useCallback(() => {
    invoiceStore.updateQuery('prestoId', invoice.trim(), true)
    invoiceStore.updateQuery('paymentStatus', status, true)
    invoiceStore.updateQuery('customerId', customers?.key, true)
    invoiceStore.updateQuery('driverId', drivers?.key, true)
    invoiceStore.updateQuery('paymentMethod', paymentMethod === APP_PAYMENT_METHOD.display ?
      APP_PAYMENT_METHOD.value : paymentMethod, true)
    invoiceStore.activateDateRange(isDateRangeActive, false)

    invoiceStore.setCloseDialog('filter')
  }, [
    status,
    customers,
    drivers,
    invoice,
    isDateRangeActive,
    paymentMethod,
  ])

  const handleReset = useCallback(() => {
    setInvoice('')
    setCustomers(null)
    setDrivers(null)
    setStatus('')
    setIsDateRangeActive(true)
    invoiceStore.activateDateRange(true, true) // reset range
    setPaymentMethod('')
    setCustomerPhoneNumber('')
  }, [])

  if (!invoiceStore.dialog.filter) return null

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      open={invoiceStore.dialog.filter}
      onClose={handleClose}
      aria-labelledby="Filter"
    >
      <CustomizedDialogTitle onReset={handleReset}>
        Invoice Filter
      </CustomizedDialogTitle>
      <DialogContent>
        <Stack
          direction="row"
          justifyContent="space-between"
          className={classes.dateRange}
        >
          <Typography>
            Date Range
          </Typography>
          <Switch
            checked={isDateRangeActive}
            onChange={(event) => {
              setIsDateRangeActive(event.target.checked)
            }}
          />
        </Stack>
        <TextField
          name="prestoId"
          type="text"
          variant="outlined"
          size="small"
          fullWidth
          label="Search Invoice"
          value={invoice}
          onChange={(e) => {
            setInvoice(e.target.value)
          }}
          className={classes.textfield}
        />
        <CustomizedAutocomplete
          label="Driver"
          size="small"
          value={drivers}
          handleOpen={handleOpenSearchDriver}
          handleInputChange={handleSearchDriverInputChange}
          handleChange={handleChangeSearchDriver}
          options={driverOptions}
          loading={driverLoading}
          className={classes.autocomplete}
        />
        <CustomizedAutocomplete
          inputValue={customerPhoneNumber}
          handleInputChange={handleCustomerPhoneNumberInputChange}
          handleChange={handleChangeSearchCustomer}
          options={customerOptions}
          loading={customerLoading}
          value={customers}
          label="Customer Phone No."
          size="small"
          className={classes.autocomplete}
        />
        <TextField
          name="status"
          type="text"
          variant="outlined"
          size="small"
          fullWidth
          select
          label="Payment Status"
          value={status}
          onChange={(e) => {
            setStatus(e.target.value)
          }}
          className={classes.textfield}
        >
          <MenuItem value="">All</MenuItem>
          <MenuItem value="paid">Paid</MenuItem>
          <MenuItem value="unpaid">Unpaid</MenuItem>
          <MenuItem value="received">Received</MenuItem>
          <MenuItem value="partial">Partial</MenuItem>
          <MenuItem value="excess">Excess</MenuItem>
        </TextField>
        <TextField
          name="paymentMethod"
          type="text"
          variant="outlined"
          size="small"
          fullWidth
          label="Payment Method"
          value={paymentMethod}
          onChange={(e) => {
            setPaymentMethod(e.target.value)
          }}
          className={classes.textfield}
        />
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={handleClose}
          color="secondary"
        >
          Cancel
        </Button>
        <Button
          type="submit"
          variant="contained"
          onClick={handleSubmit}
          color="secondary"
        >
          Apply
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default observer(Filter)
