import React, {
  useCallback,
} from 'react'
import { observer } from 'mobx-react'

import { useStores } from 'admin/hooks'

import WarningDialog from 'admin/components/dialog'

const WarningEditCustomer = observer(() => {
  const {
    bookingStore,
  } = useStores()

  const handleCloseWarningDialog = useCallback(async () => {
    bookingStore.setOpenWarningDialog(false)
  }, [])

  const handleBackToCustomerScreen = useCallback(async () => {
    bookingStore.setOpenWarningDialog(false)
    bookingStore.updateActiveStep(0)
    bookingStore.setCustomerSearchResult(null)
  }, [])

  return (
    <WarningDialog
      open={bookingStore.dialog.warning}
      title="Edit customer?"
      content="Change you made so far will not be saved."
      primaryActionLabel="Edit"
      onAccept={handleBackToCustomerScreen}
      onClose={handleCloseWarningDialog}
    />
  )
})

export default WarningEditCustomer
