export default function styles() {
  return {
    btnWrapper: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: '3rem',
    },
    btn: {
      width: '136px',
    },
  }
}
