import React, { useEffect, useMemo } from 'react'
import { observer } from 'mobx-react'

import { useStores } from 'admin/hooks'
import { BOOKING_TAG } from 'admin/config'

import CardItem from 'admin/components/dashboard-card/upper-content/overview'

const BookingLaundry = ({ rangeDate }) => {
  const { bookingStore, bookingTagStore } = useStores()
  const { percentByTag } = bookingStore
  const { list } = bookingTagStore

  const { startDate, endDate } = rangeDate

  const tags = useMemo(() => {
    const tagLaundry = list.filter(item => item.name.toLowerCase() === BOOKING_TAG.LAUNDRY.toLowerCase()).map((tag) => {
      return {
        tagId: tag.id,
        tagCode: tag.posCode.toLowerCase(),
      }
    })
    return { ...tagLaundry }
  }, [list])

  useEffect(() => {
    if (!!rangeDate && Object.keys(tags).length > 0) {
      bookingStore.fetchPercentage({
        startDate,
        endDate,
        tagsObj: tags,
      })
    }
  }, [rangeDate, tags])

  return (
    <CardItem
      title="Laundry Bookings"
      total={percentByTag.laundry || 0}
      isTag
    />
  )
}

export default observer(BookingLaundry)
