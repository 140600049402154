export default (theme) => ({
  root: {
    padding: '8px 16px',
  },
  stepButtonWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: '0px -1px 6px rgba(0, 0, 0, 0.25)',
    padding: '1rem',
    zIndex: 3,
  },
  buttonWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    flexBasis: '20%',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  button: {
    marginRight: '12px',
    [theme.breakpoints.down('md')]: {
      marginBottom: '12px',
    },
  },
  infoContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1',
    overflow: 'auto',
  },
  stepDescription: {
    display: 'flex',
    flexDirection: 'column',
  },
  iconRoot: {
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
    zIndex: 1,
    color: '#fff',
    width: 36,
    height: 36,
    display: 'flex',
    borderRadius: 8,
    justifyContent: 'center',
    alignItems: 'center',
  },
  active: {
    backgroundColor: '#E3AE24',
  },
  completed: {
    backgroundColor: '#06A616',
  },
  stepName: {
    fontWeight: 500,
    fontSize: '0.875rem',
  },
  desc: {
    fontWeight: 300,
    fontSize: '0.75rem',
  },
  customerBtn: {
    backgroundColor: theme.color.paarl,
    [theme.breakpoints.down('md')]: {
      width: '90%',
    },
  },
  backBtn: {
    borderColor: theme.color.paarl,
    color: theme.color.paarl,
    [theme.breakpoints.down('md')]: {
      width: '90%',
    },
  },
})
