import React, { useState } from 'react'
import { observer } from 'mobx-react'
import makeStyles from '@mui/styles/makeStyles'
import { useHistory } from 'react-router-dom'

import {
  Button,
  FormControl,
  TextField,
  Card,
  CardMedia,
  CardContent,
  Stack,
  Typography,
  Grid,
} from '@mui/material'

import { useStores } from 'admin/hooks'

import styles from './styles'

const useStyles = makeStyles(styles)

const ForgetPassword = () => {
  const classes = useStyles()
  const history = useHistory()
  const [username, setUsername] = useState('')
  const [isEmailSent, setEmailSent] = useState(false)

  const {
    adminStore,
    notificationStore,
  } = useStores()

  const handleSubmit = async (event) => {
    event.preventDefault()
    notificationStore.setSuccess('')
    try {
      await adminStore.forgetPassword({ username })
      setEmailSent(true)
      setUsername('')
    } catch (error) {
      notificationStore.setError(error.message)
    }
  }

  const renderNotification = () => (
    <div className={classes.wrapper}>
      <Card
        elevation={3}
        className={classes.formContainer}
      >
        <Stack alignItems="center">
          <CardMedia
            image="/images/message.png"
            title="Presto Image"
            className={classes.notificationImage}
          />
        </Stack>
        <CardContent>
          <Stack alignItems="center">
            <Typography
              variant="h1"
              className={classes.mainText}
            >
              Check your email
            </Typography>
            <Grid item lg={11}>
              <Typography
                variant="body1"
                className={classes.submainText}
              >
                We have sent a password recovery instruction
                to your email
              </Typography>
            </Grid>
            <Button
              type="submit"
              color="secondary"
              variant="contained"
              size="large"
              className={classes.button}
              onClick={() => { history.push('/') }}
            >
              Back to Login
            </Button>
          </Stack>
        </CardContent>
      </Card>
    </div>
  )

  const renderForm = () => (
    <div className={classes.wrapper}>
      <Card
        elevation={3}
        className={classes.formContainer}
      >
        <Stack alignItems="center">
          <CardMedia
            image="/images/presto.png"
            title="Presto"
            className={classes.formImage}
          />
        </Stack>
        <CardContent>
          <form
            noValidate
            onSubmit={handleSubmit}
            autoComplete="off"
          >
            <FormControl fullWidth>
              <TextField
                autoFocus
                fullWidth
                type="text"
                name="username"
                label="Username"
                value={username}
                onChange={(event) => setUsername(event.target.value)}
                required
              />
            </FormControl>
            <Stack alignItems="center">
              <Button
                type="submit"
                color="secondary"
                variant="contained"
                size="large"
                className={classes.button}
              >
                Send
              </Button>
            </Stack>
          </form>
        </CardContent>
      </Card>
    </div>
  )

  return isEmailSent ? renderNotification() : renderForm()
}

export default observer(ForgetPassword)
