import React from 'react'
import { observer } from 'mobx-react'

import { Divider } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

import { useStores } from 'admin/hooks'

import InfoSection from 'admin/components/info-section'
import HeadBar from 'admin/components/head-bar'
import Information from './information'

import styles from './styles'

const useStyles = makeStyles(styles)

const BookingPaymentInfo = () => {
  const { infoSectionStore } = useStores()
  const classes = useStyles()

  const { selected: bookingPayment } = infoSectionStore

  return (
    <InfoSection
      title="Payment"
    >
      <div className={classes.parentContainer}>
        {bookingPayment && (
          <>
            <HeadBar
              data={[
                {
                  label: 'Phone Number',
                  value: bookingPayment.booking.customer.phoneNumber,
                },
                {
                  label: 'Name',
                  value: bookingPayment.booking.customer.name,
                },
              ]}
            />
            <Divider />
            <div className={classes.infoContainer}>
              <Information />
            </div>
          </>
        )}
      </div>
    </InfoSection>
  )
}

export default observer(BookingPaymentInfo)
