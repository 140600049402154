export default () => ({
  parentContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  infoContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1',
    overflow: 'auto',
  },
  padding: {
    padding: '1rem 0.75rem 1.2rem',
  },
  leftMargin: {
    marginLeft: 'auto',
    display: 'flex',
    flexDirection: 'column',
  },
  editButton: {
    marginBottom: '0.5rem',
  },
})
