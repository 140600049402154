export default function styles(theme) {
  return {
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
    },
    formContainer: {
      width: '100%',
      maxWidth: '30rem',
      padding: '1.63rem 0.5rem 0.4rem 0.5rem',
    },
    image: {
      height: '4.9rem',
      width: '5rem',
      marginBottom: '0.5rem',
    },
    mainText: {
      fontWeight: 'bold',
    },
    submainText: {
      textAlign: 'center',
      marginBottom: '1.4rem',
    },
    field: {
      marginBottom: '0.82rem',
    },
    button: {
      marginTop: '2.19rem',
      color: theme.color.white,
      fontWeight: '600',
      textTransform: 'none',
    },
  }
}
