import React from 'react'
import { observer } from 'mobx-react'
import makeStyles from '@mui/styles/makeStyles'

import {
  Typography,
  Divider,
} from '@mui/material'

import { useStores } from 'admin/hooks'
import InfoSection from 'admin/components/info-section'
import HeadBar from 'admin/components/head-bar'
import { formatDateHourAmPm } from 'admin/utils/helper'

import InformationTab from 'admin/components/information-tab'

import styles from '../styles'

const useStyles = makeStyles(styles)

const Information = observer(() => {
  const { infoSectionStore } = useStores()
  const { selected } = infoSectionStore

  return (
    <InformationTab
      data={[
        {
          label: 'Created At',
          value: formatDateHourAmPm(selected.createdTime),
        },
        {
          label: 'Last Updated Time',
          value: formatDateHourAmPm(selected.lastUpdatedTime),
        },
        {
          label: 'Office Number',
          value: selected.officeNo || '-',
        },
        {
          label: 'Residential Number',
          value: selected.residentialNo || '-',
        },
        {
          label: 'Address',
          value: selected.address || '-',
        },
        {
          label: 'Postal Code',
          value: selected?.customerDetail?.postalCode || '-',
        },
        {
          label: 'Remarks',
          value: selected.remarks || '-',
        },
        {
          label: 'Email Notification',
          value: selected?.emailNotification?.toString(),
        },
        {
          label: 'Sms Notification',
          value: selected?.smsNotification?.toString(),
        },
      ]}
    />
  )
})

const PosCustomerInfo = () => {
  const classes = useStyles()
  const { infoSectionStore } = useStores()
  const { selected } = infoSectionStore

  return (
    <InfoSection
      title="Customer"
    >
      <div className={classes.parentContainer}>
        {selected && (
          <>
            <HeadBar
              data={[
                {
                  label: 'ID',
                  value: selected.id,
                },
                {
                  label: 'Name',
                  value: selected.name,
                },
                {
                  label: 'Phone Number',
                  value: selected.mobileNo,
                },
              ]}
            />
            <Divider />
            <Typography
              variant="h4"
              className={classes.title}
            >
              Details
            </Typography>
            <div className={classes.infoContainer}>
              <Information />
            </div>
          </>
        )}
      </div>
    </InfoSection>
  )
}

export default observer(PosCustomerInfo)
