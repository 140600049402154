export default function styles(theme) {
  return {
    autocomplete: {
      margin: '0 0.25rem',
    },
    optionCustomer: {
      display: 'flex',
      justifyContent: 'flex-end',
      color: theme.color.dustyGray,
      marginRight: '1rem',
    },
    optionPhoneNumber: {
      marginLeft: '1rem',
    },
    upperSection: {
      display: 'flex',
      flexDirection: 'row',
      marginBottom: '0.5rem',
      alignItems: 'center',
      justifyContent: 'space-between',
      zIndex: '11',
    },
  }
}
