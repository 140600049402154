import React, { useCallback } from 'react'
import { observer } from 'mobx-react'
import makeStyles from '@mui/styles/makeStyles'
import { useStores } from 'admin/hooks'
import CustomDataGrid from 'admin/components/data-grid'
import { DRIVER_HOLIDAY_TYPE } from 'admin/config'
import {
  IconButton,
  Tooltip,
} from '@mui/material'

import EditIcon from '@mui/icons-material/Edit'

import {
  formatDate,
} from 'admin/utils/helper'

import styles from './styles'

const useStyles = makeStyles(styles)

const RenderActions = observer((props) => {
  const { driverHolidayStore } = useStores()
  const classes = useStyles()

  const edit = useCallback((event) => {
    event.preventDefault()
    event.stopPropagation()
    driverHolidayStore.setActiveDialog(props.selectedData, 'edit')
  }, [props.selectedData])

  return (
    <strong>
      <Tooltip title="Edit" placement="right">
        <IconButton
          color="secondary"
          className={classes.buttonEdit}
          onClick={edit}
        >
          <EditIcon />
        </IconButton>
      </Tooltip>
    </strong>
  )
})

const columns = [
  {
    field: 'date',
    headerName: 'Date',
    width: 145,
    renderCell: (params) => `${formatDate(params.row.date)}`,
  },
  {
    field: 'remarks',
    headerName: 'Remarks',
    width: 200,
    sortable: false,
  },
  {
    field: 'type',
    headerName: 'Type',
    width: 120,
    sortable: false,
    renderCell: (params) => (
      params.row.type === DRIVER_HOLIDAY_TYPE.BLOCKED ? 'Blocked' : 'Holiday'
    ),
  },
  {
    field: 'actions',
    headerName: 'Actions',
    width: 150,
    sortable: false,
    renderCell: (params) => (
      <RenderActions selectedData={params.row} />
    ),
  },
]

const DriverHolidayDataGrid = () => {
  const { driverHolidayStore } = useStores()

  const handlePageChange = useCallback((params) => {
    driverHolidayStore.updatePage(params)
  }, [])

  const handlePageSizeChange = useCallback((param) => {
    driverHolidayStore.updateRowsPerPage(param)
  }, [])

  const onSortModelChange = (param) => {
    if (param.length === 0) {
      driverHolidayStore.resetQuery()
    }
    if (param.length === 1) {
      driverHolidayStore.updateQuery('sortBy', param[0].field, true)
      driverHolidayStore.updateQuery('sortDirection', param[0].sort, true)
    }
    return null
  }

  return (
    <CustomDataGrid
      rows={driverHolidayStore.list}
      columns={columns}
      handlePageChange={handlePageChange}
      rowCount={driverHolidayStore.total}
      pageSize={driverHolidayStore.rowsPerPage}
      page={driverHolidayStore.currentPage}
      handlePageSizeChange={handlePageSizeChange}
      loading={driverHolidayStore.loading}
      onSortModelChange={onSortModelChange}
    />
  )
}

export default observer(DriverHolidayDataGrid)
