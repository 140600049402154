import React from 'react'
import { observer } from 'mobx-react'
import makeStyles from '@mui/styles/makeStyles'

import {
  Typography,
  Divider,
} from '@mui/material'

import { useStores } from 'admin/hooks'
import InfoSection from 'admin/components/info-section'
import HeadBar from 'admin/components/head-bar'

import InformationTab from 'admin/components/information-tab'

import styles from '../styles'

const useStyles = makeStyles(styles)

const Information = observer(() => {
  const { infoSectionStore } = useStores()
  const { selected } = infoSectionStore

  return (
    <InformationTab
      data={[
        {
          label: 'Phone Number',
          value: selected.tel || '-',
        },
        {
          label: 'Code',
          value: selected.initial || '-',
        },
        {
          label: 'PIN',
          value: selected.pin || '-',
        },
        {
          label: 'Branches',
          value: selected.branches || '-',
        },
        {
          label: 'Home Collection',
          value: selected.homeCollectionBranchId || '-',
        },
      ]}
    />
  )
})

const PosDriverInfo = () => {
  const classes = useStyles()
  const { infoSectionStore } = useStores()
  const { selected } = infoSectionStore

  return (
    <InfoSection
      title="Driver"
    >
      <div className={classes.parentContainer}>
        {selected && (
          <>
            <HeadBar
              data={[
                {
                  label: 'ID',
                  value: selected.id,
                },
                {
                  label: 'Name',
                  value: selected.name,
                },
              ]}
            />
            <Divider />
            <Typography
              variant="h4"
              className={classes.title}
            >
              Details
            </Typography>
            <div className={classes.infoContainer}>
              <Information />
            </div>
          </>
        )}
      </div>
    </InfoSection>
  )
}

export default observer(PosDriverInfo)
