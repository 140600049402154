import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react'
import moment from 'moment'
import makeStyles from '@mui/styles/makeStyles'

import {
  Button,
  MenuItem,
  FormControl,
  Select,
} from '@mui/material'

import { useStores } from 'admin/hooks'
import Protected from 'admin/components/protected'
import Setting from 'admin/components/setting'
import HolidayForm from './form'
import HolidayDataGrid from './datagrid'

import styles from '../styles'

const useStyles = makeStyles(styles)

const FilterByYear = () => {
  const classes = useStyles()
  const { holidayStore } = useStores()
  const [select, setSelect] = useState(holidayStore.query.year)

  const defineYear = () => {
    const upperLimit = Number(moment().format('YYYY').toString()) + 5
    const yearArr = []

    for (let yearRange = upperLimit - 10; yearRange <= upperLimit; yearRange++) {
      yearArr.push(yearRange.toString())
    }
    return yearArr
  }

  const year = defineYear()

  const handleChange = (event) => {
    holidayStore.updateQuery('year', event.target.value)
    setSelect(holidayStore.query.year)
  }

  return (
    <FormControl>
      <Select
        value={select}
        onChange={handleChange}
        variant="outlined"
        className={classes.select}
        SelectDisplayProps={{
          style: {
            paddingTop: '3px',
            paddingBottom: '3px',
          },
        }}
      >
        {year.map((item) => {
          return (
            <MenuItem
              key={item}
              value={item}
            >
              {item}
            </MenuItem>
          )
        })}
      </Select>
    </FormControl>
  )
}

const HolidaySetting = () => {
  const { holidayStore } = useStores()

  useEffect(() => {
    holidayStore.fetch({
      type: 'all',
    })
  }, [holidayStore.queryParams])

  useEffect(() => {
    return () => {
      holidayStore.resetList()
    }
  }, [])

  return (
    <Setting
      title="Holidays or Blocked Days"
      action={(
        <Protected
          level="update"
          category="holiday"
        >
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              holidayStore.setOpenDialog(null, 'form')
            }}
          >
            Create
          </Button>
          <HolidayForm />
        </Protected>
      )}
      select={<FilterByYear />}
      table={<HolidayDataGrid />}
    />
  )
}

export default observer(HolidaySetting)
