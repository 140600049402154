import { api } from './api'

export const fetchInvoicePayment = (query) => api.get('/invoice-payment', query)
export const uploadExcel = (form, invoice) => api.post('/invoice-payment/fetch-csv', form, {
  headers: {
    Accept: 'multipart/form-data',
    'Content-Type': invoice.type,
  },
})

export const bulkUpdateInvoice = (invoices) => api.post('/invoice-payment/bulk-update', invoices)

export const downloadCSV = (query) => api.get('/invoice-payment/download-csv', query)

export const fetchSMSCache = () => api.get('/invoice-payment/sms-notification')
export const setSMSCache = ({ isChecked }) => api.post('/invoice-payment/sms-notification', { isChecked })

export const updatePayment = (payment) => api.put(`/invoice-payment/${payment.id}`, payment)
export const deletePayment = (id) => api.delete(`/invoice-payment/${id}`)
