import React from 'react'
import { observer } from 'mobx-react'
import {
  Table,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material'

import makeStyles from '@mui/styles/makeStyles'

import styles from './styles'

const useStyles = makeStyles(styles)

const infoSectionTable = ({
  columns,
  rows,
}) => {
  const classes = useStyles()

  return (
    <Table
      className={classes.table}
      aria-label="simple table"
    >
      <TableHead>
        <TableRow>
          {columns.map(column => (
            <TableCell
              key={column}
              className={classes.cellHead}
            >
              {column}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      {rows}
    </Table>
  )
}

export default observer(infoSectionTable)
