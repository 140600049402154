import React from 'react'
import { observer } from 'mobx-react'
import makeStyles from '@mui/styles/makeStyles'
import {
  Container,
  Typography,
} from '@mui/material'
import { formatDateHourAmPm } from 'admin/utils/helper'

import { useStores } from 'admin/hooks'

import styles from './styles'

const useStyles = makeStyles(styles)

const Information = () => {
  const classes = useStyles()
  const { infoSectionStore } = useStores()

  const { selected: customer } = infoSectionStore

  const details = [
    {
      title: 'Date Created',
      data: formatDateHourAmPm(customer?.createdAt),
    },
    {
      title: 'Date Updated',
      data: formatDateHourAmPm(customer?.updatedAt),
    },
    {
      title: 'Office Number',
      data: customer?.originalData?.officeNo,
    },
    {
      title: 'Residential Number',
      data: customer?.originalData?.residentialNo,
    },
    {
      title: 'Address',
      data: customer?.originalData?.address,
    },
    {
      title: 'Postal Code',
      data: customer?.postalCode,
    },
    {
      title: 'Email registered via Customer App/Website',
      data: customer?.email,
    },
    {
      title: 'Remarks',
      data: customer?.originalData?.remarks,
    },
    {
      title: 'Preferred Driver',
      data: customer?.driver && `${customer.driver.code} - ${customer.driver.name}`,
    },
    {
      title: 'Presto ID',
      data: customer?.prestoId,
    },
  ]

  return (
    <Container>
      {details.map((item) => (
        <div
          key={item.title}
          className={classes.details}
        >
          <Typography variant="h6">
            {item.title}
          </Typography>
          <Typography>
            {item.data}
          </Typography>
        </div>
      ))}
    </Container>
  )
}

export default observer(Information)
