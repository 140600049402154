export default (theme) => ({
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  detailButton: {
    margin: '0.5rem',
  },
  label: {
    fontWeight: 600,
    paddingRight: '1rem',
    lineHeight: 'inherit',
  },
  titleSection: {
    color: theme.palette.text.primary,
    padding: '1rem',
    listStyle: 'none',
  },
  infoItem: {
    paddingBottom: '1rem',
  },
  slotStatus: {
    display: 'flex',
    justifyContent: 'space-between',
    textTransform: 'capitalize',
  },
  slot: {
    width: '78px',
    backgroundColor: theme.color.paarl,
    padding: '2px 9px',
    borderRadius: '4px',
    color: theme.color.white,
  },
  img: {
    marginTop: '1rem',
    height: '300px',
    borderRadius: 4,
    cursor: 'pointer',
  },
  closeButton: {
    position: 'absolute',
    top: 0,
    right: 0,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '1rem',
  },
  titleAndAction: {
    display: 'flex',
    flexDirection: 'row',
  },
  labelDelivery: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '1.5rem',
  },
})
