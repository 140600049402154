import React, { useState, useEffect, useCallback, Suspense } from 'react'
import { observer } from 'mobx-react'
import makeStyles from '@mui/styles/makeStyles'
import {
  Button,
} from '@mui/material'

import { useApiRequest, useStores, useDebounce } from 'admin/hooks'
import * as srv from 'admin/services'
import DeleteDialog from 'admin/components/dialog'
import Page from 'admin/components/page'
import Protected from 'admin/components/protected'
import TextField from 'admin/components/textfield'

import DriverDataGrid from './datagrid'
import DriverInfo from './info'
import styles from './styles'

const DialogExportReport = React.lazy(() => import('./export-report'))
const DriverForm = React.lazy(() => import('./form'))
const useStyles = makeStyles(styles)

const FilterAndAction = observer(() => {
  const classes = useStyles()
  const { driverStore } = useStores()

  const [driverName, setDriverName] = useState('')

  const debounceCustomerName = useDebounce(driverName, 300)

  const handleReportCSVFilter = useCallback(() => {
    driverStore.setOpenDialog(null, 'report')
  }, [])

  useEffect(() => {
    driverStore.updateQuery('name', debounceCustomerName, true)
  }, [debounceCustomerName])

  return (
    <div className={classes.upperSection}>
      <TextField
        name="name"
        type="text"
        variant="outlined"
        size="small"
        fullWidth
        className={classes.field}
        label="Search Driver"
        value={driverName}
        onChange={(e) => {
          setDriverName(e.target.value)
        }}
      />
      <div style={{ display: 'flex' }}>
        <Button
          onClick={handleReportCSVFilter}
          variant="contained"
          color="secondary"
          className={classes.btnReport}
        >
          Report
        </Button>
        <Protected
          level="create"
          category="driver"
        >
          <Button
            onClick={() => {
              driverStore.setOpenDialog(null, 'form') // set to null to indicate its a new driver
            }}
            variant="contained"
            color="secondary"
          >
            Add New Driver
          </Button>
        </Protected>
      </div>
    </div>
  )
})

const Driver = observer(() => {
  const {
    notificationStore,
    driverStore,
    driverHolidayStore,
  } = useStores()

  const {
    request: deleteDriver,
  } = useApiRequest(srv.deleteDriver, { blocking: true })

  const onDelete = useCallback(async () => {
    if (
      !driverStore.selected ||
      !driverStore.selected.id
    ) return
    const response = await deleteDriver(driverStore.selected.id)
    if (!response) return
    notificationStore.setSuccess('Deleted')
    driverStore.setCloseDialog('delete')
    driverStore.reload(true)
  }, [driverStore.selected])

  useEffect(() => {
    driverStore.fetch()
  }, [driverStore.queryParams])

  useEffect(() => {
    return () => {
      driverStore.resetQuery()
      driverHolidayStore.resetQuery()
    }
  }, [])

  return (
    <Page>
      <FilterAndAction />
      <Suspense fallback={<div>Loading...</div>}>
        <DriverForm />
        <DialogExportReport />
      </Suspense>
      <DriverDataGrid />
      <DriverInfo />
      <DeleteDialog
        open={driverStore.dialog.delete}
        title={`Do you want to delete driver ${driverStore.selected?.name} account? `}
        content="Driver data that deleted cannot be recovered. Make sure you know what you are doing."
        primaryActionLabel="Delete"
        onAccept={onDelete}
        onClose={() => { driverStore.setCloseDialog('delete') }}
      />
    </Page>
  )
})

export default Driver
