export const PAYMENT_TYPE = [
  {
    id: 1,
    name: 'Cash',
  },
  {
    id: 2,
    name: 'NETS',
  },
  {
    id: 3,
    name: 'VISA / MASTER',
  },
  {
    id: 4,
    name: 'Presto Vouchers',
  },
  {
    id: 5,
    name: 'Membership Card',
  },
  {
    id: 6,
    name: 'Cheque',
  },
  {
    id: 7,
    name: 'On Credit',
  },
  {
    id: 8,
    name: 'Vouchers',
  },
  {
    id: 9,
    name: 'Cash / Vouchers',
  },
  {
    id: 10,
    name: 'NETS / Vouchers',
  },
  {
    id: 11,
    name: 'VISA/MASTER / Vouchers',
  },
  {
    id: 12,
    name: 'Cheque / Vouchers',
  },
  {
    id: 13,
    name: 'Giro',
  },
  {
    id: 14,
    name: 'Cash Refund',
  },
  {
    id: 15,
    name: 'Credits',
  },
  {
    id: 16,
    name: 'Giro Payment',
  },
  {
    id: 17,
    name: 'Discount',
  },
  {
    id: 18,
    name: 'COD',
  },
  {
    id: 19,
    name: 'PayPal',
  },
  {
    id: 20,
    name: 'PayPal Payment',
  },
  {
    id: 21,
    name: 'PayNow',
  },
  {
    id: 22,
    name: 'Cash / Credits',
  },
  {
    id: 23,
    name: 'NETS / Credits',
  },
  {
    id: 24,
    name: 'VISA/MASTER / Credits',
  },
  {
    id: 25,
    name: 'Cheque / Credits',
  },
  {
    id: 26,
    name: 'AMEX',
  },
  {
    id: 27,
    name: 'AMEX / Vouchers',
  },
  {
    id: 28,
    name: 'AMEX / Credits',
  },
  {
    id: 29,
    name: 'PayNow / Vouchers',
  },
  {
    id: 30,
    name: 'PayNow / Credits',
  },
  {
    id: 31,
    name: 'APP',
  },
]
