import React, { useEffect, useState, useRef } from 'react'
import { observer } from 'mobx-react'
import { computed } from 'mobx'
import makeStyles from '@mui/styles/makeStyles'
import {
  Card,
  CardMedia,
  IconButton,
  Stack,
  Typography,
  Box,
} from '@mui/material'
import {
  Edit,
} from '@mui/icons-material/'

import { useStores } from 'admin/hooks'
import {
  formatDate,
} from 'admin/utils/helper'

import StatusText from 'admin/components/status-text'
import MenuOption from '../../popper'

import styles from './styles'

const useStyles = makeStyles(styles)

const UpdateDefectStatus = ({
  id,
  status,
}) => {
  const classes = useStyles()

  const anchorRefPopper = useRef(null)

  const [open, setOpen] = useState(false)
  const prevOpen = useRef(open)

  const handleClose = () => {
    setOpen(false)
  }

  const handleToggle = () => {
    setOpen((revOpen) => !revOpen)
  }

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRefPopper.current.focus()
    }

    prevOpen.current = open
  }, [open])

  return (
    <Stack
      direction="row"
      alignItems="center"
      sx={{
        marginTop: '0.5rem',
      }}
    >
      <IconButton
        onClick={handleToggle}
        ref={anchorRefPopper}
        size="small"
        color="primary"
        variant="contained"
      >
        <Edit
          size="small"
          className={classes.iconEdit}
        />
      </IconButton>
      <StatusText
        status={status}
      />
      <MenuOption
        anchorRef={anchorRefPopper}
        open={open}
        onClose={handleClose}
        id={id}
      />
    </Stack>
  )
}

const Defect = ({
  value,
  title,
  dateProcessing,
}) => {
  const classes = useStyles()
  const { invoiceItemStore } = useStores()

  return (
    <div className={classes.infoItem}>
      <Typography variant="h6">
        {title}
      </Typography>
      {value.length ? value.map(defectValue => (
        <React.Fragment key={defectValue.id}>
          <Typography component="div">
            <Box sx={{ fontWeight: 'bold', m: 1 }}>
              {defectValue.name}
            </Box>
          </Typography>
          <Stack
            direction="row"
            spacing={2}
            sx={{
              marginLeft: '0.5rem',
            }}
          >
            {(
              Array.isArray(defectValue.images) &&
              defectValue.images.length > 0
            ) && defectValue.images.map(image => (
              <Card
                sx={{
                  width: 120,
                }}
                key={image.url}
              >
                <CardMedia
                  component="img"
                  image={image.url}
                  title={title}
                  height="120"
                  alt={title}
                  onClick={() => {
                    invoiceItemStore.openDefectImageDialog({
                      name: defectValue.name,
                      url: image.url,
                    }, true)
                  }}
                />
              </Card>
            ))}
          </Stack>
          <UpdateDefectStatus
            id={defectValue.id}
            status={defectValue.status}
          />
          {dateProcessing && (
            <Typography component="div">
              <Box sx={{ fontWeight: 'bold', m: 1 }}>
                {dateProcessing}
              </Box>
            </Typography>
          )}
        </React.Fragment>
      )) : (
        <Typography component="div">
          <Box sx={{ fontWeight: 'bold', m: 1 }}>
            -
          </Box>
        </Typography>
      )}
    </div>
  )
}

const Information = () => {
  const classes = useStyles()
  const { infoSectionStore } = useStores()

  const { selected: item } = infoSectionStore

  const data = computed(() => {
    let createdBy = '-'
    if (item.invoice?.admin) {
      createdBy = `Admin ${item.invoice.admin.name}`
    } else if (item.invoice?.driver) {
      createdBy = `Driver ${item.invoice.driver.code}`
    }
    return [
      {
        label: 'Color/Brand/Name',
        value: `${item.colors || ''} ${item.originalResponse?.brand?.name || ''} ${item.name || ''}`.trim(),
      },
      {
        label: 'Pattern',
        value: item.patterns || '-',
      },
      {
        label: 'POS Defect',
        value: item.defects,
      },
      {
        label: 'Invoice Created Date',
        value: formatDate(item.invoice?.originalResponse?.droppedOffTime),
      },
      {
        label: 'Customer Name',
        value: item.invoice?.customer?.name,
      },
      {
        label: 'Phone',
        value: item.invoice?.customer?.phoneNumber,
      },
      {
        label: 'Created by',
        value: createdBy,
      },
    ]
  }).get()

  const defects = computed(() => {
    const defect1 = []
    const defect2 = []
    const titleCounter = 'Defect captured by counter on'
    const titleOffice = 'Defect captured by HQ/office on'
    let dateCreated1 = ''
    let dateCreated2 = ''
    let dateProcessing1 = ''
    let dateProcessing2 = ''
    item.defectsList.map(row => {
      if (row.jobRole?.name === 'Counter Staff') {
        defect1.push(row)
        dateCreated1 = formatDate(row.createdAt) || '-'
        dateProcessing1 = row.dateProcessing && `${titleCounter} date: ${formatDate(row.dateProcessing)}`
      } else {
        defect2.push(row)
        dateCreated2 = formatDate(row.createdAt) || '-'
        dateProcessing2 = row.dateProcessing && `${titleOffice} date: ${formatDate(row.dateProcessing)}`
      }
      return null
    })
    return ({
      defect1,
      defect2,
      titleDateCreated1: `(1) ${titleCounter} created: ${dateCreated1 || '-'}`,
      titleDateCreated2: `(2) ${titleOffice} created: ${dateCreated2 || '-'}`,
      dateProcessing1,
      dateProcessing2,
    })
  }).get()

  return (
    <div className={classes.titleSection}>
      <div>
        <Defect
          title={defects.titleDateCreated1}
          value={defects.defect1}
          dateProcessing={defects.dateProcessing1}
        />
        <Defect
          title={defects.titleDateCreated2}
          value={defects.defect2}
          dateProcessing={defects.dateProcessing2}
        />
      </div>
      {data && data.map((detail) => (
        <div
          key={detail.label}
          className={classes.infoItem}
        >
          <Typography variant="h6">
            {detail.label}
          </Typography>
          <Typography>
            {detail.value}
          </Typography>
        </div>
      ))}
    </div>
  )
}

export default observer(Information)
