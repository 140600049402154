import React from 'react'
import { useTheme } from '@mui/material/styles'
import { Typography, Stack } from '@mui/material'

import CancelIcon from './cancel'
import TickIcon from './tick'
import UntickIcon from './untick'

const setColor = (invoiceStatus, paymentStatus, paymentTypes, themeColor) => {
  let color = ''

  if (paymentStatus === 'unpaid') {
    color = themeColor.candyAppleRed
  } else if (invoiceStatus === 'Delivery') {
    color = themeColor.cinnabar
  } else if (invoiceStatus === 'Completed') {
    color = themeColor.darkGreen
  } else if (((
    paymentTypes === 'Cash' ||
    paymentTypes === 'PayNow' ||
    paymentTypes === 'Credit Card') && (
    paymentStatus === 'paid' ||
    paymentStatus === 'received'
  )) || paymentTypes === 'On Credit'
  ) {
    color = themeColor.darkSilver
  }
  return color
}

const InvoiceIcon = ({ invoice }) => {
  const { color: themeColor } = useTheme()
  const {
    paymentStatus,
    prestoId,
    items,
    originalResponse: { orderItems, statusTypes, paymentTypes },
    accesoriesCount,
    orderStatus,
    paymentMethod,
  } = invoice
  const orderStatusData = orderStatus || statusTypes?.name
  const paymentMethodData = paymentMethod || paymentTypes?.name
  const itemLength = items.length || orderItems?.length || 0
  const color = setColor(orderStatusData, paymentStatus, paymentMethodData, themeColor)

  const renderIcon = () => {
    return color ? (
      <TickIcon
        style={{ color }}
      />
    ) : (
      <UntickIcon
        style={{ color: themeColor.darkSilver }}
      />
    )
  }

  return (
    <Stack
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      spacing={1}
    >
      {paymentStatus === 'unpaid' ? (
        <CancelIcon
          style={{ color }}
        />
      ) : renderIcon()}
      <Typography
        variant="body2"
      >
        {prestoId} ({itemLength} + {accesoriesCount || 0})
      </Typography>
    </Stack>
  )
}

export default InvoiceIcon
