import React, { useMemo } from 'react'
import { observer } from 'mobx-react'
import {
  Stack,
  Typography,
} from '@mui/material'

import { useStores } from 'admin/hooks'

import Chart from 'admin/components/chart'
import CardChart from 'admin/components/dashboard-card/upper-content/chart'

const InvoicePaymentChart = () => {
  const { bookingStore } = useStores()
  const { percentByPaymentStatus } = bookingStore

  const data = useMemo(() => {
    if (!percentByPaymentStatus && percentByPaymentStatus.length <= 0) return {}
    return {
      labels: percentByPaymentStatus.map(item => {
        return [item.total, item.status]
      }),
      datasets: [
        {
          label: '',
          data: percentByPaymentStatus.map(item => item.total),
          backgroundColor: [
            'rgba(49,244,0,1)',
            'rgba(255,153,0,1)',
            'rgba(255,245,0,1)',
            'rgba(0,133,255,1)',
            'rgba(205,0,209,1)',
          ],
          borderColor: [
            'rgba(49,244,0,2)',
            'rgba(255,153,0,2)',
            'rgba(255,245,0,2)',
            'rgba(0,133,255,2)',
            'rgba(205,0,209,2)',
          ],
          borderWidth: 1,
          afterLabel: percentByPaymentStatus.map(item => item.status),
        },
      ],
    }
  }, [percentByPaymentStatus])

  return (
    <CardChart
      title="Invoice Payment Status"
    >
      {percentByPaymentStatus.length > 0 ? (
        <Chart
          data={data}
          customLegend
          customTooltip
        />
      ) : (
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          style={{ minHeight: '15rem' }}
        >
          <Typography>
            No Percentage
          </Typography>
        </Stack>
      )}
    </CardChart>
  )
}

export default observer(InvoicePaymentChart)
