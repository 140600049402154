import React, {
  useEffect,
  useState,
} from 'react'
import { observer } from 'mobx-react'
import {
  Bar,
  BarChart,
  LabelList,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from 'recharts'
import {
  Box,
  CircularProgress,
  Stack,
  Typography,
} from '@mui/material'

import AssessmentIcon from '@mui/icons-material/Assessment'
import AssignmentLateIcon from '@mui/icons-material/AssignmentLate'
import makeStyles from '@mui/styles/makeStyles'

import { useStores, useApiRequest } from 'admin/hooks'
import * as srv from 'admin/services'

import styles from './styles'

const useStyles = makeStyles(styles)

const NoData = ({
  isLoading,
  title,
}) => {
  return (
    <Box
      sx={{
        width: 250,
        height: 250,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'end',
      }}
    >
      {isLoading ? (
        <CircularProgress color="secondary" />
      ) : (
        <Typography>No data available {title ? `(${title})` : null}</Typography>
      )}
    </Box>
  )
}

const HorizontalBar = ({
  data,
  isLoading,
  formatter,
  title,
}) => {
  const classes = useStyles()
  if (!data || (Array.isArray(data) && !data.length)) {
    return <NoData isLoading={isLoading} title={title} />
  }

  return (
    <>
      {title && (
        <span>{title}</span>
      )}
      <ResponsiveContainer
        width="50%"
        height={50 * data.length}
        debounce={50}
        className={classes.responsiveContainer}
      >
        <BarChart
          data={data}
          layout="vertical"
          margin={{
            left: 100,
            right: 50,
          }}
        >
          <XAxis
            hide
            axisLine={false}
            type="number"
          />
          <YAxis
            type="category"
            width={150}
            padding={{ left: 20 }}
            dataKey="value"
          />
          <Bar
            dataKey="count"
            fill="#ECCC79"
            barSize={16}
          >
            <LabelList
              dataKey="count"
              position="right"
              formatter={formatter}

            />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </>
  )
}

const Analytics = () => {
  const {
    invoiceItemStore,
    notificationStore,
  } = useStores()
  const [data, setData] = useState({})

  const {
    request: fetchTopList,
    isLoading,
  } = useApiRequest(srv.fetchTopList, { blocking: false })

  useEffect(() => {
    const fetchDefectList = async () => {
      try {
        const { activeTab, query, startDate, endDate } = invoiceItemStore
        if (
          activeTab.main === 0 &&
          !query.adminId
        ) {
          setData({})
          return
        }
        const params = {
          startDate,
          endDate,
        }
        if (query.adminId) {
          params.adminId = query.adminId
        }
        if (invoiceItemStore.activeTab.main === 1) {
          params.level = 'all'
        }
        const response = await fetchTopList(params)
        if (response?.data) {
          setData(response.data)
        }
      } catch (error) {
        notificationStore.setError(error)
      }
    }
    fetchDefectList()
  }, [
    invoiceItemStore.activeTab.main,
    invoiceItemStore.query.adminId,
    invoiceItemStore.startDate,
    invoiceItemStore.endDate,
  ])

  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        sx={{
          marginTop: '1rem',
          marginBottom: '1rem',
        }}
      >
        <Typography variant="h3">
          Top Missed Defects
        </Typography>
        <AssessmentIcon color="primary" />
      </Stack>
      {Array.isArray(data?.top5Defect) ? data.top5Defect.map((topmissed) => (
        <HorizontalBar
          key={topmissed.branch}
          data={topmissed.data}
          isLoading={isLoading}
          formatter={(label => `${label} Missed`)}
          title={topmissed.branch}
        />
      )) : (
        <NoData isLoading={isLoading} />
      )}
      <Stack
        direction="row"
        alignItems="center"
        sx={{
          marginTop: '1rem',
          marginBottom: '1rem',
        }}
      >
        <Typography variant="h3">
          Missed Percentage
        </Typography>
        <AssignmentLateIcon color="primary" />
      </Stack>
      <HorizontalBar
        data={data?.missedRate}
        isLoading={isLoading}
        formatter={(label => `${label}%`)}
      />
    </>
  )
}

export default observer(Analytics)
