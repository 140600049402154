import * as Yup from 'yup'
import { TRANSACTION_TYPE } from 'admin/config'

const ContactSchema = Yup.object().shape({
  phoneNumber: Yup.object().nullable()
    .required('Phone number is required.'),
  paymentAmount: Yup.string()
    .when('$type', (type, schema) => {
      if (type === TRANSACTION_TYPE.TOPUP) {
        return schema.required('Payment amount is required.')
      }
      return schema.nullable()
    }),
  creditAmount: Yup.string()
    .required('Credit amount is required.'),
})

export default ContactSchema
