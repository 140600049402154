export default function styles() {
  return {
    upperSection: {
      display: 'flex',
      flexDirection: 'row',
      marginBottom: '0.5rem',
      alignItems: 'center',
      justifyContent: 'space-between',
      zIndex: '11',
    },
    field: {
      maxWidth: '24.4rem',
    },
  }
}
