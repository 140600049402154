export default (theme) => ({
  table: {
    maxWidth: '100%',
  },
  cellHead: {
    color: theme.color.primary,
    fontWeight: 700,
    fontSize: '1rem',
  },
  listItemTextPadding: {
    paddingLeft: '1rem',
    textTransform: 'uppercase',
  },
})
