import React from 'react'
import { observer } from 'mobx-react'
import makeStyles from '@mui/styles/makeStyles'

import { Divider, Typography } from '@mui/material'

import { useStores } from 'admin/hooks'

import InfoSection from 'admin/components/info-section'
import HeaderBar from 'admin/components/head-bar'
import { TRANSACTION_TYPE } from 'admin/config'

import styles from './styles'

const useStyles = makeStyles(styles)

const WalletInfo = () => {
  const classes = useStyles()
  const { infoSectionStore } = useStores()

  const { selected } = infoSectionStore

  const details = [
    {
      title: 'Type Transaction',
      data: selected?.action,
      action: null,
    },
    {
      title: 'Payment Ref',
      data: selected?.notes ? selected?.notes : '-',
      action: TRANSACTION_TYPE.TOPUP,
    },
    {
      title: 'Notes',
      data: selected?.notes ? selected?.notes : '-',
      action: TRANSACTION_TYPE.SPEND,
    },
    {
      title: 'Date',
      data: selected?.transactionDate,
      action: null,
    },
    {
      title: 'Payment Amount',
      data: selected?.paymentAmount ? `$${Number(selected?.paymentAmount).toFixed(2)}` : '',
      action: TRANSACTION_TYPE.TOPUP,
    },
    {
      title: 'Credit Amount',
      data: selected?.amount ? `$${Number(selected?.amount).toFixed(2)}` : '-',
      action: null,
    },
  ]

  return (
    <InfoSection
      title="Wallet"
    >
      {selected && (
        <>
          <div className={classes.headbar}>
            <HeaderBar
              data={[
                {
                  label: 'Phone Number',
                  value: selected.wallet.customer.phoneNumber,
                },
                {
                  label: 'Name',
                  value: selected.wallet.customer.name,
                },
              ]}
            />
          </div>
          <Divider />
          <Typography
            variant="h4"
            className={classes.title}
          >
            Details
          </Typography>
          <div className={classes.details}>
            {details.filter((detail) => {
              if (selected.action === TRANSACTION_TYPE.TOPUP) {
                return detail.action !== TRANSACTION_TYPE.SPEND
              }
              if (selected.action === TRANSACTION_TYPE.SPEND) {
                return detail.action !== TRANSACTION_TYPE.TOPUP
              }

              return true
            }).map((item) => (
              <div
                key={item.title}
                className={classes.info}
              >
                <Typography
                  variant="h4"
                  className={classes.titleLabel}
                >
                  {item.title}
                </Typography>
                <Typography>
                  {item.data}
                </Typography>
              </div>
            ))}
          </div>
        </>
      )}
    </InfoSection>
  )
}

export default observer(WalletInfo)
