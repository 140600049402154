import React from 'react'
import { observer } from 'mobx-react'
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

import { useStores } from 'admin/hooks'

import Protected from 'admin/components/protected'
import Setting from 'admin/components/setting'
import SyncButton from './sync'

import styles from '../styles'

const useStyles = makeStyles(styles)

const TableOutlet = observer(() => {
  const { branchStore } = useStores()
  const classes = useStyles()

  if (branchStore.list.length === 0) return null
  return (
    <TableContainer className={classes.tableContainer}>
      <Table
        aria-label="outlet table"
        stickyHeader
      >
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableCell}>
              Outlet Name
            </TableCell>
            <TableCell className={classes.tableCell}>
              Code
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {branchStore.list?.map((row) => (
            <TableRow key={row.id}>
              <TableCell component="th" scope="row">
                {row.name}
              </TableCell>
              <TableCell component="th" scope="row">
                {row.code}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
})

const OutletSetting = () => {
  return (
    <Setting
      title="Outlet"
      action={(
        <Protected
          level="create"
          category="outlet"
        >
          <SyncButton />
        </Protected>
      )}
      table={<TableOutlet />}
    />
  )
}

export default observer(OutletSetting)
