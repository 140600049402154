export default (theme) => ({
  dialogContent: {
    paddingTop: '0 !important',
    paddingBottom: '2rem',
  },
  tableContainer: {
    maxHeight: '420px',
    overflow: 'auto',
  },
  tableCell: {
    borderBottom: 'none',
    paddingBottom: 0,
    fontWeight: 400,
  },
  tableTh: {
    color: theme.color.dustyGray,
  },
  grid: {
    marginTop: '0.5rem',
  },
  gridSlot: {
    marginBottom: '1rem',
  },
  gridDelete: {
    paddingLeft: '0 !important',
  },
  button: {
    padding: '5px',
    minWidth: 0,
    [theme.breakpoints.down('md')]: {
      marginTop: '3px',
    },
  },
  formControlLabel: {
    fontSize: '0.625rem',
  },
  formControlLabelRoot: {
    marginLeft: 0,
  },
  dialog: {
    maxWidth: '950px',
    [theme.breakpoints.down('md')]: {
      maxWidth: '1000px',
    },
  },
  dialogHoliday: {
    maxWidth: '800px',
  },
  tableCellIcon: {
    display: 'flex',
    alignItems: 'center',
  },
  iconLabel: {
    paddingLeft: '0.5rem',
  },
  text: {
    height: '4rem',
  },
  select: {
    marginLeft: '1rem',
    width: '8.625rem',
    height: '2rem',
  },
  permissionItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    borderBottom: `0.5px solid ${theme.palette.secondary.main}`,
  },
  permissionLabel: {
    width: '12.5rem',
  },
  permissionCheckboxes: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  checkboxLabel: {
    fontSize: '0.8rem',
    marginBottom: '-0.8rem',
  },
  loadingProgress: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  warningMessage: {
    backgroundColor: theme.color.brown,
    fontSize: '0.9rem',
    padding: '1rem',
    borderRadius: '10px',
  },
  textCapitalize: {
    textTransform: 'capitalize',
  },
  dataGridHoliday: {
    fontSize: '1rem !important',
    '& .MuiDataGrid-main': {
      marginTop: '1rem',
    },
    '& .MuiDataGrid-columnsContainer': {
      backgroundColor: `${theme.color.white}`,
    },
    '& .MuiDataGrid-cell': {
      color: theme.palette.text.primary,
    },
    '& .MuiDataGrid-row': {
      fontWeight: 400,
      '&:nth-of-type(even)': {
        backgroundColor: `${theme.color.white}`,
      },
    },
    '& .MuiDataGrid-columnHeaderTitle': {
      color: `${theme.color.black}`,
    },
    '& .MuiDataGrid-columnHeaders': {
      backgroundColor: `${theme.color.white}`,
    },
  },
  overrideLayout: {
    height: 650,
    width: '100%',
  },
  gridPriceCategory: {
    marginTop: '1rem',
    marginBottom: '1rem',
  },
  gridPriceItem: {
    marginBottom: '1rem',
  },
  dialogPrice: {
    maxWidth: '750px',
  },
  containerPriceItem: {
    marginLeft: '0.05rem',
    marginTop: '0.05rem',
    marginBottom: '0.05rem',
  },
  gridViewAll: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  buttonViewAll: {
    width: '120px',
  },
  dividerPriceCategory: {
    backgroundColor: theme.color.lightBrown,
    marginTop: '1.5rem',
    marginBottom: '0.8rem',
  },
})
