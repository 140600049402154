import { makeAutoObservable } from 'mobx'

const USE_VIRTUAL_KEYBOARD = 'useVirtualKeyboard'

export default class KeyboardStore {
  input = ''
  onChange = null
  useVirtual = false

  constructor(rootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
    this.useVirtual = localStorage.getItem(USE_VIRTUAL_KEYBOARD) === 'true'
  }

  setUseVirtual = (value) => {
    this.useVirtual = value
    localStorage.setItem(USE_VIRTUAL_KEYBOARD, value)
  }

  setOnChange = (func) => {
    this.onChange = func
  }

  setInput = (value) => {
    this.input = value
  }
}
