import React, { useCallback } from 'react'
import clsx from 'clsx'
import { observer } from 'mobx-react'
import {
  Button,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  Tooltip,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import DoneIcon from '@mui/icons-material/Done'
import RemoveIcon from '@mui/icons-material/Remove'

import DeleteDialog from 'admin/components/dialog'
import Protected from 'admin/components/protected'
import Setting from 'admin/components/setting'
import { useApiRequest, useStores } from 'admin/hooks'
import * as srv from 'admin/services'
import BookingTagForm from './form'
import PosCodeForm from './pos-code-form'

import styles from '../styles'

const useStyles = makeStyles(styles)

const TableTag = observer(() => {
  const classes = useStyles()
  const { bookingTagStore } = useStores()

  if (bookingTagStore.list.length === 0) return null

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell className={classes.tableCell}>
            Tag Name
          </TableCell>
          <TableCell className={classes.tableCell}>
            Min. Spend
          </TableCell>
          <TableCell className={classes.tableCell}>
            Wgt.
          </TableCell>
          <TableCell className={classes.tableCell}>
            Priority
          </TableCell>
          <TableCell className={classes.tableCell}>
            <div className={classes.tableCellIcon}>
              <img
                src="/images/service-pick-up.svg"
                alt="pick-up"
                width="20px"
                height="20px"
              />
              <Typography
                variant="inherit"
                className={classes.iconLabel}
              >
                Pick-up
              </Typography>
            </div>
          </TableCell>
          <TableCell className={classes.tableCell}>
            <div className={classes.tableCellIcon}>
              <img
                src="/images/service-delivery.svg"
                alt="delivery"
                width="20px"
                height="20px"
              />
              <Typography
                variant="inherit"
                className={classes.iconLabel}
              >
                Delivery
              </Typography>
            </div>
          </TableCell>
          <TableCell className={classes.tableCell}>
            Enabled
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {bookingTagStore.list?.map((row) => (
          <TableRow key={row.id}>
            <TableCell
              component="th"
              scope="row"
              className={clsx({
                [classes.tableTh]: !row.enabled,
              })}
            >
              {row.name}
            </TableCell>
            <TableCell
              component="th"
              scope="row"
              className={clsx({
                [classes.tableTh]: !row.enabled,
              })}
            >
              ${row.minimumSpend}
            </TableCell>
            <TableCell
              component="th"
              scope="row"
              className={clsx({
                [classes.tableTh]: !row.enabled,
              })}
            >
              {row.weightage}
            </TableCell>
            <TableCell
              component="th"
              scope="row"
              className={clsx({
                [classes.tableTh]: !row.enabled,
              })}
            >
              {row.priority}
            </TableCell>
            <TableCell
              component="th"
              scope="row"
              className={clsx({
                [classes.tableTh]: !row.enabled,
              })}
            >
              {row.isPickupTag && <DoneIcon />}
            </TableCell>
            <TableCell
              component="th"
              scope="row"
              className={clsx({
                [classes.tableTh]: !row.enabled,
              })}
            >
              {row.isDeliveryTag && <DoneIcon />}
            </TableCell>
            <TableCell
              component="th"
              scope="row"
              className={clsx({
                [classes.tableTh]: !row.enabled,
              })}
            >
              {row.enabled ? (
                <DoneIcon />
              ) : (
                <Tooltip
                  title="This tag is currently disabled on the pickup website and the customer app"
                  placement="right"
                >
                  <RemoveIcon />
                </Tooltip>
              )}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
})

const BookingTagSetting = () => {
  const { bookingTagStore, notificationStore } = useStores()
  const {
    request: deleteTag,
    isLoading: deleteProcessing,
  } = useApiRequest(srv.deleteTag, { blocking: true })

  const onDelete = useCallback(async () => {
    if (deleteProcessing) return
    await deleteTag(bookingTagStore.selected)
    notificationStore.setSuccess('Delete tag success')
    bookingTagStore.setCloseDialog('delete')
    bookingTagStore.reload(true)
  }, [bookingTagStore?.selected])

  return (
    <Setting
      title="Booking tags"
      action={(
        <Protected
          level="update"
          category="tag"
        >
          <div>
            <Button
              variant="text"
              style={{ margin: '0 1rem' }}
              color="secondary"
              onClick={() => {
                bookingTagStore.setSelected(null)
                bookingTagStore.setOpenDialog(null, 'barcode')
              }}
            >
              POS Barcode
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                bookingTagStore.setSelected(null)
                bookingTagStore.setOpenDialog(null, 'form')
              }}
            >
              Edit
            </Button>
          </div>
          <BookingTagForm />
          <PosCodeForm />
          <DeleteDialog
            open={bookingTagStore.dialog.delete}
            title="Delete Booking Tag"
            content="Please note that removing tag will also remove the tag info from existing bookings. The effect is not reversible."
            primaryActionLabel="Delete"
            onAccept={onDelete}
            onClose={() => { bookingTagStore.setCloseDialog('delete') }}
          />
        </Protected>
      )}
      table={<TableTag />}
    />
  )
}

export default observer(BookingTagSetting)
