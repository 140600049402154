import React, { useState } from 'react'
import { observer } from 'mobx-react'
import { useHistory, useLocation } from 'react-router-dom'
import makeStyles from '@mui/styles/makeStyles'
import {
  Button,
  FormControl,
  Card,
  CardMedia,
  CardContent,
  Stack,
  Typography,
  Grid,
} from '@mui/material'

import { useStores } from 'admin/hooks'
import TextField from 'admin/components/textfield'

import styles from './styles'

const useStyles = makeStyles(styles)

const ResetPassword = () => {
  const classes = useStyles()
  const history = useHistory()
  const location = useLocation()
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')

  const {
    adminStore,
    notificationStore,
  } = useStores()

  const handleSubmit = async (event) => {
    event.preventDefault()
    if (password !== confirmPassword) {
      notificationStore.setError('Password not match')
      return
    }
    const resetToken = location.search?.replace('?reset-token=', '')
    if (!resetToken) {
      notificationStore.setError('Missing reset token')
      return
    }
    notificationStore.setSuccess('')
    try {
      await adminStore.resetPassword({
        password,
        resetToken,
      })
      notificationStore.setSuccess('Reset Password Successful!')
      history.push('/')
    } catch (error) {
      notificationStore.setError(error.message)
    }
  }

  return (
    <div className={classes.wrapper}>
      <Card
        elevation={3}
        className={classes.formContainer}
      >
        <Stack alignItems="center">
          <CardMedia
            image="/images/change-password.png"
            title="Presto Image"
            className={classes.image}
          />
        </Stack>
        <CardContent>
          <form
            noValidate
            onSubmit={handleSubmit}
            autoComplete="off"
          >
            <Stack alignItems="center">
              <Typography
                variant="h1"
                className={classes.mainText}
              >
                Create new password
              </Typography>
              <Grid item lg={11}>
                <Typography
                  variant="body1"
                  className={classes.submainText}
                >
                  Your new password must be diffferent from
                  previously used password
                </Typography>
              </Grid>
              <FormControl
                fullWidth
                className={classes.field}
              >
                <TextField
                  autoFocus
                  fullWidth
                  type="text"
                  name="password"
                  label="Password"
                  value={password}
                  onChange={(event) => setPassword(event.target.value)}
                  required
                />
              </FormControl>
              <FormControl fullWidth>
                <TextField
                  fullWidth
                  type="text"
                  name="confirmPassword"
                  label="Confirm Password"
                  value={confirmPassword}
                  onChange={(event) => setConfirmPassword(event.target.value)}
                  required
                />
              </FormControl>
              <Button
                type="submit"
                color="secondary"
                variant="contained"
                size="large"
                className={classes.button}
              >
                Send
              </Button>
            </Stack>
          </form>
        </CardContent>
      </Card>
    </div>
  )
}

export default observer(ResetPassword)
