export default function styles() {
  return {
    infoItem: {
      paddingBottom: '1.25rem',
    },
    titleSection: {
      padding: '1rem',
    },
    iconEdit: {
      fontSize: '1rem',
    },
  }
}
