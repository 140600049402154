import { makeAutoObservable } from 'mobx'

export default class ProfileStore {
  loading = false

  dialog = {
    profile: false,
    password: false,
  }

  processing = false
  selected = null

  DEFAULT = {
    name: '',
    username: '',
    email: '',
    phoneNumber: '',
  }

  constructor(rootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  resetSelected = () => {
    this.selected = { ...this.DEFAULT }
  }

  setOpenDialog = (selected, type) => {
    this.dialog[type] = true
    if (selected) {
      this.selected = { ...this.DEFAULT, ...selected }
    } else {
      this.selected = { ...this.DEFAULT }
    }
  }

  setCloseDialog = (type, reset = true) => {
    this.dialog[type] = false
    if (reset) setTimeout(this.resetSelected, 50)
  }
}
