import React from 'react'
import { observer } from 'mobx-react'
import {
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

import Protected from 'admin/components/protected'
import Setting from 'admin/components/setting'
import { useStores } from 'admin/hooks'
import ChannelForm from './form'
import DeleteDialog from './delete-dialog'

import styles from '../styles'

const useStyles = makeStyles(styles)

const TableChannel = observer(() => {
  const { channelStore } = useStores()
  const classes = useStyles()

  if (channelStore.list.length === 0) return null
  return (
    <TableContainer className={classes.tableContainer}>
      <Table
        aria-label="channel table"
        stickyHeader
      >
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableCell}>
              Channel Name
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {channelStore.list?.map((row) => (
            <TableRow key={row.name}>
              <TableCell component="th" scope="row">
                {row.name}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
})

const ChannelSetting = () => {
  const { channelStore } = useStores()

  return (
    <Setting
      title="Channels"
      action={(
        <Protected
          level="update"
          category="channel"
        >
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              channelStore.setSelected(null)
              channelStore.setOpenDialog(true)
            }}
          >
            Edit
          </Button>
          <ChannelForm />
          <DeleteDialog />
        </Protected>
      )}
      table={<TableChannel />}
    />
  )
}

export default observer(ChannelSetting)
