export default function styles(theme) {
  return {
    container: {
      display: 'flex',
      flexDirection: 'row',
    },
    filter: {
      display: 'flex',
      flexDirection: 'row',
      marginTop: '0.6rem',
      marginBottom: '0.5rem',
      marginLeft: '0.5rem',
    },
    dateOption: {
      display: 'flex',
      marginBottom: '0.5rem',
      alignItems: 'center',
    },
    datePicker: {
      display: 'flex',
      flex: 1,
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    textField: {
      marginBottom: '0.5rem',
    },
    filterNumber: {
      color: theme.color.white,
      backgroundColor: theme.color.paarl,
      borderRadius: '50px',
      padding: '0 9px',
      marginRight: '5px',
    },
    autocomplete: {
      marginBottom: '0.25rem',
    },
  }
}
