import React from 'react'
import { observer } from 'mobx-react'
import makeStyles from '@mui/styles/makeStyles'

import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'

import { useStores } from 'admin/hooks'
import { formatDateHourAmPm } from 'admin/utils/helper'
import { STATUS_TYPE } from 'admin/config/status-type'
import InformationTab from 'admin/components/information-tab'
import InfoSection from 'admin/components/info-section'
import HeadBar from 'admin/components/head-bar'

import styles from '../styles'

const useStyles = makeStyles(styles)

const Information = observer(() => {
  const { infoSectionStore } = useStores()
  const { selected } = infoSectionStore

  return (
    <InformationTab
      data={[
        {
          label: 'Invoice ID',
          value: selected.orderId,
        },
        {
          label: 'Invoice Item ID',
          value: selected.itemId,
        },
        {
          label: 'Remarks',
          value: selected.remarks || '-',
        },
        {
          label: 'Status',
          value: selected.statusTypeId
            ? STATUS_TYPE.find((status) => status.id === selected.statusTypeId)?.name : '-',
        },
        {
          label: 'Sub Status Type Id',
          value: selected.subStatusTypeId || '-',
        },
        {
          label: 'Is Manual Received',
          value: selected?.isManualReceived?.toString() || '-',
        },
        {
          label: 'LastUpdated Time',
          value: formatDateHourAmPm(selected.lastUpdatedTime),
        },
        {
          label: 'created at',
          value: formatDateHourAmPm(selected.createdTime),
        },
      ]}
    />
  )
})

const PosInvoiceInfo = () => {
  const classes = useStyles()
  const { infoSectionStore } = useStores()
  const { selected } = infoSectionStore

  return (
    <InfoSection
      title="Item Accessory"
    >
      <div className={classes.parentContainer}>
        {selected && (
          <>
            <HeadBar
              data={[
                {
                  label: 'ID',
                  value: selected.id,
                },
                {
                  label: 'Accessory ID',
                  value: selected.accessoryId || '-',
                },
                {
                  label: 'Barcode',
                  value: selected.barcode || '-',
                },
              ]}
            />
            <Divider />
            <Typography
              variant="h4"
              className={classes.title}
            >
              Details
            </Typography>
            <div className={classes.infoContainer}>
              <Information />
            </div>
          </>
        )}
      </div>
    </InfoSection>
  )
}

export default observer(PosInvoiceInfo)
