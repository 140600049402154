import React, { useCallback, useState, useMemo } from 'react'
import { observer } from 'mobx-react'
import {
  Dialog,
  DialogContent,
  DialogActions,
  Grid,
  Typography,
  Tooltip,
  Button,
  MenuItem,
  Divider,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

import AddIcon from '@mui/icons-material/Add'
import CloseIcon from '@mui/icons-material/Close'

import CustomizedDialogTitle from 'admin/components/dialog-title'
import TextField from 'admin/components/textfield'

import { useApiRequest, useStores } from 'admin/hooks'
import * as srv from 'admin/services'

import styles from '../styles'

const useStyles = makeStyles(styles)

const PriceItemForm = observer((props) => {
  const classes = useStyles()
  const { priceCategoryStore, notificationStore } = useStores()
  const [priceItem, setPriceItem] = useState({
    id: '',
    label: '',
    price: '',
    metric: '',
  })

  const handleChangeItem = useCallback((event) => {
    setPriceItem({
      ...priceItem,
      [event.target.name]: event.target.value,
    })
  }, [priceItem])

  const handleChangeSubItem = (event, idSubItem, idPriceCategory) => {
    if (priceCategoryStore.selected.allPriceCategory.length > 0) {
      const findIndexCategory = priceCategoryStore.selected.allPriceCategory.findIndex(objCategory => objCategory.id === idPriceCategory)
      priceCategoryStore.selected.allPriceCategory[findIndexCategory].items[idSubItem][event.target.name] = event.target.value
    } else {
      const findIndexItem = priceCategoryStore.selected.items.findIndex(obj => obj.id === idSubItem)
      priceCategoryStore.selected.items[findIndexItem][event.target.name] = event.target.value
    }
  }

  const handleChangeLabel = (event, id) => {
    if (priceCategoryStore.selected.allPriceCategory.length > 0) {
      const findIndexLabel = priceCategoryStore.selected.allPriceCategory.findIndex(obj => obj.id === id)
      priceCategoryStore.selected.allPriceCategory[findIndexLabel][event.target.name] = event.target.value
    } else {
      priceCategoryStore.updateSelected('label', event.target.value)
    }
  }

  const addSubItem = useCallback((idPriceCategory) => {
    if (!priceItem.label) {
      notificationStore.setError('Sub item name is required')
      return
    }

    if (!priceItem.price) {
      notificationStore.setError('Price is required')
      return
    }

    if (priceCategoryStore.selected.allPriceCategory.length > 0) {
      const objPriceItem = {
        ...priceItem,
        id: priceCategoryStore.selected.allPriceCategory[idPriceCategory].items.length,
        metric: !priceItem.metric ? '-' : priceItem.metric,
      }
      priceCategoryStore.selected.allPriceCategory[idPriceCategory].items = [
        ...priceCategoryStore.selected.allPriceCategory[idPriceCategory].items,
        objPriceItem,
      ]
    } else {
      const objPriceItem = {
        ...priceItem,
        id: props.price.items.length,
        metric: !priceItem.metric ? '-' : priceItem.metric,
      }
      priceCategoryStore.updateSelected('items', [
        ...props.price.items,
        objPriceItem,
      ])
    }

    setPriceItem({
      id: 0,
      label: '',
      price: '',
      metric: '',
    })
  }, [priceItem, priceCategoryStore?.selected, props.price?.items])

  const onDeleteSubItem = useCallback((item, idPriceCategory) => {
    let findSubItem = []
    if (priceCategoryStore?.selected?.allPriceCategory.length > 0) {
      findSubItem = priceCategoryStore.selected.allPriceCategory[idPriceCategory].items.filter(subItem => subItem.id !== item.id)
      priceCategoryStore.selected.allPriceCategory[idPriceCategory].items = findSubItem
    } else {
      findSubItem = priceCategoryStore.selected.items.filter(subItem => subItem.label !== item.label)
      priceCategoryStore.updateSelected('items', findSubItem)
    }
  }, [priceCategoryStore?.selected])

  const isCheckPriceCategory = useMemo(() => {
    return priceCategoryStore?.selected?.allPriceCategory.length < 0 ||
    priceCategoryStore?.selected?.id
  }, [priceCategoryStore?.selected])

  return (
    <Grid
      container
      direction="row"
      alignItems="center"
    >
      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        spacing={2}
      >
        <Grid
          sm={isCheckPriceCategory ? 10 : 12}
          item
          className={classes.gridPriceCategory}
        >
          <TextField
            fullWidth
            label="Item category"
            name="label"
            size="small"
            value={props.price.label || ''}
            onChange={(e) => handleChangeLabel(e, props.price.id)}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        {isCheckPriceCategory && (
          <Grid
            sm={2}
            item
            className={classes.gridViewAll}
          >
            <Button
              variant="outlined"
              color="primary"
              className={classes.buttonViewAll}
              onClick={() => {
                priceCategoryStore.setSelected(null)
                priceCategoryStore.updateSelected('allPriceCategory', priceCategoryStore.list)
              }}
            >
              View All
            </Button>
          </Grid>
        )}
      </Grid>
      <Grid
        item
        className={classes.gridPriceItem}
      >
        <Typography>
          Sub Item
        </Typography>
      </Grid>
      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        spacing={2}
      >
        {/* input add sub item */}
        <Grid
          sm={7}
          item
        >
          <TextField
            fullWidth
            label="Sub Item name"
            name="label"
            placeholder="Sub Item name"
            color="secondary"
            size="small"
            value={priceItem.label}
            onChange={handleChangeItem}
          />
        </Grid>
        <Grid
          sm={2}
          item
        >
          <TextField
            fullWidth
            label="Price"
            name="price"
            placeholder="Price"
            color="secondary"
            size="small"
            value={priceItem.price}
            onChange={handleChangeItem}
          />
        </Grid>
        <Grid
          sm={2}
          item
        >
          <TextField
            fullWidth
            label="Metric"
            select
            name="metric"
            type="text"
            size="small"
            value={priceItem.metric}
            onChange={handleChangeItem}
          >
            <MenuItem value="Per Kg">Per Kg</MenuItem>
            <MenuItem value="Psf">Psf</MenuItem>
            <MenuItem value="-">-</MenuItem>
          </TextField>
        </Grid>
        <Grid
          sm={1}
          item
        >
          <Tooltip title="Add">
            <Button
              variant="outlined"
              color="secondary"
              className={classes.button}
              onClick={() => { addSubItem(props.rowIndex) }}
            >
              <AddIcon />
            </Button>
          </Tooltip>
        </Grid>
        {/* list sub item */}
        {Array.isArray(props.price.items) && props.price.items.length > 0 &&
        props.price.items.map((item) => (
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            spacing={2}
            className={classes.containerPriceItem}
            key={`price-${item.id}`}
          >
            <Grid
              sm={7}
              item
            >
              <TextField
                fullWidth
                label="Sub Item name"
                name="label"
                placeholder="Sub Item name"
                color="secondary"
                size="small"
                value={item.label}
                onChange={(e) => handleChangeSubItem(e, item.id, props.price.id)}
              />
            </Grid>
            <Grid
              sm={2}
              item
            >
              <TextField
                fullWidth
                label="Price"
                name="price"
                placeholder="Price"
                color="secondary"
                size="small"
                value={item.price}
                onChange={(e) => handleChangeSubItem(e, item.id, props.price.id)}
              />
            </Grid>
            <Grid
              sm={2}
              item
            >
              <TextField
                fullWidth
                label="Metric"
                select
                name="metric"
                type="text"
                size="small"
                value={item.metric}
                onChange={(e) => handleChangeSubItem(e, item.id, props.price.id)}
              >
                <MenuItem value="Per Kg">Per Kg</MenuItem>
                <MenuItem value="Psf">Psf</MenuItem>
                <MenuItem value="-">-</MenuItem>
              </TextField>
            </Grid>
            <Grid
              sm={1}
              item
            >
              <Tooltip title="Delete">
                <Button
                  variant="outlined"
                  color="secondary"
                  className={classes.button}
                  onClick={() => onDeleteSubItem(item, props.rowIndex)}
                >
                  <CloseIcon />
                </Button>
              </Tooltip>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Grid>
  )
})

const PriceCategoryForm = () => {
  const classes = useStyles()
  const { notificationStore, priceCategoryStore } = useStores()

  const {
    request: addPriceCategory,
    isLoading: addProcessing,
  } = useApiRequest(srv.addPriceCategory, { blocking: true })

  const {
    request: updatePriceCategory,
    isLoading: updateProcessing,
  } = useApiRequest(srv.updatePriceCategory, { blocking: true })

  const {
    request: bulkUpdatePriceCategory,
    isLoading: bulkUpdateProcessing,
  } = useApiRequest(srv.bulkUpdatePriceCategory, { blocking: true })

  const handleClose = useCallback(() => {
    if (addProcessing || updateProcessing || bulkUpdateProcessing) return
    priceCategoryStore.setCloseDialog('form')
    priceCategoryStore.reload(true)
  }, [])

  const handleSubmit = useCallback(async (event) => {
    event.preventDefault()
    if (addProcessing || updateProcessing || bulkUpdateProcessing) return
    const isEdit = !!priceCategoryStore.selected?.id
    const isOpenAll = !!priceCategoryStore.selected.allPriceCategory.length > 0
    let request = ''
    if (priceCategoryStore.selected.allPriceCategory.length > 0) {
      request = bulkUpdatePriceCategory
    } else if (isEdit) {
      request = updatePriceCategory
    } else {
      request = addPriceCategory
    }

    const response = await request({ ...priceCategoryStore.selected })
    if (!response) return

    await priceCategoryStore.reload(true)
    if (isOpenAll) {
      notificationStore.setSuccess('Update All items price category success')
    } else if (isEdit) {
      notificationStore.setSuccess('Update price category success')
    } else {
      notificationStore.setSuccess('Save price category success')
    }
    priceCategoryStore.setCloseDialog('form')
  }, [priceCategoryStore.selected])

  const labelTitleDialog = useMemo(() => {
    if (priceCategoryStore?.selected?.allPriceCategory.length > 0) {
      return 'All Items Price List'
    }
    if (priceCategoryStore?.selected?.id) {
      return 'Edit Price List'
    }
    return 'Create Price List'
  }, [priceCategoryStore?.selected])

  return (
    <Dialog
      open={priceCategoryStore.dialog.form}
      onClose={handleClose}
      maxWidth="sm"
      classes={{
        paper: classes.dialogPrice,
      }}
      fullWidth
    >
      <CustomizedDialogTitle onClose={handleClose}>
        { labelTitleDialog }
      </CustomizedDialogTitle>
      <DialogContent>
        {(priceCategoryStore?.selected?.allPriceCategory.length !== 0) ?
          priceCategoryStore?.selected?.allPriceCategory.map((item, index) => {
            return (
              <div key={item.id}>
                <PriceItemForm
                  rowIndex={index}
                  price={item}
                />
                <Divider className={classes.dividerPriceCategory} />
              </div>
            )
          }) : (
            <PriceItemForm
              rowIndex={0}
              price={priceCategoryStore.selected}
            />
          )}
      </DialogContent>
      <DialogActions style={{ paddingRight: '25px' }}>
        <Button
          variant="outlined"
          color="primary"
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          disabled={addProcessing || updateProcessing || bulkUpdateProcessing}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default observer(PriceCategoryForm)
