import React, { useMemo } from 'react'
import { observer } from 'mobx-react'
import makeStyles from '@mui/styles/makeStyles'
import { parseISO } from 'date-fns'
import {
  Button,
  TableCell,
  TableRow,
  Icon,
} from '@mui/material'

import ServiceIcon from 'admin/components/service-icon'
import {
  formatDayName,
  getTags,
  isBeforeToday,
  slotDisplay,
} from 'admin/utils/helper'
import { BOOKING_STATUS } from 'admin/config'
import { useStores } from 'admin/hooks'

import styles from './styles'

const useStyles = makeStyles(styles)

const BookingTable = ({
  row,
}) => {
  const { adminStore, bookingStore } = useStores()
  const classes = useStyles()

  const cantEdit = useMemo(() => {
    return (
      row.status === BOOKING_STATUS.COMPLETED ||
      row.status === BOOKING_STATUS.FAILED ||
      (!adminStore.isAdmin && isBeforeToday(row.date))
    )
  }, [row, adminStore.isAdmin])

  const {
    pickup,
    delivery,
  } = getTags(row.bookingTags)

  const handleEdit = () => {
    const selected = {
      ...row,
      // https://github.com/date-fns/date-fns/blob/master/docs/upgradeGuide.md#string-arguments
      date: parseISO(row.date),
    }
    bookingStore.setSelected(selected)
    bookingStore.updateActiveStep(bookingStore.activeStep + 1)
    bookingStore.setNewBooking(false)
  }

  return (
    <TableRow
      key={row.id}
      classes={{ root: classes.tableRow }}
    >
      <TableCell
        component="th"
        scope="row"
        classes={{
          body: classes.body,
        }}
      >
        {formatDayName(row.date)} ({slotDisplay(row.slot.start, row.slot.end)})
      </TableCell>
      <TableCell classes={{ body: classes.body }}>
        {pickup.length > 0 && (
          <ServiceIcon
            iconName="pickup"
            data={pickup}
          />
        )}
        {delivery.length > 0 && (
          <ServiceIcon
            iconName="delivery"
            data={delivery}
          />
        )}
      </TableCell>
      <TableCell classes={{ body: classes.body }}>
        {row.driver ? row.driver.code : '-'}
      </TableCell>
      <TableCell classes={{ body: classes.body }}>
        {row.status}
      </TableCell>
      <TableCell classes={{ body: classes.body }}>
        {cantEdit ? ('-') : (
          <Button
            color="secondary"
            variant="outlined"
            size="small"
            startIcon={<Icon>edit</Icon>}
            className={classes.button}
            onClick={handleEdit}
          >
            Edit
          </Button>
        )}
      </TableCell>
    </TableRow>
  )
}

export default observer(BookingTable)
