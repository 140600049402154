import React, { useCallback } from 'react'
import { observer } from 'mobx-react'
import { useStores } from 'admin/hooks'

import { TextField as MuiTextfield } from '@mui/material'

const TextField = (props) => {
  const { keyboardStore } = useStores()

  const onChange = useCallback((value) => {
    const e = { target: { value } }
    if (props.name) e.target.name = props.name
    props.onChange(e)
  }, [props.name, props.onChange])

  const handleVirtualKeyboard = useCallback((e) => {
    e.preventDefault()
    e.stopPropagation()
    if (!keyboardStore.useVirtual || !props.onChange) return
    keyboardStore.setInput(props.value)
    keyboardStore.setOnChange(onChange)
  }, [keyboardStore.useVirtual, props.value, props.onChange, onChange])

  return (
    <MuiTextfield
      {...props}
      onClick={handleVirtualKeyboard}
      inputProps={{ readOnly: keyboardStore.useVirtual }}
    />
  )
}

export default observer(TextField)
