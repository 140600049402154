import React, { useEffect } from 'react'
import {
  Grid,
} from '@mui/material'

import Page from 'admin/components/page'
import ActivateKeyboardSetting from 'admin/pages/setting/active-keyboard'
import BookingTagSetting from 'admin/pages/setting/booking-tag'
import ChannelSetting from 'admin/pages/setting/channel'
import HolidaySetting from 'admin/pages/setting/holiday'
import RoleSetting from 'admin/pages/setting/role'
import SlotSetting from 'admin/pages/setting/slot'
import PriceCategorySetting from 'admin/pages/setting/price-category'
import OutletSetting from 'admin/pages/setting/outlet'

import { useStores } from 'admin/hooks'

const Setting = () => {
  const {
    channelStore,
    slotStore,
    bookingTagStore,
    roleStore,
    priceCategoryStore,
    branchStore,
  } = useStores()

  useEffect(() => {
    channelStore.fetch()
    slotStore.fetch()
    bookingTagStore.fetch()
    roleStore.fetch()
    priceCategoryStore.fetch()
    branchStore.fetch()
  }, [])

  return (
    <Page>
      <Grid
        container
        direction="row"
      >
        <Grid
          item
          md={8}
        >
          <BookingTagSetting />
          <RoleSetting />
          <HolidaySetting />
          <ActivateKeyboardSetting />
        </Grid>
        <Grid
          item
          md={4}
        >
          <SlotSetting />
          <ChannelSetting />
          <PriceCategorySetting />
          <OutletSetting />
        </Grid>
      </Grid>
    </Page>
  )
}

export default Setting
