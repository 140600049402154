import React, { useCallback, useState, useEffect } from 'react'
import { observer } from 'mobx-react'
import { DatePicker } from '@mui/x-date-pickers'
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  FormControl,
  MenuItem,
  InputAdornment,
  TextField as MuiTextField,
  Box,
} from '@mui/material'
import { yupResolver } from '@hookform/resolvers/yup'

import { useForm, Controller } from 'react-hook-form'
import CustomizedAutocomplete from 'admin/components/autocomplete'
import makeStyles from '@mui/styles/makeStyles'

import { useStores, useApiRequest, useRequest } from 'admin/hooks'
import * as srv from 'admin/services'

import CustomizedDialogTitle from 'admin/components/dialog-title'
import TextField from 'admin/components/textfield'
import { TRANSACTION_TYPE } from 'admin/config'

import schema from './schema'

import styles from './styles'

const useStyles = makeStyles(styles)

const PAYMENT_METHOD = [
  TRANSACTION_TYPE.TOPUP,
  TRANSACTION_TYPE.SPEND,
]

const WalletForm = () => {
  const classes = useStyles()
  const { walletStore, notificationStore } = useStores()
  const [customerOptions, setCustomerOptions] = useState([])
  const [isTopup, setIsTopup] = useState(true)
  const [selectedUserBalance, setSelectedUserBalance] = useState(0)
  const {
    request: fetchCustomer,
    loading,
  } = useRequest(srv.fetchCustomer, {
    concurrent: true,
    initialData: { list: [], total: 0 },
    transformData: (data) => {
      if (data && data.list.length > 0) {
        setCustomerOptions(data.list.map(d => ({
          key: d.id,
          label: `${d.phoneNumber}   ${d.name}`,
          id: d.id,
          phoneNumber: d.phoneNumber,
          name: d.name,
          balance: d.wallet?.balance || 0,
        })))
      }
    },
  })

  const handleOpenSearch = useCallback(() => {
    fetchCustomer({ limit: 5 })
  }, [])

  const handleSearchInputChange = useCallback((event, newValue) => {
    if (!newValue) {
      setCustomerOptions([])
      return
    }
    setTimeout(() => {
      fetchCustomer({
        phoneNumber: newValue,
        limit: 5,
      })
    }, 500)
  }, [])

  const { control, handleSubmit: handleSubmitForm, reset, setValue } = useForm({
    resolver: yupResolver(schema),
    defaultValues: { ...walletStore.DEFAULT },
    context: { type: isTopup ? TRANSACTION_TYPE.TOPUP : TRANSACTION_TYPE.SPEND },
  })

  const {
    request: addWallet,
    isLoading: addProcessing,
  } = useApiRequest(srv.addWallet, { blocking: true })

  const handleClose = useCallback(() => {
    walletStore.setCloseDialog()
  }, [])

  const handleSubmit = async (value) => {
    try {
      const res = await addWallet({
        paymentAmount: value.paymentAmount,
        creditAmount: value.creditAmount,
        customerId: value.phoneNumber.id,
        reference: value.reference,
        notes: value.notes,
        transactionDate: value.transactionDate,
        transactionType: value.transactionType,
      })
      if (!res) {
        throw new Error('Invalid add transaction')
      }

      await walletStore.fetch()
      notificationStore.setSuccess('Wallet has been created successfully')
      walletStore.setCloseDialog()
    } catch (err) {
      notificationStore.setError(err.message)
    }
  }

  const handleChangeMethod = (method) => {
    const defaultWallet = {
      ...walletStore.DEFAULT,
      transactionType: method,
    }

    if (method === TRANSACTION_TYPE.SPEND) {
      setIsTopup(false)
    } else {
      setIsTopup(true)
    }
    setSelectedUserBalance(0)
    reset({ ...defaultWallet })
  }

  useEffect(() => {
    reset({ ...walletStore.DEFAULT })

    return () => {
      setIsTopup(true)
    }
  }, [walletStore.selected])

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      open={walletStore.dialog}
      onClose={handleClose}
    >
      <CustomizedDialogTitle onClose={handleClose}>
        Create Wallet Transaction
      </CustomizedDialogTitle>
      <form
        noValidate
        onSubmit={handleSubmitForm(handleSubmit)}
        autoComplete="off"
      >
        <DialogContent>
          <FormControl fullWidth margin="normal">
            <Controller
              control={control}
              name="transactionType"
              render={({
                field: { ref, ...fieldProps },
                fieldState: { error },
              }) => (
                <TextField
                  fullWidth
                  label="Transaction Type"
                  select
                  error={!!error?.message}
                  helperText={error?.message}
                  inputRef={ref}
                  {...fieldProps}
                >
                  {PAYMENT_METHOD.map(item => (
                    <MenuItem
                      key={item}
                      value={item}
                      onClick={() => {
                        handleChangeMethod(item)
                      }}
                    >
                      {item}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
          </FormControl>
          <FormControl
            fullWidth
            margin="normal"
          >
            <Controller
              control={control}
              name="phoneNumber"
              render={({
                field: { onChange, value, ref, ...fieldProps },
                fieldState: { error },
              }) => (
                <CustomizedAutocomplete
                  label="Phone Number"
                  value={value}
                  handleOpen={handleOpenSearch}
                  handleInputChange={handleSearchInputChange}
                  handleChange={(e, newValue) => {
                    setSelectedUserBalance(newValue?.balance)
                    onChange(newValue)
                  }}
                  options={customerOptions}
                  loading={loading}
                  classesAutocomplete={classes.autocomplete}
                  renderOption={(option, state) => (
                    <div
                      {...option}
                      className={classes.upperSection}
                    >
                      <div className={classes.optionPhoneNumber}>
                        { state.phoneNumber }
                      </div>
                      <div className={classes.optionCustomer}>
                        {state.name }
                      </div>
                    </div>
                  )}
                  errorMessage={!!error?.message}
                  helperTextTextField={error?.message}
                  inputRefTextField={ref}
                  {...fieldProps}
                />
              )}
            />
          </FormControl>
          <FormControl
            fullWidth
            margin="normal"
          >
            <Controller
              name="transactionDate"
              control={control}
              render={({ field }) => {
                return (
                  <DatePicker
                    {...field}
                    label="Transaction Date"
                    mask="__/__/____"
                    inputFormat="dd/MM/yyyy"
                    onChange={(e) => field.onChange(e)}
                    selected={field.value}
                    renderInput={(params) => {
                      return (
                        <MuiTextField
                          {...params}
                          size="small"
                          helperText={null}
                        />
                      )
                    }}
                  />
                )
              }}
            />
          </FormControl>
          {isTopup && (
            <FormControl
              fullWidth
              margin="normal"
            >
              <Controller
                control={control}
                name="paymentAmount"
                render={({
                  field: { ref, onChange, ...fieldProps },
                  fieldState: { error },
                }) => (
                  <TextField
                    label="Payment Amount"
                    InputLabelProps={{ shrink: true }}
                    error={!!error?.message}
                    type="number"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          $
                        </InputAdornment>
                      ),
                    }}
                    helperText={error?.message}
                    inputRef={ref}
                    onChange={(e) => {
                      onChange(e.target.value)
                      setValue('creditAmount', e.target.value)
                    }}
                    {...fieldProps}
                  />
                )}
              />
            </FormControl>
          )}
          <FormControl
            fullWidth
            margin="normal"
          >
            <Controller
              control={control}
              name="creditAmount"
              render={({
                field: { ref, ...fieldProps }, fieldState: { error },
              }) => (
                <TextField
                  label="Credit Amount"
                  InputLabelProps={{ shrink: true }}
                  error={!!error?.message}
                  type="number"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        $
                      </InputAdornment>
                    ),
                  }}
                  helperText={error?.message}
                  inputRef={ref}
                  {...fieldProps}
                />
              )}
            />
          </FormControl>
          {!isTopup && (
            <>
              <Box>
                User balance: ${selectedUserBalance}
              </Box>

              <FormControl
                fullWidth
                margin="normal"
              >
                <Controller
                  control={control}
                  name="notes"
                  render={({
                    field: { ref, ...fieldProps }, fieldState: { error },
                  }) => (
                    <TextField
                      label="Notes"
                      InputLabelProps={{ shrink: true }}
                      error={!!error?.message}
                      multiline
                      minRows={2}
                      helperText={error?.message}
                      inputRef={ref}
                      {...fieldProps}
                    />
                  )}
                />
              </FormControl>
            </>
          )}
          {isTopup && (
            <FormControl
              fullWidth
              margin="normal"
            >
              <Controller
                control={control}
                name="reference"
                render={({
                  field: { ref, ...fieldProps }, fieldState: { error },
                }) => (
                  <TextField
                    label="Reference"
                    InputLabelProps={{ shrink: true }}
                    error={!!error?.message}
                    helperText={error?.message}
                    inputRef={ref}
                    {...fieldProps}
                  />
                )}
              />
            </FormControl>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            color="primary"
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={addProcessing}
          >
            Create
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default observer(WalletForm)
