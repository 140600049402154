export default function styles(theme) {
  return {
    filterContainer: {
      borderBottom: 1,
      borderColor: 'divider',
      display: 'flex',
      alignItems: 'center',
      marginBottom: '1rem',
    },
    overrideLayout: {
      height: '80vh',
    },
    datePickerStart: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
    },
    textfield: {
      marginBottom: '0.5rem',
    },
    filterWrapper: {
      display: 'flex',
      flexWrap: 'wrap',
      width: '100%',
    },
    searchField: {
      display: 'flex',
      flexDirection: 'row',
      margin: '0.5rem 0.2rem 0.5rem',
    },
    filterNumber: {
      color: theme.color.white,
      backgroundColor: theme.color.paarl,
      borderRadius: '50px',
      padding: '0 9px',
      marginRight: '5px',
    },
  }
}
