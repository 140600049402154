export default (theme) => ({
  paper: {
    borderRadius: 0,
    boxShadow: `0px 1px 2px ${theme.color.camel}`,
  },
  tableRowRoot: {
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  },
  override: {
    backgroundColor: theme.color.floralWhite,
  },
  button: {
    textTransform: 'none',
    borderRadius: '10px',
    fontSize: '0.8rem',
    '&:active': {
      boxShadow: 'none',
      backgroundColor: theme.palette.secondary.main,
      color: theme.color.white,
    },
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: theme.palette.secondary.main,
      color: theme.color.white,
    },
  },
  addButton: {
    padding: '0px',
    minWidth: 0,
    marginRight: '0.3rem',
  },
  addNewSlot: {
    display: 'flex',
    margin: '1rem',
  },
  noRecords: {
    textAlign: 'center',
    margin: '2rem auto',
  },
  textNoRecord: {
    display: 'inline',
    marginRight: '0.3rem',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: '1rem',
  },
  detailButton: {
    margin: '0.5rem',
  },
})
