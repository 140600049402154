import React, { useEffect } from 'react'
import { observer } from 'mobx-react'

import { useStores } from 'admin/hooks'
import {
  dateOnlyFormat,
  isTodayDate,
} from 'admin/utils/helper'

import CardItem from 'admin/components/dashboard-card/upper-content/overview'

const TotalCustomer = ({ date }) => {
  const { customerStore } = useStores()

  useEffect(() => {
    customerStore.fetchTotal({
      createdAt: dateOnlyFormat(date),
    })
  }, [date])

  return (
    <CardItem
      total={customerStore.total}
      title={`New Customers ${isTodayDate(date) ? 'Today' : ''}`}
      icon="face"
    />
  )
}

export default observer(TotalCustomer)
