import { startOfDay, endOfDay, addDays, subDays } from 'date-fns'
import { makeAutoObservable, flow } from 'mobx'
import moment from 'moment'

import { TODAY } from 'admin/utils/helper'
import * as srv from 'admin/services'
import handleError from 'admin/services/error-handler'

export default class DriverSlotStore {
  loading = false
  list = []
  total = 0

  DEFAULT = {
    date: TODAY(),
    remarks: '',
  }

  query = {
    sortBy: 'date',
    sortDirection: 'DESC',
  }

  currentPage = 0
  rowsPerPage = 10

  startDate = startOfDay(subDays(TODAY(), 7))
  endDate = endOfDay(addDays(TODAY(), 7))

  isDateRangeActive = true

  activeDialog = ''

  selected = null

  constructor() {
    makeAutoObservable(this, {
      fetch: flow,
    })
  }

  setActiveDialog = (selected, value) => {
    this.activeDialog = value
    if (selected) {
      this.selected = { ...this.DEFAULT, ...selected }
    } else {
      this.selected = { ...this.DEFAULT }
    }
  }

  setCloseActiveDialog = (reset = true) => {
    this.activeDialog = ''
    if (reset) setTimeout(this.resetSelected, 50)
  }

  resetSelected = () => {
    this.selected = { ...this.DEFAULT }
  }

  updateStartDate = (value) => {
    this.startDate = value
  }

  updateEndDate = (value) => {
    this.endDate = value
  }

  activateDateRange = (value) => {
    this.isDateRangeActive = value
  }

  updatePage = (page) => {
    this.currentPage = page
  }

  updateRowsPerPage = (perPage) => {
    this.rowsPerPage = perPage
  }

  resetQuery = () => {
    this.query = {
      sortBy: 'date',
      sortDirection: 'DESC',
    }
    this.currentPage = 0
    this.startDate = startOfDay(subDays(TODAY(), 7))
    this.endDate = endOfDay(addDays(TODAY(), 7))
    this.isDateRangeActive = true
  }

  updateQuery = (key, value, resetPage = false) => {
    this.query[key] = value
    if (resetPage) this.currentPage = 0
  }

  get queryParams() {
    const params = {
      limit: this.rowsPerPage,
      offset: this.currentPage * this.rowsPerPage,
    }
    if (this.query.sortBy) params.sortBy = this.query.sortBy
    if (this.query.sortDirection) params.sortDirection = this.query.sortDirection
    if (this.isDateRangeActive) {
      const sDate = moment(this.startDate).startOf('day').toISOString()
      const eDate = moment(this.endDate).endOf('day').toISOString()
      params.startDate = sDate
      params.endDate = eDate
    }
    return params
  }

  * fetch(params) {
    try {
      this.loading = true
      const response = yield srv.fetchDriverHoliday({
        ...this.queryParams,
        ...params,
      })
      if (response.ok) {
        this.list = response.data.list
        this.total = response.data.total
        return response.data
      }
      throw handleError(response)
    } catch (error) {
      return null
    } finally {
      this.loading = false
    }
  }
}
