import React, {
  useState,
  useCallback,
  useEffect,
} from 'react'
import { observer } from 'mobx-react'
import makeStyles from '@mui/styles/makeStyles'
import {
  Box,
  Button,
  Icon,
  IconButton,
  Tab,
  Tabs,
} from '@mui/material'
import { formatDate } from 'admin/utils/helper'

import DateRangePicker from 'admin/components/date-range-picker'

import DateRangeIcon from '@mui/icons-material/DateRange'

import { useStores } from 'admin/hooks'
import Page from 'admin/components/page'

import WalletForm from './form'
import WalletInfo from './info'
import WalletDataGrid from './datagrid'
import styles from './styles'

const useStyles = makeStyles(styles)

const FilterAndAction = observer(() => {
  const classes = useStyles()
  const { walletStore } = useStores()

  const [anchorEl, setAnchorEl] = useState(null)
  const [activeTab, setActiveTab] = useState(0)

  const onChangeTab = useCallback((event, value) => {
    setActiveTab(value)
  }, [])

  const {
    isDateRangeActive,
    datePickerSelectionRange,
  } = walletStore

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleSelect = useCallback(value => {
    const { startDate, endDate } = value.selection
    walletStore.updateDatePickerSelectionRange(startDate, endDate)
  }, [])

  useEffect(() => {
    walletStore.setActiveTab(activeTab)
    return () => {
      walletStore.setActiveTab(null)
    }
  }, [activeTab])

  const walletfilter = (action) => {
    if (action === 'all') {
      walletStore.resetQuery()
      return
    }
    walletStore.updateQuery('action', action)
  }

  return (
    <Box sx={{
      borderBottom: 1,
      borderColor: 'divider',
      display: 'flex',
      alignItems: 'center',
      marginBottom: '1rem',
    }}
    >
      <div className={classes.container}>
        <div className={classes.wrapper}>
          <Tabs
            classes={{
              root: classes.tabsRoot,
              indicator: classes.tabsIndicator,
            }}
            value={activeTab}
            onChange={onChangeTab}
          >
            {[
              'all',
              'topup',
              'spend',
            ].map((item) => (
              <Tab
                key={item}
                label={item}
                classes={{
                  root: classes.tabListing,
                  selected: classes.tabSelected,
                }}
                onClick={() => {
                  walletfilter(item)
                }}
              />
            ))}
          </Tabs>
        </div>
        {isDateRangeActive && (
          <>
            <div className={classes.datePickerStartEnd}>
              <IconButton onClick={handleClick} size="large">
                <DateRangeIcon />
              </IconButton>
              <span>
                {formatDate(datePickerSelectionRange.startDate)} - {formatDate(datePickerSelectionRange.endDate)}
              </span>
            </div>
            <DateRangePicker
              anchorEl={anchorEl}
              handleClose={handleClose}
              handleSelect={handleSelect}
              selectionRange={walletStore.datePickerSelectionRange}
            />
          </>
        )}
        <div className={classes.wrapperButton}>
          <IconButton
            variant="contained"
            onClick={walletStore.resetQuery}
            color="secondary"
            size="large"
          >
            <Icon>sync</Icon>
          </IconButton>
          <Button
            onClick={() => {
              walletStore.setOpenCreateDialog(true)
            }}
            variant="contained"
            color="secondary"
          >
            Create Wallet Transaction
          </Button>
        </div>
      </div>
    </Box>
  )
})

const Wallet = observer(() => {
  const { walletStore, notificationStore } = useStores()

  useEffect(() => {
    const fetch = async () => {
      try {
        await walletStore.fetch()
      } catch (err) {
        notificationStore.setError(err.message)
      }
    }
    fetch()
  }, [walletStore.queryParams])

  return (
    <Page>
      <FilterAndAction />
      <WalletForm />
      <WalletDataGrid />
      <WalletInfo />
    </Page>
  )
})

export default Wallet
