import React from 'react'
import makeStyles from '@mui/styles/makeStyles'

import {
  Card,
  CardContent,
  Typography,
} from '@mui/material'

import styles from './styles'

const useStyles = makeStyles(styles)

const CardChart = ({ title, children }) => {
  const classes = useStyles()

  return (
    <Card className={classes.card}>
      <Typography
        variant="h4"
        mb={1}
      >
        {title}
      </Typography>
      <CardContent
        className={classes.cardContent}
      >
        { children }
      </CardContent>
    </Card>
  )
}

export default CardChart
