import React, { useEffect, useCallback } from 'react'
import { observer } from 'mobx-react'
import { useStores } from 'admin/hooks'

import {
  Typography,
  Stack,
} from '@mui/material'

import CustomDataGrid from 'admin/components/data-grid'
import {
  formatTimeOnly,
  formatDate,
  dateOnlyFormat,
} from 'admin/utils/helper'

const columns = [
  {
    field: 'createdAt',
    headerName: 'Date',
    width: 200,
    sortable: false,
    renderCell: (params) => {
      return (
        <Stack direction="column">
          <Typography variant="h5">
            {formatDate(params.row.createdAt)}
          </Typography>
          <Typography variant="h5">
            {formatTimeOnly(params.row.createdAt)}
          </Typography>
        </Stack>
      )
    },
  },
  {
    field: 'action',
    headerName: 'Object',
    width: 180,
    sortable: false,
  },
  {
    field: 'subject',
    headerName: 'Subject',
    width: 200,
    sortable: false,
    renderCell: (params) => {
      return (
        <Stack direction="column">
          <Typography variant="h5" style={{ textTransform: 'capitalize' }}>
            {params.row.subject}
          </Typography>
          {params.row.subject === 'admin' && params.row.subjectId === '0' ? (
            <Typography variant="h5">
              System
            </Typography>
          ) : (
            <Typography variant="h5">
              {params.row?.subjectDetail?.name}
            </Typography>
          )}
        </Stack>
      )
    },
  },
]

const SmsLogDataGrid = () => {
  const { actionLogStore, infoSectionStore } = useStores()

  useEffect(() => {
    const params = {
      object: ['sms', 'smsNotification', 'smsCancelBooking'],
      createdAt: dateOnlyFormat(actionLogStore.date),
    }

    actionLogStore.fetch(params)
  }, [actionLogStore.date])

  const handlePageChange = useCallback((params) => {
    actionLogStore.updatePage(params)
  }, [])

  const handlePageSizeChange = useCallback((param) => {
    actionLogStore.updateRowsPerPage(param)
  }, [])

  const handleRowClick = useCallback((rowData) => {
    infoSectionStore.select(rowData.row)
  }, [])

  const onSortModelChange = (param) => {
    if (param.length === 0) {
      actionLogStore.resetQuery()
    }
    if (param.length === 1) {
      actionLogStore.updateQuery('sortBy', param[0].field, true)
      actionLogStore.updateQuery('sortDirection', param[0].sort, true)
    }
    return null
  }

  return (
    <CustomDataGrid
      rows={actionLogStore.list}
      columns={columns}
      handleRowClick={handleRowClick}
      handlePageChange={handlePageChange}
      rowCount={actionLogStore.total}
      pageSize={actionLogStore.rowsPerPage}
      page={actionLogStore.currentPage}
      handlePageSizeChange={handlePageSizeChange}
      loading={actionLogStore.loading}
      onSortModelChange={onSortModelChange}
      rowHeight={80}
    />
  )
}

export default observer(SmsLogDataGrid)
