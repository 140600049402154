const PAGE_WIDTH = '560px'
const INACTIVE_POS = '800px'

export default (theme) => ({
  infoSection: {
    position: 'fixed',
    top: '0',
    bottom: '0',
    right: '0',
    left: 'initial',
    transform: `translate3d(${INACTIVE_POS}, 0, 0)`,
    zIndex: '1110',
    width: PAGE_WIDTH,
    overflow: 'hidden',
    background: theme.color.white,
    border: '0',
    transition: 'transform 0.4s',
    willChange: 'transform',
    boxShadow: `-4px 40px 8px 2px ${theme.color.shadow}`,
  },
  open: {
    transform: 'translate3d(0, 0, 0)',
  },
  container: {
    paddingTop: '4rem',
    height: '100%',
    overflow: 'auto',
    '-webkit-overflow-scrolling': 'touch',
  },
  closeSection: {
    position: 'absolute',
    top: 0,
    right: 0,
    left: 0,
    zIndex: 10,
    padding: '0 1rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.primary.main,
    height: '64px',
  },
  closeButton: {
    fontSize: '0.8rem',
    padding: 0,
    color: theme.color.white,
  },
  title: {
    fontSize: '1.25rem',
    fontWeight: '600',
    color: theme.color.white,
  },
})
