import React, { useState, useCallback } from 'react'
import { observer } from 'mobx-react'
import moment from 'moment'
import CsvDownloader from 'react-csv-downloader'
import makeStyles from '@mui/styles/makeStyles'
import {
  Button,
  Box,
  Checkbox,
  Dialog,
  DialogContent,
  DialogActions,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Typography,
} from '@mui/material'
import { formatDate } from 'admin/utils/helper'

import CustomizedDialogTitle from 'admin/components/dialog-title'

import { useStores, useApiRequest } from 'admin/hooks'
import * as srv from 'admin/services'

import styles from './styles'

const useStyles = makeStyles(styles)

const columns = [
  {
    id: 'paymentReceiptDate',
    displayName: 'Payment Receipt Date',
  },
  {
    id: 'driver',
    displayName: 'Driver',
  },
  {
    id: 'invoicePrestoId',
    displayName: 'Invoice #',
  },
  {
    id: 'paymentAmount',
    displayName: 'Payment Amt',
  },
  {
    id: 'settlementMethod',
    displayName: 'Settlement Method',
  },
  {
    id: 'paymentRef',
    displayName: 'Payment Ref #',
  },
]

const FILENAME = `DMS_PaymentRef_${moment().format('DDMMYY')}`

const FILTER = [
  { key: 1, label: 'Cash', isChecked: true },
  { key: 2, label: 'PayNow', isChecked: true },
  { key: 3, label: 'APP', isChecked: true },
  { key: 4, label: 'On Credit', isChecked: [true, true] }, // index 0 for payNow, 1 for cash
]

const ON_CREDIT_LABEL = {
  PAYNOW: 'OnCredit.payNow',
  CASH: 'OnCredit.cash',
}

const ExportCSV = observer(() => {
  const classes = useStyles()
  const { invoicePaymentStore } = useStores()
  const { startDate, endDate } = invoicePaymentStore
  const [paymentMethods, setPaymentMethods] = useState(FILTER)

  const {
    request: downloadCSV,
    isLoading: isDownloadingCSV,
  } = useApiRequest(srv.downloadCSV, { blocking: true })

  const handleCloseFilterDialog = useCallback(() => {
    invoicePaymentStore.setDialog('export', false)
  }, [])

  const handleChangeCheckbox = useCallback((event) => {
    setPaymentMethods(prev => {
      return prev.map((object) => {
        if (object.key === 4) {
          if (event.target.name === 'On Credit') {
            return {
              ...object,
              isChecked: [event.target.checked, event.target.checked],
            }
          }
          if (event.target.name === ON_CREDIT_LABEL.PAYNOW) {
            return {
              ...object,
              isChecked: [event.target.checked, object.isChecked[1]],
            }
          }
          if (event.target.name === ON_CREDIT_LABEL.CASH) {
            return {
              ...object,
              isChecked: [object.isChecked[0], event.target.checked],
            }
          }
        }
        if (object.label === event.target.name) {
          return {
            ...object,
            isChecked: event.target.checked,
          }
        }
        return object
      })
    })
  }, [])

  const asyncFnComputeData = async () => {
    invoicePaymentStore.setDialog('export', false)
    invoicePaymentStore.setDialog('loading', true)
    const sDate = moment(startDate).startOf('day').toISOString()
    const eDate = moment(endDate).endOf('day').toISOString()

    const selectedPaymentMethods = paymentMethods.reduce((previousValue, currentValue) => {
      if (currentValue.key === 4) {
        if (currentValue.isChecked[0] || currentValue.isChecked[1]) {
          previousValue.push(currentValue.label)
        }
      } else if (currentValue.isChecked) {
        previousValue.push(currentValue.label)
      }
      return previousValue
    }, [])

    const settlementMethods = []
    if (paymentMethods[3].isChecked[0]) settlementMethods.push(ON_CREDIT_LABEL.PAYNOW)
    if (paymentMethods[3].isChecked[1]) settlementMethods.push(ON_CREDIT_LABEL.CASH)

    const query = {
      paymentMethods: selectedPaymentMethods,
      settlementMethods,
      startDate: sDate,
      endDate: eDate,
    }
    const response = await downloadCSV(query)
    invoicePaymentStore.setDialog('loading', false)
    return response.data.list.map((item) => {
      const data = {
        paymentReceiptDate: moment(item.paymentTime).format('DD-MMM-YY'),
        driver: item.invoice.booking?.driver?.code && `${item.invoice.booking?.driver?.code} (${item.invoice.booking?.driver?.name})`,
        invoicePrestoId: item.invoice.prestoId,
        paymentAmount: item.amount,
        settlementMethod: item.paymentChannel,
        paymentRef: item.referenceNumber,
      }
      return data
    })
  }

  const onCreditChildren = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        ml: 3,
      }}
    >
      <FormControlLabel
        label="PayNow"
        name="OnCredit.payNow"
        control={(
          <Checkbox
            checked={paymentMethods[3].isChecked[0]}
            onChange={handleChangeCheckbox}
          />
        )}
      />
      <FormControlLabel
        label="Cash"
        name="OnCredit.cash"
        control={(
          <Checkbox
            checked={paymentMethods[3].isChecked[1]}
            onChange={handleChangeCheckbox}
          />
        )}
      />
    </Box>
  )

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      open={invoicePaymentStore.dialog.export}
      onClose={handleCloseFilterDialog}
      aria-labelledby="Export Filter"
    >
      <CustomizedDialogTitle onClose={handleCloseFilterDialog}>
        Export Payment Ref
      </CustomizedDialogTitle>
      <DialogContent>
        <Typography variant="h4" className={classes.range}>
          Payment Received Date: {formatDate(startDate)} - {formatDate(endDate)}
        </Typography>
        <FormControl
          component="fieldset"
        >
          <FormLabel component="legend">
            <Typography variant="h4">
              Payment Methods
            </Typography>
          </FormLabel>
          <FormGroup>
            {paymentMethods.map((method) => {
              const checked = method.key === 4 ? (
                method.isChecked[0] && method.isChecked[1]
              ) : method.isChecked
              const indeterminate = method.key === 4 ? (
                method.isChecked[0] !== method.isChecked[1]
              ) : false
              return (
                <FormControlLabel
                  key={method.key}
                  control={(
                    <Checkbox
                      checked={checked}
                      onChange={handleChangeCheckbox}
                      indeterminate={indeterminate}
                      name={method.label}
                    />
                  )}
                  label={method.label}
                />
              )
            })}
            {onCreditChildren}
          </FormGroup>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={handleCloseFilterDialog}
          color="secondary"
          className={classes.button}
        >
          Close
        </Button>
        <CsvDownloader
          filename={FILENAME}
          extension=".csv"
          separator=","
          wrapColumnChar=""
          columns={columns}
          datas={asyncFnComputeData}
          text="EXPORT"
        >
          <Button
            variant="contained"
            color="secondary"
            className={classes.button}
            type="submit"
            disabled={isDownloadingCSV}
          >
            {isDownloadingCSV ? 'Exporting..' : 'Export'}
          </Button>
        </CsvDownloader>
      </DialogActions>
    </Dialog>
  )
})

export default ExportCSV
