import React from 'react'
import { observer } from 'mobx-react'

import makeStyles from '@mui/styles/makeStyles'
import { Divider } from '@mui/material'

import { useStores } from 'admin/hooks'

import InfoSection from 'admin/components/info-section'
import HeadBar from 'admin/components/head-bar'
import Information from './information'

import styles from './styles'

const useStyles = makeStyles(styles)

const DefectInfo = () => {
  const classes = useStyles()
  const { infoSectionStore } = useStores()

  const { selected: defect } = infoSectionStore

  return (
    <InfoSection
      title="Defect"
    >
      <div className={classes.parentContainer}>
        {defect && (
          <>
            <HeadBar
              data={[
                {
                  label: 'Barcode',
                  value: defect.barcode,
                },
                {
                  label: 'Invoice',
                  value: defect.invoice?.prestoId,
                },
              ]}
            />
            <Divider />
            <div className={classes.infoContainer}>
              <Information />
            </div>
          </>
        )}
      </div>
    </InfoSection>
  )
}

export default observer(DefectInfo)
