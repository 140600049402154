import React, { useState, useCallback } from 'react'
import { observer } from 'mobx-react'
import makeStyles from '@mui/styles/makeStyles'
import {
  Avatar,
  Badge,
  Button,
  Tooltip,
} from '@mui/material'
import clxs from 'clsx'
import { useStores } from 'admin/hooks'

import {
  getDriverInfo,
} from 'admin/utils/helper'

import InfoSection from 'admin/components/info-section'
import CustomizedTabs from 'admin/components/tabs'
import HeadBar from 'admin/components/head-bar'
import Protected from 'admin/components/protected'
import ServiceArea from './service-area'
import WorkingDays from './working-days'
import LoginAccess from './login-access'
import Holiday from './holiday'

import styles from './styles'

const useStyles = makeStyles(styles)

const EditDeleteButton = observer(() => {
  const classes = useStyles()

  const {
    infoSectionStore,
    driverStore,
  } = useStores()

  const { selected: driver } = infoSectionStore

  const openUpdateDriverDialog = useCallback(() => {
    driverStore.setOpenDialog(driver, 'form')
  }, [driver])

  const openDeleteDriverDialog = useCallback(() => {
    driverStore.setOpenDialog(driver, 'delete')
  }, [driver])

  return (
    <div className={clxs(classes.padding, classes.leftMargin)}>
      <Protected
        level="update"
        category="driver"
      >
        <Button
          variant="contained"
          color="secondary"
          size="small"
          onClick={openUpdateDriverDialog}
          className={classes.editButton}
        >
          Edit
        </Button>
      </Protected>
      <Protected
        level="delete"
        category="driver"
      >
        <Button
          variant="outlined"
          color="secondary"
          size="small"
          onClick={openDeleteDriverDialog}
        >
          Delete
        </Button>
      </Protected>
    </div>
  )
})

const DriverInfo = () => {
  const classes = useStyles()
  const [activeTab, setActiveTab] = useState(0)

  const {
    infoSectionStore,
  } = useStores()

  const { selected: driver } = infoSectionStore

  const handleChangeTab = useCallback((event, value) => {
    setActiveTab(value)
  }, [])

  return (
    <InfoSection
      title="Driver"
    >
      <div className={classes.parentContainer}>
        {driver && (
          <>
            <div style={{ display: 'flex' }}>
              <Badge
                overlap="circular"
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                className={classes.padding}
                badgeContent={(
                  <Tooltip
                    title={getDriverInfo(driver.type).label}
                    placement="bottom-start"
                  >
                    <img
                      className={classes.icon}
                      src={getDriverInfo(driver.type).imgSource}
                      alt={getDriverInfo(driver.type).label}
                    />
                  </Tooltip>
                )}
              >
                <Avatar
                  alt="Driver avatar"
                  src={driver.imageUrl}
                  sx={{ width: 98, height: 98 }}
                >
                  {driver.code}
                </Avatar>
              </Badge>
              <HeadBar
                data={[{
                  label: 'Name',
                  value: driver.name,
                }, {
                  label: 'Code',
                  value: `${driver.code} ${driver.prestoId && `(${driver.prestoId})`}`,
                }, {
                  label: 'Phone Number',
                  value: driver.phoneNumber,
                }, {
                  label: 'Auto Cancel Booking ',
                  value: driver.canCancelBooking ? 'On' : 'Off',
                },
                ]}
              />
              <EditDeleteButton />
            </div>

            <CustomizedTabs
              activeTab={activeTab}
              handleChangeTab={handleChangeTab}
              labels={[
                'Service Area',
                'Working Days',
                'Holidays / Leave',
                'Login Access',
              ]}
            />
            <div className={classes.infoContainer}>
              {activeTab === 0 && <ServiceArea key={driver.id} />}
              {activeTab === 1 && <WorkingDays key={driver.id} />}
              {activeTab === 2 && <Holiday key={driver.id} />}
              {activeTab === 3 && <LoginAccess key={driver.id} />}
            </div>
          </>
        )}
      </div>
    </InfoSection>
  )
}

export default observer(DriverInfo)
