import { startOfDay, endOfDay, addDays, subDays } from 'date-fns'
import { makeAutoObservable, flow } from 'mobx'
import moment from 'moment'

import { TODAY } from 'admin/utils/helper'
import * as srv from 'admin/services'
import handleError from 'admin/services/error-handler'

export default class BookingPaymentStore {
  loading = false
  processing = false
  dialog = false
  list = []
  total = 0
  query = {
    sortBy: 'createdAt',
    sortDirection: 'desc',
    status: '',
    phoneNumber: '',
  }
  startDate = startOfDay(subDays(TODAY(), 7))
  endDate = endOfDay(addDays(TODAY(), 7))

  currentPage = 0
  rowsPerPage = 10

  constructor(rootStore) {
    makeAutoObservable(this, {
      fetch: flow,
    })
    this.rootStore = rootStore
  }

  setOpenFilterDialog = (value) => {
    this.dialog = value
  }

  updateStartDate = (value) => {
    this.startDate = value
  }

  updateEndDate = (value) => {
    this.endDate = value
  }

  updatePage = (page) => {
    this.currentPage = page
  }

  updateRowsPerPage = (perPage) => {
    this.rowsPerPage = perPage
  }

  updateQuery = (key, value, resetPage = false) => {
    this.query[key] = value
    if (resetPage) this.currentPage = 0
  }

  resetQuery = (sDate, eDate) => {
    this.query = {
      sortBy: 'createdAt',
      sortDirection: 'desc',
      status: '',
      phoneNumber: '',
    }
    this.currentPage = 0
    this.rowsPerPage = 10
    this.startDate = sDate || startOfDay(subDays(TODAY(), 7))
    this.endDate = eDate || endOfDay(addDays(TODAY(), 7))
  }

  get queryParams() {
    const sDate = moment(this.startDate).startOf('day').toISOString()
    const eDate = moment(this.endDate).endOf('day').toISOString()
    const params = {
      sortBy: this.query.sortBy,
      sortDirection: this.query.sortDirection,
      limit: this.rowsPerPage,
      offset: this.currentPage * this.rowsPerPage,
      startDate: sDate,
      endDate: eDate,
    }
    if (this.query.phoneNumber) params.phoneNumber = this.query.phoneNumber
    if (this.query.status) params.status = this.query.status
    if (this.query.createdAt) params.createdAt = this.query.createdAt
    return params
  }

  * fetch(params) {
    try {
      this.loading = true
      const response = yield srv.fetchBookingPayment({
        ...this.queryParams,
        ...params,
      })
      if (response.ok) {
        this.list = response.data.list
        this.total = response.data.total
        return response.data
      }
      throw handleError(response)
    } catch (error) {
      return null
    } finally {
      this.loading = false
    }
  }
}
