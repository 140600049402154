import React, { useCallback, useState, useEffect } from 'react'
import moment from 'moment'
import {
  Grid,
  Typography,
  Icon,
  Stack,
  Tabs,
  Tab,
  Box,
  TextField as MuiTextField,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { DatePicker } from '@mui/x-date-pickers'

import { useStores } from 'admin/hooks'

import {
  isTodayDate,
} from 'admin/utils/helper'
import Page from 'admin/components/page'
import TotalBooking from 'admin/components/dashboard/card-info/booking'
import TotalCustomer from 'admin/components/dashboard/card-info/customer'
import BookingTable from 'admin/components/dashboard/booking-table'
import TodayButton from 'admin/components/button-jump-to-today'
import Analytics from './analytics'

import styles from './styles'

const useStyles = makeStyles(styles)

const Overview = () => {
  const classes = useStyles()
  const [date, setDate] = useState(moment().startOf('day').format())

  const handleChange = (newDateValue) => {
    setDate(moment(newDateValue).startOf('day').format())
  }

  return (
    <Box>
      <Stack
        direction="row"
        justifyContent="space-between"
        className={classes.upperTitle}
      >
        <Stack
          direction="row"
          alignItems="flex-end"
        >
          <Icon className={classes.icon}>
            dashboard
          </Icon>
          <Typography variant="h3">
            Overview
          </Typography>
        </Stack>
        <Stack
          direction="row"
          alignItems="center"
        >
          {isTodayDate(date) ? (
            <Typography
              variant="h4"
              className={classes.today}
            >
              Today
            </Typography>
          ) : (
            <TodayButton
              setDate={setDate}
              style={classes.todayButton}
            />
          )}
          <DatePicker
            label="Select Date"
            value={date}
            onChange={handleChange}
            renderInput={(params) => (
              <MuiTextField
                {...params}
                size="small"
                helperText={null}
              />
            )}
            mask="__/__/____"
            inputFormat="dd/MM/yyyy"
          />
        </Stack>
      </Stack>
      <Grid
        container
        spacing={4}
      >
        <Grid
          item
          xs={6}
          lg={6}
        >
          <TotalBooking date={date} />
        </Grid>
        <Grid
          item
          xs={6}
          lg={6}
        >
          <TotalCustomer date={date} />
        </Grid>
      </Grid>
      <BookingTable date={date} />
    </Box>
  )
}

const Dashboard = () => {
  const classes = useStyles()
  const { bookingTagStore } = useStores()

  const [activeTab, setActiveTab] = useState(0)

  const handleChangeTab = useCallback((event, value) => {
    setActiveTab(value)
  }, [])

  useEffect(() => {
    if (activeTab === 0) return
    async function fetchData() {
      await bookingTagStore.fetch()
    }
    fetchData()
  }, [activeTab])

  return (
    <Page>
      <Box>
        <Tabs
          value={activeTab}
          onChange={handleChangeTab}
          textColor="secondary"
          indicatorColor="secondary"
        >
          {['Dashboard',
            'Analytics',
          ].map((day) => (
            <Tab
              key={day}
              label={day}
              classes={{
                root: classes.tab,
              }}
            />
          ))}
        </Tabs>
      </Box>
      {activeTab === 0 && <Overview />}
      {activeTab === 1 && <Analytics />}
    </Page>
  )
}

export default Dashboard
