import React, {
  useState,
  useCallback,
  useMemo,
} from 'react'
import { observer } from 'mobx-react'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'

import {
  Grid,
  Typography,
  Stack,
  Icon,
  Button,
  Divider,
} from '@mui/material'

import { CUSTOMER_CREATED } from 'admin/config'
import { useStores } from 'admin/hooks'
import {
  getTags,
  weightage,
  extractPostalCodeFromAddress,
} from 'admin/utils/helper'

import ServiceIcon from 'admin/components/service-icon'
import Status from 'admin/components/status'

import styles from './styles'

const useStyles = makeStyles(styles)

const BookedData = ({
  timeslot,
  index,
  bookedList,
  bookedListAWeekAgo,
}) => {
  const classes = useStyles()
  const { infoSectionStore, notificationStore } = useStores()
  const [hover, setHover] = useState({})

  const handleHover = (event, indexItem) => {
    setHover({ ...hover, [indexItem]: event })
  }

  const handleDirectionsWaypoints = useCallback((items) => {
    let url = ''
    const query = items.map(item => {
      let extractPostalCode = extractPostalCodeFromAddress(item.address) || item.customer?.postalCode
      if (!extractPostalCode) {
        extractPostalCode = item.address || item.customer?.address
      }
      if (!extractPostalCode) {
        return notificationStore.setError('Postal code or address not found.')
      }
      return encodeURIComponent(`Singapore ${extractPostalCode}`)
    }).join('/')

    if (items.length !== 1) {
      url = `https://www.google.com/maps/dir/${query}`
    } else {
      url = `https://www.google.com/maps/dir/?api=1&travelmode=driving&dir_action=navigate&destination=${query}`
    }

    window.open(url, '_blank')
  }, [])

  const totalWeightage = () => {
    let total = 0
    bookedList.map((item) => {
      const totalWeight = weightage(item)
      total += totalWeight
      return totalWeight
    })
    if (bookedListAWeekAgo.length > 0) {
      bookedListAWeekAgo.map((item) => {
        const totalWeight = weightage(item)
        total += totalWeight
        return true
      })
    }
    return total
  }

  const handleClick = useCallback((data) => {
    infoSectionStore.select(data)
  }, [])

  const filterBooking = useMemo(() => {
    return bookedList.filter(item => !['completed', 'failed'].includes(item.status))
  }, [bookedList])

  return (
    <Grid
      container
      className={classes.container}
    >
      <Grid
        item
        lg={2}
        xs={2}
        className={classes.timeslot}
      >
        <Stack
          direction="row"
          justifyContent="center"
        >
          <Typography
            variant="h5"
            className={classes.slotText}
          >
            {timeslot.start} - {timeslot.end}
          </Typography>
        </Stack>
      </Grid>
      <Grid
        item
        lg={10}
        xs={10}
      >
        <div className={classes.slot}>
          <Stack
            direction="row"
            justifyContent="space-between"
          >
            <Typography
              variant="h5"
              className={classes.available}
            >
              {`${totalWeightage()}/${timeslot.capacity}`}
            </Typography>
            <div
              onMouseEnter={() => { handleHover(true, index) }}
              onMouseLeave={() => { handleHover(false, index) }}
              className={!filterBooking.length ?
                classes.mapDisabled : classes.map}
            >
              <Button
                onClick={() => {
                  handleDirectionsWaypoints(filterBooking)
                }}
                className={classes.button}
                disabled={!filterBooking.length}
              >
                <Icon
                  className={clsx('', {
                    [classes.iconHover]: filterBooking.length > 0 && hover[index],
                    [classes.icon]: filterBooking.length > 0 && !hover[index],
                  })}
                >
                  map
                </Icon>
              </Button>
            </div>
          </Stack>
          <Divider />
        </div>
        <Grid>
          {bookedList?.map((data) => {
            const {
              pickup,
              delivery,
            } = getTags(data.bookingTags)
            return (
              <React.Fragment key={data.id}>
                <Stack
                  direction="column"
                  className={clsx(
                    classes.customer,
                    (
                      !data.hasReviewed &&
                      CUSTOMER_CREATED.includes(data.channel?.name)
                    ) && classes.review,
                  )}
                  onClick={() => { handleClick(data) }}
                >
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                  >
                    <Typography variant="h5">
                      {data.customer?.name || data.customer?.originalData?.name}
                    </Typography>
                    <Status
                      variant="h5"
                      status={data.status}
                    />
                  </Stack>
                  <Typography
                    variant="h5"
                    className={classes.mobileNo}
                  >
                    {data.customer?.phoneNumber || data.customer?.originalData?.mobileNo}
                  </Typography>
                  <Typography variant="h5">
                    {data.address || data.customer?.address}
                  </Typography>
                  <Typography
                    variant="h5"
                    className={classes.remarks}
                  >
                    {data.remarks}
                  </Typography>
                  <div>
                    {pickup.length > 0 && (
                      <ServiceIcon
                        iconName="pickup"
                        data={pickup}
                        className={classes.tagColor}
                      />
                    )}
                    {delivery.length > 0 && (
                      <ServiceIcon
                        iconName="delivery"
                        data={delivery}
                        className={classes.tagColor}
                      />
                    )}
                  </div>
                </Stack>
                <Divider />
              </React.Fragment>
            )
          })}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default observer(BookedData)
