export default (theme) => ({
  tableIcon: {
    whiteSpace: 'normal',
    wordWrap: 'break-word',
  },
  container: {
    backgroundColor: '#fff',
    borderRadius: '0 0 20px 20px',
    padding: '10px',
    minHeight: '100px',
  },
  table: {
    minWidth: 650,
  },
  cellHeader: {
    fontWeight: 'bold',
    fontSize: '1rem',
  },
  text: {
    minHeight: '6.25rem',
  },
  bottomTitle: {
    marginTop: '2.5rem',
    marginBottom: '0.75rem',
  },
  icon: {
    marginRight: '0.7rem',
  },
  link: {
    color: theme.color.paarl,
    fontSize: '0.875rem',
    fontWeight: 600,
  },
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    '& > span': {
      maxWidth: 40,
      width: '100%',
      backgroundColor: '#635ee7',
    },
  },
  flexContainer: {
    display: 'inline-flex',
    width: '100%',
  },
  root: {
    textTransform: 'none',
    color: theme.color.primary,
    backgroundColor: '#fff',
    border: `1px solid ${theme.color.primary}`,
    borderRadius: '16px 16px 0 0',
    transition: '0.5s',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    '& $wrapper': {
      display: 'flex !important',
      flexDirection: 'row !important',
      textAlign: 'left !important',
      justifyContent: 'flex-start',
      '& > p': {
        marginRight: '1rem',
      },
    },
    '&$selected': {
      backgroundColor: theme.color.primary,
      color: theme.color.white,
    },
    '&:focus': {
      opacity: 1,
    },
    [theme.breakpoints.down('md')]: {
      textAlign: 'left',
    },
  },
  rootTabs: {
    minHeight: '4.5rem',
  },
  selected: {},
  wrapper: {},
  iconText: {
    padding: '0 1rem 0 1rem',
  },
  card: {
    borderRadius: '8px',
  },
  cardContent: {
    height: '165px',
    overflow: 'auto',
  },
})
