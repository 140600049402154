export default (theme) => ({
  tabsRoot: {
    backgroundColor: theme.color.pearlBush,
  },
  tabsIndicator: {
    height: 3,
    borderTopLeftRadius: 3,
    borderTopRightRadius: 3,
    backgroundColor: theme.color.lightBlack,
  },
  scrollButtons: {
    '&.Mui-disabled': {
      opacity: 0.3,
    },
  },
  tab: {
    textTransform: 'capitalize',
    color: theme.palette.secondary.main,
    minWidth: 0,
    fontSize: '14px',
    '&:not(:first-of-type)': {
      marginLeft: -1,
    },
  },
  tabSelected: {
    borderBottomWidth: 0,
    color: `${theme.palette.secondary.main} !important`,
  },
  wrapper: {
    fontWeight: 600,
    letterSpacing: 0.15,
    display: 'flex',
    flexDirection: 'row',
    textTransform: 'capitalize',
  },
})
