export default function styles(theme) {
  return {
    boxDateRange: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginBottom: '1rem',
    },
    dateRangePicker: {
      border: `1px solid ${theme.color.lightGray}`,
      borderRadius: '4px',
    },
    spanDate: {
      marginLeft: '0.75rem',
    },
    driverHightest: {
      marginBottom: '1rem',
    },
  }
}
