import React, {
  useCallback,
} from 'react'
import { observer } from 'mobx-react'

import makeStyles from '@mui/styles/makeStyles'
import {
  Typography,
  Stack,
} from '@mui/material'
import { useStores } from 'admin/hooks'

import CustomDataGrid from 'admin/components/data-grid'
import StatusText from 'admin/components/status-text'

import {
  formatDate,
  formatTimeOnly,
} from 'admin/utils/helper'

import styles from './styles'

const useStyles = makeStyles(styles)

const columns = [
  {
    field: 'date',
    headerName: 'Date',
    width: 145,
    renderCell: (params) => (
      <Stack direction="column">
        <Typography variant="h5">
          {formatDate(params.row.createdAt)}
        </Typography>
        <Typography variant="h5">
          {formatTimeOnly(params.row.createdAt)}
        </Typography>
      </Stack>
    ),
  },
  {
    field: 'customer',
    sortable: false,
    headerName: 'Customer',
    width: 160,
    renderCell: (params) => (
      <Stack direction="column">
        <Typography variant="h5">
          {params.row.booking?.customer?.name ||
          params.row.booking?.customer?.originalData.name}
        </Typography>
        <Typography variant="h5">
          {params.row.booking?.customer?.phoneNumber ||
          params.row.booking?.customer?.originalData.mobileNo}
        </Typography>
      </Stack>
    ),
  },
  {
    field: 'invoicePrestoIds',
    headerName: 'Invoices',
    width: 210,
    renderCell: (params) => params.row.invoicePrestoIds || '-',
  },
  {
    field: 'paymentMethod',
    headerName: 'Payment Method',
    width: 150,
    renderCell: (params) => {
      return (
        <Stack direction="column">
          <Typography
            variant="h5"
          >
            {params.row.paymentMethod}
          </Typography>
        </Stack>
      )
    },
  },
  {
    field: 'amount',
    headerName: 'Amount',
    width: 130,
    renderCell: (params) => `$${Number(params.row.amount).toFixed(2)}`,
  },
  {
    field: 'fee',
    headerName: 'Service Fee',
    width: 130,
    renderCell: (params) => `$${Number(params.row.fee).toFixed(2)}`,
  },
  {
    field: 'net',
    headerName: 'Net',
    width: 130,
    renderCell: (params) => `$${Number(params.row.net).toFixed(2)}`,
  },
  {
    field: 'status',
    headerName: 'Charge CC',
    width: 240,
    renderCell: (params) => {
      return (
        <Stack direction="column">
          <StatusText
            variant="caption"
            status={params.row.status || '-'}
          />
        </Stack>
      )
    },
  },
]

const BookingPaymentDataGrid = () => {
  const classes = useStyles()
  const { bookingPaymentStore, infoSectionStore } = useStores()

  const handlePageChange = useCallback((params) => {
    bookingPaymentStore.updatePage(params)
  }, [])

  const handlePageSizeChange = useCallback((param) => {
    bookingPaymentStore.updateRowsPerPage(param)
  }, [])

  const handleRowClick = useCallback((rowData) => {
    infoSectionStore.select(rowData.row)
  }, [])

  const onSortModelChange = (param) => {
    if (param.length === 0) {
      bookingPaymentStore.resetQuery()
    }
    if (param.length === 1) {
      bookingPaymentStore.updateQuery('sortBy', param[0].field, true)
      bookingPaymentStore.updateQuery('sortDirection', param[0].sort, true)
    }
    return null
  }

  return (
    <CustomDataGrid
      rows={bookingPaymentStore.list}
      columns={columns}
      handleRowClick={handleRowClick}
      handlePageChange={handlePageChange}
      rowCount={bookingPaymentStore.total}
      pageSize={bookingPaymentStore.rowsPerPage}
      page={bookingPaymentStore.currentPage}
      handlePageSizeChange={handlePageSizeChange}
      loading={bookingPaymentStore.loading}
      onSortModelChange={onSortModelChange}
      rowHeight={100}
      overrideLayout={classes.overrideLayout}
    />
  )
}

export default observer(BookingPaymentDataGrid)
