import React, { useCallback } from 'react'
import { observer } from 'mobx-react'
import {
  useForm,
  Controller,
} from 'react-hook-form'

import {
  Stack,
  Typography,
  InputAdornment,
  IconButton,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

import { Search as SearchIcon } from '@mui/icons-material'

import { useStores } from 'admin/hooks'
import Dialog from 'admin/components/dialog'
import AppTextField from 'admin/components/textfield'
import { validpostalCode } from 'admin/utils/helper'

import styles from './styles'

const useStyles = makeStyles(styles)

const AddressDialog = ({
  address,
  postalCode,
  onSubmit,
  unitNumber,
}) => {
  const classes = useStyles()
  const { bookingStore } = useStores()

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
    setError,
    reset,
  } = useForm()

  const onSearch = useCallback(async (data) => {
    // call onemap api
    await bookingStore.fetchAddress(data.postalCode)
    setValue('address', bookingStore.address || '')
  }, [])

  const resetValue = useCallback((data) => {
    bookingStore.setOpenConfirmDialog(false)
    reset({
      postalCode: data.postalCode,
      address: data.address,
      unitNumber: data.unitNumber,
    })
  }, [])

  // called when clicking Set button inside Addresss dialog
  const onSubmitAddres = useCallback(async (data) => {
    if (data.postalCode !== validpostalCode(data.address)) {
      return setError('postalCode', {
        types: 'search',
      })
    }
    if (!data.address) {
      return setError('address', {
        types: 'This is required',
      })
    }
    await onSubmit(data)
    resetValue(data)
    return null
  }, [setError])

  return (
    <Dialog
      open={bookingStore.dialog.confirm}
      title="Address"
      content={(
        <>
          <Controller
            control={control}
            render={({
              field: { value, onChange },
            }) => (
              <Stack
                direction="column"
                className={classes.field}
              >
                <AppTextField
                  label="Postal Code"
                  variant="standard"
                  onChange={onChange}
                  value={value}
                  error={!!errors.postalCode}
                  className={classes.textField}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleSubmit(onSearch)}
                        >
                          <SearchIcon color="primary" />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                {
                  errors.postalCode && errors.postalCode.type === 'required' && (
                    <Typography
                      variant="caption"
                      className={classes.errorMessage}
                    >
                      This is required.
                    </Typography>
                  )
                }
                {
                  errors.postalCode && errors.postalCode.types === 'search' && (
                    <Typography
                      variant="caption"
                      className={classes.errorMessage}
                    >
                      Search postal code
                    </Typography>
                  )
                }
                {
                  errors.postalCode && (
                    errors.postalCode.type === 'maxLength' ||
                    errors.postalCode.type === 'minLength'
                  ) && (
                    <Typography
                      variant="caption"
                      className={classes.errorMessage}
                    >
                      Must be 6 characters
                    </Typography>
                  )
                }
              </Stack>
            )}
            name="postalCode"
            rules={{
              required: true,
              maxLength: 6,
              minLength: 6,
            }}
            defaultValue={postalCode}
          />
          <Controller
            control={control}
            render={({ field: { value, onChange } }) => (
              <Stack
                direction="column"
                className={classes.field}
              >
                <AppTextField
                  label="Address"
                  variant="standard"
                  onChange={onChange}
                  value={value}
                  error={!!errors.address}
                  multiline
                  minRows={1}
                />
                {errors.address?.types && (
                  <Typography
                    variant="caption"
                    className={classes.errorMessage}
                  >
                    {errors.address?.types}
                  </Typography>
                )}
              </Stack>
            )}
            name="address"
            defaultValue={address}
          />
          <Controller
            control={control}
            render={({ field: { value, onChange } }) => (
              <Stack direction="column">
                <AppTextField
                  label="Unit Number"
                  variant="standard"
                  onChange={onChange}
                  value={value}
                />
              </Stack>
            )}
            name="unitNumber"
            defaultValue={unitNumber}
          />
        </>
      )}
      primaryActionLabel="Set"
      onAccept={handleSubmit(onSubmitAddres)}
      onClose={resetValue}
    />
  )
}

export default observer(AddressDialog)
