import { flow, makeAutoObservable } from 'mobx'

import * as srv from 'admin/services'
import handleError from 'admin/services/error-handler'

export default class BookingTagStore {
  loading = false
  processing = false
  dialog = {
    form: false,
    delete: false,
    barcode: false,
  }
  selected = null
  list = []
  total = 0
  query = {
    name: '',
    weightage: '',
  }

  DEFAULT = {
    name: '',
    weightage: 0,
  }

  constructor() {
    makeAutoObservable(this, {
      reload: flow,
      fetch: flow,
    })
  }

  setOpenDialog = (selected, type) => {
    this.dialog[type] = true
    if (selected) {
      this.selected = { ...this.DEFAULT, ...selected }
    } else {
      this.selected = { ...this.DEFAULT }
    }
  }

  setCloseDialog = (type, reset = true) => {
    this.dialog[type] = false
    if (reset) setTimeout(this.resetSelected, 50)
  }

  setSelected = (value) => {
    this.selected = value
  }

  * reload(reset = false) {
    yield this.fetch()
    if (reset) {
      this.selected = { ...this.DEFAULT }
    }
  }

  * fetch(params) {
    try {
      this.loading = true
      const response = yield srv.fetchTag({ ...params })
      if (response.ok) {
        this.list = response.data.list
        this.total = response.data.total
        return response.data
      }
      throw handleError(response)
    } catch (error) {
      return null
    } finally {
      this.loading = false
    }
  }
}
