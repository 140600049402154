export default (theme) => ({
  parentContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  infoContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1',
    overflow: 'auto',
  },
  divider: {
    margin: '1rem 0 0',
    background: theme.color.wheat,
  },
})
