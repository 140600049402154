import React from 'react'
import { computed } from 'mobx'
import { observer } from 'mobx-react'

import { useStores } from 'admin/hooks'

const ProtectInfo = ({ icon, silent }) => {
  if (silent) return null
  return icon || (
    <div style={{
      padding: '1rem',
      color: 'grey',
      fontSize: '0.9rem',
      textAlign: 'center',
    }}
    >
      Your account level is not allowed to view this data / perform this action
    </div>
  )
}

const Protected = ({ level, category, children, icon, forceBlock = false, silent = false }) => {
  const { adminStore } = useStores()
  const hasPermission = computed(() => {
    // always return true for admin level role
    if (adminStore.isAdmin) return true

    const { admin } = adminStore
    const permission = admin.jobRole.permissions.find(p => p.name === category)

    if (!permission || !permission.RolePermission) return false

    // add, update, delete level permission always give view permission
    const allowPermission = permission.RolePermission
    if (
      level === 'view' &&
      (
        allowPermission.create ||
        allowPermission.update ||
        allowPermission.delete
      )
    ) return true

    return !!allowPermission[level]
  }).get()

  return (hasPermission && !forceBlock) ? children : (
    <ProtectInfo icon={icon} silent={silent} />
  )
}

export default observer(Protected)
