export default (theme) => ({
  parentContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  infoContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1',
    overflow: 'auto',
  },
  infoItem: {
    marginTop: '2rem',
  },
  img: {
    height: '135px',
    borderRadius: 4,
    cursor: 'pointer',
  },
  removeButton: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
  button: {
    minWidth: '0',
    boxShadow: 'none',
    padding: '0.3rem',
    backgroundColor: theme.color.paarl,
  },
  icon: {
    fontSize: '1.25rem',
  },
  logButton: {
    height: '2.5rem',
    margin: '15px',
    borderColor: theme.color.paarl,
    color: theme.color.paarl,
  },
})
