export default (theme) => ({
  status: ({ status, text }) => {
    let color = ''
    if (status === 'completed') {
      color = theme.color.darkGreen
    } else if (status === 'on the way') {
      color = theme.color.cinnabar
    } else if (status === 'failed') {
      color = theme.color.redCherry
    } else if (status === 'pending') {
      color = theme.color.lightBlack
    } else if (status === 'deleted') {
      color = theme.color.dustyGray
    } else {
      color = theme.color.lightBrown
    }

    return {
      textTransform: 'capitalize',
      padding: text ? '' : '2px 9px',
      borderRadius: text ? '' : '4px',
      color: text ? color : theme.color.white,
      backgroundColor: text ? '' : color,
    }
  },
  reason: {
    textTransform: 'capitalize',
  },
})
