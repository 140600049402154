import React, { useCallback } from 'react'
import { observer } from 'mobx-react'
import { useStores, useApiRequest } from 'admin/hooks'
import * as srv from 'admin/services'
import { Typography } from '@mui/material'
import CustomDialog from 'admin/components/dialog'
import { computed } from 'mobx'

const ConfirmDialog = () => {
  const { bookingStore, infoSectionStore, notificationStore } = useStores()
  const { request: updateBookingStatus, isLoading } = useApiRequest(
    srv.updateBookingFromSidemenu,
    { blocking: true },
  )

  const bookingStatus = computed(() => {
    const { confirmStatus } = bookingStore

    return {
      status: confirmStatus.status,
      reason: confirmStatus.reason,
    }
  }).get()

  const onConfirm = useCallback(async () => {
    const { selected: booking } = infoSectionStore
    const { confirmStatus } = bookingStore

    if (booking.id === undefined) {
      notificationStore.setError('Booking id is undefined')
      return
    }

    if (isLoading) return
    const res = await updateBookingStatus(booking, {
      status: confirmStatus.status,
      reason: confirmStatus.reason,
    })
    if (!res) return

    bookingStore.setResetConfirmStatus()
    notificationStore.setSuccess('Booking status is updated')
    bookingStore.reload() // dont close the side menu
  }, [])

  const handleCloseDialog = useCallback(async () => {
    bookingStore.setResetConfirmStatus()
  }, [])

  return (
    <CustomDialog
      open={!!bookingStore.confirmStatus.status}
      size="sm"
      title="Confirm Status"
      primaryActionLabel="Confirm"
      content={(
        <Typography variant="body2">
          Do you want to change booking status to
          {bookingStatus.status === 'failed' ?
            ` ${bookingStatus.status} with reason is ${bookingStatus.reason}?` :
            ` ${bookingStatus.status}?`}
        </Typography>
      )}
      loading={isLoading}
      onAccept={onConfirm}
      onClose={handleCloseDialog}
    />
  )
}

export default observer(ConfirmDialog)
