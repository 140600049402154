export default function styles() {
  return {
    list: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '1rem',
    },
    days: {
      textTransform: 'capitalize',
      fontWeight: 600,
    },
    btnWrapper: {
      margin: '1rem 0 2rem',
      display: 'flex',
      justifyContent: 'center',
    },
  }
}
