import React from 'react'
import { observer } from 'mobx-react'
import { Divider } from '@mui/material'

import makeStyles from '@mui/styles/makeStyles'

import { useStores } from 'admin/hooks'

import InfoSection from 'admin/components/info-section'
import HeadBar from 'admin/components/head-bar'
import { formatDateHourAmPm } from 'admin/utils/helper'
import Information from './information'

import styles from './styles'

const useStyles = makeStyles(styles)

const InvoicePaymentInfo = () => {
  const { infoSectionStore } = useStores()
  const classes = useStyles()

  const { selected: actionLog } = infoSectionStore

  return (
    <InfoSection
      title="Sms Reminder"
    >
      <div className={classes.parentContainer}>
        {actionLog && (
          <>
            <HeadBar
              data={[
                {
                  label: 'Date',
                  value: formatDateHourAmPm(actionLog.createdAt),
                },
                {
                  label: 'Subject',
                  value: `${actionLog.subjectId === '0' ? 'system' : `${actionLog.subject} ${actionLog?.subjectDetail?.name}`}`,
                },
                {
                  label: 'Object',
                  value: actionLog.action,
                },
                {
                  label: 'Type',
                  value: (actionLog.object === 'sms' && 'All Sms Reminder') || (actionLog.object === 'smsNotification' && 'Send Sms Notification') ||
                    (actionLog.object === 'smsCancelBooking' && 'Cancel Booking'),
                },
                {
                  label: actionLog.subjectId !== '0' && 'Send SMS Notification',
                  value: actionLog.subjectId !== '0' && (actionLog?.value?.isChecked ? 'true' : 'false'),
                },
              ]}
            />
            <Divider className={classes.divider} />
            <div className={classes.infoContainer}>
              <Information />
            </div>
          </>
        )}
      </div>
    </InfoSection>
  )
}

export default observer(InvoicePaymentInfo)
