import React, { useMemo } from 'react'
import { observer } from 'mobx-react'
import {
  Stack,
  Typography,
} from '@mui/material'

import { useStores } from 'admin/hooks'

import Chart from 'admin/components/chart'
import CardChart from 'admin/components/dashboard-card/upper-content/chart'

import { APP_PAYMENT_METHOD } from 'admin/config'

const PaymentMethodChart = () => {
  const { bookingStore } = useStores()
  const { percentByPaymentMethod } = bookingStore

  const data = useMemo(() => {
    if (!percentByPaymentMethod && percentByPaymentMethod.length <= 0) return {}
    return {
      labels: percentByPaymentMethod.map(item => {
        const name = item.name === APP_PAYMENT_METHOD.value ? APP_PAYMENT_METHOD.display : item.name
        return [item.total, name]
      }),
      datasets: [
        {
          label: '',
          data: percentByPaymentMethod.map(item => item.total),
          backgroundColor: [
            'rgba(2,194,255,1)',
            'rgba(205,0,209,1)',
            'rgba(255,153,0,1)',
            'rgba(0,190,122,1)',
            'rgba(255,245,0,1)',
            'rgba(136,136,136,1)',
            'rgba(136,136,136,1)',
            'rgba(136,136,136,1)',
          ],
          borderColor: [
            'rgba(2,194,255,2)',
            'rgba(205,0,209,2)',
            'rgba(255,153,0,2)',
            'rgba(0,190,122,2)',
            'rgba(255,245,0,2)',
            'rgba(136,136,136,1)',
            'rgba(136,136,136,2)',
            'rgba(136,136,136,2)',
          ],
          borderWidth: 1,
          afterLabel: percentByPaymentMethod.map(item => item.name),
        },
      ],
    }
  }, [percentByPaymentMethod])

  return (
    <CardChart
      title="Payment Method"
    >
      {percentByPaymentMethod.length > 0 ? (
        <Chart
          data={data}
          customLegend
          customTooltip
        />
      ) : (
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          style={{ minHeight: '15rem' }}
        >
          <Typography>
            No Percentage
          </Typography>
        </Stack>
      )}
    </CardChart>
  )
}

export default observer(PaymentMethodChart)
