import { makeAutoObservable, flow } from 'mobx'

import * as srv from 'admin/services'
import handleError from 'admin/services/error-handler'

export default class ChannelStore {
  loading = false
  processing = false
  openDialog = false
  deleteDialog = false
  selected = null
  list = []
  total = 0
  query = {
    name: '',
  }

  DEFAULT = {
    id: '',
    name: '',
  }

  constructor() {
    makeAutoObservable(this, {
      reload: flow,
      fetch: flow,
    })
  }

  setOpenDialog = (value) => {
    this.openDialog = value
  }

  setDeletedDialog = (value) => {
    this.deleteDialog = value
  }

  setSelected = (value) => {
    this.selected = value
  }

  resetSelected = () => {
    this.selected = { ...this.DEFAULT }
  }

  updateSelected = (key, value) => {
    this.selected = { ...this.selected, [key]: value }
  }

  * reload(reset = false) {
    yield this.fetch()
    if (reset) {
      this.selected = { ...this.DEFAULT }
    }
  }

  * fetch(name) {
    try {
      this.loading = true
      const response = yield srv.fetchChannel({ name })
      if (response.ok) {
        this.list = response.data.list
        this.total = response.data.total
        return response.data
      }
      throw handleError(response)
    } catch (error) {
      return null
    } finally {
      this.loading = false
    }
  }
}
