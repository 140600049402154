import React from 'react'
import { observer } from 'mobx-react'
import makeStyles from '@mui/styles/makeStyles'
import { Typography } from '@mui/material'

import { useStores } from 'admin/hooks'
import handleError from 'admin/services/error-handler'
import UploadImage from 'admin/components/upload-image'

import styles from './styles'

const useStyles = makeStyles(styles)

const UploadImg = ({ title, onUpload }) => {
  const classes = useStyles()
  const { notificationStore } = useStores()

  return (
    <div className={classes.infoItem}>
      <Typography
        variant="h6"
        style={{ marginBottom: '1rem' }}
      >
        {title}
      </Typography>
      <UploadImage
        actionButton
        multi={false}
        maxSize={1048576} // 1mb
        onUpload={(uploads) => onUpload(uploads[0])}
        onError={(error) => {
          notificationStore.setError(handleError(error))
        }}
      />
    </div>
  )
}
export default observer(UploadImg)
