import React, { useCallback, useState } from 'react'
import { useStores, useApiRequest } from 'admin/hooks'
import * as srv from 'admin/services'
import {
  CardMedia,
  Button,
} from '@mui/material'
import { Delete } from '@mui/icons-material'
import makeStyles from '@mui/styles/makeStyles'

import styles from './styles'

const useStyles = makeStyles(styles)

const Img = ({
  title,
  img,
  imgId,
  bookingId,
  onClick,
  className,
}) => {
  const classes = useStyles()
  const { notificationStore, infoSectionStore } = useStores()
  const [display, setDisplay] = useState(true)

  const {
    request: deleteBookingImage,
  } = useApiRequest(srv.deleteBookingImage, { blocking: true })

  const onDelete = useCallback(async () => {
    const response = await deleteBookingImage({
      id: imgId,
      bookingId,
    })
    if (!response) return
    notificationStore.setSuccess('Delete image success')
    setDisplay(false)
    infoSectionStore.replace({
      ...infoSectionStore.selected,
      images: infoSectionStore.selected.images.filter(el => el.id !== imgId) })
  }, [])

  return (
    <div className={className}>
      {display && (
        <div style={{ position: 'relative' }}>
          <CardMedia
            image={img}
            title={title}
            className={classes.img}
            onClick={onClick}
          />
          <div className={classes.removeButton}>
            <Button
              variant="contained"
              className={classes.button}
              onClick={() => { onDelete() }}
            >
              <Delete className={classes.icon} />
            </Button>
          </div>
        </div>
      )}
    </div>
  )
}

export default Img
