import { create } from 'apisauce'
import qs from 'qs'

import eventEmitter from 'admin/utils/event'

const scheme = process.env.API_SERVER_SCHEME || 'http'
const host = process.env.API_SERVER_HOST || 'localhost'
const port = process.env.API_SERVER_PORT || '9000'

const baseURL = `${scheme}://${host}:${port}`

const apiSauce = create({
  baseURL,
  headers: {
    Accept: 'application/json;charset=UTF-8',
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
  },
  paramsSerializer: qs.stringify,
  timeout: 30000,
})

// any 401 error means session expired or login info invalid, should set loggedIn false
const loginMonitor = (response) => {
  if (response.status === 401) {
    eventEmitter.emit('LOGOUT')
  }
}
apiSauce.addMonitor(loginMonitor)

export const setApiAuth = (token) => apiSauce.setHeader('Authorization', token)
export const api = apiSauce

export const awsApi = (url) => create({
  baseURL: url,
  headers: {
    Accept: 'application/json;charset=UTF-8',
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
  },
  paramsSerializer: qs.stringify,
  timeout: 30000,
})
