import { makeAutoObservable, flow } from 'mobx'

import { moveAnytimeToFront } from 'admin/utils/helper'
import * as srv from 'admin/services'
import handleError from 'admin/services/error-handler'

export default class SlotStore {
  loading = false
  processing = false
  openDialog = false
  selected = null
  list = []
  total = 0

  DEFAULT = {
    id: [],
    start: '',
    end: '',
    capacity: '',
  }

  constructor(rootStore) {
    makeAutoObservable(this, {
      reload: flow,
      fetch: flow,
    })
    this.rootStore = rootStore
  }

  setOpenDialog = (value) => {
    this.openDialog = value
  }

  get capacitySlot() {
    if (this.list.length === 0) return ''
    const capacity = new Set()
    this.list.forEach((item) => capacity.add(item.capacity))
    return Array.from(capacity)
  }

  get allIdSlot() {
    if (this.list.length === 0) return ''
    const id = new Set()
    this.list.forEach((item) => id.add(item.id))
    return Array.from(id)
  }

  updateSelected = (key, value) => {
    this.selected = { ...this.selected, [key]: value }
  }

  setSelected = (value) => {
    this.selected = value
  }

  * reload(reset = false) {
    yield this.fetch()
    if (reset || !this.list.length) {
      this.selected = { ...this.DEFAULT }
    }
  }

  * fetch() {
    try {
      this.loading = true
      const response = yield srv.fetchSlot()
      if (response.ok) {
        this.list = response.data.list.sort(moveAnytimeToFront)
        this.total = response.data.total
        return response.data
      }
      throw handleError(response)
    } catch (error) {
      return null
    } finally {
      this.loading = false
    }
  }
}
