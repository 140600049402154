import React, {
  useState,
  useCallback,
  useEffect,
} from 'react'
import { computed } from 'mobx'
import { observer } from 'mobx-react'
import {
  Box,
  Grid,
  IconButton,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import DateRangeIcon from '@mui/icons-material/DateRange'

import DateRangePicker from 'admin/components/date-range-picker'
import { formatDate, aWeekAgo } from 'admin/utils/helper'

import { useStores } from 'admin/hooks'

import BookingCancelled from 'admin/components/dashboard/card-info/booking-cancelled'
import BookingFailed from 'admin/components/dashboard/card-info/booking-failed'
import BookingLaundry from 'admin/components/dashboard/card-info/booking-laundry'
import BookingCurtain from 'admin/components/dashboard/card-info/booking-curtain'
import DriverHighest from 'admin/components/dashboard/card-info/driver-highest'
import DriverLowest from 'admin/components/dashboard/card-info/driver-lowest'
import AveragePickUp from 'admin/components/dashboard/booking-table/average-pick-up'
import BookingChart from 'admin/components/dashboard/chart-info/booking'
import InvoicePaymentChart from 'admin/components/dashboard/chart-info/invoice'
import PaymentMethodChart from 'admin/components/dashboard/chart-info/payment-method'

import styles from './styles'

const useStyles = makeStyles(styles)

const Analytics = () => {
  const classes = useStyles()
  const { bookingStore } = useStores()

  const [anchorEl, setAnchorEl] = useState(null)
  const [rangeDate, setRangeDate] = useState({
    key: 'selection',
    startDate: aWeekAgo,
    endDate: new Date(),
  })

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleSelect = useCallback(values => {
    const { startDate, endDate } = values.selection
    setRangeDate(prev => ({
      ...prev,
      startDate,
      endDate,
    }))
  }, [])

  const date = computed(() => {
    const { startDate, endDate } = rangeDate
    return `${formatDate(startDate)} - ${formatDate(endDate)}`
  }).get()

  useEffect(() => {
    bookingStore.fetchCountHeightLow({
      startDate: rangeDate.startDate,
      endDate: rangeDate.endDate,
    })

    bookingStore.fetchPercentageInvoice({
      startCreatedAt: rangeDate.startDate,
      endCreatedAt: rangeDate.endDate,
    })
  }, [rangeDate])

  return (
    <Box>
      <Box className={classes.boxDateRange}>
        <Box className={classes.dateRangePicker}>
          <span className={classes.spanDate}>
            {date}
          </span>
          <IconButton onClick={handleClick} size="small">
            <DateRangeIcon />
          </IconButton>
        </Box>
        <DateRangePicker
          anchorEl={anchorEl}
          handleClose={handleClose}
          handleSelect={handleSelect}
          selectionRange={rangeDate}
        />
      </Box>
      <Grid
        container
        spacing={2}
      >
        <Grid
          item
          xs={6}
        >
          <BookingFailed rangeDate={rangeDate} />
        </Grid>
        <Grid
          item
          xs={6}
        >
          <BookingCancelled rangeDate={rangeDate} />
        </Grid>
        <Grid
          item
          xs={6}
        >
          <AveragePickUp rangeDate={rangeDate} />
        </Grid>
        <Grid
          item
          xs={6}
        >
          <div className={classes.driverHightest}>
            <DriverHighest />
          </div>
          <DriverLowest />
        </Grid>
        <Grid
          item
          xs={6}
        >
          <BookingLaundry rangeDate={rangeDate} />
        </Grid>
        <Grid
          item
          xs={6}
        >
          <BookingCurtain rangeDate={rangeDate} />
        </Grid>
      </Grid>
      <Grid
        container
        spacing={2}
        mt={1}
      >
        <Grid
          item
          xs={4}
        >
          <BookingChart rangeDate={rangeDate} />
        </Grid>
        <Grid
          item
          xs={4}
        >
          <InvoicePaymentChart />
        </Grid>
        <Grid
          item
          xs={4}
        >
          <PaymentMethodChart />
        </Grid>
      </Grid>
    </Box>
  )
}

export default observer(Analytics)
