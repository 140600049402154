import React, {
  useState,
  useCallback,
} from 'react'
import { observer } from 'mobx-react'
import moment from 'moment'
import CsvDownloader from 'react-csv-downloader'
import { computed } from 'mobx'
import makeStyles from '@mui/styles/makeStyles'
import {
  Button,
  Tab,
  Tabs,
  Typography,
} from '@mui/material'
import FilterListIcon from '@mui/icons-material/FilterList'

import {
  useStores,
  useApiRequest,
} from 'admin/hooks'
import * as srv from 'admin/services'
import {
  formatDate,
  isTodayDate,
} from 'admin/utils/helper'

import styles from '../styles'
import BookingDataGrid from './datagrid'
import BookingInfo from '../info'
import FilterListInsideDialog from './filter'

const useStyles = makeStyles(styles)
const VIEW_MODE = [
  'booking',
  'invoice',
]

const columns = [
  {
    id: 'customer',
    displayName: 'Customer Name',
  },
  {
    id: 'customerAddress',
    displayName: 'Customer Address',
  },
  {
    id: 'invoiceNumber',
    displayName: 'Invoice Number',
  },
]

const FILENAME = `DMS_Pick_List_${moment().format('DDMMYY')}`

const FilterOptionsOutsideDialog = observer(() => {
  const classes = useStyles()
  const { bookingStore } = useStores()

  const [activeTab, setActiveTab] = useState(0)
  const [viewMode, setViewMode] = useState(VIEW_MODE[0])

  const {
    request: exportBooking,
    isLoading: isExporting,
  } = useApiRequest(srv.fetchBooking, { blocking: true })

  const onChangeTab = useCallback((event, value) => {
    setActiveTab(value)
    setViewMode(VIEW_MODE[value])
  }, [])

  const filterLength = computed(() => {
    const object = {
      phoneNumber: bookingStore.query.phoneNumber,
      address: bookingStore.query.address,
      channelId: bookingStore.query.channelId,
      channelIds: !!(bookingStore.query.channelIds && bookingStore.query.channelIds.length > 0),
      default: bookingStore.defaultFilter,
      driverId: bookingStore.query.driverId,
      status: bookingStore.query.status,
      date: bookingStore.query.startDate,
      createdAt: bookingStore.defaultFilter || bookingStore.query.startCreatedAt,
      showDelete: bookingStore.query.showDelete,
      createdBy: bookingStore.query.createdBy,
      invoicePaymentMethod: bookingStore.query.invoicePaymentMethod,
      reviewStatus: bookingStore.query.reviewStatus,
    }
    const objArr = Object.values(object)
    const filterArr = objArr.filter(item => item)
    return filterArr.length
  }).get()

  const selectedDateFilter = computed(() => {
    if (bookingStore.defaultFilter) {
      const yesterdayCreatedAt = new Date()
      yesterdayCreatedAt.setDate(yesterdayCreatedAt.getDate() - 1)
      return `${formatDate(yesterdayCreatedAt)} - ${formatDate(new Date())}`
    }
    const { startCreatedAt, endCreatedAt } = bookingStore.query
    if (!startCreatedAt) return '-'
    if (isTodayDate(startCreatedAt) && isTodayDate(endCreatedAt)) {
      return `Today, ${formatDate(startCreatedAt)}`
    }
    if (startCreatedAt === endCreatedAt) {
      return formatDate(startCreatedAt)
    }
    return `${formatDate(startCreatedAt)} - ${formatDate(endCreatedAt)}`
  }).get()

  const renderIcon = () => {
    return (
      <>
        {filterLength > 0 ? (
          <div className={classes.filterNumber}>
            {filterLength}
          </div>
        ) : (
          <FilterListIcon />
        )}
        <span>
          Filter
        </span>
      </>
    )
  }

  const getDataBooking = async () => {
    const params = {
      isExportCsv: true,
      default: bookingStore.defaultFilter || '',
      sortBy: 'createdAt',
      sortDirection: 'desc',
    }
    // params query based on what is selected from the booking filter
    if (bookingStore.query.startDate) params.startDate = bookingStore.query.startDate
    if (bookingStore.query.endDate) params.endDate = bookingStore.query.endDate
    if (bookingStore.query.startCreatedAt) params.startCreatedAt = bookingStore.query.startCreatedAt
    if (bookingStore.query.endCreatedAt) params.endCreatedAt = bookingStore.query.endCreatedAt
    if (bookingStore.query.showDelete) params.showDelete = bookingStore.query.showDelete
    if (bookingStore.query.code) params.code = bookingStore.query.code
    if (bookingStore.query.phoneNumber) params.phoneNumber = bookingStore.query.phoneNumber
    if (bookingStore.query.driverId) params.driverId = bookingStore.query.driverId
    if (bookingStore.query.address) params.address = bookingStore.query.address
    if (bookingStore.query.status) params.status = bookingStore.query.status
    if (bookingStore.query.invoicePaymentMethod) params.invoicePaymentMethod = bookingStore.query.invoicePaymentMethod
    if (bookingStore.query.reviewStatus) params.reviewStatus = bookingStore.query.reviewStatus
    if (bookingStore.query.createdBy) params.createdBy = bookingStore.query.createdBy
    if (bookingStore.query.channelIds) params.channelIds = bookingStore.query.channelIds

    const response = await exportBooking(params)
    return response.data.list.map((booking) => {
      const prestoIdsArr = booking.invoices.map(item => item.prestoId)
      const customerAddress = booking.address || booking.customer?.address || '-'
      const customerName = booking.customer?.name || booking.customer?.originalData.name
      const data = {
        customer: customerName.replaceAll(',', ''),
        customerAddress: customerAddress.replaceAll(',', ''),
        invoiceNumber: prestoIdsArr.join(' ') || '-',
      }
      return data
    })
  }

  return (
    <div className={classes.filterWrapper}>
      <div className={classes.searchField}>
        <div>
          <Tabs
            classes={{
              root: classes.tabsRoot,
              indicator: classes.tabsIndicator,
            }}
            value={activeTab}
            onChange={onChangeTab}
          >
            {VIEW_MODE.map((item) => (
              <Tab
                key={item}
                label={item}
                classes={{
                  root: classes.tabListing,
                  selected: classes.tabSelected,
                }}
              />
            ))}
          </Tabs>
        </div>
        <Button
          onClick={() => {
            bookingStore.setOpenFilterDialog(true)
          }}
          variant="outlined"
          color="secondary"
        >
          {renderIcon()}
        </Button>
      </div>
      <div className={classes.actionExport}>
        <CsvDownloader
          filename={FILENAME}
          extension=".csv"
          separator=","
          wrapColumnChar=""
          columns={columns}
          datas={getDataBooking}
          text="EXPORT"
        >
          <Button
            variant="contained"
            color="secondary"
            type="submit"
            className={classes.buttonExport}
            disabled={isExporting}
          >
            {isExporting ? 'Exporting..' : 'Export'}
          </Button>
        </CsvDownloader>
      </div>
      <div className={classes.selectedDateFilter}>
        <Typography variant="h6">
          Date created
        </Typography>
        <Typography>
          {selectedDateFilter}
        </Typography>
      </div>
      <BookingDataGrid viewMode={viewMode} />
    </div>
  )
})

export default function Listing() {
  return (
    <>
      <FilterOptionsOutsideDialog />
      <FilterListInsideDialog />
      <BookingInfo />
    </>
  )
}
