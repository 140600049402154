import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import makeStyles from '@mui/styles/makeStyles'

import { Typography, Divider } from '@mui/material'

import { BOOKING_LOG_DETAILS } from 'admin/config'
import { useStores } from 'admin/hooks'
import InfoSection from 'admin/components/info-section'
import HeadBar from 'admin/components/head-bar'
import {
  formatDate,
  formatTimeOnly,
  getTags,
} from 'admin/utils/helper'

import styles from './styles'

const useStyles = makeStyles(styles)

const DisplayData = ({ logs }) => {
  const classes = useStyles()
  return logs.map((data) => (
    <div className={classes.infoItem} key={data.label}>
      <Typography variant="h6">
        {data.label}
      </Typography>
      <Typography>
        {String(data.value)}
      </Typography>
    </div>
  ))
}

function LogInfo() {
  const classes = useStyles()
  const { infoSectionStore } = useStores()
  const [data, setData] = useState([])

  const { selected } = infoSectionStore

  const subject = (select) => {
    if (select.subjectId !== '0') {
      if (select.subject === 'admin') {
        return `${select.subject} ${select.subjectDetail?.name}`
      }
      if (select.subject === 'driver') {
        return `${select.subject} ${select.subjectDetail?.name}`
      }
      if (select.subject === 'customer') {
        return `${select.subject} ${select.subjectDetail?.name}`
      }
      return `${select.subject} System`
    }
    return null
  }

  useEffect(() => {
    const arrObj = []
    if (selected) {
      BOOKING_LOG_DETAILS.map((item) => {
        if (selected.value[item.code] || typeof selected.value[item.code] === 'boolean') {
          switch (item.code) {
          case 'createdAt':
          case 'deletedAt':
          case 'date':
          case 'completedTime': {
            const valueDate = item.code === 'date' ? (
              `${formatDate(selected.value[item.code])}`
            ) : (
              `${formatDate(selected.value[item.code])} ${formatTimeOnly(selected.value[item.code])}`
            )
            arrObj.push({
              label: item.label,
              value: valueDate,
            })
            break
          }
          case 'tags':
          case 'bookingTags':
          case 'invoiceIds':
            if (selected.value[item.code].length > 0) {
              let itemValue
              if (item.code === 'tags' || (item.code === 'bookingTags' && item.label === 'Tags')) {
                const {
                  pickup,
                  delivery,
                } = getTags(selected.value[item.code])
                const value = []
                if (pickup.length) value.push(`Pickup: ${pickup.map((tag) => tag).join(', ')}`)
                if (delivery.length) value.push(`Delivery: ${delivery.map((tag) => tag).join(', ')}`)
                itemValue = value.join('\n')
              } else if (item.label === 'Pickup Booking Tags') {
                itemValue = selected.value[item.code]
                  .map((tag) => {
                    if (tag.additionalInfo?.numberOfRoom) return `${tag.name} - ${tag.additionalInfo.numberOfRoom} room`
                    return tag.name
                  })
                  .join(', ')
              } else {
                itemValue = selected.value[item.code].join(', ')
              }
              arrObj.push({
                label: item.label,
                value: itemValue,
              })
            }
            break
          case 'invoices':
            if (selected.value[item.code].length > 0) {
              arrObj.push({
                label: item.label,
                value: selected.value[item.code].map(value => !!value.label && value.label).join(', '),
              })
            }
            break
          case 'customer':
            if (selected.value[item.code].name) {
              arrObj.push({
                label: item.label,
                value: `${selected.value[item.code].name} ${selected.value[item.code].phoneNumber}`,
              })
            }
            break
          case 'driver':
          case 'channel':
            if (selected.value[item.code].name) {
              arrObj.push({
                label: item.label,
                value: selected.value[item.code].name,
              })
            }
            break
          case 'slot':
            if (selected.value[item.code].start) {
              arrObj.push({
                label: item.label,
                value: `${selected.value[item.code].start} - ${selected.value[item.code].end}`,
              })
            }
            break
          default:
            arrObj.push({
              label: item.label,
              value: selected.value[item.code],
            })
            break
          }
        }
        return null
      })
    }
    setData(arrObj)
  }, [selected])

  return (
    <InfoSection title="Log">
      {selected && (
        <>
          <div className={classes.headbar}>
            <HeadBar
              data={[
                {
                  label: 'Date',
                  value: `${formatDate(selected.createdAt)} ${formatTimeOnly(selected.createdAt)}`,
                },
                {
                  label: 'Action',
                  value: selected.action,
                  capitalize: true,
                },
                {
                  label: 'Subject',
                  value: subject(selected),
                  capitalize: true,
                },
              ]}
            />
          </div>
          <Divider />
          <Typography
            variant="h4"
            className={classes.title}
          >
            Details
          </Typography>
          {data.length !== 0 ? (
            <DisplayData logs={data} />
          ) : (
            <Typography className={classes.noDataText}>
              No Data
            </Typography>
          )}
        </>
      )}
    </InfoSection>
  )
}

export default observer(LogInfo)
