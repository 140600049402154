import React, { memo } from 'react'
import PropTypes from 'prop-types'

import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
} from 'chart.js'
import { Doughnut } from 'react-chartjs-2'

import { APP_PAYMENT_METHOD } from 'admin/config'

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
)

const doughnutGenerateLabelsLegend = ChartJS.overrides.doughnut.plugins.legend.labels.generateLabels
let others = []

const customLegends = {
  position: 'bottom',
  align: 'start',
  labels: {
    boxWidth: 23,
    boxHeight: 12,
    font: {
      family: 'Open Sans',
      lineHeight: 11,
      weight: 500,
    },
    generateLabels: (chart) => {
      const allLabels = doughnutGenerateLabelsLegend(chart)
      const fiveLabels = allLabels.filter(obj => obj.index <= 4)

      others = allLabels.filter(obj => obj.index > 4)
      if (others.length > 0) {
        const sumTotal = others.reduce((previousValue, currentValue) => {
          return previousValue + Number(currentValue.text[0])
        }, 0)

        fiveLabels.push({
          text: [sumTotal, 'Others'],
          fillStyle: 'rgba(136,136,136,1)',
          strokeStyle: 'rgba(136,136,136,2)',
        })
      }

      return fiveLabels.map((item) => ({
        text: `${item.text[0]}% ${item.text[1]}`,
        fillStyle: item.fillStyle,
        strokeStyle: item.strokeStyle,
        fontColor: 'rgba(0, 0, 0, 0.7)',
        borderRadius: 4,
      }))
    },
  },
}

const customTooltips = {
  callbacks: {
    title: () => {
      return ''
    },
    label: (context) => {
      return `${context.label[0]}%`
    },
    afterLabel: (context) => {
      return context.label[1] === APP_PAYMENT_METHOD.value ? APP_PAYMENT_METHOD.display : context.label[1]
    },
  },
}

// https://react-chartjs-2.js.org/examples/doughnut-chart
const Chart = (props) => {
  const {
    data,
    customLegend,
    customTooltip,
  } = props

  return (
    <Doughnut
      data={data}
      options={{
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: customLegend ? customLegends : {},
          tooltip: customTooltip ? customTooltips : {},
        },
      }}
    />
  )
}

Chart.propTypes = {
  data: PropTypes.object,
}

Chart.defaultProps = {
  data: {},
}

export default memo(Chart)
