import React, { useCallback } from 'react'
import { observer } from 'mobx-react'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'
import { Icon, IconButton } from '@mui/material'

import { useStores } from 'admin/hooks'

import styles from './styles'

const useStyles = makeStyles(styles)

const InfoSection = (props) => {
  const { onClose, children, title } = props
  const { infoSectionStore } = useStores()
  const classes = useStyles()

  const close = useCallback(() => {
    infoSectionStore.reset()
    if (onClose) onClose()
  }, [])

  return (
    <div
      className={clsx(classes.infoSection, {
        [classes.open]: !!infoSectionStore.selected,
      })}
    >
      <div
        className={classes.closeSection}
      >
        <span className={classes.title}>
          {title}
        </span>
        <IconButton className={classes.closeButton} onClick={close} size="large">
          <Icon>close</Icon>
        </IconButton>
      </div>
      <div className={classes.container}>
        {children}
      </div>
    </div>
  )
}

export default observer(InfoSection)
