import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
} from 'react'
import { observer } from 'mobx-react'
import { useStores, useApiRequest } from 'admin/hooks'
import * as srv from 'admin/services'

import {
  validpostalCode,
} from 'admin/utils/helper'

import { BOOKING_STATUS } from 'admin/config'

import Dialog from 'admin/components/dialog'
import Schedule from 'admin/pages/booking/schedule'

const RebookDialog = () => {
  const {
    bookingStore,
    infoSectionStore,
    notificationStore,
  } = useStores()
  const containerRef = useRef()
  const [containerWidth, setContainerWidth] = useState(null)

  const handleClose = () => {
    bookingStore.setRebookDialog(false)
  }

  const {
    request: updateBooking,
  } = useApiRequest(srv.updateBooking, { blocking: true })

  const handleSelect = useCallback(async () => {
    try {
      const {
        selected,
      } = bookingStore

      if (!selected.driverId) {
        throw new Error('Driver is required')
      }
      if (!selected.timeslotId) {
        throw new Error('Timeslot is required')
      }
      if (!selected.address) {
        throw new Error('Please insert an address before continue')
      }
      const postalCode = validpostalCode(selected.address) || selected.customer?.postalCode
      if (!postalCode) throw new Error('Please insert a postal code before continue')

      const updateData = {
        ...selected,
        status: BOOKING_STATUS.SCHEDULED,
        reason: '',
      }

      const request = updateBooking
      const response = await request(updateData)
      if (!response) return
      // omit code because updateBooking always returns a new booking code
      const { code, ...restResponseData } = response.data
      let rest = null
      rest = restResponseData

      infoSectionStore.replace({
        ...bookingStore.selected,
        ...rest,
      })

      notificationStore.setSuccess('Rebooking schedule success')
      bookingStore.setRebookDialog(false)
    } catch (err) {
      notificationStore.setError(err)
    }
  }, [])

  useEffect(() => {
    if (containerRef.current) {
      setContainerWidth(containerRef.current.getBoundingClientRect().width)
    }
  }, [])

  return (
    <Dialog
      size="lg"
      fullWidth
      primaryActionLabel="Select"
      open={bookingStore.rebookDialog}
      onClose={handleClose}
      onAccept={handleSelect}
      content={(
        <div
          ref={containerRef}
          style={{
            height: '750px',
          }}
        >
          <Schedule containerWidth={containerWidth} />
        </div>
      )}
    />
  )
}

export default observer(RebookDialog)
