import React, {
  useState,
  useCallback,
  useEffect,
} from 'react'
import makeStyles from '@mui/styles/makeStyles'
import {
  Button,
  Dialog,
  Icon,
  IconButton,
  DialogActions,
  DialogContent,
} from '@mui/material'

import TextField from 'admin/components/textfield'
import CustomizedDialogTitle from 'admin/components/dialog-title'
import SVGMap from './svg'

import styles from './styles'

const useStyles = makeStyles(styles)

const MapDialog = ({
  open,
  onClose,
  serviceArea,
  setServiceArea,
  onSave,
  showAction = true,
  title,
  disabled,
  label,
  setLabel,
}) => {
  const classes = useStyles({ label })

  const [x, setX] = useState(0)
  const [y, setY] = useState(0)
  const [width, setWidth] = useState(800)
  const [height, setHeight] = useState(500)

  const [viewBox, setViewBox] = useState(`${x} ${y} ${width} ${height}`)

  const updateViewBox = () => {
    const vb = `${x} ${y} ${width} ${height}`
    setViewBox(vb)
  }

  const zoomIn = () => {
    setWidth((prev) => prev - 20)
    setX((prev) => prev + 10)
    setY((prev) => prev + 10)
    updateViewBox()
  }

  const zoomOut = () => {
    setWidth((prev) => prev + 20)
    setX((prev) => prev - 10)
    setY((prev) => prev - 10)
    updateViewBox()
  }

  const reset = useCallback(() => {
    setX(0)
    setY(0)
    setWidth(800)
    setHeight(500)
    updateViewBox()
  }, [])

  useEffect(() => {
    updateViewBox()
  }, [x, y, width, height])

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={open}
      onClose={onClose}
    >
      <CustomizedDialogTitle
        disableTypography
        className={classes.root}
        onClose={onClose}
      >
        {title || 'Select Service Area'}
      </CustomizedDialogTitle>
      <TextField
        name="label"
        type="text"
        variant="outlined"
        size="small"
        placeholder="Enter Label"
        value={label}
        onChange={setLabel}
        InputProps={{
          classes: {
            notchedOutline: classes.notchedOutline,
          },
        }}
        className={classes.field}
      />
      <DialogContent>
        <SVGMap
          viewBox={viewBox}
          area={serviceArea}
          setArea={setServiceArea}
          disabled={disabled}
        />
      </DialogContent>
      <DialogActions className={classes.dialogAction}>
        <div>
          <IconButton size="small" aria-label="zoom in" onClick={zoomIn}>
            <Icon>add_circle</Icon>
          </IconButton>
          <IconButton size="small" aria-label="zoom out" onClick={zoomOut}>
            <Icon>remove_circle</Icon>
          </IconButton>
          <IconButton size="small" aria-label="reset" onClick={reset}>
            <Icon>change_circle</Icon>
          </IconButton>
        </div>
        {showAction && (
          <div>
            <Button
              variant="outlined"
              color="secondary"
              onClick={onClose}
              size="small"
              className={classes.button}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="secondary"
              size="small"
              className={classes.button}
              onClick={onSave}
            >
              Save
            </Button>
          </div>
        )}
      </DialogActions>
    </Dialog>
  )
}

export default MapDialog
