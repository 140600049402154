import React, {
  useCallback,
  useState,
  useEffect,
  useMemo,
} from 'react'
import { observer } from 'mobx-react'
import { computed } from 'mobx'
import moment from 'moment'

import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Switch,
  IconButton,
} from '@mui/material'
import { DateRange } from '@mui/icons-material/'
import makeStyles from '@mui/styles/makeStyles'

import { STATUS_TYPE } from 'admin/config/status-type'
import { PAYMENT_TYPE } from 'admin/config/payment-type'
import DateRangePicker from 'admin/components/date-range-picker'
import { useStores } from 'admin/hooks'

import CustomizedAutocomplete from 'admin/components/autocomplete'
import CustomizedDialogTitle from 'admin/components/dialog-title'
import TextField from 'admin/components/textfield'
import { formatDate, replaceToNumbers } from 'admin/utils/helper'

import styles from './styles'

const useStyles = makeStyles(styles)

const DateFilter = ({
  checked,
  onChange,
  label,
  handleSelect,
  selectionRange,
}) => {
  const classes = useStyles()

  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleCloseCalendar = () => {
    setAnchorEl(null)
  }

  const date = computed(() => {
    const { startDate, endDate } = selectionRange
    if (!startDate) return '-'
    if (formatDate(startDate) !== formatDate(endDate)) {
      return `${formatDate(startDate)} - ${formatDate(endDate)}`
    }
    return formatDate(startDate)
  }).get()

  return (
    <>
      <Typography variant="h4">
        {label}
      </Typography>
      <div className={classes.dateOption}>
        <Switch
          checked={checked}
          onChange={onChange}
          name="date"
        />
        <div className={classes.datePicker}>
          <span>
            {date}
          </span>
          <IconButton
            onClick={handleClick}
            size="large"
          >
            <DateRange />
          </IconButton>
        </div>
        <DateRangePicker
          anchorEl={anchorEl}
          handleClose={handleCloseCalendar}
          handleSelect={handleSelect}
          selectionRange={selectionRange}
          onDialog
        />
      </div>
    </>
  )
}

const FilterInvoice = ({ activeTab }) => {
  const classes = useStyles()
  const {
    posStore,
  } = useStores()

  const [isChecked, setIsChecked] = useState(false)
  const [id, setId] = useState('')
  const [name, setName] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [orderId, setOrderId] = useState('')
  const [customerId, setCustomerId] = useState('')
  const [paymentTypes, setPaymentTypes] = useState(null)
  const [paymentOptions, setPaymentOptions] = useState([])
  const [statusType, setStatusType] = useState(null)
  const [statusOptions, setStatusOptions] = useState([])
  const [accessoryId, setAccessoryId] = useState('')
  const [itemId, setItemId] = useState('')

  const [rangeDate, setRangeDate] = useState({
    key: 'selection',
    startDate: null,
    endDate: new Date(''),
  })

  const handleClose = useCallback(() => {
    posStore.setCloseDialog(false)
  }, [])

  const handleSubmit = useCallback(() => {
    const startCreatedAt = moment(rangeDate.startDate).startOf('day').format()
    const endCreatedAt = moment(rangeDate.endDate).endOf('day').format()

    posStore.updateQuery('id', id.trim(), true)
    posStore.updateQuery('name', name.trim(), true)
    posStore.updateQuery('phoneNumber', phoneNumber.trim(), true)
    posStore.updateQuery('orderId', orderId.trim(), true)
    posStore.updateQuery('paymentTypeId', paymentTypes?.key, true)
    posStore.updateQuery('customerId', customerId.trim(), true)
    posStore.updateQuery('statusTypeId', statusType?.key, true)
    posStore.updateQuery('accessoryId', accessoryId.trim(), true)
    posStore.updateQuery('itemId', itemId.trim(), true)
    posStore.updateQuery('startDate', isChecked
      ? startCreatedAt : null, true)
    posStore.updateQuery('endDate', isChecked
      ? endCreatedAt : null, true)
    posStore.setCloseDialog(false)
  }, [
    rangeDate,
    id,
    name,
    phoneNumber,
    orderId,
    customerId,
    paymentTypes,
    statusType,
    itemId,
    accessoryId,
  ])

  const handleReset = useCallback(() => {
    setId('')
    setName('')
    setPhoneNumber('')
    setOrderId('')
    setCustomerId('')
    setAccessoryId('')
    setItemId('')
    setPaymentTypes(null)
    setStatusType(null)
    setIsChecked(false)
    setRangeDate(prev => ({
      ...prev,
      startDate: null,
      endDate: new Date(''),
    }))
  }, [])

  const handleChangeSwitch = (event) => {
    setIsChecked(event.target.checked)
    setRangeDate(prev => ({
      ...prev,
      startDate: null,
      endDate: new Date(''),
    }))
  }

  const handleSelectRangeDate = useCallback(values => {
    const { startDate, endDate } = values.selection
    setRangeDate(prev => ({
      ...prev,
      startDate,
      endDate,
    }))
    setIsChecked(true)
  }, [])

  const handleChangeSearchPayment = useCallback((_, newValue) => {
    setPaymentTypes(newValue)
  }, [])

  const handleChangeSearchStatus = useCallback((_, newValue) => {
    setStatusType(newValue)
  }, [])

  useEffect(() => {
    setPaymentOptions(PAYMENT_TYPE.map(item => ({
      key: item.id,
      label: item.name,
    })))
    setStatusOptions(STATUS_TYPE.map(item => ({
      key: item.id,
      label: item.name,
    })))
  }, [])

  useEffect(() => {
    handleReset()
  }, [activeTab])

  const titleDialog = useMemo(() => {
    let title
    if (activeTab === 0) title = 'Customer Filter'
    if (activeTab === 1) title = 'Driver Filter'
    if (activeTab === 2) title = 'Invoice Filter'
    if (activeTab === 3) title = 'Invoice Item Filter'
    if (activeTab === 4) title = 'Item Accessory Filter'
    return title
  }, [activeTab])

  if (!posStore.dialog) return null

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      open={posStore.dialog}
      onClose={handleClose}
      aria-labelledby="Filter"
    >
      <CustomizedDialogTitle onReset={handleReset}>
        {titleDialog}
      </CustomizedDialogTitle>
      <DialogContent>
        {activeTab !== 1 && (
          <DateFilter
            checked={isChecked}
            onChange={handleChangeSwitch}
            label={activeTab === 2 ? 'Dropoff Time' : 'Created At'}
            handleSelect={handleSelectRangeDate}
            selectionRange={rangeDate}
          />
        )}
        <TextField
          name="id"
          type="text"
          variant="outlined"
          size="small"
          fullWidth
          label="ID"
          className={classes.textField}
          value={id}
          onChange={(e) => {
            setId(e.target.value)
          }}
        />
        {activeTab === 4 && (
          <TextField
            name="accessoryId"
            type="text"
            variant="outlined"
            size="small"
            fullWidth
            label="Accessory Id"
            className={classes.textField}
            value={accessoryId}
            onChange={(e) => {
              setAccessoryId(e.target.value)
            }}
          />
        )}
        {(activeTab === 0 || activeTab === 1) && (
          <>
            <TextField
              name="name"
              type="text"
              variant="outlined"
              size="small"
              fullWidth
              label="Name"
              className={classes.textField}
              value={name}
              onChange={(e) => {
                setName(e.target.value)
              }}
            />
            <TextField
              name="phoneNumber"
              type="text"
              variant="outlined"
              size="small"
              fullWidth
              label="Phone Number"
              className={classes.textField}
              value={phoneNumber}
              onChange={(e) => {
                setPhoneNumber(replaceToNumbers(e.target.value))
              }}
            />
          </>
        )}
        {(activeTab === 3 || activeTab === 4) && (
          <TextField
            name="orderId"
            type="text"
            variant="outlined"
            size="small"
            fullWidth
            label="Invoice Id"
            className={classes.textField}
            value={orderId}
            onChange={(e) => {
              setOrderId(e.target.value)
            }}
          />
        )}
        {activeTab === 4 && (
          <TextField
            name="itemId"
            type="text"
            variant="outlined"
            size="small"
            fullWidth
            label="Invoice Item Id"
            className={classes.textField}
            value={itemId}
            onChange={(e) => {
              setItemId(e.target.value)
            }}
          />
        )}
        {activeTab === 2 && (
          <>
            <TextField
              name="customerId"
              type="text"
              variant="outlined"
              size="small"
              fullWidth
              label="Customer Id"
              className={classes.textField}
              value={customerId}
              onChange={(e) => {
                setCustomerId(e.target.value)
              }}
            />
            <CustomizedAutocomplete
              label="Payment Types"
              size="small"
              value={paymentTypes}
              handleChange={handleChangeSearchPayment}
              options={paymentOptions}
              className={classes.autocomplete}
            />
          </>
        )}
        {(activeTab === 2 || activeTab === 3) && (
          <CustomizedAutocomplete
            label="Status"
            size="small"
            value={statusType}
            handleChange={handleChangeSearchStatus}
            options={statusOptions}
            className={classes.autocomplete}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={handleClose}
          color="secondary"
        >
          Cancel
        </Button>
        <Button
          type="submit"
          variant="contained"
          color="secondary"
          onClick={handleSubmit}
        >
          Apply
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default observer(FilterInvoice)
