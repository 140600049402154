export default (theme) => ({
  detailButton: {
    margin: '0.5rem',
  },
  titleSection: {
    padding: '1rem',
  },
  label: {
    marginBottom: '0.3rem',
  },
  slot: {
    backgroundColor: theme.color.paarl,
    padding: '2px 9px',
    borderRadius: '4px',
    color: theme.color.white,
  },
  infoItem: {
    paddingBottom: '1.25rem',
  },
  tagColor: {
    color: theme.palette.text.primary,
  },
  failedReason: {
    backgroundColor: theme.color.linen,
    color: theme.color.redCherry,
    marginLeft: '1rem',
  },
  infoIcon: {
    width: '1.25rem',
    height: '1.25rem',
  },
  reasonLabel: {
    paddingLeft: '5px',
  },
  whatsappButton: {
    margin: '0.5rem',
    color: '#fff',
    '&:hover': {
      background: '#1dad53',
    },
  },
  iconButton: {
    width: 20,
    marginLeft: '0.5rem',
    height: 20,
    background: theme.color.secondary,
    borderRadius: 5,
    '&:hover': {
      '& $iconEdit': {
        color: theme.color.secondary,
        fontSize: '1rem',
      },
    },
  },
  iconEdit: {
    fontSize: '1rem',
    color: '#fff',
  },
  completeTime: {
    marginLeft: '1rem',
  },
})
