export default function styles(theme) {
  return {
    datePickerStart: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
    },
    textfield: {
      marginBottom: '0.5rem',
    },
    filterWrapper: {
      display: 'flex',
      flexWrap: 'wrap',
      width: '100%',
    },
    searchField: {
      display: 'flex',
      flexDirection: 'row',
      margin: '0.5rem 0.2rem 0.5rem',
    },
    filterNumber: {
      color: theme.color.white,
      backgroundColor: theme.color.paarl,
      borderRadius: '50px',
      padding: '0 9px',
      marginRight: '5px',
    },
    tab: {
      textTransform: 'none',
      color: theme.palette.secondary.main,
      fontWeight: 600,
    },
    tabsIndicator: {
      display: 'none',
    },
    tabsRoot: {
      borderRadius: '5px',
      marginRight: '1rem',
    },
    tabListing: {
      textTransform: 'capitalize',
      border: `0.5px solid ${theme.color.secondary}`,
    },
    tabSelected: {
      border: 'transparent',
      background: theme.color.paarl,
      color: `${theme.color.white} !important`,
      fontWeight: 600,
    },
    autocomplete: {
      marginBottom: '0.25rem',
    },
    info: {
      display: 'flex',
      flexDirection: 'column',
      marginLeft: '0.7rem',
      justifyContent: 'center',
    },
    dateOption: {
      display: 'flex',
      marginBottom: '0.5rem',
      alignItems: 'center',
    },
    datePicker: {
      display: 'flex',
      flex: 1,
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  }
}
