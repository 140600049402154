import React from 'react'
import {
  Grid,
} from '@mui/material'

import Page from 'admin/components/page'
import ProfileInfo from './info'

const Profile = () => {
  return (
    <Page>
      <Grid
        container
        direction="row"
      >
        <Grid
          item
          xs={7}
        >
          <ProfileInfo />
        </Grid>
      </Grid>
    </Page>
  )
}

export default Profile
