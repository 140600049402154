import React, { useState, useCallback } from 'react'
import { observer } from 'mobx-react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  IconButton,
  InputLabel,
  InputAdornment,
  OutlinedInput,
} from '@mui/material'

import {
  Visibility,
  VisibilityOff,
} from '@mui/icons-material'

import { useStores, useApiRequest } from 'admin/hooks'
import * as srv from 'admin/services'

import CustomizedDialogTitle from 'admin/components/dialog-title'

const UpdatePassword = observer(() => {
  const {
    notificationStore,
    profileStore,
  } = useStores()
  const [password, setPassword] = useState('')
  const [showPassword, setShowPassword] = useState(false)

  const {
    request: updateProfilePassword,
    isLoading: updateProcessing,
  } = useApiRequest(srv.updateProfilePassword, { blocking: true })

  const handleChangePassword = useCallback((event) => {
    setPassword(event.target.value)
  }, [])

  const handleShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const handleMouseDownPassword = (event) => {
    event.preventDefault()
  }

  const handleClose = useCallback(() => {
    if (updateProcessing) return
    profileStore.setCloseDialog('password')
    setPassword('')
  }, [])

  const handleSubmit = async (event) => {
    event.preventDefault()
    await updateProfilePassword(password)
    handleClose()
    notificationStore.setSuccess('Password has been updated successfully')
  }

  return (
    <Dialog
      open={profileStore.dialog.password}
      onClose={handleClose}
      aria-labelledby="Profile Form"
      maxWidth="md"
    >
      <CustomizedDialogTitle onClose={handleClose}>
        Change Password
      </CustomizedDialogTitle>
      <form
        noValidate
        onSubmit={handleSubmit}
        autoComplete="off"
      >
        <DialogContent>
          <FormControl
            variant="outlined"
            fullWidth
            margin="normal"
          >
            <InputLabel htmlFor="outlined-adornment-password">
              Password
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              type={showPassword ? 'text' : 'password'}
              value={password}
              onChange={handleChangePassword}
              endAdornment={(
                <InputAdornment position="end">
                  <IconButton
                    onClick={handleShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                    size="large"
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              )}
              label="Password"
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="secondary"
            type="submit"
            disabled={updateProcessing}
          >
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
})

export default UpdatePassword
