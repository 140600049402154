import React, { useState, useCallback } from 'react'
import { observer } from 'mobx-react'
import makeStyles from '@mui/styles/makeStyles'

import { useStores } from 'admin/hooks'

import InfoSection from 'admin/components/info-section'
import CustomizedTabs from 'admin/components/tabs'
import HeadBar from 'admin/components/head-bar'
import Information from './information'
import OrderHistory from './order-history'
import Wallet from './wallet'

import styles from './styles'

const useStyles = makeStyles(styles)

const CustomerInfo = () => {
  const classes = useStyles()
  const [activeTab, setActiveTab] = useState(0)
  const { infoSectionStore } = useStores()

  const { selected: customer } = infoSectionStore

  const handleChangeTab = useCallback((event, value) => {
    setActiveTab(value)
  }, [])

  return (
    <InfoSection
      title="Customer"
    >
      <div className={classes.parentContainer}>
        {customer && (
          <>
            <div className={classes.headbar}>
              <HeadBar
                data={[
                  {
                    label: 'Name',
                    value: customer.name,
                  },
                  {
                    label: 'Phone Number',
                    value: customer.phoneNumber,
                  },
                ]}
              />
            </div>
            <CustomizedTabs
              activeTab={activeTab}
              handleChangeTab={handleChangeTab}
              labels={[
                'Information',
                'Order History',
                'Wallet',
              ]}
            />
            <div className={classes.infoContainer}>
              {activeTab === 0 && <Information key={customer.id} />}
              {activeTab === 1 && <OrderHistory key={customer.id} />}
              {activeTab === 2 && <Wallet key={customer.id} />}
            </div>
          </>
        )}
      </div>
    </InfoSection>
  )
}

export default observer(CustomerInfo)
