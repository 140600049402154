import React, { useEffect } from 'react'
import { observer } from 'mobx-react'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'

import {
  Typography,
  Divider,
  Stack,
} from '@mui/material'
import { useStores } from 'admin/hooks'

import styles from './styles'

const useStyles = makeStyles(styles)

const Wallet = () => {
  const classes = useStyles()
  const { walletStore, infoSectionStore, notificationStore } = useStores()

  useEffect(() => {
    const fetch = async () => {
      try {
        await walletStore.fetchWalletByCustomerId(infoSectionStore.selected.id)
      } catch (err) {
        notificationStore.setError(err.message)
      }
    }
    fetch()
  }, [walletStore.queryParams])

  return (
    <>
      <div className={classes.wrapperBalance}>
        <Typography variant="h6">
          Wallet Balance
        </Typography>
        <Typography variant="h2">
          ${Number(infoSectionStore.selected?.wallet?.balance).toFixed(2) || '0.00'}
        </Typography>
      </div>
      <Divider />
      <div className={classes.balanceLog}>
        <Typography
          variant="body2"
          className={classes.titleLogLabel}
        >
          Wallet Transaction Log
        </Typography>
        {walletStore.list.map((item) => (
          <div
            key={item.id}
            className={classes.infoLog}
          >
            <div className={classes.infoTransaction}>
              <Stack
                direction="row"
                spacing={2}
              >
                <Typography
                  variant="body2"
                  className={clsx(classes.greenTypelabel, {
                    [classes.orangeTypelabel]: item.action !== 'topup',
                  })}
                >
                  {item.action}
                </Typography>
                {item.action !== 'topup' && (
                  <Typography
                    variant="body2"
                  >
                    {item.notes}
                  </Typography>
                )}
              </Stack>
              <Typography
                variant="body2"
                className={clsx(classes.greenTypelabel, {
                  [classes.orangeTypelabel]: item.action !== 'topup',
                })}
              >
                {item.action !== 'topup' ? `-${Number(item.amount).toFixed(2)}` : `+${Number(item.amount).toFixed(2)}`}
              </Typography>
            </div>
            <Typography
              className={classes.timeLabel}
            >
              {item.transactionDate}
            </Typography>
            <Divider className={classes.infoLog} />
          </div>
        ))}
      </div>
    </>
  )
}

export default observer(Wallet)
