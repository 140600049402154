export default () => ({
  option: {
    fontSize: '0.938rem',
    '& > span': {
      marginRight: '0.625rem',
      fontSize: '1.125rem',
    },
  },
}
)
