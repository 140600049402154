import React from 'react'
import {
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
  MenuItem,
  MenuList,
  ListItemText,
} from '@mui/material'
import { useStores, useApiRequest } from 'admin/hooks'
import * as srv from 'admin/services'

const MenuListComposition = ({
  anchorRef,
  open,
  onClose,
  id,
}) => {
  const handleClose = () => {
    onClose()
  }

  const {
    invoiceItemStore,
    notificationStore,
  } = useStores()

  const {
    request: updateDefectStatus,
  } = useApiRequest(srv.updateDefectStatus, { blocking: true })

  const handleUpdateStatus = async (data) => {
    const isUpdate = !!data

    updateDefectStatus({
      id,
      status: data,
    })

    await invoiceItemStore.reload()
    if (isUpdate) {
      notificationStore.setSuccess('Status has been updated successfully')
    }
    handleClose()
  }

  return (
    <Popper
      open={Boolean(open)}
      anchorEl={anchorRef?.current}
      role={undefined}
      placement="bottom-start"
      transition
      disablePortal
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin:
                  placement === 'bottom-start' ? 'left top' : 'left bottom',
          }}
        >
          <Paper sx={{ maxWidth: 320 }}>
            <ClickAwayListener onClickAway={handleClose}>
              <div>
                <MenuList
                  dense
                  autoFocusItem={open}
                  id="composition-menu"
                  aria-labelledby="composition-button"
                  onKeyDown={(event) => {
                    event.preventDefault()
                    event.stopPropagation()
                  }}
                >
                  <MenuItem onClick={() => handleUpdateStatus('reported')}>
                    <ListItemText>
                      Reported
                    </ListItemText>
                  </MenuItem>
                  <MenuItem onClick={() => handleUpdateStatus('unreported')}>
                    <ListItemText>
                      Unreported
                    </ListItemText>
                  </MenuItem>
                </MenuList>
              </div>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  )
}

export default MenuListComposition
