import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { Link as MuiLink } from '@mui/material'

const Link = (props) => (
  <MuiLink
    component={RouterLink}
    to={props.to}
    className={props.className}
  >
    {props.children}
  </MuiLink>
)

export default Link
