export default (theme) => ({
  mapSelected: {
    fill: theme.palette.primary.main,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  mapUnselected: {
    fill: theme.color.dustyGray,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  gray: {
    fill: theme.color.dustyGray,
  },
  // Dialog
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  dialogAction: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  button: {
    marginRight: '0.5rem',
  },
  field: ({ label }) => {
    return {
      maxWidth: '15rem',
      position: 'absolute',
      top: '4rem',
      marginLeft: label === '-' ? '2rem' : '',
    }
  },
  notchedOutline: {
    borderWidth: '0 !important',
  },
})
