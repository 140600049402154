import React, { useEffect } from 'react'
import { observer } from 'mobx-react'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useStores } from 'admin/hooks'
import { formatDate, formatTimeOnly } from 'admin/utils/helper'
import styles from './styles'

const useStyles = makeStyles(styles)

const InvoiceInfo = () => {
  const { infoSectionStore, actionLogStore } = useStores()
  const classes = useStyles()

  const { selected: invoice } = infoSectionStore

  const user = (row) => {
    if (Number(row.subjectId) === 0) return 'Admin (System)'
    return row?.subjectDetail?.name
  }

  useEffect(() => {
    const params = {
      objectId: invoice.id,
      object: 'invoice',
      limit: 20,
    }
    actionLogStore.fetch(params)
  }, [invoice])

  return (
    <div>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell className={classes.cellHead}>
              Date
            </TableCell>
            <TableCell className={classes.cellHead}>
              Action
            </TableCell>
            <TableCell className={classes.cellHead}>
              Updated By
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {actionLogStore.list.map((row) => (
            <TableRow key={`log-${row.id}`}>
              <TableCell component="th" scope="row">
                <div>
                  {formatDate(row.createdAt)}
                </div>
                <div>
                  {formatTimeOnly(row.createdAt)}
                </div>
              </TableCell>
              <TableCell>{row.action}</TableCell>
              <TableCell>{user(row)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  )
}

export default observer(InvoiceInfo)
