import React from 'react'
import { observer } from 'mobx-react'
import {
  Button,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

import Protected from 'admin/components/protected'
import Setting from 'admin/components/setting'
import { useStores } from 'admin/hooks'
import SlotForm from './form'

import styles from '../styles'

const useStyles = makeStyles(styles)

const TableSlots = observer(() => {
  const { slotStore } = useStores()
  const classes = useStyles()

  if (slotStore.list.length === 0) return null
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell className={classes.tableCell}>
            Time Start
          </TableCell>
          <TableCell className={classes.tableCell}>
            Time End
          </TableCell>
          <TableCell className={classes.tableCell}>
            Capacity
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {slotStore.list?.map((row) => (
          <TableRow key={row.id}>
            <TableCell component="th" scope="row">
              {row.start}
            </TableCell>
            <TableCell component="th" scope="row">
              {row.end}
            </TableCell>
            <TableCell component="th" scope="row">
              {row.capacity}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
})

const SlotSetting = () => {
  const { slotStore } = useStores()

  return (
    <Setting
      title="Slots Setting"
      subtitle={!!slotStore.capacitySlot.length &&
        `Capacity per slot: ${slotStore.capacitySlot[0]}`}
      action={(
        <Protected
          level="update"
          category="slot"
        >
          <Button
            variant="contained"
            color="secondary"
            onClick={() => slotStore.setOpenDialog(true)}
          >
            Edit
          </Button>
          <SlotForm />
        </Protected>
      )}
      table={<TableSlots />}
    />
  )
}

export default observer(SlotSetting)
