import React from 'react'
import {
  Paper,
  Typography,
  Grid,
} from '@mui/material'

const Setting = ({
  title,
  subtitle,
  action,
  table,
  select,
}) => {
  return (
    <Paper sx={{ margin: '1rem' }}>
      <Grid
        sx={{ padding: '1rem' }}
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <div>
          <Typography variant="h3">
            {title}
          </Typography>
          <Typography>
            {subtitle}
          </Typography>
        </div>
        {action}
      </Grid>
      {select}
      {table}
    </Paper>
  )
}

export default Setting
