export default (theme) => ({
  datePickerStartEnd: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  textfield: {
    marginBottom: '0.5rem',
  },
  autocomplete: {
    marginBottom: '0.25rem',
  },
  filterWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
  },
  searchField: {
    display: 'flex',
    flexDirection: 'row',
    margin: '0.5rem 0.2rem 0.5rem',
  },
  filterNumber: {
    color: theme.color.white,
    backgroundColor: theme.color.paarl,
    borderRadius: '50px',
    padding: '0 9px',
    marginRight: '5px',
  },
  input: {
    display: 'none',
  },
  uploadButton: {
    margin: '0 1rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      margin: '0 0.3rem',
    },
  },
  root: {
    height: '1rem',
    borderRadius: '0.5rem',
    marginBottom: '1rem',
  },
  colorPrimary: {
    backgroundColor: theme.color.dustyGray,
  },
  barColorPrimary: {
    backgroundColor: theme.color.darkGreen,
  },
  formControlLabel: {
    width: '250px',
  },
  checkBoxContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    flex: 1,
    [theme.breakpoints.down('md')]: {
      paddingBottom: '3.7rem',
    },
  },
})
