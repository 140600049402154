import React, { useEffect } from 'react'
import { observer } from 'mobx-react'

import { useStores } from 'admin/hooks'

import CardItem from 'admin/components/dashboard-card/upper-content/overview'

const BookingCancelled = ({ rangeDate }) => {
  const { bookingStore } = useStores()
  const { percentByStatus } = bookingStore

  const { startDate, endDate } = rangeDate

  useEffect(() => {
    bookingStore.fetchPercentage({
      startDate,
      endDate,
      status: 'cancelled',
    })
  }, [rangeDate])

  return (
    <CardItem
      title="Cancelled Booking"
      status="cancelled"
      total={percentByStatus.cancelled || 0}
    />
  )
}

export default observer(BookingCancelled)
