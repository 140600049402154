import { makeAutoObservable, reaction, flow } from 'mobx'

import { JOB_ROLE } from 'admin/config'
import * as srv from 'admin/services'
import handleError from 'admin/services/error-handler'
import { setApiAuth } from 'admin/services/api'

export default class AdminStore {
  loading = false

  dialog = {
    form: false,
    delete: false,
  }

  admin = null
  openDialog = false
  accessToken = null
  processing = false
  selected = null
  list = []
  total = 0
  isAdmin = false
  isCounterStaff = false
  isOfficeUser = false
  query = {
    columnIndex: -1,
    sortBy: 'status',
    sortDirection: 'asc',
  }
  currentPage = 0
  rowsPerPage = 10

  DEFAULT = {
    name: '',
    username: '',
    email: '',
    phoneNumber: '',
    outletCode: [],
    roleId: '',
    prestoId: '',
    password: '',
    role: 'user',
    status: 'active',
  }

  constructor(rootStore) {
    makeAutoObservable(this, {
      reload: flow,
      fetch: flow,
      current: flow,
      login: flow,
      logout: flow,
      forgetPassword: flow,
      resetPassword: flow,
    })

    this.rootStore = rootStore
    this.accessToken = localStorage.getItem('accessToken')
    reaction(
      () => this.accessToken,
      (accessToken) => {
        setApiAuth(accessToken)
      },
      { fireImmediately: true },
    )
  }

  setOpenDialog = (selected, type) => {
    this.dialog[type] = true
    if (selected) {
      this.selected = { ...this.DEFAULT, ...selected }
    } else {
      this.selected = { ...this.DEFAULT }
    }
  }

  setCloseDialog = (type, reset = true) => {
    this.dialog[type] = false
    if (reset) setTimeout(this.resetSelected, 50)
  }

  select = (id) => {
    this.selected = this.list.find((item) => item.id === id)
  }

  resetSelected = () => {
    this.selected = { ...this.DEFAULT }
  }

  updatePage = (page) => {
    this.currentPage = page
  }

  setSelected = (value) => {
    this.selected = value
  }

  updateRowsPerPage = (perPage) => {
    this.rowsPerPage = perPage
  }

  updateQuery = (key, value, resetPage = false) => {
    this.query[key] = value
    if (resetPage) this.currentPage = 0
  }

  resetQuery = () => {
    this.query = {
      columnIndex: -1,
      sortBy: 'status',
      sortDirection: 'asc',
    }
    this.currentPage = 0
  }

  get queryParams() {
    const params = {
      sortBy: this.query.sortBy,
      sortDirection: this.query.sortDirection,
      limit: this.rowsPerPage,
      offset: this.currentPage * this.rowsPerPage,
    }
    return params
  }

  get adminPageAccess() {
    if (this.isAdmin) return true
    return (
      this.admin &&
      this.admin.jobRole &&
      this.admin.jobRole.permissions.length > 0 &&
      this.admin.jobRole.permissions[0].RolePermission &&
      this.admin.jobRole.permissions[0].RolePermission.read
    )
  }

  setAccessToken(token) {
    this.accessToken = token
    localStorage.setItem('accessToken', this.accessToken)
  }

  * reload(reset = false) {
    yield this.fetch()
    if (reset) {
      this.selected = { ...this.DEFAULT }
      this.rootStore.infoSectionStore.reset()
    } else if (this.rootStore.infoSectionStore.selected?.id) {
      // reselect after fetch
      this.select(this.rootStore.infoSectionStore.selected.id)

      // update the selected object in infoSectionStore
      this.rootStore.infoSectionStore.replace(this.selected)
    }
  }

  * fetch(params) {
    try {
      this.loading = true
      const response = yield srv.fetchAdmin({
        ...this.queryParams,
        ...params,
      })
      if (response.ok) {
        this.list = response.data.list
        this.total = response.data.total
        return response.data
      }
      throw handleError(response)
    } catch (error) {
      return null
    } finally {
      this.loading = false
    }
  }

  * current() {
    const response = yield srv.currentAdmin()
    if (response.ok) {
      this.admin = response.data
      this.isAdmin = this.admin.role === 'admin'
      this.isCounterStaff = this.admin.jobRole?.name === JOB_ROLE.COUNTER_STAFF
      this.isOfficeUser = this.admin.jobRole?.name === JOB_ROLE.OFFICE_USER
      return response.data
    }
    throw handleError(response)
  }

  * login({ username, password }) {
    try {
      if (this.processing) return null
      this.processing = true
      const response = yield srv.loginAdmin({ username, password })
      if (response.ok) {
        this.setAccessToken(response.data.accessToken)
        return response.ok
      }
      throw handleError(response)
    } catch (err) {
      this.rootStore.notificationStore.setError(err)
      return null
    } finally {
      this.processing = false
    }
  }

  * logout() {
    if (this.processing) return null
    this.processing = true
    try {
      const response = yield srv.logoutAdmin()
      if (response.ok) {
        this.admin = null
        this.isAdmin = false
        this.accessToken = null
        localStorage.setItem('accessToken', '')
        return response.ok
      }
      throw handleError(response)
    } finally {
      this.processing = false
    }
  }

  * forgetPassword(params) {
    if (this.processing) return null
    this.processing = true

    const { username } = params
    try {
      const response = yield srv.forgetPasswordAdmin({ username })
      if (response.ok) return response.data
      throw handleError(response)
    } finally {
      this.processing = false
    }
  }

  * resetPassword(params) {
    if (this.processing) return null
    this.processing = true

    const { password, resetToken } = params
    try {
      const response = yield srv.resetPasswordAdmin({ password, resetToken })
      if (response.ok) return response.data
      throw handleError(response)
    } finally {
      this.processing = false
    }
  }
}
