import React, { useCallback } from 'react'
import { observer } from 'mobx-react'
import {
  Typography,
} from '@mui/material'
import {
  formatDate,
} from 'admin/utils/helper'
import { useStores } from 'admin/hooks'
import CustomDataGrid from 'admin/components/data-grid'
import StatusText from 'admin/components/status-text'

const columns = [
  {
    field: 'paymentTime',
    headerName: 'Payment Receive Date',
    width: 200,
    renderCell: (params) => `${formatDate(params.row.paymentTime)}`,
  },
  {
    field: 'driver',
    headerName: 'Driver',
    width: 200,
    sortable: false,
    renderCell: (params) => {
      const { invoice } = params.row
      return invoice?.booking?.driver ? (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <Typography variant="h5">
            {invoice.booking.driver.code}
          </Typography>
          <Typography
            variant="h5"
            style={{
              marginLeft: '0.5rem',
            }}
          >
            ({invoice.booking.driver.name})
          </Typography>
        </div>
      ) : (
        <Typography variant="h5">
          -
        </Typography>
      )
    },
  },
  {
    field: 'prestoId',
    headerName: 'Invoice Number',
    width: 180,
    sortable: false,
    renderCell: (params) => params.row.invoice?.prestoId,
  },
  {
    field: 'amount',
    headerName: 'Payment Amt',
    width: 150,
    renderCell: (params) => {
      const amount = Number(params.row.amount).toFixed(2)
      return (
        <Typography variant="h5">
          {String(amount) ? `$${amount}` : '-'}
        </Typography>
      )
    },
  },
  {
    field: 'paymentChannel',
    headerName: 'Settlement Method',
    width: 180,
    renderCell: (params) => params.row.paymentChannel,
  },
  {
    field: 'referenceNumber',
    headerName: 'Payment Ref #',
    width: 220,
    renderCell: (params) => params.row.referenceNumber,
  },
  {
    field: 'paymentStatus',
    headerName: 'Invoice Payment Status',
    width: 200,
    renderCell: (params) => {
      const { invoice } = params.row
      return (
        <StatusText
          variant="caption"
          status={invoice.paymentStatus}
        />
      )
    },
  },
  {
    field: 'customer',
    headerName: 'Customer',
    width: 150,
    sortable: false,
    renderCell: (params) => {
      const { customer } = params.row.invoice
      return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Typography variant="h5">
            {customer?.originalData?.name || customer?.name || '-'}
          </Typography>
          <Typography variant="h5">
            {customer?.originalData?.mobileNo || customer?.phoneNumber}
          </Typography>
        </div>
      )
    },
  },
]

const InvoicePaymentDataGrid = () => {
  const { infoSectionStore, invoicePaymentStore } = useStores()
  const sDate = invoicePaymentStore.startDate
  const eDate = invoicePaymentStore.endDate

  const handlePageChange = useCallback((params) => {
    invoicePaymentStore.updatePage(params)
  }, [])

  const handlePageSizeChange = useCallback((param) => {
    invoicePaymentStore.updateRowsPerPage(param)
  }, [])

  const handleRowClick = useCallback((rowData) => {
    infoSectionStore.select(rowData.row)
  }, [])

  const onSortModelChange = (param) => {
    if (param.length === 1) {
      invoicePaymentStore.updateQuery('sortBy', param[0].field, true)
      invoicePaymentStore.updateQuery('sortDirection', param[0].sort, true)
      return
    }
    invoicePaymentStore.resetQuery(sDate, eDate)
  }

  return (
    <CustomDataGrid
      rows={invoicePaymentStore.list}
      columns={columns}
      handleRowClick={handleRowClick}
      handlePageChange={handlePageChange}
      rowCount={invoicePaymentStore.total}
      pageSize={invoicePaymentStore.rowsPerPage}
      page={invoicePaymentStore.currentPage}
      handlePageSizeChange={handlePageSizeChange}
      loading={invoicePaymentStore.loading}
      onSortModelChange={onSortModelChange}
      rowHeight={100}
    />
  )
}

export default observer(InvoicePaymentDataGrid)
