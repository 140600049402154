export default () => ({
  headSection: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: '1rem',
  },
  field: {
    marginRight: '1rem',
  },
  textField: {
    margin: '0 0.25rem',
  },
})
