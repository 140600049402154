import React, { useState, useEffect } from 'react'
import { reaction, toJS } from 'mobx'
import { observer } from 'mobx-react'
import { makeStyles } from '@mui/styles'

import eventEmitter from 'admin/utils/event'
import { useStores } from 'admin/hooks'
import {
  RoutesNotLoggedIn,
  RoutesLoggedIn,
  RoutesCounterStaff,
} from 'admin/routes'

import NotificationBar from 'admin/components/notification-bar'
import SideMenu from 'admin/components/side-menu'
import VirtualKeyboard from 'admin/components/virtual-keyboard'
import RebookDialog from 'admin/components/rebook-dialog'

import styles from './styles'

const useStyles = makeStyles(styles)

const App = () => {
  const classes = useStyles()
  const { adminStore, notificationStore } = useStores()
  const { admin, isCounterStaff } = adminStore

  const [error, setError] = useState('')

  useEffect(() => {
    // Need to define event listener and reactions inside the useEffect []
    eventEmitter.on('LOGOUT', () => {
      adminStore.setAccessToken(null)
    })

    eventEmitter.on('NOTIF_ERROR', (errMessage) => {
      notificationStore.setError(errMessage)
    })

    reaction(
      () => notificationStore.errors.length,
      () => {
        if (notificationStore.errors.length === 0) return
        const err = toJS(notificationStore.errors[0])
        setError(err.message || err)
      },
    )
  }, [])

  const onCloseError = () => {
    setError('')
    notificationStore.shiftError()
  }

  return (
    <main className={classes.rootContainer}>
      {admin ? (
        <div className={classes.contentContainer}>
          <SideMenu />
          <div className={classes.appContent}>
            {!isCounterStaff ? <RoutesLoggedIn /> : <RoutesCounterStaff />}
          </div>
        </div>
      ) : (
        <RoutesNotLoggedIn />
      )}
      <NotificationBar
        severity="error"
        open={!!error}
        message={error}
        onClose={onCloseError}
      />
      <NotificationBar
        severity="success"
        open={!!notificationStore.success}
        message={notificationStore.success}
        onClose={notificationStore.clearSuccess}
      />
      <RebookDialog />
      <VirtualKeyboard />
    </main>
  )
}

export default observer(App)
