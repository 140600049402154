import React, {
  useEffect,
  Suspense,
  useCallback,
  useState,
} from 'react'
import { observer } from 'mobx-react'
import { computed } from 'mobx'

import makeStyles from '@mui/styles/makeStyles'
import {
  Box,
  Button,
  IconButton,
} from '@mui/material'
import {
  FilterList,
  DateRange,
} from '@mui/icons-material/'

import { useStores } from 'admin/hooks'
import { formatDate } from 'admin/utils/helper'

import Page from 'admin/components/page'
import DateRangePicker from 'admin/components/date-range-picker'

import BookingPaymentDataGrid from './datagrid'
import FilterDialog from './filter'
import BookingPaymentInfo from './info'

import styles from './styles'

const useStyles = makeStyles(styles)

const OutsideFilter = () => {
  const classes = useStyles()

  const { bookingPaymentStore } = useStores()

  const [anchorEl, setAnchorEl] = useState(null)

  const [date, setDate] = useState({
    key: 'selection',
    startDate: bookingPaymentStore.startDate,
    endDate: bookingPaymentStore.endDate,
  })

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleCloseCalendar = () => {
    setAnchorEl(null)
  }

  const handleSelect = useCallback(value => {
    const { startDate, endDate } = value.selection
    setDate(prev => ({
      ...prev,
      startDate,
      endDate,
    }))
    bookingPaymentStore.updateStartDate(startDate)
    bookingPaymentStore.updateEndDate(endDate)
  }, [])

  const filterLength = computed(() => {
    const object = {
      phoneNumber: bookingPaymentStore.query.phoneNumber,
      status: bookingPaymentStore.query.status,
    }
    const objArr = Object.values(object)
    const filterArr = objArr.filter(item => item)
    return filterArr.length
  }).get()

  const renderIcon = () => {
    return (
      <>
        {filterLength > 0 ? (
          <div className={classes.filterNumber}>
            {filterLength}
          </div>
        ) : (
          <FilterList />
        )}
        <span>
          Filter
        </span>
      </>
    )
  }

  return (
    <Box className={classes.filterContainer}>
      <div className={classes.filterWrapper}>
        <div className={classes.searchField}>
          <Button
            onClick={() => {
              bookingPaymentStore.setOpenFilterDialog(true)
            }}
            variant="outlined"
            color="secondary"
          >
            {renderIcon()}
          </Button>
        </div>
        <div className={classes.datePickerStart}>
          <IconButton onClick={handleClick} size="large">
            <DateRange />
          </IconButton>
          <span>
            {formatDate(date.startDate)} - {formatDate(date.endDate)}
          </span>
        </div>
        <DateRangePicker
          anchorEl={anchorEl}
          handleClose={handleCloseCalendar}
          handleSelect={handleSelect}
          selectionRange={date}
        />
      </div>
    </Box>
  )
}

const AppCharge = () => {
  const { bookingPaymentStore } = useStores()

  useEffect(() => {
    bookingPaymentStore.fetch({
      paymentMethod: 'stripe',
    })
  }, [bookingPaymentStore.queryParams])

  useEffect(() => {
    return () => {
      bookingPaymentStore.resetQuery()
    }
  }, [])

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Page>
        <OutsideFilter />
        <FilterDialog />
        <BookingPaymentDataGrid />
        <BookingPaymentInfo />
      </Page>
    </Suspense>
  )
}

export default observer(AppCharge)
