export default (theme) => ({
  icon: {
    marginRight: '0.3rem',
  },
  checkboxLabel: {
    display: 'flex',
    alignItems: 'center',
  },
  schedule: {
    display: 'flex',
    marginTop: '1rem',
    marginBottom: '1rem',
  },
  margin: {
    marginRight: '2rem',
  },
  serviceWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  serviceOptions: {
    display: 'flex',
    marginTop: '1rem',
    marginBottom: '0.2rem',
  },
  marginLabel: {
    marginTop: '1rem',
    marginBottom: '1rem',
  },
  selectedInvoice: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '0.5rem',
  },
  numberRooms: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginLeft: '2.2rem',
  },
  disableText: {
    color: theme.color.dustyGray,
  },
  icons: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
})
