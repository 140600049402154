import React, { useEffect, useState } from 'react'
import {
  IconButton,
  Icon,
  Typography,
} from '@mui/material'

import * as srv from 'admin/services'
import { formatDateTimeSeconds } from 'admin/utils/helper'
import { useStores, useApiRequest } from 'admin/hooks'

const SyncButton = () => {
  const [currentSync, setCurrentSync] = useState(null)
  const { branchStore, notificationStore } = useStores()
  const {
    request: syncBranch,
    isLoading,
  } = useApiRequest(srv.syncBranch, { blocking: true })
  const {
    request: fetchCurrentSync,
  } = useApiRequest(srv.fetchCurrentSync, { blocking: true })

  const fetchSync = async () => {
    try {
      const response = await fetchCurrentSync('branch')
      if (response) setCurrentSync(response.data)
    } catch (error) {
      notificationStore.setError(error)
    }
  }

  const handleSync = async () => {
    if (isLoading) return
    const res = await syncBranch()
    if (!res) return

    await branchStore.reload()
    fetchSync()
    notificationStore.setSuccess('Outlet is sync')
  }

  useEffect(() => {
    fetchSync()
  }, [])

  return (
    <div style={{ display: 'flex' }}>
      <IconButton
        variant="contained"
        color="secondary"
        onClick={handleSync}
        disabled={isLoading}
        size="large"
      >
        <Icon>sync</Icon>
      </IconButton>
      {currentSync?.syncUpdatedAt && (
        <div>
          <Typography variant="body2">
            Last Sync
          </Typography>
          <Typography variant="body2">
            {formatDateTimeSeconds(currentSync.syncUpdatedAt)}
          </Typography>
        </div>
      )}
    </div>
  )
}

export default SyncButton
