import * as Yup from 'yup'
import { phoneRegex } from 'admin/utils/regex'

const ContactSchema = Yup.object().shape({
  name: Yup.string()
    .required('Name is required.'),
  username: Yup.string()
    .required('Username is required.'),
  email: Yup.string()
    .required('Email is required.')
    .email('Email is invalid.'),
  phoneNumber: Yup.string()
    .required('Phone number is required.')
    .matches(phoneRegex, 'Phone number is invalid.'),
  password: Yup.string()
    .when('$type', (type, schema) => {
      if (type === 'add') {
        return schema.required('Password is required.')
      }
      return schema.nullable()
    }),
  outletCode: Yup.array().min(1, 'Outlet code is required.'),
  roleId: Yup.string()
    .required('Role is required.'),
  status: Yup.string()
    .required('Status is required.'),
})

export default ContactSchema
