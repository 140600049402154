import { makeAutoObservable, flow } from 'mobx'

import * as srv from 'admin/services'
import handleError from 'admin/services/error-handler'

export default class POSStore {
  loading = false
  list = []
  total = 0
  currentPage = 0
  rowsPerPage = 20
  dialog = false
  query = {
    sortBy: 'id',
    sortDirection: 'desc',
  }

  constructor(rootStore) {
    makeAutoObservable(this, {
      fetchCustomer: flow,
      fetchDriver: flow,
      fetchInvoice: flow,
      syncPOSInvoice: flow,
      syncPOSCustomer: flow,
      fetchInvoiceItem: flow,
      fetchItemAccessory: flow,
    })
    this.rootStore = rootStore
  }

  resetList = () => {
    this.list = []
    this.total = 0
    this.currentPage = 0
    this.resetQuery()
  }

  updatePage = (page) => {
    this.currentPage = page
  }

  updateRowsPerPage = (perPage) => {
    this.rowsPerPage = perPage
  }

  updateQuery = (key, value, resetPage = false) => {
    this.query[key] = value
    if (resetPage) this.currentPage = 0
  }

  resetQuery = () => {
    this.query = {
      sortBy: 'id',
      sortDirection: 'desc',
    }
  }

  setOpenDialog = (value) => {
    this.dialog = value
  }

  setCloseDialog = (value) => {
    this.dialog = value
  }

  get queryParams() {
    const params = {
      sortBy: this.query.sortBy,
      sortDirection: this.query.sortDirection,
      limit: this.rowsPerPage,
      offset: this.currentPage * this.rowsPerPage,
    }
    if (this.query.phoneNumber) params.phoneNumber = this.query.phoneNumber
    if (this.query.name) params.name = this.query.name
    if (this.query.id) params.id = this.query.id
    if (this.query.paymentTypeId) params.paymentTypeId = this.query.paymentTypeId
    if (this.query.customerId) params.customerId = this.query.customerId
    if (this.query.statusTypeId) params.statusTypeId = this.query.statusTypeId
    if (this.query.startDate) params.startDate = this.query.startDate
    if (this.query.endDate) params.endDate = this.query.endDate
    if (this.query.orderId) params.orderId = this.query.orderId
    if (this.query.accessoryId) params.accessoryId = this.query.accessoryId
    if (this.query.itemId) params.itemId = this.query.itemId
    return params
  }

  * fetchCustomer(params) {
    try {
      this.loading = true
      const response = yield srv.fetchPOSCustomer({
        ...this.queryParams,
        ...params,
      })
      if (response.ok) {
        this.list = response.data.list
        this.total = response.data.total
        return response.data
      }
      throw handleError(response)
    } catch (error) {
      return null
    } finally {
      this.loading = false
    }
  }

  * fetchDriver(params) {
    try {
      this.loading = true
      const response = yield srv.fetchPOSDriver({
        ...this.queryParams,
        ...params,
      })
      if (response.ok) {
        this.list = response.data.list
        this.total = response.data.total
        return response.data
      }
      throw handleError(response)
    } catch (error) {
      return null
    } finally {
      this.loading = false
    }
  }

  * fetchInvoice(params) {
    try {
      this.loading = true
      const response = yield srv.fetchPOSInvoice({
        ...this.queryParams,
        ...params,
      })
      if (response.ok) {
        this.list = response.data.list
        this.total = response.data.total
        return response.data
      }
      throw handleError(response)
    } catch (error) {
      return null
    } finally {
      this.loading = false
    }
  }

  * syncPOSInvoice(params) {
    try {
      this.loading = true
      const response = yield srv.syncPOSInvoice(params)
      if (response.ok) {
        return response.data
      }
      throw handleError(response)
    } catch (error) {
      return null
    } finally {
      this.loading = false
    }
  }

  * syncPOSCustomer(params) {
    try {
      this.loading = true
      const response = yield srv.syncPOSCustomer(params)
      if (response.ok) {
        return response.data
      }
      throw handleError(response)
    } catch (error) {
      return null
    } finally {
      this.loading = false
    }
  }

  * fetchInvoiceItem(params) {
    try {
      this.loading = true
      const response = yield srv.fetchPOSInvoiceItem({
        ...this.queryParams,
        ...params,
      })
      if (response.ok) {
        this.list = response.data.list
        this.total = response.data.total
        return response.data
      }
      throw handleError(response)
    } catch (error) {
      return null
    } finally {
      this.loading = false
    }
  }

  * fetchItemAccessory(params) {
    try {
      this.loading = true
      const response = yield srv.fetchPOSItemAccessory({
        ...this.queryParams,
        ...params,
      })
      if (response.ok) {
        this.list = response.data.list
        this.total = response.data.total
        return response.data
      }
      throw handleError(response)
    } catch (error) {
      return null
    } finally {
      this.loading = false
    }
  }
}
