import { makeAutoObservable, flow } from 'mobx'

import { TODAY } from 'admin/utils/helper'
import * as srv from 'admin/services'
import handleError from 'admin/services/error-handler'

export default class BookingStore {
  loading = false
  processing = false
  dialog = {
    delete: false,
    filter: false,
    warning: false,
    confirm: false,
    remarks: false,
    hasReviewed: false,
  }
  selectedImage = ''
  rebookDialog = false
  imageDialog = false
  newBooking = true
  selected = null
  list = []
  listAWeekAgo = []
  total = 0
  totalDashboard = 0
  query = {
    sortBy: 'createdAt',
    sortDirection: 'desc',
    status: '',
    phoneNumber: '',
    address: '',
    channelIds: '',
    showDelete: false,
    invoicePaymentMethod: '',
    reviewStatus: '',
    code: '',
  }
  timesheetQuery = null
  listingQuery = null
  defaultFilter = true
  confirmStatus = {
    status: null,
    reason: null,
  }
  counts = {
    count: 0,
    scheduledCount: 0,
    onTheWayCount: 0,
    completedCount: 0,
    failedCount: 0,
  }

  percentByStatus = {
    failed: null,
    cancelled: null,
  }

  percentByTag = {
    laundry: null,
    curtain: null,
  }

  currentPage = 0
  rowsPerPage = 10

  activeStep = 0
  activeTab = null

  address = ''

  DEFAULT = {
    id: '',
    customerId: '',
    driverId: 0,
    timeslotId: 0,
    channelId: '',
    date: TODAY(),
    code: '',
    image: '',
    remarks: '',
    status: 'scheduled',
    tags: [],
    bookingTags: null,
    customer: null,
    hasReviewed: false,
  }

  payments = []
  customerSearchResult = null
  checkedTags = []
  averageBooking = []
  percentByChannel = []
  percentByPaymentStatus = []
  percentByPaymentMethod = []
  countHighestDriver = 0
  countLowestDriver = 0

  constructor(rootStore) {
    makeAutoObservable(this, {
      reload: flow,
      fetch: flow,
      fetchPickupAWeekAgo: flow,
      fetchTotal: flow,
      fetchBookingCounts: flow,
      fetchAddress: flow,
      fetchPayments: flow,
      fetchPercentage: flow,
      fetchCountAverage: flow,
    })
    this.rootStore = rootStore
  }

  setDefaultFilter = (value) => {
    this.defaultFilter = value
  }

  setOpenWarningDialog = (value) => {
    this.dialog.warning = value
  }

  setOpenFilterDialog = (value) => {
    this.dialog.filter = value
  }

  setOpenConfirmDialog = (value) => {
    this.dialog.confirm = value
  }

  setOpenDialog = (selected, type) => {
    this.dialog[type] = true
    if (selected) {
      this.selected = { ...this.DEFAULT, ...selected }
    } else {
      this.selected = { ...this.DEFAULT }
    }
  }

  setCloseDialog = (type, reset = true) => {
    this.dialog[type] = false
    if (reset) setTimeout(this.resetSelected, 50)
  }

  setCustomerSearchResult = (value) => {
    this.customerSearchResult = value
  }

  setCheckedTags = (value) => {
    this.checkedTags = value
  }

  setSelectedImg = (value) => {
    this.selectedImage = value
  }

  setImageDialog = (value) => {
    this.imageDialog = value
  }

  setRebookDialog = (value) => {
    this.rebookDialog = value
  }

  updateActiveStep = (step) => {
    this.activeStep = step
  }

  setActiveTab = (tab) => {
    this.activeTab = tab
  }

  setServiceLoad = (value) => {
    this.loading = value
  }

  setNewBooking = (value) => {
    this.newBooking = value
  }

  select = (id) => {
    this.selected = this.list.find((item) => item.id === id)
  }

  setSelected = (value) => {
    this.selected = value
  }

  setConfirmStatus = (value) => {
    this.confirmStatus = value
  }

  setResetConfirmStatus = () => {
    this.confirmStatus = {
      status: null,
      reason: null,
    }
  }

  updateSelected = (key, value) => {
    this.selected = { ...this.selected, [key]: value }
  }

  updatePage = (page) => {
    this.currentPage = page
  }

  updateRowsPerPage = (perPage) => {
    this.rowsPerPage = perPage
  }

  setTimesheetQuery = (value) => {
    this.timesheetQuery = value
  }

  setListingQuery = (value) => {
    this.listingQuery = value
  }

  updateQuery = (key, value, resetPage = false) => {
    this.query[key] = value
    if (resetPage) this.currentPage = 0
  }

  resetQuery = () => {
    this.query = {
      sortBy: 'createdAt',
      sortDirection: 'desc',
      status: '',
      phoneNumber: '',
      address: '',
      channelIds: '',
      showDelete: false,
      invoicePaymentMethod: '',
      reviewStatus: '',
      code: '',
    }
    this.currentPage = 0
    this.rowsPerPage = 10
  }

  resetList = () => {
    this.list = []
    this.total = 0
    this.currentPage = 0
  }

  resetSort = () => {
    this.query.sortBy = 'createdAt'
    this.query.sortDirection = 'desc'
  }

  resetBookingFlow = () => {
    this.newBooking = true
    this.activeStep = 0
    this.selected = null
    this.customerSearchResult = null
    this.checkedTags = []
  }

  resetSelectedSchedule = () => {
    this.updateSelected('driverId', null)
    this.updateSelected('timeslotId', null)
    this.updateSelected('driver', null)
    this.updateSelected('slot', null)
  }

  setImageList = (bookingId, imgList) => {
    this.list = this.list.map((obj) => (obj.id === bookingId ? { ...obj, images: imgList } : obj))
  }

  get queryParams() {
    const params = {
      sortBy: this.query.sortBy,
      sortDirection: this.query.sortDirection,
      limit: this.rowsPerPage,
      offset: this.currentPage * this.rowsPerPage,
    }
    if (this.query.code) params.code = this.query.code
    if (this.query.phoneNumber) params.phoneNumber = this.query.phoneNumber
    if (this.query.status) params.status = this.query.status
    if (this.query.driverId) params.driverId = this.query.driverId
    if (this.query.startDate) params.startDate = this.query.startDate
    if (this.query.endDate) params.endDate = this.query.endDate
    if (this.query.date) params.date = this.query.date
    if (this.query.address) params.address = this.query.address
    if (this.query.createdAt) params.createdAt = this.query.createdAt
    if (this.query.startCreatedAt) params.startCreatedAt = this.query.startCreatedAt
    if (this.query.endCreatedAt) params.endCreatedAt = this.query.endCreatedAt
    if (this.query.channelIds) params.channelIds = this.query.channelIds
    if (this.query.showDelete) params.showDelete = this.query.showDelete
    if (this.query.createdBy) params.createdBy = this.query.createdBy
    if (this.query.invoicePaymentMethod) params.invoicePaymentMethod = this.query.invoicePaymentMethod
    if (this.query.reviewStatus) params.reviewStatus = this.query.reviewStatus
    if (this.query.tagsObj) params.tagsObj = this.query.tagsObj
    if (this.query.isChannel) params.isChannel = this.query.isChannel
    return params
  }

  * reload(reset = false) {
    let params = null
    if (this.activeTab === 0) params = { ...this.timesheetQuery }
    if (this.activeTab === 1) params = { ...this.listingQuery }
    yield this.fetch(params)

    if (reset) {
      this.selected = null
      this.rootStore.infoSectionStore.reset()
    } else if (this.rootStore.infoSectionStore.selected?.id) {
      // update the selected object in infoSectionStore
      const selectedInfoSection = this.list.find(
        (item) => item.id === this.rootStore.infoSectionStore.selected.id,
      )
      this.rootStore.infoSectionStore.replace(selectedInfoSection)
    }
  }

  * fetch(params) {
    try {
      this.loading = true
      const response = yield srv.fetchBooking({
        ...this.queryParams,
        ...params,
      })
      if (response.ok) {
        this.list = response.data.list
        this.total = response.data.total
        return response.data
      }
      throw handleError(response)
    } catch (error) {
      return null
    } finally {
      this.loading = false
    }
  }

  * fetchPickupAWeekAgo(params) {
    try {
      this.loading = true
      const response = yield srv.fetchBooking({
        ...this.queryParams,
        ...params,
      })
      if (response.ok) {
        this.listAWeekAgo = response.data.list
        return response.data
      }
      throw handleError(response)
    } catch (error) {
      return null
    } finally {
      this.loading = false
    }
  }

  * fetchTotal(params) {
    try {
      this.loading = true
      const response = yield srv.fetchTotalBooking({
        ...params,
      })
      if (response.ok) {
        this.totalDashboard = response.data.total
        return response.data
      }
      throw handleError(response)
    } catch (error) {
      return null
    } finally {
      this.loading = false
    }
  }

  * fetchBookingCounts(params) {
    try {
      this.loading = true
      const response = yield srv.fetchBookingCounts({
        ...this.queryParams,
        ...params,
      })
      if (response.ok) {
        this.counts = response.data
        return response.data
      }
      throw handleError(response)
    } catch (error) {
      return null
    } finally {
      this.loading = false
    }
  }

  * fetchAddress(postalCode) {
    try {
      this.loading = true
      const response = yield srv.fetchAddress(postalCode)
      if (response.ok) {
        this.address = response.data.address
        return response.data
      }
      throw handleError(response)
    } catch (error) {
      return null
    } finally {
      this.loading = false
    }
  }

  * fetchPayments(params) {
    try {
      this.loading = true
      const response = yield srv.fetchBookingPayments(params)
      if (response.ok) {
        this.payments = response.data.list
        return response.data
      }
      throw handleError(response)
    } catch (error) {
      return null
    } finally {
      this.loading = false
    }
  }

  * fetchPercentage(params) {
    try {
      this.loading = true
      const response = yield srv.fetchPercentageBooking({
        ...this.queryParams,
        ...params,
      })
      if (response.ok) {
        if (params.status) this.percentByStatus[params.status] = response.data.totalByStatus
        if (params.tagsObj) this.percentByTag[params.tagsObj[0].tagCode] = response.data.totalByTag
        if (params.isChannel) this.percentByChannel = response.data.totalByChannelArr
        return response.data
      }
      throw handleError(response)
    } catch (error) {
      return null
    } finally {
      this.loading = false
    }
  }

  * fetchCountAverage(params) {
    try {
      this.loading = true
      const response = yield srv.fetchCountAverageBooking({
        ...this.queryParams,
        ...params,
      })
      if (response.ok) {
        this.averageBooking = response.data.list
        return response.data
      }
      throw handleError(response)
    } catch (error) {
      return null
    } finally {
      this.loading = false
    }
  }

  * fetchCountHeightLow(params) {
    try {
      this.loading = true
      const response = yield srv.fetchCountHeightLowBooking({
        ...this.queryParams,
        ...params,
      })
      if (response.ok) {
        this.countHighestDriver = response.data.highestDriver
        this.countLowestDriver = response.data.lowestDriver
        return response.data
      }
      throw handleError(response)
    } catch (error) {
      return null
    } finally {
      this.loading = false
    }
  }

  * fetchPercentageInvoice(params) {
    try {
      this.loading = true
      const response = yield srv.fetchPercentageInvoice({
        ...this.queryParams,
        ...params,
      })
      if (response.ok) {
        this.percentByPaymentStatus = response.data.resultStatus
        this.percentByPaymentMethod = response.data.resultMethod
        return response.data
      }
      throw handleError(response)
    } catch (error) {
      return null
    } finally {
      this.loading = false
    }
  }
}
