export default (theme) => ({
  border: ({ icon, status }) => {
    return {
      borderRadius: '8px',
      borderLeft: `1.25rem solid ${icon === 'face' || status === 'cancelled' ? theme.color.paarl : theme.color.cinnabar}`,
    }
  },
  icon: ({ icon }) => {
    return {
      fontSize: '3rem !important',
      color: icon === 'face' ? theme.color.paarl : theme.color.cinnabar,
    }
  },
  item: ({ icon }) => {
    return {
      padding: !icon ? '0.94rem 0.94rem 0.94rem 1.25rem' : '0.94rem 2.4rem 0.94rem 1.25rem',
      [theme.breakpoints.down('md')]: {
        padding: '0.94rem 0.9rem 0.94rem 1.25rem',
      },
    }
  },
  borderCard: {
    borderRadius: '8px',
  },
  totalPercent: {
    display: 'flex',
    flexDirection: 'row',
  },
  total: {
    fontSize: '2.5rem',
    fontWeight: 600,
    lineHeight: 1.2,
  },
  percent: {
    display: 'flex',
    alignItem: 'flex-end',
    fontSize: '1.5rem',
    fontWeight: 400,
    lineHeight: 1.2,
  },
  card: {
    width: '100%',
    padding: '1.5rem',
    borderRadius: '8px',
  },
  cardContent: {
    position: 'relative',
    height: '338px',
    width: '100%',
    '&.MuiCardContent-root': {
      padding: '0',
    },
  },
})
