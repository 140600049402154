import React, { useCallback } from 'react'
import { observer } from 'mobx-react'
import {
  Button,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

import DeleteDialog from 'admin/components/dialog'
import Protected from 'admin/components/protected'
import Setting from 'admin/components/setting'
import { useApiRequest, useStores } from 'admin/hooks'
import * as srv from 'admin/services'

import RoleForm from './form'

import styles from '../styles'

const useStyles = makeStyles(styles)

const TableRole = observer(() => {
  const { roleStore } = useStores()
  const classes = useStyles()

  if (roleStore.list.length === 0) {
    return (
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        className={classes.text}
      >
        <Typography>
          No Rows
        </Typography>
      </Stack>
    )
  }

  return (
    <Table size="small">
      <TableBody>
        {roleStore.list?.map((row) => (
          <TableRow
            key={row.name}
            sx={{
              '&:last-child td, &:last-child th': {
                border: 0,
              },
            }}
          >
            <TableCell component="th" scope="row">
              {row.name}
            </TableCell>
            <TableCell align="right" scope="row">
              <Button
                variant="text"
                color="secondary"
                size="small"
                onClick={() => {
                  roleStore.setSelected(row)
                  roleStore.setOpenDialog(row, 'form')
                }}
              >
                Edit
              </Button>
              <Button
                variant="text"
                color="secondary"
                size="small"
                onClick={() => {
                  roleStore.setSelected(row)
                  roleStore.setOpenDialog(row, 'delete')
                }}
              >
                Delete
              </Button>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
})

const RoleSetting = () => {
  const { notificationStore, roleStore } = useStores()

  const {
    request: deleteRole,
  } = useApiRequest(srv.deleteRole, { blocking: true })

  const onDelete = useCallback(async () => {
    if (
      !roleStore.selected ||
      !roleStore.selected.id
    ) return
    const response = await deleteRole({ id: roleStore.selected.id })
    if (!response) return
    notificationStore.setSuccess('Delete role success')
    roleStore.setCloseDialog('delete')
    roleStore.reload(true)
  }, [roleStore.selected])

  return (
    <Setting
      title="Roles"
      action={(
        <>
          <Protected
            level="update"
            category="role"
          >
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                roleStore.setSelected(null)
                roleStore.setOpenDialog(null, 'form')
              }}
            >
              Create
            </Button>
            <RoleForm />
          </Protected>
          <Protected
            level="delete"
            category="role"
          >
            <DeleteDialog
              open={roleStore.dialog.delete}
              title={`Do you want to delete ${roleStore.selected?.name} role?`}
              content="Role data that deleted cannot be recovered. Make sure you know what you are doing."
              primaryActionLabel="Delete"
              onAccept={onDelete}
              onClose={() => { roleStore.setCloseDialog('delete') }}
            />
          </Protected>
        </>
      )}
      table={<TableRole />}
    />
  )
}

export default observer(RoleSetting)
