import React, {
  useState,
  useCallback,
  useEffect,
} from 'react'
import {
  Card,
  CardContent,
  Typography,
  CardActionArea,
  CardHeader,
  Icon,
} from '@mui/material'
// import { makeStyles } from '@mui/material/styles'
import { observer } from 'mobx-react'
import { useStores } from 'admin/hooks'
import { getTags, weightage } from 'admin/utils/helper'
import Map from 'admin/components/map'

import ServiceIcon from 'admin/components/service-icon'
// import styles from './styles'

// const useStyles = makeStyles(styles)

const BookingCard = ({
  bookedList,
  timeslot,
  driver,
  driverSlot,
  codeArea,
  bookedListAWeekAgo,
}) => {
  // const classes = useStyles()
  const { bookingStore } = useStores()

  const [openMap, setOpenMap] = useState(false)

  const totalWeightage = () => {
    let total = 0
    bookedList.map((b) => {
      const tw = weightage(b)
      total += tw
      return tw
    })
    if (bookedListAWeekAgo.length > 0) {
      bookedListAWeekAgo.map((item) => {
        const totalWeight = weightage(item)
        total += totalWeight
        return true
      })
    }
    return total
  }

  const setSchedule = async (event) => {
    event.preventDefault()
    event.stopPropagation()
    bookingStore.updateSelected('driverId', driver.id)
    bookingStore.updateSelected('timeslotId', timeslot.id)
    bookingStore.updateSelected('driver', driver)
    bookingStore.updateSelected('slot', timeslot)
  }

  const handleOpenMap = useCallback((event) => {
    event.preventDefault()
    event.stopPropagation()
    setOpenMap(true)
  }, [])

  useEffect(() => {
    if (bookingStore.rebookDialog) {
      bookingStore.setSelected({
        ...bookingStore.selected,
        timeslotId: 0,
      })
    }
  }, [bookingStore.rebookDialog])

  const selected = bookingStore?.selected?.driverId === driver.id &&
  bookingStore?.selected?.timeslotId === timeslot.id

  const hasCodeArea = () => {
    // if customer has a preferred driver
    // turn off preferred slots from other drivers
    if (Number(bookingStore.selected?.customer?.driverId)) {
      if (bookingStore.selected?.customer?.driverId !== driver.id) {
        return false
      }
    }
    // otherwise check preferred slots from all drivers
    return driverSlot?.driverSlot?.serviceArea.includes(codeArea)
  }

  const getBackgroundColor = () => {
    if (
      !driverSlot?.driverSlot?.isActive
    ) {
      return '#E6E0CF'
    }
    if (selected) {
      return '#B74826'
    }
    if (hasCodeArea()) {
      return '#E3AE24'
    }
    return '#ffff'
  }

  const getAvailability = () => {
    if (!driverSlot?.driverSlot?.isActive) {
      return 'Not Available'
    }
    return `${totalWeightage()}/${timeslot.capacity}`
  }

  const disableCard = () => {
    return !driverSlot?.driverSlot?.isActive
  }

  const textIconColor = () => {
    if (hasCodeArea()) {
      return selected ? '#E3AE24' : '#fff'
    }
    if (selected) {
      return '#fff'
    }
    if (!driverSlot?.driverSlot?.isActive) {
      return 'rgba(0, 0, 0, 0.4)'
    }
    return '#B74826'
  }

  return (
    <>
      <Card
        sx={{
          width: 289,
          borderRadius: '4px',
          border: `1px solid ${getBackgroundColor()}`,
          marginBottom: '0.5rem',
        }}
      >
        <CardActionArea
          onClick={setSchedule}
          disabled={disableCard()}
        >
          <CardHeader
            sx={{
              padding: '6px',
              background: getBackgroundColor(),
              borderBottom: bookedList.length > 0 ? '1px solid #f0f0f0' : 'none',
            }}
            title={(
              <div style={{
                display: 'flex',
                justifyContent: 'space-between',
                color: hasCodeArea() && selected ? '#fff' : textIconColor() }}
              >
                <Typography variant="body2">
                  {timeslot.start} - {timeslot.end}
                </Typography>
                <Typography variant="body2">
                  {getAvailability()}
                </Typography>
              </div>
            )}
            subheader={(
              <div style={{ display: 'flex', marginTop: '0.5rem' }}>
                <div
                  role="button"
                  tabIndex={0}
                  onClick={handleOpenMap}
                >
                  <Icon sx={{ color: textIconColor() }}>
                    map
                  </Icon>
                </div>
                <Typography
                  variant="body2"
                  style={{
                    padding: '0px',
                    marginLeft: '0.3rem',
                    color: textIconColor(),
                  }}
                >
                  {driverSlot?.driverSlot?.serviceArea?.map(sa => sa).join(', ')}
                </Typography>
              </div>
            )}
          />
          {bookedList.length > 0 && (
            <CardContent style={{ padding: '0' }}>
              {bookedList.map((data, index) => {
                const {
                  pickup,
                  delivery,
                } = getTags(data.bookingTags)
                return (
                  <div
                    key={data.id}
                    style={{
                      padding: '6px 6px',
                      boxShadow: (
                        bookedList.length - 1 === index
                      ) ? 'none' : 'inset 0px -1px 1px rgba(0, 0, 0, 0.25)',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Typography variant="caption">
                        {data.address || data.customer?.address}
                      </Typography>
                      <Typography variant="caption">
                        {(weightage(data))}
                      </Typography>
                    </div>
                    <Typography variant="caption">
                      {data.remarks}
                    </Typography>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        marginTop: '0.5rem',
                      }}
                    >
                      {pickup.length > 0 && (
                        <ServiceIcon
                          iconName="pickup"
                          data={pickup}
                        />
                      )}
                      {delivery.length > 0 && (
                        <ServiceIcon
                          iconName="delivery"
                          data={delivery}
                        />
                      )}
                    </div>
                  </div>
                )
              })}
            </CardContent>
          )}
        </CardActionArea>
      </Card>
      <Map
        open={openMap}
        onClose={() => setOpenMap(false)}
        serviceArea={driverSlot?.driverSlot?.serviceArea}
        onSave={() => {}}
        showAction={false}
        title="Driver Service Area"
        disabled
      />
    </>
  )
}

export default observer(BookingCard)
