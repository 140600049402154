import React, { useEffect, useMemo } from 'react'
import { observer } from 'mobx-react'

import { useStores } from 'admin/hooks'
import { BOOKING_TAG } from 'admin/config'

import CardItem from 'admin/components/dashboard-card/upper-content/overview'

const BookingCurtain = ({ rangeDate }) => {
  const { bookingStore, bookingTagStore } = useStores()
  const { percentByTag } = bookingStore
  const { list } = bookingTagStore

  const { startDate, endDate } = rangeDate

  const tags = useMemo(() => {
    const tagCurtain = list.filter(item => item.name.toLowerCase() === BOOKING_TAG.CURTAIN_WITH_DISMANTLING.toLowerCase())?.map((tag) => {
      return {
        tagId: tag.id,
        tagCode: tag.posCode.toLowerCase(),
      }
    })
    return { ...tagCurtain }
  }, [list])

  useEffect(() => {
    if (!!rangeDate && Object.keys(tags).length > 0) {
      bookingStore.fetchPercentage({
        startDate,
        endDate,
        tagsObj: tags,
      })
    }
  }, [rangeDate, tags])

  return (
    <CardItem
      title="Curtain (dismantling) Bookings"
      total={percentByTag.curtain || 0}
      isTag
    />
  )
}

export default observer(BookingCurtain)
