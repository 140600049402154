import React from 'react'
import { observer } from 'mobx-react'
import makeStyles from '@mui/styles/makeStyles'

import {
  Typography,
  Divider,
} from '@mui/material'

import { useStores } from 'admin/hooks'

import { STATUS_TYPE } from 'admin/config/status-type'
import InfoSection from 'admin/components/info-section'
import HeadBar from 'admin/components/head-bar'
import { formatDateHourAmPm } from 'admin/utils/helper'

import InformationTab from 'admin/components/information-tab'

import styles from '../styles'

const useStyles = makeStyles(styles)

const Information = observer(() => {
  const { infoSectionStore } = useStores()
  const { selected } = infoSectionStore

  return (
    <InformationTab
      data={[
        {
          label: 'Created at',
          value: formatDateHourAmPm(selected.createdTime),
        },
        {
          label: 'Last Updated Time',
          value: formatDateHourAmPm(selected.lastUpdatedTime),
        },
        {
          label: 'Status',
          value: selected.statusTypeId ?
            STATUS_TYPE.find((status) => status.id === selected.statusTypeId)?.name : '-',
        },
        {
          label: 'Barcode',
          value: selected.barcode || '-',
        },
        {
          label: 'Defects',
          value: selected.defects || '-',
        },
        {
          label: 'Remarks',
          value: selected.remarks || '-',
        },
        {
          label: 'Price',
          value: `$${selected?.price?.toFixed(2)}`,
        },
        {
          label: 'Sub Status Type Id',
          value: selected.subStatusTypeId || '-',
        },
        {
          label: 'Wash Type Id',
          value: selected.washTypeId || '-',
        },
        {
          label: 'Laundry Id',
          value: selected.laundryId || '-',
        },
        {
          label: 'Accessories',
          value: selected.accessories || '-',
        },
        {
          label: 'Patterns',
          value: selected.patterns || '-',
        },
        {
          label: 'Colors',
          value: selected.colors || '-',
        },
        {
          label: 'Brand Id',
          value: selected.brandId || '-',
        },
        {
          label: 'Is Manual Received',
          value: selected?.isManualReceived?.toString() || '-',
        },
      ]}
    />
  )
})

const PosInvoiceItemInfo = () => {
  const classes = useStyles()
  const { infoSectionStore } = useStores()
  const { selected } = infoSectionStore

  return (
    <InfoSection
      title="Invoice Item"
    >
      <div className={classes.parentContainer}>
        {selected && (
          <>
            <HeadBar
              data={[
                {
                  label: 'ID',
                  value: selected.id,
                },
                {
                  label: 'Invoice Id',
                  value: selected.orderId,
                },
                {
                  label: 'Item',
                  value: selected.name,
                },
              ]}
            />
            <Divider />
            <Typography
              variant="h4"
              className={classes.title}
            >
              Details
            </Typography>
            <div className={classes.infoContainer}>
              <Information />
            </div>
          </>
        )}
      </div>
    </InfoSection>
  )
}

export default observer(PosInvoiceItemInfo)
