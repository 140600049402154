import React, { useEffect } from 'react'
import { observer } from 'mobx-react'
import makeStyles from '@mui/styles/makeStyles'
import {
  Button,
} from '@mui/material'

import Page from 'admin/components/page'
import Protected from 'admin/components/protected'

import { useStores } from 'admin/hooks'

import ShortLinkForm from './form'
import LinkDataGrid from './datagrid'
import styles from './styles'

const useStyles = makeStyles(styles)

const Action = observer(() => {
  const classes = useStyles()
  const { shortLinkStore } = useStores()

  return (
    <div className={classes.headSection}>
      <Protected
        level="create"
        category="admin"
      >
        <Button
          onClick={() => {
            shortLinkStore.setOpenDialog(true)
          }}
          variant="contained"
          color="secondary"
        >
          Create Short Link
        </Button>
      </Protected>
    </div>
  )
})

const ShortLink = () => {
  const { shortLinkStore } = useStores()

  useEffect(() => {
    shortLinkStore.fetch()
  }, [shortLinkStore.queryParams])

  useEffect(() => {
    return () => {
      shortLinkStore.resetQuery()
    }
  }, [])

  return (
    <Page>
      <Action />
      <ShortLinkForm />
      <LinkDataGrid />
    </Page>
  )
}

export default observer(ShortLink)
