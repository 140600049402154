export default (theme) => ({
  root: {
    position: 'relative',
    margin: 0,
    padding: theme.spacing(2),
    fontSize: '1.25rem',
    textTransform: 'capitalize',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.color.shadow,
  },
  resetButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
})
