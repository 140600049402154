import React, { useEffect, useState, useCallback } from 'react'
import { observer } from 'mobx-react'
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  FormControl,
  MenuItem,
} from '@mui/material'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm, Controller } from 'react-hook-form'
import CustomizedAutocomplete from 'admin/components/autocomplete'
import makeStyles from '@mui/styles/makeStyles'

import { useStores, useApiRequest, useRequest } from 'admin/hooks'
import * as srv from 'admin/services'

import CustomizedDialogTitle from 'admin/components/dialog-title'
import TextField from 'admin/components/textfield'
import schema from './schema'

import styles from './styles'

const useStyles = makeStyles(styles)

const AdminForm = () => {
  const { adminStore, notificationStore, roleStore } = useStores()
  const classes = useStyles()
  const [driverOptions, setDriverOptions] = useState([])

  const {
    request: fetchBranch,
    loading,
  } = useRequest(srv.fetchBranch, {
    concurrent: true,
    initialData: { list: [], total: 0 },
    transformData: (data) => {
      if (data && data.list.length > 0) {
        setDriverOptions(data.list.map(d => ({
          key: d.id,
          label: d.code,
          ...d,
        })))
      }
    },
  })

  const handleOpenSearch = useCallback(() => {
    fetchBranch({ limit: 5 })
  }, [])

  const handleSearchInputChange = useCallback((event, newValue) => {
    if (!newValue) {
      setDriverOptions([])
      return
    }
    setTimeout(() => {
      fetchBranch({ code: newValue })
    }, 500)
  }, [])

  const { control, watch, handleSubmit: handleSubmitForm, reset } = useForm({
    resolver: yupResolver(schema),
    defaultValues: { ...adminStore.DEFAULT },
    context: { type: adminStore.selected?.id ? 'update' : 'add' },
  })

  const watchAdminId = watch('id')

  const {
    request: addAdmin,
    isLoading: addProcessing,
  } = useApiRequest(srv.addAdmin, { blocking: true })
  const {
    request: updateAdmin,
    isLoading: updateProcessing,
  } = useApiRequest(srv.updateAdmin, { blocking: true })

  useEffect(() => {
    if (adminStore.selected) {
      const { outletCode, ...selected } = adminStore.selected

      const outletCodes = adminStore
        ?.selected?.branches?.map((item) => {
          return {
            key: item.id,
            label: item.code,
            ...item,
          }
        })

      reset({
        ...selected,
        outletCode: outletCodes || [],
      })

      return
    }
    reset({ ...adminStore.DEFAULT })
  }, [adminStore.selected])

  useEffect(() => {
    roleStore.fetch()
  }, [])

  const handleClose = useCallback(() => {
    if (addProcessing || updateProcessing) return
    adminStore.setCloseDialog('form')
  }, [])

  const handleSubmit = async (value) => {
    const isUpdate = !!value.id
    const { outletCode, ...resValue } = value
    resValue.outletCodes = outletCode

    const request = (isUpdate) ? updateAdmin : addAdmin
    const res = await request(resValue)
    if (!res) return

    await adminStore.reload()
    if (isUpdate) {
      notificationStore.setSuccess('Admin account has been updated successfully')
    } else {
      notificationStore.setSuccess('Admin account has been created successfully')
    }
    adminStore.setCloseDialog('form')
  }

  return (
    <Dialog
      open={adminStore.dialog.form}
      onClose={handleClose}
      aria-labelledby="Admin Form"
    >
      <CustomizedDialogTitle onClose={handleClose}>
        {watchAdminId ? 'Update Admin' : 'Add New Admin'}
      </CustomizedDialogTitle>

      <form
        noValidate
        onSubmit={handleSubmitForm(handleSubmit)}
        autoComplete="off"
      >
        <DialogContent>
          <FormControl
            fullWidth
            margin="normal"
          >
            <Controller
              control={control}
              name="name"
              render={({
                field: { ref, ...fieldProps },
                fieldState: { error },
              }) => (
                <TextField
                  label="Name"
                  InputLabelProps={{ shrink: true }}
                  autoFocus
                  error={!!error?.message}
                  helperText={error?.message}
                  inputRef={ref}
                  {...fieldProps}
                />
              )}
            />
          </FormControl>
          <FormControl
            fullWidth
            margin="normal"
          >
            <Controller
              control={control}
              name="username"
              render={({
                field: { ref, ...fieldProps },
                fieldState: { error },
              }) => (
                <TextField
                  label="Username"
                  InputLabelProps={{ shrink: true }}
                  error={!!error?.message}
                  helperText={error?.message}
                  inputRef={ref}
                  {...fieldProps}
                />
              )}
            />
          </FormControl>
          <FormControl
            fullWidth
            margin="normal"
          >
            <Controller
              control={control}
              name="email"
              render={({
                field: { ref, ...fieldProps },
                fieldState: { error },
              }) => (
                <TextField
                  label="Email"
                  InputLabelProps={{ shrink: true }}
                  error={!!error?.message}
                  helperText={error?.message}
                  inputRef={ref}
                  {...fieldProps}
                />
              )}
            />
          </FormControl>
          <FormControl
            fullWidth
            margin="normal"
          >
            <Controller
              control={control}
              name="phoneNumber"
              render={({
                field: { ref, ...fieldProps },
                fieldState: { error },
              }) => (
                <TextField
                  label="Phone No."
                  InputLabelProps={{ shrink: true }}
                  error={!!error?.message}
                  helperText={error?.message}
                  inputRef={ref}
                  {...fieldProps}
                />
              )}
            />
          </FormControl>
          <FormControl
            fullWidth
            margin="normal"
          >
            <Controller
              control={control}
              name="outletCode"
              render={({
                field: { onChange, value, ref, ...fieldProps },
                fieldState: { error },
              }) => (
                <CustomizedAutocomplete
                  label="Outlet Code"
                  multiple
                  handleOpen={handleOpenSearch}
                  handleInputChange={handleSearchInputChange}
                  handleChange={(e, newValue) => {
                    onChange(newValue)
                  }}
                  options={driverOptions}
                  loading={loading}
                  classesAutocomplete={classes.textField}
                  value={value}
                  renderOption={(option, state) => (
                    <div {...option}>
                      {`${state.code} - ${state.name}`}
                    </div>
                  )}
                  errorMessage={!!error?.message}
                  helperTextTextField={error?.message}
                  inputRefTextField={ref}
                  {...fieldProps}
                />
              )}
            />
          </FormControl>
          <FormControl fullWidth margin="normal">
            <Controller
              control={control}
              name="roleId"
              render={({
                field: { ref, ...fieldProps },
                fieldState: { error },
              }) => (
                <TextField
                  fullWidth
                  label="Role"
                  select
                  error={!!error?.message}
                  helperText={error?.message}
                  inputRef={ref}
                  {...fieldProps}
                >
                  {roleStore.list.map(role => (
                    <MenuItem key={role.id} value={role.id}>
                      {role.name}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
          </FormControl>
          {!watchAdminId && (
            <FormControl
              fullWidth
              margin="normal"
            >
              <Controller
                control={control}
                name="password"
                render={({
                  field: { ref, ...fieldProps },
                  fieldState: { error },
                }) => (
                  <TextField
                    label="Password"
                    type="password"
                    autoComplete="current-password"
                    InputLabelProps={{ shrink: true }}
                    error={!!error?.message}
                    helperText={error?.message}
                    inputRef={ref}
                    {...fieldProps}
                  />
                )}
              />
            </FormControl>
          )}
          <FormControl
            fullWidth
            margin="normal"
          >
            <Controller
              control={control}
              name="prestoId"
              render={({
                field: { ref, ...fieldProps },
                fieldState: { error },
              }) => (
                <TextField
                  label="Presto Id"
                  InputLabelProps={{ shrink: true }}
                  error={!!error?.message}
                  helperText={error?.message}
                  inputRef={ref}
                  {...fieldProps}
                />
              )}
            />
          </FormControl>
          <FormControl fullWidth margin="normal">
            <Controller
              control={control}
              name="status"
              render={({
                field: { ref, ...fieldProps },
                fieldState: { error },
              }) => (
                <TextField
                  fullWidth
                  label="Status"
                  select
                  error={!!error?.message}
                  helperText={error?.message}
                  inputRef={ref}
                  {...fieldProps}
                >
                  <MenuItem value="active">
                    Active
                  </MenuItem>
                  <MenuItem value="inactive">
                    Inactive
                  </MenuItem>
                </TextField>
              )}
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            color="primary"
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={addProcessing || updateProcessing}
          >
            {watchAdminId ? 'Save' : 'Create'}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default observer(AdminForm)
