import React, {
  useCallback,
  useEffect,
} from 'react'
import { observer } from 'mobx-react'
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  FormControl,
} from '@mui/material'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm, Controller } from 'react-hook-form'

import { useStores, useApiRequest } from 'admin/hooks'
import * as srv from 'admin/services'

import CustomizedDialogTitle from 'admin/components/dialog-title'
import TextField from 'admin/components/textfield'
import schema from './schema'

const ProfileForm = () => {
  const {
    adminStore,
    notificationStore,
    profileStore,
  } = useStores()

  const { control, handleSubmit: handleSubmitForm, reset } = useForm({
    resolver: yupResolver(schema),
    defaultValues: { ...profileStore.DEFAULT },
  })

  useEffect(() => {
    if (!profileStore.selected) {
      reset({ ...profileStore.DEFAULT })
      return
    }
    reset({ ...profileStore.selected })
  }, [profileStore.selected])

  const {
    request: updateProfile,
    isLoading: updateProcessing,
  } = useApiRequest(srv.updateProfile, { blocking: true })

  const handleClose = useCallback(() => {
    if (updateProcessing) return
    profileStore.setCloseDialog('profile')
  }, [])

  const handleSubmit = async (value) => {
    const res = await updateProfile(value)
    if (!res) return

    await adminStore.current()
    notificationStore.setSuccess('Profile has been updated successfully')
    handleClose()
  }

  return (
    <Dialog
      open={profileStore.dialog.profile}
      onClose={handleClose}
      aria-labelledby="Profile Form"
      maxWidth="sm"
    >
      <CustomizedDialogTitle onClose={handleClose}>
        Edit Profile
      </CustomizedDialogTitle>

      <form
        noValidate
        onSubmit={handleSubmitForm(handleSubmit)}
        autoComplete="off"
      >
        <DialogContent>
          <FormControl
            fullWidth
            margin="normal"
          >
            <Controller
              control={control}
              name="name"
              render={({
                field: { ref, ...fieldProps },
                fieldState: { error },
              }) => (
                <TextField
                  label="Name"
                  InputLabelProps={{ shrink: true }}
                  autoFocus
                  error={!!error?.message}
                  helperText={error?.message}
                  inputRef={ref}
                  {...fieldProps}
                />
              )}
            />
          </FormControl>
          <FormControl
            fullWidth
            margin="normal"
          >
            <Controller
              control={control}
              name="username"
              render={({
                field: { ref, ...fieldProps },
                fieldState: { error },
              }) => (
                <TextField
                  label="Username"
                  InputLabelProps={{ shrink: true }}
                  error={!!error?.message}
                  helperText={error?.message}
                  inputRef={ref}
                  {...fieldProps}
                />
              )}
            />
          </FormControl>
          <FormControl
            fullWidth
            margin="normal"
          >
            <Controller
              control={control}
              name="email"
              render={({
                field: { ref, ...fieldProps },
                fieldState: { error },
              }) => (
                <TextField
                  label="Email"
                  InputLabelProps={{ shrink: true }}
                  error={!!error?.message}
                  helperText={error?.message}
                  inputRef={ref}
                  {...fieldProps}
                />
              )}
            />
          </FormControl>
          <FormControl
            fullWidth
            margin="normal"
          >
            <Controller
              control={control}
              name="phoneNumber"
              render={({
                field: { ref, ...fieldProps },
                fieldState: { error },
              }) => (
                <TextField
                  label="Phone No."
                  InputLabelProps={{ shrink: true }}
                  error={!!error?.message}
                  helperText={error?.message}
                  inputRef={ref}
                  {...fieldProps}
                />
              )}
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="secondary"
            disabled={updateProcessing}
          >
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default observer(ProfileForm)
