import React, { useCallback } from 'react'
import { observer } from 'mobx-react'
import makeStyles from '@mui/styles/makeStyles'
import {
  Button,
} from '@mui/material'

import Protected from 'admin/components/protected'
import InformationTab from 'admin/components/information-tab'
import { useStores } from 'admin/hooks'
import styles from './styles'

const useStyles = makeStyles(styles)

const EditDeleteMenu = observer(() => {
  const classes = useStyles()

  const {
    infoSectionStore,
    adminStore,
  } = useStores()

  const { selected: admin } = infoSectionStore

  const openUpdateAdminDialog = useCallback(() => {
    adminStore.setOpenDialog(admin, 'form')
  }, [admin])

  const openDeleteAdminDialog = useCallback(() => {
    adminStore.setOpenDialog(admin, 'delete')
  }, [admin])

  return (
    <div className={classes.buttonContainer}>
      <Protected
        level="update"
        category="admin"
      >
        <Button
          variant="contained"
          color="secondary"
          onClick={openUpdateAdminDialog}
          className={classes.detailButton}
        >
          Edit
        </Button>
      </Protected>
      <Protected
        level="delete"
        category="admin"
      >
        <Button
          variant="outlined"
          color="secondary"
          onClick={openDeleteAdminDialog}
          className={classes.detailButton}
        >
          Delete
        </Button>
      </Protected>
    </div>
  )
})

const Information = () => {
  const {
    infoSectionStore,
  } = useStores()

  const { selected: admin } = infoSectionStore

  return (
    <>
      <InformationTab
        data={[
          {
            label: 'Name',
            value: admin.name,
          },
          {
            label: 'Presto Id',
            value: admin.prestoId || '-',
          },
          {
            label: 'Phone Number',
            value: admin.phoneNumber,
          },
          {
            label: 'Outlet',
            value: admin.branches?.map((item) => item.code).join(', ') || '-',
          },
          {
            label: 'Role',
            value: admin.jobRole ? `${admin.jobRole.name} (${admin.role})` : admin.role,
          },
          {
            label: 'Status',
            value: admin.status,
          },
        ]}
      />
      <EditDeleteMenu />
    </>
  )
}

export default Information
