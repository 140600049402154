import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
} from 'react'
import moment from 'moment'
import { computed } from 'mobx'
import { observer } from 'mobx-react'

import {
  Typography,
  Stack,
  IconButton,
} from '@mui/material'
import {
  Edit as EditIcon,
} from '@mui/icons-material'

import makeStyles from '@mui/styles/makeStyles'

import { useStores } from 'admin/hooks'
import {
  withoutUnitNumber,
  validUnitNumber,
  formatDayName,
  formatDayOnly,
  dateOnlyFormat,
  TODAY_MOMENT,
  isBeforeToday,
  groupBy,
  validpostalCode,
  bookedListAWeekAgo,
  mergeList,
} from 'admin/utils/helper'
import { regexCodeArea } from 'admin/utils/regex'

import DriverType from 'admin/components/driver-type'
import { DRIVER_TYPE } from 'admin/config'
import BookingCard from './booking-card'
import Calendar from './calendar'
import styles from './styles'
import AddressDialog from './address'

const useStyles = makeStyles(styles)

const DriverAndSlots = observer(({
  driver,
  workday,
  codeArea,
  isPreferred = false,
  dialog,
}) => {
  const classes = useStyles({
    dialog,
    bgColor: driver.taggingColor,
  })

  const {
    slotStore,
    bookingStore,
  } = useStores()

  const driverSlots = useCallback((slotId) => {
    return driver.isOnHoliday || !workday ?
      {} : driver.slots?.find(driverSlot => driverSlot.id === slotId)
  }, [driver.isOnHoliday, driver.slots, workday])

  const booklists = useCallback((slotId) => {
    if (driver.isOnHoliday || !workday) return []
    function doesBelongToDriverAndSlot(value) {
      return value.driverId === driver.id && value.timeslotId === slotId
    }
    return bookingStore.list.filter(doesBelongToDriverAndSlot)
  }, [bookingStore.list, driver.id, driver.isOnHoliday, workday])

  const booklistsAWeekAgo = (slotId) => {
    return bookedListAWeekAgo(bookingStore.list, bookingStore.listAWeekAgo, driver.id, slotId)
  }

  const groupByDriver = groupBy(mergeList(bookingStore.listAWeekAgo, bookingStore.list), 'driverId')

  return (
    <div className={classes.wrapper}>
      <div
        className={classes.driverTitle}
      >
        {driver && (
          <DriverType
            driver={driver}
            label={driver.name}
            date={bookingStore.selected?.date}
            color="black"
            groupByDriver={groupByDriver}
            tag
            isPreferred={isPreferred}
          />
        )}
      </div>
      <div>
        {(slotStore.list && slotStore.list.length > 0) && slotStore.list.map((slot) => (
          <BookingCard
            key={slot.id}
            timeslot={slot}
            codeArea={codeArea}
            driver={driver}
            driverSlot={driverSlots(slot.id)}
            bookedList={booklists(slot.id)}
            bookedListAWeekAgo={booklistsAWeekAgo(slot.id)}
          />
        ))}
      </div>
    </div>
  )
})

const Schedule = ({ containerWidth }) => {
  const {
    adminStore,
    bookingStore,
    slotStore,
    driverStore,
    holidayStore,
    notificationStore,
  } = useStores()

  const { selected, rebookDialog } = bookingStore

  const classes = useStyles({ containerWidth, rebookDialog })

  const { isCounterStaff, isAdmin } = adminStore

  const [preferredDriver, setPreferredDriver] = useState(null)
  const [otherDriverData, setOtherDriverData] = useState([])
  const [totalDriver, setTotalDriver] = useState(0)
  const [day, setDay] = useState('')
  const [holiday, setHoliday] = useState({
    isOnHoliday: false,
    remarks: '',
  })
  const [address, setAddress] = useState(selected.address)
  // selected.address (booking address) must be the default
  const [postalCode, setPostalCode] = useState(validpostalCode(selected.address) || selected.customer?.postalCode)
  const [unitNumber, setUnitNumber] = useState(validUnitNumber(selected.address))

  // called when clicking Set button inside Addresss dialog
  // after react-hook-form form validation check
  const onSubmit = useCallback((data) => {
    // data.address from onemap api doesn't contain unit number
    // it only contains:
    // street number and name, building name, and name of town + postcode
    // reformat it to include unit number for booking address
    const bookingAddress = data.unitNumber ? `${data.address} #${data.unitNumber}` : data.address
    bookingStore.updateSelected('address', bookingAddress)
    setAddress(bookingAddress)

    setPostalCode(data.postalCode)
    setUnitNumber(data.unitNumber)
    bookingStore.setOpenConfirmDialog(false)
    notificationStore.setSuccess('Address has been updated successfully')
  }, [])

  const isSelectedDateOutOfRange = computed(() => {
    if (!bookingStore.selected) return false
    const given = moment(bookingStore.selected.date)
    return given.diff(TODAY_MOMENT, 'days') > 30
  }).get()

  const isDateBeforeToday = computed(() => {
    const given = moment(bookingStore.selected.date)
    return isBeforeToday(given)
  }).get()

  // return 2 digits code area
  const codeArea = useMemo(() => {
    if (postalCode) return postalCode.slice(0, 2)
    if (!address) return ''

    const result = regexCodeArea.exec(address)
    if (!result) return ''
    return result[0].slice(0, 2)
  }, [postalCode, address])

  const selectedSchedule = computed(() => {
    const date = formatDayName(bookingStore.selected?.date)
    return date
  }).get()

  const bookingCustomerInfo = [
    { title: 'Address', val: address || '-' },
    { title: 'Postal Code', val: postalCode || '-' },
  ]

  useEffect(() => {
    if (!bookingStore.selected || !bookingStore.selected.date) return
    async function fetchData() {
      try {
        bookingStore.setServiceLoad(true)
        const lowerCaseDay = formatDayOnly(bookingStore.selected.date).toLowerCase()
        setDay(lowerCaseDay)

        // only need to take the first response from holidayStore.searchHoliday
        const [res] = await Promise.all([
          holidayStore.searchHoliday({
            date: dateOnlyFormat(bookingStore.selected.date),
          }),
          slotStore.fetch(),
          driverStore.fetch({
            day: lowerCaseDay,
            limit: 1000,
            date: dateOnlyFormat(bookingStore.selected.date),
            status: 'active',
          }),
        ])

        setHoliday({
          isOnHoliday: !!res,
          remarks: res ? res.remarks : '',
        })
      } catch (error) {
        throw new Error(error)
      } finally {
        bookingStore.setServiceLoad(false)
      }
    }
    fetchData()
  }, [bookingStore.selected?.date])

  useEffect(() => {
    if (
      !driverStore.list ||
      driverStore.list.length === 0 ||
      !bookingStore.selected ||
      !bookingStore.selected.date
    ) return
    const { list } = driverStore
    const allDriverHolidayResult = list

    function isPreferred(driver) {
      if (isCounterStaff) return driver.type === DRIVER_TYPE.OUTLET && bookingStore.selected.customer?.driverId === driver.id
      return bookingStore.selected.customer?.driverId === driver.id
    }
    function isNonPreferred(driver) {
      if (isCounterStaff) return driver.type === DRIVER_TYPE.OUTLET && bookingStore.selected.customer?.driverId !== driver.id
      return bookingStore.selected.customer?.driverId !== driver.id
    }
    const foundPreferredDriver = allDriverHolidayResult.find(isPreferred)
    const filteredNonPreferredDriver = allDriverHolidayResult.filter(isNonPreferred)

    if (isCounterStaff) {
      setTotalDriver(filteredNonPreferredDriver.length + (foundPreferredDriver ? 1 : 0))
    } else {
      setTotalDriver(allDriverHolidayResult.length)
    }
    setPreferredDriver(foundPreferredDriver)
    // Other driver data is all drivers that is not preferred driver
    setOtherDriverData(filteredNonPreferredDriver)
  }, [driverStore.list, bookingStore.selected?.date, isCounterStaff])

  const renderNotAvailable = () => {
    let label = ''
    if (isDateBeforeToday) {
      label = 'Cannot select date before today'
    } else if (isSelectedDateOutOfRange) {
      label = 'Selected date exceeds booking date limit'
    } else if (holiday.isOnHoliday) {
      label = `On ${holiday.remarks} holiday/blocked day. Please select another date.`
    }
    return (
      <>
        <Typography className={classes.notAvailable}>
          Not Available
        </Typography>
        <Typography variant="h4">
          {label}
        </Typography>
      </>
    )
  }

  return (
    <div className={classes.container}>
      <div className={classes.upperContainer}>
        <Typography variant="h3">
          Select Schedule
        </Typography>
        <Stack
          direction="row"
          className={classes.customerInfo}
        >
          {bookingCustomerInfo.map((info, index) => (
            <Stack
              direction="row"
              key={info.title}
              className={classes.info}
            >
              <Typography
                variant="h4"
                className={classes.titleInfo}
              >
                {`${info.title} : ${info.val}`}
              </Typography>
              {isAdmin && index === 1 && (
                <IconButton
                  size="small"
                  color="secondary"
                  variant="contained"
                  className={classes.iconButton}
                  onClick={() => { bookingStore.setOpenConfirmDialog(true) }}
                >
                  <EditIcon size="small" className={classes.iconEdit} />
                </IconButton>
              )}
            </Stack>
          ))}
        </Stack>

        <Calendar />
      </div>

      <div className={classes.scheduleInfo}>
        <Typography
          variant="h4"
          className={classes.selected}
        >
          Selected Schedule: {selectedSchedule}
        </Typography>
      </div>

      <Stack direction="column">
        {totalDriver === 0 && (
          <Typography variant="caption">
            Please add {isCounterStaff && 'outlet'} driver accounts first in the driver page
          </Typography>
        )}
        {slotStore.list.length === 0 && (
          <Typography variant="caption">
            Please set slots first in the setting page
          </Typography>
        )}
      </Stack>
      <div className={classes.driverContainer}>
        {(isDateBeforeToday || isSelectedDateOutOfRange || holiday.isOnHoliday ? renderNotAvailable() : (
          <Stack direction="row">
            {/* preferred driver column */}
            {preferredDriver && (
              <DriverAndSlots
                key={preferredDriver.id}
                driver={preferredDriver}
                workday={(preferredDriver.workday && preferredDriver.workday[day.substring(0, 3)])}
                codeArea={codeArea}
                isPreferred
                dialog={rebookDialog}
              />
            )}
            {/* non preferred driver columns */}
            {(otherDriverData && otherDriverData.length > 0) && otherDriverData.map((driver) => {
              const workday = (driver.workday !== null) ? driver.workday[day.substring(0, 3)] : false
              return (
                <DriverAndSlots
                  key={driver.id}
                  driver={driver}
                  workday={workday}
                  codeArea={codeArea}
                />
              )
            })}
          </Stack>
        ))}
      </div>
      <AddressDialog
        // address state will contain unit number
        // remove unit number from address when showing at AddressDialog
        address={withoutUnitNumber(address)}
        postalCode={postalCode}
        onSubmit={onSubmit}
        unitNumber={unitNumber}
      />
    </div>
  )
}

export default observer(Schedule)
