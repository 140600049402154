export default (theme) => ({
  titleSection: ({ padding }) => {
    return {
      color: theme.palette.text.primary,
      padding: padding ? '1rem 1rem 1rem 0' : '1rem',
      listStyle: 'none',
    }
  },
  infoItem: {
    display: 'table-row',
  },
  label: {
    fontWeight: 600,
    display: 'table-cell',
    paddingRight: '1rem',
    lineHeight: 'inherit',
  },
  capitalize: {
    textTransform: 'capitalize',
  },
  textRed: {
    color: theme.color.redBerry,
  },
  textGreen: {
    color: theme.color.camarone,
  },
  textOrange: {
    color: theme.color.cinnabar,
  },
  textBrown: {
    color: theme.color.indochine,
  },
})
