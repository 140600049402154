import React from 'react'

import {
  Card,
  Typography,
  Stack,
  Icon,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import clx from 'clsx'

import styles from './styles'

const useStyles = makeStyles(styles)

const CardItem = ({
  icon,
  total,
  title,
  status,
  isTag,
  isDriver,
  driverName,
}) => {
  const classes = useStyles({ icon, status })

  return (
    <Card
      className={clx(classes.borderCard, {
        [classes.border]: !!icon || !!status,
      })}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        className={classes.item}
      >
        {icon && (
          <>
            <div>
              <Typography variant="h2">
                {total}
              </Typography>
              <Typography variant="h5">
                {title}
              </Typography>
            </div>
            <Icon className={classes.icon}>
              {icon}
            </Icon>
          </>
        )}
        {(status || isTag) && (
          <>
            <Typography
              variant="h4"
              mb={isTag && 2}
              mt={status && 2}
            >
              {title}
            </Typography>
            <div className={classes.totalPercent}>
              <Typography
                className={classes.total}
                mr={1}
              >
                {total}
              </Typography>
              <Typography
                className={classes.percent}
                mt={2}
              >
                %
              </Typography>
            </div>
          </>
        )}
        {isDriver && (
          <>
            <div>
              <Typography
                variant="h4"
                mb={3}
              >
                {title}
              </Typography>
              <Typography variant="h3">
                {driverName}
              </Typography>
            </div>
            <Typography
              className={classes.total}
              mr={1}
            >
              {total}
            </Typography>
          </>
        )}
      </Stack>
    </Card>
  )
}

export default (CardItem)
