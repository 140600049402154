import React from 'react'
import { observer } from 'mobx-react'
import makeStyles from '@mui/styles/makeStyles'

import { useStores } from 'admin/hooks'

import {
  Typography,
  Divider,
} from '@mui/material'

import { STATUS_TYPE } from 'admin/config/status-type'
import { PAYMENT_TYPE } from 'admin/config/payment-type'
import InfoSection from 'admin/components/info-section'
import HeadBar from 'admin/components/head-bar'
import { formatDateHourAmPm } from 'admin/utils/helper'

import InformationTab from 'admin/components/information-tab'

import styles from '../styles'

const useStyles = makeStyles(styles)

const Information = observer(() => {
  const { infoSectionStore } = useStores()
  const { selected } = infoSectionStore

  return (
    <InformationTab
      data={[
        {
          label: 'Droppoff Time',
          value: formatDateHourAmPm(selected.droppedOffTime),
        },
        {
          label: 'LastUpdated Time',
          value: formatDateHourAmPm(selected.lastUpdatedTime),
        },
        {
          label: 'Ready Time',
          value: formatDateHourAmPm(selected.readyTime),
        },
        {
          label: 'created at',
          value: formatDateHourAmPm(selected.createdTime),
        },
        {
          label: 'Customer Id',
          value: selected.customerId,
        },
        {
          label: 'Revenue',
          value: `$${selected?.revenue?.toFixed(2)}`,
        },
        {
          label: 'Amount Received',
          value: `$${selected?.amountReceived?.toFixed(2)}`,
        },
        {
          label: 'Items',
          value: selected.items,
        },
        {
          label: 'Sub Status Type Id',
          value: selected.subStatusTypeId,
        },
        {
          label: 'User Id',
          value: selected.userId,
        },
        {
          label: 'Remarks',
          value: selected.remarks,
        },
        {
          label: 'Is HomeCollection',
          value: selected?.isHomeCollection?.toString(),
        },
        {
          label: 'Is HomeDelivery',
          value: selected?.isHomeDelivery?.toString(),
        },
      ]}
    />
  )
})

const PosInvoiceInfo = () => {
  const classes = useStyles()
  const { infoSectionStore } = useStores()
  const { selected } = infoSectionStore

  return (
    <InfoSection
      title="Invoice"
    >
      <div className={classes.parentContainer}>
        {selected && (
          <>
            <HeadBar
              data={[
                {
                  label: 'ID',
                  value: selected.id,
                },
                {
                  label: 'Status',
                  value: selected.statusTypeId ?
                    STATUS_TYPE.find((status) => status.id === selected.statusTypeId)?.name : '-',
                },
                {
                  label: 'Payment Types',
                  value: selected.paymentTypeId ?
                    PAYMENT_TYPE.find((payment) => payment.id === selected.paymentTypeId)?.name : '-',
                },
              ]}
            />
            <Divider />
            <Typography
              variant="h4"
              className={classes.title}
            >
              Details
            </Typography>
            <div className={classes.infoContainer}>
              <Information />
            </div>
          </>
        )}
      </div>
    </InfoSection>
  )
}

export default observer(PosInvoiceInfo)
