import React, {
  useEffect,
} from 'react'
import { observer } from 'mobx-react'
import { useStores } from 'admin/hooks'

import InvoiceItemDataGrid from './datagrid'
import InvoiceItemInfo from '../info'

const Listing = () => {
  const {
    invoiceItemStore,
  } = useStores()

  useEffect(() => {
    invoiceItemStore.fetch()
  }, [invoiceItemStore.queryParams])

  return (
    <>
      <InvoiceItemDataGrid />
      <InvoiceItemInfo />
    </>
  )
}

export default observer(Listing)
