import React, {
  useCallback,
  useState,
} from 'react'
import { observer } from 'mobx-react'

import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  MenuItem,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

import CustomizedDialogTitle from 'admin/components/dialog-title'
import TextField from 'admin/components/textfield'
import { replaceToNumbers } from 'admin/utils/helper'

import { useStores } from 'admin/hooks'

import styles from './styles'

const useStyles = makeStyles(styles)

const Filter = () => {
  const classes = useStyles()
  const {
    bookingPaymentStore,
  } = useStores()

  const [status, setStatus] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')

  const handleClose = useCallback(() => {
    bookingPaymentStore.setOpenFilterDialog(false)
  }, [])

  const handleSubmit = useCallback(() => {
    bookingPaymentStore.updateQuery('phoneNumber', phoneNumber.trim(), true)
    bookingPaymentStore.updateQuery('status', status, true)

    bookingPaymentStore.setOpenFilterDialog(false)
  }, [
    status,
    phoneNumber,
  ])

  const handleReset = useCallback(() => {
    setPhoneNumber('')
    setStatus('')
  }, [])

  if (!bookingPaymentStore.dialog) return null

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      open={bookingPaymentStore.dialog}
      onClose={handleClose}
    >
      <CustomizedDialogTitle onReset={handleReset}>
        App Charge Filter
      </CustomizedDialogTitle>
      <DialogContent>
        <TextField
          name="phoneNumber"
          type="text"
          variant="outlined"
          size="small"
          fullWidth
          label="Phone Number"
          value={phoneNumber}
          onChange={(e) => {
            setPhoneNumber(replaceToNumbers(e.target.value))
          }}
          className={classes.textfield}
        />
        <TextField
          name="status"
          type="text"
          variant="outlined"
          size="small"
          fullWidth
          select
          label="Payment Status"
          value={status}
          onChange={(e) => {
            setStatus(e.target.value)
          }}
          className={classes.textfield}
        >
          <MenuItem value="">All</MenuItem>
          <MenuItem value="succeeded">Succeeded</MenuItem>
          <MenuItem value="failed">Failed</MenuItem>
        </TextField>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={handleClose}
          color="secondary"
        >
          Cancel
        </Button>
        <Button
          type="submit"
          variant="contained"
          onClick={handleSubmit}
          color="secondary"
        >
          Apply
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default observer(Filter)
