import React, { useCallback } from 'react'
import { observer } from 'mobx-react'
import { useStores, useApiRequest } from 'admin/hooks'
import * as srv from 'admin/services'
import { Typography } from '@mui/material'
import CustomDialog from 'admin/components/dialog'

const ReviewedDialog = () => {
  const { bookingStore, infoSectionStore, notificationStore } = useStores()
  const { request: updateBookingStatus, isLoading } = useApiRequest(
    srv.updateBookingFromSidemenu,
    { blocking: true },
  )

  const onConfirm = useCallback(async () => {
    const { selected: booking } = infoSectionStore

    if (booking.id === undefined) {
      notificationStore.setError('Booking id is undefined')
      return
    }

    if (isLoading) return
    const res = await updateBookingStatus(
      {
        id: booking.id,
      },
      {
        hasReviewed: !booking.hasReviewed,
      },
    )
    if (!res) return

    bookingStore.setCloseDialog('hasReviewed')
    notificationStore.setSuccess('Booking reviewed is updated')
    bookingStore.reload() // dont close the side menu
  }, [])

  const handleCloseDialog = useCallback(async () => {
    bookingStore.setCloseDialog('hasReviewed')
  }, [])

  return (
    <CustomDialog
      open={bookingStore.dialog.hasReviewed}
      fullWidth
      size="sm"
      primaryActionLabel="Confirm"
      content={(
        <Typography variant="h3">
          Mark as{' '}
          {infoSectionStore.selected.hasReviewed ? 'unreviewed' : 'reviewed'} ?
        </Typography>
      )}
      loading={isLoading}
      onAccept={onConfirm}
      onClose={handleCloseDialog}
    />
  )
}

export default observer(ReviewedDialog)
