export default (theme) => ({
  root: {
    margin: '0 !important',
  },
  slotDate: {
    textAlign: 'center',
    color: theme.color.paarl,
    border: `1px solid ${theme.color.paarl}`,
    minWidth: 105,
    maxHeight: 53,
    marginRight: '0.5rem',
    padding: '0.5rem',
    borderRadius: '4px',
    position: 'relative',
    cursor: 'pointer',
  },
  slotDateDisable: {
    borderColor: theme.color.lightGray,
    cursor: 'default',
  },
  slotDateSelected: {
    backgroundColor: theme.color.paarl,
  },
  slotDateSelectedDisable: {
    backgroundColor: theme.color.lightGray,
  },
  slotDayOnlyLabel: {
    opacity: 0.6,
    textAlign: 'center',
    fontSize: '1rem',
    fontWeight: 600,
  },
  slotMonthDateLabel: {
    opacity: 0.6,
    textAlign: 'center',
    fontSize: '0.75rem',
  },
  slotDateLabelDisable: {
    color: theme.color.lightGray,
  },
  slotDateLabelSelected: {
    opacity: 1,
    color: theme.color.white,
  },
  slotDateToday: ({ selected }) => {
    return {
      position: 'absolute',
      top: '4px',
      left: '50px',
      fontSize: '7px !important',
      color: selected ? theme.color.white : theme.color.coffee,
    }
  },
  notAvailable: {
    fontSize: '2rem',
    color: theme.color.alpine,
  },
  driverTitle: ({ bgColor }) => {
    return {
      padding: '1rem 0.5rem',
      backgroundColor: bgColor || theme.color.seashell,
      border: `thin solid ${bgColor === '#FFFFFF' ? 'black' : theme.color.gray}`,
      position: 'sticky',
      top: 40,
      zIndex: 10,
    }
  },
  wrapper: {
    marginRight: '1rem',
    position: 'relative',
  },
  calendar: {
    marginTop: '1rem',
    marginBottom: '2rem',
    display: 'flex',
    alignItems: 'center',
  },
  driverName: {
    marginLeft: '0.5rem',
    textTransform: 'capitalize',
  },
  container: {
    marginBottom: '1rem',
    overflow: 'auto',
    height: '100%',
  },
  customerInfo: {
    marginTop: '1rem',
    marginBottom: '1rem',
  },
  info: {
    marginRight: '4rem',
  },
  titleInfo: {
    marginBottom: '0.5rem',
  },
  schedule: {
    marginTop: '2rem',
    marginBottom: '2rem',
  },
  selected: {
    marginTop: '1rem',
    marginBottom: '1rem',
    color: theme.color.paarl,
  },
  driverContainer: ({ containerWidth }) => {
    return {
      width: containerWidth - 30,
      position: 'relative',
    }
  },
  scheduleInfo: ({ rebookDialog }) => {
    return {
      position: 'sticky',
      top: 0,
      zIndex: 10,
      backgroundColor: rebookDialog ? theme.color.white : theme.color.seashell,
      paddingBottom: 10,
      left: 0,
    }
  },
  upperContainer: {
    position: 'sticky',
    left: 0,
  },
  iconButton: {
    width: 20,
    marginLeft: '0.5rem',
    height: 20,
    borderRadius: 5,
    border: `1px solid ${theme.color.paarl}`,
  },
  iconEdit: {
    fontSize: '1rem',
    color: theme.color.paarl,
  },
  field: {
    marginBottom: '1rem',
  },
  textField: {
    width: '23rem',
  },
  errorMessage: {
    color: theme.color.red,
    marginTop: 0,
    marginBottom: '1rem',
  },
})
