import React, {
  useCallback, useState, useEffect,
} from 'react'
import { observer } from 'mobx-react'
import {
  Button,
  IconButton,
  Switch,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'

import DateRangeIcon from '@mui/icons-material/DateRange'

import DateRangePicker from 'admin/components/date-range-picker'

import { useStores } from 'admin/hooks'
import { DRIVER_HOLIDAY_DIALOG } from 'admin/config'

import { formatDate } from 'admin/utils/helper'

import styles from './styles'
import DriverHolidayDatagrid from './datagrid'
import DriverHolidayFormDialog from './form'

const useStyles = makeStyles(styles)

const Filter = observer(() => {
  const classes = useStyles()
  const { driverHolidayStore } = useStores()

  const [anchorEl, setAnchorEl] = useState(null)

  const [date, setDate] = useState({
    key: 'selection',
    startDate: driverHolidayStore.startDate,
    endDate: driverHolidayStore.endDate,
  })

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const openAddFormDialog = () => {
    driverHolidayStore.setActiveDialog(null, DRIVER_HOLIDAY_DIALOG.add)
  }

  const openDeleteFormDialog = () => {
    driverHolidayStore.setActiveDialog(null, DRIVER_HOLIDAY_DIALOG.delete)
  }

  const handleSelect = useCallback(value => {
    const { startDate, endDate } = value.selection
    setDate(prev => ({
      ...prev,
      startDate,
      endDate,
    }))
    driverHolidayStore.updateStartDate(startDate)
    driverHolidayStore.updateEndDate(endDate)
  }, [])

  return (
    <div
      className={classes.filterWrapper}
    >
      <Switch
        checked={driverHolidayStore.isDateRangeActive}
        onChange={(event) => {
          driverHolidayStore.activateDateRange(event.target.checked)
        }}
        style={{ flex: 1 }}
      />
      <div
        className={classes.datePicker}
      >
        <IconButton onClick={handleClick} size="large">
          <DateRangeIcon />
        </IconButton>
        {driverHolidayStore.isDateRangeActive ? (
          <span>
            {formatDate(date.startDate)} - {formatDate(date.endDate)}
          </span>
        ) : <span>All time</span>}
      </div>
      <DateRangePicker
        anchorEl={anchorEl}
        handleClose={handleClose}
        handleSelect={handleSelect}
        selectionRange={date}
      />
      <div
        className={classes.buttons}
      >
        <Button
          variant="contained"
          color="secondary"
          size="small"
          onClick={openAddFormDialog}
          className={clsx(classes.buttonAdd, {
            [classes.hideButtons]: !driverHolidayStore.isDateRangeActive,
          })}
        >
          Add
        </Button>
        <Button
          variant="outlined"
          color="secondary"
          size="small"
          onClick={openDeleteFormDialog}
          className={clsx({
            [classes.hideButtons]: !driverHolidayStore.list.length,
          })}
        >
          Delete
        </Button>
      </div>
    </div>
  )
})

const DriverHoliday = () => {
  const { infoSectionStore, driverHolidayStore } = useStores()

  const { selected: driver } = infoSectionStore

  useEffect(() => {
    if (driver.id) {
      const params = {
        id: driver.id,
      }
      driverHolidayStore.fetch(params)
    }
  }, [driver.id, driverHolidayStore.queryParams])

  useEffect(() => {
    return () => {
      driverHolidayStore.setCloseActiveDialog()
    }
  })

  if (!driver.id) return null

  return (
    <>
      <Filter />
      <DriverHolidayFormDialog />
      <DriverHolidayDatagrid />
    </>
  )
}

export default observer(DriverHoliday)
