import React from 'react'
import { observer } from 'mobx-react'

import makeStyles from '@mui/styles/makeStyles'
import {
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@mui/material'
import CancelIcon from '@mui/icons-material/Cancel'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { green, red } from '@mui/material/colors'

import { useStores } from 'admin/hooks'
import { BOOKING_PAYMENT_STATUS } from 'admin/config'
import { formatDateTimeSeconds } from 'admin/utils/helper'

import HeadBar from 'admin/components/head-bar'

import styles from './styles'

const useStyles = makeStyles(styles)

const AppInfo = () => {
  const classes = useStyles()
  const {
    infoSectionStore,
  } = useStores()

  const {
    selected: bookingPayment,
  } = infoSectionStore

  const createdAt = formatDateTimeSeconds(bookingPayment.createdAt)

  return (
    <List className={classes.listContainer}>
      <Typography
        className={classes.latestStatusText}
        variant="h6"
      >
        Latest Status:
      </Typography>
      <ListItem>
        {bookingPayment.status === BOOKING_PAYMENT_STATUS.SUCCEEDED && (
          <CheckCircleIcon sx={{ color: green[500] }} />
        )}
        {bookingPayment.status === BOOKING_PAYMENT_STATUS.FAILED && (
          <CancelIcon sx={{ color: red[500] }} />
        )}
        <ListItemText
          primary={bookingPayment.status || '-'}
          secondary={createdAt}
          className={classes.listItemTextPadding}
        />
      </ListItem>
      <HeadBar
        data={[
          {
            label: 'Amount',
            value: Number(bookingPayment.amount).toFixed(2),
          },
          {
            label: 'Fee',
            value: Number(bookingPayment.fee).toFixed(2),
          },
          {
            label: 'Net',
            value: Number(bookingPayment.net).toFixed(2),
          },
          {
            label: 'Invoices',
            value: bookingPayment.invoicePrestoIds,
          },
          {
            label: 'Fail reason',
            value: bookingPayment.declineCode,
          },
        ]}
      />
    </List>

  )
}

export default observer(AppInfo)
