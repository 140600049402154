import React, { useCallback, useState } from 'react'
import { observer } from 'mobx-react'
import { Button } from '@mui/material'

import makeStyles from '@mui/styles/makeStyles'

import { useStores } from 'admin/hooks'
import CustomizedTabs from 'admin/components/tabs'

import InfoSection from 'admin/components/info-section'
import HeadBar from 'admin/components/head-bar'
import Information from './information'
import Log from './log'
import InvoiceForm from './form'

import styles from './styles'

const useStyles = makeStyles(styles)

const EditMenu = observer(() => {
  const classes = useStyles()

  const {
    infoSectionStore,
    invoiceStore,
  } = useStores()

  const { selected: invoice } = infoSectionStore

  const openUpdateInvoiceDialog = useCallback(() => {
    invoiceStore.setOpenDialog(invoice, 'form')
  }, [invoice])

  return (
    <div className={classes.leftMargin}>
      <Button
        variant="contained"
        color="secondary"
        size="small"
        onClick={openUpdateInvoiceDialog}
        className={classes.editButton}
      >
        Edit
      </Button>
    </div>
  )
})

const InvoiceInfo = () => {
  const { infoSectionStore } = useStores()
  const [activeTab, setActiveTab] = useState(0)
  const classes = useStyles()

  const { selected: invoice } = infoSectionStore
  const handleChangeTab = useCallback((event, value) => {
    setActiveTab(value)
  }, [])

  return (
    <InfoSection
      title="Invoice"
    >
      <div className={classes.parentContainer}>
        {invoice && (
          <>
            <div style={{ display: 'flex' }}>
              <div>
                <HeadBar
                  data={[
                    {
                      label: 'Invoice',
                      value: invoice.prestoId,
                    },
                    {
                      label: 'Payment Status',
                      value: invoice.paymentStatus,
                      capitalize: true,
                      textRed: invoice.paymentStatus === 'unpaid',
                      textBrown: invoice.paymentStatus === 'paid',
                      textOrange: invoice.paymentStatus === 'partial',
                      textGreen: invoice.paymentStatus === 'received',
                    },
                  ]}
                />
              </div>
              <EditMenu />
            </div>
            <CustomizedTabs
              activeTab={activeTab}
              handleChangeTab={handleChangeTab}
              labels={[
                'Information',
                'Log',
              ]}
            />
            <div className={classes.infoContainer}>
              {activeTab === 0 && <Information key={`info-${invoice.id}`} />}
              {activeTab === 1 && <Log key={`permission-${invoice.id}`} />}
            </div>
          </>
        )}
      </div>
      <InvoiceForm />
    </InfoSection>
  )
}

export default observer(InvoiceInfo)
