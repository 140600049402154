import React, {
  useState,
  useCallback,
  useEffect,
} from 'react'
import { useHistory } from 'react-router-dom'
import { observer } from 'mobx-react'
import makeStyles from '@mui/styles/makeStyles'
import {
  Tabs,
  Tab,
  Box,
  Button,
} from '@mui/material'
import { useStores, useApiRequest } from 'admin/hooks'
import * as srv from 'admin/services'

import Dialog from 'admin/components/dialog'
import Page from 'admin/components/page'

import styles from './styles'
import Listing from './listing'
import Timesheet from './timesheet'

const useStyles = makeStyles(styles)

const DeleteDialog = observer(() => {
  const {
    bookingStore,
    notificationStore,
  } = useStores()
  const {
    request: deleteBooking,
  } = useApiRequest(srv.deleteBooking, { blocking: true })

  const onDelete = useCallback(async () => {
    if (
      !bookingStore.selected ||
      !bookingStore.selected.id
    ) return
    const response = await deleteBooking(bookingStore.selected.id)
    if (!response) return
    notificationStore.setSuccess('Deleted')
    bookingStore.setCloseDialog('delete')
    bookingStore.reload(true) // close the drawer
  }, [bookingStore.selected])

  return (
    <Dialog
      open={bookingStore.dialog.delete}
      title="Do you want to delete this booking?"
      content="Booking data that deleted cannot be recovered. Make sure you know what you are doing."
      primaryActionLabel="Delete"
      onAccept={onDelete}
      onClose={() => bookingStore.setCloseDialog('delete')}
    />
  )
})

const ImageDialog = observer(() => {
  const classes = useStyles()
  const { bookingStore } = useStores()

  return (
    <Dialog
      size="lg"
      open={bookingStore.imageDialog}
      title="Proof of Delivery Image"
      content={(
        <img
          src={bookingStore.selectedImage}
          alt="Proof of delivery"
          className={classes.imgDialog}
        />
      )}
      noButton
      onClose={() => bookingStore.setImageDialog(false)}
    />
  )
})

const ListingAndTimesheet = () => {
  const classes = useStyles()
  const route = useHistory()
  const { bookingStore } = useStores()
  const [activeTab, setActiveTab] = useState(0)

  const handleChangeTab = useCallback((event, value) => {
    setActiveTab(value)
  }, [])

  const createBooking = () => {
    if (bookingStore.selected) {
      bookingStore.setSelected(null)
    }
    bookingStore.updateActiveStep(0)
    route.push('/booking')
  }

  useEffect(() => {
    bookingStore.setActiveTab(activeTab)
    return () => {
      bookingStore.setActiveTab(null)
    }
  }, [activeTab])

  return (
    <Page lockScroll>
      <Box sx={{
        borderBottom: 1,
        borderColor: 'divider',
        display: 'flex',
        alignItems: 'center',
      }}
      >
        <Tabs
          value={activeTab}
          onChange={handleChangeTab}
          textColor="secondary"
          indicatorColor="secondary"
        >
          {['Timesheet',
            'Listing',
          ].map((day) => (
            <Tab
              key={day}
              label={day}
              classes={{
                root: classes.tab,
              }}
            />
          ))}
        </Tabs>
        <Button
          variant="contained"
          color="secondary"
          size="small"
          onClick={createBooking}
        >
          Create Booking
        </Button>
      </Box>
      {activeTab === 0 && <Timesheet />}
      {activeTab === 1 && <Listing />}
      <DeleteDialog />
      <ImageDialog />
    </Page>
  )
}

export default observer(ListingAndTimesheet)
