import React, { useCallback, useState } from 'react'
import { observer } from 'mobx-react'
import {
  Paper,
  Stack,
  Box,
  Button,
  FormControl,
  InputAdornment,
  Typography,
} from '@mui/material'
import { useForm, Controller } from 'react-hook-form'
import makeStyles from '@mui/styles/makeStyles'

import { useStores, useRequest, useApiRequest } from 'admin/hooks'
import * as srv from 'admin/services'

import CustomizedAutocomplete from 'admin/components/autocomplete'
import Page from 'admin/components/page'
import TextField from 'admin/components/textfield'

import styles from './styles'

const useStyles = makeStyles(styles)

const ManualCharge = () => {
  const classes = useStyles()
  const { manualChargeStore, notificationStore } = useStores()
  const [customerOptions, setCustomerOptions] = useState([])

  const {
    request: fetchCustomer,
    loading,
  } = useRequest(srv.fetchCustomer, {
    concurrent: true,
    initialData: { list: [], total: 0 },
    transformData: (data) => {
      if (data && data.list.length > 0) {
        setCustomerOptions(data.list.map(d => ({
          key: d.id,
          label: `${d.phoneNumber}   ${d.name}`,
          id: d.id,
          phoneNumber: d.phoneNumber,
          name: d.name,
          paymentProfileId: d.paymentProfile[0].id,
          stripeCustomerId: d.stripeId,
        })))
      }
    },
  })

  const {
    request: chargeCCManualCustomer,
    isLoading: chargeProcessing,
  } = useApiRequest(srv.chargeCCManualCustomer, { blocking: true })

  const { control, handleSubmit: handleSubmitForm, reset } = useForm({
    defaultValues: { ...manualChargeStore.DEFAULT },
  })

  const handleOpenSearch = useCallback(() => {
    fetchCustomer({
      limit: 5,
      isStripeCustomer: true,
    })
  }, [])

  const handleSearchInputChange = useCallback((event, newValue) => {
    if (!newValue) {
      setCustomerOptions([])
      return
    }
    setTimeout(() => {
      fetchCustomer({
        phoneNumber: newValue,
        isStripeCustomer: true,
        limit: 5,
      })
    }, 500)
  }, [])

  const handleSubmit = async (value) => {
    if (chargeProcessing) return
    if (
      !value.phoneNumber ||
      !value.invoiceId ||
      value.amount <= 0 ||
      !value.description
    ) {
      notificationStore.setError('Please enter all data for manual charge')
      return
    }
    try {
      const response = await chargeCCManualCustomer({
        stripeCustomerId: value.phoneNumber.stripeCustomerId,
        paymentProfileId: value.phoneNumber.paymentProfileId,
        prestoId: value.invoiceId,
        amount: value.amount,
        description: value.description,
        stripeChargeId: value.stripeChargeId,
      })
      if (!response) return

      reset({ ...manualChargeStore.DEFAULT })
      notificationStore.setSuccess('Manual charge successfully')
    } catch (err) {
      notificationStore.setError(err.message)
    }
  }

  const handleCancel = useCallback(() => {
    const {
      phoneNumber,
      amount,
    } = manualChargeStore.DEFAULT
    reset({
      phoneNumber,
      amount,
    })
  }, [])

  return (
    <Page>
      <Paper sx={{ maxWidth: 500 }}>
        <Stack sx={{ padding: '1rem' }}>
          <Typography>
            Manual charge
          </Typography>
          <form
            noValidate
            onSubmit={handleSubmitForm(handleSubmit)}
            autoComplete="off"
          >
            <Box>
              <FormControl
                fullWidth
                margin="normal"
              >
                <Controller
                  control={control}
                  name="phoneNumber"
                  render={({
                    field: { onChange, value, ref, ...fieldProps },
                    fieldState: { error },
                  }) => (
                    <CustomizedAutocomplete
                      label="Phone Number Customer"
                      value={value}
                      handleOpen={handleOpenSearch}
                      handleInputChange={handleSearchInputChange}
                      handleChange={(e, newValue) => {
                        onChange(newValue)
                      }}
                      options={customerOptions}
                      loading={loading}
                      classesAutocomplete={classes.autocomplete}
                      renderOption={(option, state) => (
                        <div
                          {...option}
                          className={classes.upperSection}
                        >
                          <div className={classes.optionPhoneNumber}>
                            { state.phoneNumber }
                          </div>
                          <div className={classes.optionCustomer}>
                            {state.name }
                          </div>
                        </div>
                      )}
                      errorMessage={!!error?.message}
                      helperTextTextField={error?.message}
                      inputRefTextField={ref}
                      {...fieldProps}
                    />
                  )}
                />
              </FormControl>
              <FormControl
                fullWidth
                margin="normal"
              >
                <Controller
                  control={control}
                  name="invoiceId"
                  render={({
                    field: { ref, onChange, ...fieldProps },
                    fieldState: { error },
                  }) => (
                    <TextField
                      label="Invoice POS ID"
                      InputLabelProps={{ shrink: true }}
                      error={!!error?.message}
                      type="text"
                      helperText={error?.message}
                      inputRef={ref}
                      onChange={(e) => {
                        onChange(e.target.value)
                      }}
                      {...fieldProps}
                    />
                  )}
                />
              </FormControl>
              <FormControl
                fullWidth
                margin="normal"
              >
                <Controller
                  control={control}
                  name="amount"
                  render={({
                    field: { ref, onChange, ...fieldProps },
                    fieldState: { error },
                  }) => (
                    <TextField
                      label="Charge Amount"
                      InputLabelProps={{ shrink: true }}
                      error={!!error?.message}
                      type="number"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            $
                          </InputAdornment>
                        ),
                      }}
                      helperText={error?.message}
                      inputRef={ref}
                      onChange={(e) => {
                        onChange(e.target.value)
                      }}
                      {...fieldProps}
                    />
                  )}
                />
              </FormControl>
              <FormControl
                fullWidth
                margin="normal"
              >
                <Controller
                  control={control}
                  name="description"
                  render={({
                    field: { ref, onChange, ...fieldProps },
                    fieldState: { error },
                  }) => (
                    <TextField
                      label="Description"
                      InputLabelProps={{ shrink: true }}
                      error={!!error?.message}
                      type="text"
                      helperText={error?.message}
                      inputRef={ref}
                      onChange={(e) => {
                        onChange(e.target.value)
                      }}
                      {...fieldProps}
                    />
                  )}
                />
              </FormControl>
              <FormControl
                fullWidth
                margin="normal"
              >
                <Controller
                  control={control}
                  name="stripeChargeId"
                  render={({
                    field: { ref, onChange, ...fieldProps },
                    fieldState: { error },
                  }) => (
                    <TextField
                      label="Stripe Charge ID (optional)"
                      InputLabelProps={{ shrink: true }}
                      error={!!error?.message}
                      type="text"
                      helperText={error?.message}
                      inputRef={ref}
                      onChange={(e) => {
                        onChange(e.target.value)
                      }}
                      {...fieldProps}
                    />
                  )}
                />
              </FormControl>
            </Box>
            <Box sx={{ marginTop: '1rem' }}>
              <Button
                variant="outlined"
                color="primary"
                style={{ marginRight: '1rem' }}
                onClick={handleCancel}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={chargeProcessing}
              >
                {chargeProcessing ? 'Charging..' : 'Charge'}
              </Button>
            </Box>
          </form>
        </Stack>
      </Paper>
    </Page>
  )
}

export default observer(ManualCharge)
