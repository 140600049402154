import React, {
  useCallback,
  useState,
  useEffect,
  useRef,
} from 'react'
import { observer } from 'mobx-react'
import moment from 'moment'
import { DatePicker } from '@mui/x-date-pickers'
import makeStyles from '@mui/styles/makeStyles'
import {
  Dialog,
  Button,
  DialogContent,
  Grid,
  Tooltip,
  TextField as MuiTextField,
  MenuItem,
} from '@mui/material'

import AddIcon from '@mui/icons-material/Add'
import CloseIcon from '@mui/icons-material/Close'
import DoneIcon from '@mui/icons-material/Done'
import DeleteIcon from '@mui/icons-material/Delete'

import Protected from 'admin/components/protected'
import CustomizedDialogTitle from 'admin/components/dialog-title'
import TextField from 'admin/components/textfield'

import { useStores, useApiRequest } from 'admin/hooks'
import * as srv from 'admin/services'
import { defaultDate, minDate, maxDate } from 'admin/utils/helper'

import styles from '../styles'

const useStyles = makeStyles(styles)

const HolidayItemForm = (props) => {
  const classes = useStyles()
  const { holidayStore, notificationStore } = useStores()
  const [isEdit, setIsEdit] = useState(false)
  const [holiday, setHoliday] = useState({
    date: null,
    remarks: '',
    type: 'holiday',
  })
  const remarkRef = useRef()

  const {
    request: addHoliday,
    isLoading: addProcessing,
  } = useApiRequest(srv.addHoliday, { blocking: true })
  const {
    request: updateHoliday,
    isLoading: updateProcessing,
  } = useApiRequest(srv.updateHoliday, { blocking: true })
  const {
    request: deleteHoliday,
    isLoading: deleteProcessing,
  } = useApiRequest(srv.deleteHoliday, { blocking: true })

  const handleChange = useCallback((key, value) => {
    setHoliday({
      ...holiday,
      [key]: value,
    })
  }, [holiday])

  const resetHoliday = useCallback(() => {
    setHoliday({
      date: null,
      remarks: '',
      type: 'holiday',
    })
  }, [])

  useEffect(() => {
    if (!props.item) return
    setHoliday(props.item)
    setIsEdit(!!props.item.id)
  }, [props.item])

  const handleSubmit = useCallback(async (event) => {
    event.preventDefault()
    if (addProcessing || updateProcessing) return

    const request = (isEdit) ? updateHoliday : addHoliday
    const response = await request({ ...holiday, date: moment(holiday.date).format('YYYY-MM-DD') })
    if (!response) return

    await holidayStore.reload({ type: 'all' }, true)
    if (isEdit) {
      notificationStore.setSuccess('Update holiday or blocked day success')
    } else {
      notificationStore.setSuccess('Save holiday or blocked day success')
    }

    remarkRef?.current?.blur()

    holidayStore.setCloseDialog('form')
  }, [holiday])

  const onDelete = useCallback(async () => {
    if (deleteProcessing) return
    await deleteHoliday(holiday)
    notificationStore.setSuccess('Delete holiday or blocked day success')
    holidayStore.reload({ type: 'all' }, true)
    holidayStore.setCloseDialog('form')
  }, [holiday])

  return (
    <form
      noValidate
      onSubmit={handleSubmit}
      autoComplete="off"
    >
      <Grid
        container
        direction="row"
        alignItems="center"
        spacing={2}
        className={classes.grid}
      >
        <Grid item md={3}>
          <DatePicker
            label="Date"
            value={holiday.date}
            onChange={(newValue) => {
              handleChange('date', newValue)
            }}
            defaultCalendarMonth={defaultDate(holidayStore.query.year)}
            minDate={minDate(holidayStore.query.year)}
            maxDate={maxDate(holidayStore.query.year)}
            renderInput={(params) => (
              <MuiTextField
                {...params}
                size="small"
                helperText={null}
              />
            )}
            mask="__/__/____"
            inputFormat="dd/MM/yyyy"
          />
        </Grid>
        <Grid item md={5}>
          <TextField
            fullWidth
            label="Remarks"
            name="remarks"
            color="secondary"
            size="small"
            inputRef={remarkRef}
            value={holiday.remarks}
            onChange={(event) => {
              handleChange(event.target.name, event.target.value)
            }}
          />
        </Grid>
        <Grid item md={2}>
          <TextField
            name="type"
            type="text"
            variant="outlined"
            size="small"
            fullWidth
            select
            label="Type"
            value={holiday.type}
            onChange={(e) => {
              handleChange(e.target.name, e.target.value)
            }}
            className={classes.textfield}
          >
            <MenuItem value="holiday">Holiday</MenuItem>
            <MenuItem value="blocked_day">Blocked</MenuItem>
          </TextField>
        </Grid>
        <Grid
          item
          xs={1}
        >
          <Tooltip title="Save">
            <Button
              type="submit"
              variant="outlined"
              color="secondary"
              className={classes.button}
              disabled={addProcessing || updateProcessing}
            >
              {isEdit ? <DoneIcon /> : <AddIcon />}
            </Button>
          </Tooltip>
        </Grid>
        <Grid
          xs={1}
          item
        >
          {isEdit ? (
            <Protected
              level="delete"
              category="holiday"
            >
              <Tooltip title="Delete">
                <Button
                  variant="outlined"
                  color="secondary"
                  className={classes.button}
                  onClick={onDelete}
                  disabled={deleteProcessing}
                >
                  <DeleteIcon />
                </Button>
              </Tooltip>
            </Protected>
          ) : (
            <Tooltip title="Cancel">
              <Button
                variant="outlined"
                color="secondary"
                className={classes.button}
                onClick={resetHoliday}
              >
                <CloseIcon />
              </Button>
            </Tooltip>
          )}
        </Grid>
      </Grid>
    </form>
  )
}

const HolidayForm = () => {
  const classes = useStyles()
  const { holidayStore } = useStores()

  const { selected: holiday } = holidayStore

  const handleClose = useCallback(() => {
    holidayStore.setCloseDialog('form')
  }, [])

  return (
    <Dialog
      open={holidayStore.dialog.form}
      onClose={handleClose}
      maxWidth="sm"
      classes={{
        paper: classes.dialogHoliday,
      }}
      fullWidth
    >
      <CustomizedDialogTitle onClose={handleClose}>
        {`Holidays / Blocked Days Setting ${holidayStore.query.year}`}
      </CustomizedDialogTitle>
      <DialogContent className={classes.dialogContent}>
        <HolidayItemForm item={holiday} />
      </DialogContent>
    </Dialog>
  )
}

export default observer(HolidayForm)
