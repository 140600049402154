import React, { useState, useCallback } from 'react'
import { observer } from 'mobx-react'
import makeStyles from '@mui/styles/makeStyles'
import {
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  FormControl,
} from '@mui/material'

import { useStores, useApiRequest } from 'admin/hooks'
import SimpleTableHead from 'admin/components/table-head'
import TextField from 'admin/components/textfield'

import { replaceToNumbers } from 'admin/utils/helper'

import * as srv from 'admin/services'

import BookingTable from './booking-table'
import styles from './styles'

const useStyles = makeStyles(styles)

const headCell = [
  'Name',
  'Address',
  'Phone Number',
  'Alternate Phone Number',
  'Preferred Driver',
  '',
]

const headCellBooking = [
  'Date',
  'Services',
  'Driver',
  'Booking Status',
  'Action',
]

const CustomerStep = ({ actions }) => {
  const {
    customerStore,
    bookingStore,
  } = useStores()
  const classes = useStyles()
  const [phoneNumber, setPhoneNumber] = useState(bookingStore.customerSearchResult?.phoneNumber || '')
  const [error, seterror] = useState({
    message: '',
    status: false,
  })

  const {
    request: fetchBooking,
    error: fetchBookingError,
    data: bookingData,
  } = useApiRequest(srv.fetchBooking, { blocking: false })

  const handleSearch = useCallback(async (event) => {
    event.preventDefault()

    const result = await customerStore.fetchOne({
      phoneNumber: phoneNumber.trim(),
    })

    if (result?.message) {
      seterror({
        message: result.message,
        status: true,
      })
      bookingStore.setSelected(null)
      return
    }
    if (!result) {
      seterror({
        message: 'Customer account does not exist',
        status: true,
      })
      bookingStore.setSelected(null)
      return
    }
    if (!result?.address) {
      seterror({
        message: 'Please add address for this customer in POS before continue.',
        status: true,
      })
      bookingStore.setSelected(null)
      return
    }
    seterror({
      message: '',
      status: false,
    })

    // 10 latest bookings
    await fetchBooking({
      customerId: result.id,
      limit: 10,
      offset: 0,
      sortBy: 'createdAt',
      sortDirection: 'desc',
    })

    bookingStore.setCustomerSearchResult(result)
  }, [phoneNumber])

  const hasCustomer = !!bookingStore.customerSearchResult &&
    !!bookingStore.customerSearchResult.id

  return (
    <div>
      <Typography variant="h3">
        Select Customers
      </Typography>
      <form
        noValidate
        onSubmit={handleSearch}
        autoComplete="off"
        className={classes.searchWrapper}
      >
        <FormControl
          fullWidth
        >
          <TextField
            autoFocus
            size="small"
            label="Customer Phone Number"
            color="secondary"
            fullWidth
            value={phoneNumber}
            onChange={(e) => {
              setPhoneNumber(replaceToNumbers(e.target.value))
            }}
            error={error.status}
            helperText={error.status ? error.message : ''}
          />
        </FormControl>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleSearch}
          disabled={customerStore.loading}
          className={classes.searchButton}
        >
          Search
        </Button>
      </form>
      {!error.status && hasCustomer && (
        <TableContainer
          component={Paper}
          className={classes.tableContainer}
        >
          <Table
            className={classes.table}
            aria-label="customer table"
          >
            <SimpleTableHead
              cell={headCell}
            />
            <TableBody>
              <TableRow
                key={bookingStore.customerSearchResult.id}
                classes={{ root: classes.tableRow }}
              >
                <TableCell
                  component="th"
                  scope="row"
                  classes={{
                    body: classes.body,
                  }}
                >
                  {bookingStore.customerSearchResult.name}
                </TableCell>
                <TableCell classes={{ body: classes.body }}>
                  {bookingStore.customerSearchResult.address}
                </TableCell>
                <TableCell classes={{ body: classes.body }}>
                  {bookingStore.customerSearchResult.phoneNumber}
                </TableCell>
                <TableCell classes={{ body: classes.body }}>
                  {bookingStore.customerSearchResult.originalData?.alternatePhoneNumber || '-'}
                </TableCell>
                <TableCell classes={{ body: classes.body }}>
                  {bookingStore.customerSearchResult.driver?.code || '-'}
                </TableCell>
                <TableCell>
                  {actions}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {fetchBookingError && (
        <Typography variant="h6">
          Fetch Booking Failed
        </Typography>
      )}
      {(
        !error.status &&
        hasCustomer &&
        !fetchBookingError &&
        bookingData &&
        Array.isArray(bookingData.list) &&
        bookingData.list.length !== 0) && (
        <TableContainer
          component={Paper}
          className={classes.tableContainer}
        >
          <Table
            className={classes.table}
            aria-label="booking table"
            stickyHeader
          >
            <SimpleTableHead
              cell={headCellBooking}
              overrideHead={classes.headBooking}
            />
            <TableBody>
              {bookingData.list.map((row) => (
                <BookingTable
                  key={row.id}
                  row={row}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  )
}

export default observer(CustomerStep)
