import React, { useState, useEffect, useCallback } from 'react'
import { observer } from 'mobx-react'

import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  FormControl,
} from '@mui/material'

import makeStyles from '@mui/styles/makeStyles'

import { useStores, useApiRequest } from 'admin/hooks'
import * as srv from 'admin/services'

import CustomizedAutocomplete from 'admin/components/autocomplete'
import CustomizedDialogTitle from 'admin/components/dialog-title'
import { PAYMENT_TYPE } from 'admin/config/payment-type'

import styles from './styles'

const useStyles = makeStyles(styles)

const InvoiceForm = () => {
  const classes = useStyles()
  const {
    notificationStore,
    invoiceStore,
  } = useStores()

  const { selected } = invoiceStore

  const [paymentOptions, setPaymentOptions] = useState([])
  const [paymentMethod, setPaymentMethod] = useState(null)

  const {
    request: updateInvoicePaymentMethod,
    isLoading: updateProcessing,
  } = useApiRequest(srv.updateInvoicePaymentMethod, { blocking: true })

  useEffect(() => {
    setPaymentOptions(PAYMENT_TYPE.map(item => ({
      key: item.id,
      label: item.name,
    })))
  }, [])

  const handleChangeSearchPayment = useCallback((_, newValue) => {
    setPaymentMethod(newValue)
  }, [])

  const handleClose = useCallback(() => {
    if (updateProcessing) return

    const selectedPayment = paymentOptions.find(x => x.label === selected.paymentMethod)
    setPaymentMethod(selectedPayment)
    invoiceStore.setCloseDialog('form')
  }, [paymentOptions, selected])

  const handleSubmit = useCallback(async (event) => {
    event.preventDefault()
    const res = await updateInvoicePaymentMethod(selected.id, paymentMethod?.label)
    if (!res) return

    await invoiceStore.reload()
    notificationStore.setSuccess('Payment method updated successfully')

    invoiceStore.setCloseDialog('form')
  }, [selected, paymentMethod])

  useEffect(() => {
    if (selected && paymentOptions) {
      const selectedPayment = paymentOptions.find(x => x.label === selected.paymentMethod)
      setPaymentMethod(selectedPayment || null)
    }
  }, [selected, paymentOptions])

  return (
    <Dialog
      open={invoiceStore.dialog.form}
      onClose={handleClose}
      aria-labelledby="Invoice Form"
      fullWidth
      maxWidth="xs"
    >
      <CustomizedDialogTitle onClose={handleClose}>
        Update Payment Method
      </CustomizedDialogTitle>
      <form
        noValidate
        onSubmit={handleSubmit}
        autoComplete="off"
      >
        <DialogContent className={classes.dialogContent}>
          <FormControl fullWidth margin="normal">
            <CustomizedAutocomplete
              label=""
              size="small"
              value={paymentMethod}
              handleChange={handleChangeSearchPayment}
              options={paymentOptions}
              className={classes.autocomplete}
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="secondary"
            disabled={updateProcessing}
          >
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default observer(InvoiceForm)
