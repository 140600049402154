export default () => ({
  link: {
    textDecoration: 'underline',
    padding: '0px',
    justifyContent: 'flex-start',
    fontWeight: 300,
  },
  subInfo: {
    margin: '1rem',
    minWidth: '8rem',
    flex: 1,
    flexBasis: '30%',
  },
  mainLabel: {
    margin: '1rem 0',
    minWidth: '10rem',
  },
  flex: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  icon: {
    marginRight: '0.3rem',
  },
  formControl: {
    width: '100%',
    maxWidth: 400,
  },
  selectedChannel: {
    marginTop: '1rem',
    marginBottom: '1rem',
  },
})
