import React, { useEffect } from 'react'
import { observer } from 'mobx-react'
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableContainer,
  Typography,
  Card,
  Stack,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

import { useStores } from 'admin/hooks'

import styles from './styles'

const useStyles = makeStyles(styles)

const AveragePickUp = ({ rangeDate }) => {
  const classes = useStyles()
  const { bookingStore } = useStores()
  const { averageBooking } = bookingStore

  useEffect(() => {
    bookingStore.fetchCountAverage({
      startDate: rangeDate.startDate,
      endDate: rangeDate.endDate,
    })
  }, [rangeDate])

  return (
    <Card className={classes.card}>
      <Typography
        variant="h4"
        ml={2}
        mt={2}
        mb={1}
      >
        Average No of Pick Up
      </Typography>
      <TableContainer className={classes.cardContent}>
        {averageBooking.length > 0 ? (
          <Table aria-label="average pick up table">
            <TableBody>
              {averageBooking.map((row) => (
                <TableRow
                  key={row.id}
                >
                  <TableCell
                    size="small"
                  >
                    <Typography
                      variant="body1"
                    >
                      {row.name}
                    </Typography>
                  </TableCell>
                  <TableCell
                    size="small"
                    align="right"
                  >
                    <Typography
                      variant="h4"
                    >
                      {row.total}
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : (
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            className={classes.text}
          >
            <Typography>
              No Rows
            </Typography>
          </Stack>
        )}
      </TableContainer>
    </Card>
  )
}

export default observer(AveragePickUp)
