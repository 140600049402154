import React, { useState, useEffect, useCallback } from 'react'
import { observer } from 'mobx-react'
import {
  Dialog,
  Button,
  DialogContent,
  Grid,
  Tooltip,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

import AddIcon from '@mui/icons-material/Add'
import CloseIcon from '@mui/icons-material/Close'
import DoneIcon from '@mui/icons-material/Done'
import DeleteIcon from '@mui/icons-material/Delete'

import Protected from 'admin/components/protected'
import CustomizedDialogTitle from 'admin/components/dialog-title'
import TextField from 'admin/components/textfield'

import { useStores, useApiRequest } from 'admin/hooks'
import * as srv from 'admin/services'

import styles from '../styles'

const useStyles = makeStyles(styles)

const ChannelItemForm = observer((props) => {
  const classes = useStyles()
  const { channelStore, notificationStore } = useStores()

  const [isEdit, setIsEdit] = useState(false)
  const [channel, setChannel] = useState({ name: '' })

  const {
    request: addChannel,
    isLoading: addProcessing,
  } = useApiRequest(srv.addChannel, { blocking: true })
  const {
    request: updateChannel,
    isLoading: updateProcessing,
  } = useApiRequest(srv.updateChannel, { blocking: true })

  const handleChange = useCallback((event) => {
    setChannel({
      ...channel,
      [event.target.name]: event.target.value,
    })
  }, [channel])

  useEffect(() => {
    if (!props.item) return
    setChannel(props.item)
    setIsEdit(!!props.item.id)
  }, [props.item])

  const handleSubmit = useCallback(async (event) => {
    event.preventDefault()
    if (addProcessing || updateProcessing) return

    const request = (isEdit) ? updateChannel : addChannel
    const response = await request(channel)
    if (!response) return

    if (isEdit) {
      notificationStore.setSuccess('Update channel success')
    } else {
      setChannel({ name: '' }) // reset
      notificationStore.setSuccess('Save channel success')
    }

    channelStore.reload(true)
  }, [channel])

  const onDelete = useCallback(async (item) => {
    channelStore.setDeletedDialog(item)
  }, [])

  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      spacing={2}
      className={classes.grid}
    >
      <Grid
        sm={8}
        item
      >
        <TextField
          fullWidth
          name="name"
          placeholder="Enter a unique channel name"
          color="secondary"
          value={channel.name}
          onChange={handleChange}
          size="small"
        />
      </Grid>
      <Grid
        sm={1}
        item
      >
        <Tooltip title="Save">
          <Button
            variant="outlined"
            color="secondary"
            className={classes.button}
            onClick={handleSubmit}
            disabled={addProcessing || updateProcessing}
          >
            {isEdit ? <DoneIcon /> : <AddIcon />}
          </Button>
        </Tooltip>
      </Grid>
      <Grid
        sm={2}
        item
      >
        {isEdit ? (
          <Protected
            level="delete"
            category="channel"
          >
            <Tooltip title="Delete">
              <Button
                variant="outlined"
                color="secondary"
                className={classes.button}
                onClick={() => onDelete(channel)}
              >
                <DeleteIcon />
              </Button>
            </Tooltip>
          </Protected>
        ) : (
          <Tooltip title="Cancel">
            <Button
              variant="outlined"
              color="secondary"
              className={classes.button}
              onClick={() => {
                setChannel({ name: '' })
              }}
            >
              <CloseIcon />
            </Button>
          </Tooltip>
        )}
      </Grid>
    </Grid>
  )
})

const ChannelForm = () => {
  const classes = useStyles()
  const { channelStore } = useStores()

  const handleClose = useCallback(() => {
    channelStore.setOpenDialog(false)
  }, [])

  return (
    <Dialog
      open={channelStore.openDialog}
      onClose={handleClose}
      maxWidth="sm"
      fullWidth
    >
      <CustomizedDialogTitle onClose={handleClose}>
        Channels Setting
      </CustomizedDialogTitle>
      <DialogContent className={classes.dialogContent}>
        <ChannelItemForm item={null} />
        {(channelStore.list.length !== 0) && channelStore.list.map((channel) => (
          <ChannelItemForm
            key={channel.id}
            item={channel}
          />
        ))}
      </DialogContent>
    </Dialog>
  )
}

export default observer(ChannelForm)
