import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { computed } from 'mobx'
import moment from 'moment'
import makeStyles from '@mui/styles/makeStyles'
import { DatePicker } from '@mui/x-date-pickers'
import {
  Grid,
  Stack,
  Typography,
  TextField as MuiTextField,
  MenuItem,
} from '@mui/material'

import { useStores } from 'admin/hooks'
import TextField from 'admin/components/textfield'
import DriverType from 'admin/components/driver-type'
import TodayButton from 'admin/components/button-jump-to-today'
import {
  isTodayDate,
  dateOnlyFormat,
  TODAY_MOMENT,
  groupBy,
  bookedListAWeekAgo,
  mergeList,
} from 'admin/utils/helper'
import { DRIVER_TYPE } from 'admin/config'
import BookedData from './booked-list'
import BookingInfo from '../info'

import styles from './styles'

const useStyles = makeStyles(styles)

const BookingItem = observer(({
  date,
  driver,
  groupByDriver,
}) => {
  const classes = useStyles({
    bgColor: driver.taggingColor,
  })
  const { bookingStore, slotStore } = useStores()

  return (
    <div className={classes.items}>
      <div
        className={classes.driver}
      >
        {driver && (
          <DriverType
            driver={driver}
            date={date}
            color="black"
            labelVariant="h4"
            groupByDriver={groupByDriver}
            tag
          />
        )}
      </div>
      {slotStore.list.map((slot, index) => {
        return (
          <BookedData
            key={slot.id}
            timeslot={slot}
            index={index}
            driver={driver}
            bookedList={bookingStore.list.filter(
              (bookingFilter) => bookingFilter.driverId === driver.id &&
                bookingFilter.timeslotId === slot.id,
            )}
            bookedListAWeekAgo={bookedListAWeekAgo(bookingStore.list, bookingStore.listAWeekAgo, driver.id, slot.id)}
          />
        )
      })}
    </div>
  )
})

const Timesheet = () => {
  const classes = useStyles()

  const {
    adminStore,
    bookingStore,
    driverStore,
    slotStore,
  } = useStores()

  const { isCounterStaff } = adminStore

  const [date, setDate] = useState(bookingStore.selected?.date || TODAY_MOMENT.format())
  const [driverType, setDriverType] = useState('')

  useEffect(() => {
    const bookingParams = {
      date: dateOnlyFormat(date),
      limit: 1000,
      offset: 0,
    }

    driverStore.fetch({
      limit: 1000,
      offset: 0,
      status: 'active',
      date: dateOnlyFormat(date),
    })
    slotStore.fetch({ limit: 1000, offset: 0 })
    bookingStore.fetch(bookingParams)
    bookingStore.setTimesheetQuery(bookingParams)

    const seventDaysAgo = moment(dateOnlyFormat(date)).subtract(7, 'days').startOf('day')

    bookingStore.fetchPickupAWeekAgo({
      ...bookingParams,
      date: dateOnlyFormat(seventDaysAgo),
    })
  }, [date, bookingStore.rebookDialog])

  const handleChange = (newDateValue) => {
    setDate(moment(newDateValue).startOf('day').format())
  }

  const driverList = computed(() => {
    if (!adminStore.isCounterStaff) {
      let drivers = driverStore.list
      if (driverType) {
        drivers = driverStore.list.filter(driver => driver.type === driverType)
      }
      return drivers
    }
    return driverStore.list.filter(driver => driver.type === DRIVER_TYPE.OUTLET)
  }).get()

  const groupByDriver = groupBy(mergeList(bookingStore.listAWeekAgo, bookingStore.list), 'driverId')

  return (
    <>
      <Grid
        container
        className={classes.filter}
      >
        <TextField
          name="type"
          type="text"
          size="small"
          select
          label="Driver Type"
          value={driverType}
          onChange={(e) => {
            setDriverType(e.target.value)
          }}
          className={classes.textField}
        >
          <MenuItem value="">All</MenuItem>
          <MenuItem value={DRIVER_TYPE.HOME_PICKUP}>Home Pick-up Driver</MenuItem>
          <MenuItem value={DRIVER_TYPE.OUTLET}>Outlet Driver</MenuItem>
        </TextField>
        <DatePicker
          label="Select Date"
          value={date}
          onChange={handleChange}
          renderInput={(params) => (
            <MuiTextField
              {...params}
              size="small"
              helperText={null}
              className={classes.calendar}
            />
          )}
          mask="__/__/____"
          inputFormat="dd/MM/yyyy"
        />
        {isTodayDate(date) ? (
          <Typography className={classes.today}>
            Today
          </Typography>
        ) : (
          <TodayButton
            setDate={setDate}
            style={classes.todayButton}
          />
        )}
      </Grid>
      <div className={classes.sheetsContainer}>
        <Stack
          direction="row"
          spacing={4}
        >
          {driverList.length > 0 ? driverList.map(driver => (
            <BookingItem
              key={driver.id}
              driver={driver}
              date={date}
              groupByDriver={groupByDriver}
            />
          )) : (
            <Typography variant="caption">
              Please add {isCounterStaff && 'outlet'} driver accounts first in the driver page
            </Typography>
          )}
        </Stack>
      </div>
      <BookingInfo />
    </>
  )
}

export default observer(Timesheet)
