export default function styles() {
  return {
    parentContainer: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    },
    headbar: {
      display: 'flex',
    },
    infoContainer: {
      display: 'flex',
      flexDirection: 'column',
      flex: '1',
      overflow: 'auto',
    },
  }
}
