import React, { useEffect } from 'react'
import { observer } from 'mobx-react'

import { useStores } from 'admin/hooks'
import {
  dateOnlyFormat,
  isTodayDate,
} from 'admin/utils/helper'

import CardItem from 'admin/components/dashboard-card/upper-content/overview'

const TotalBooking = ({ date }) => {
  const { bookingStore } = useStores()

  useEffect(() => {
    bookingStore.fetchTotal({
      createdAt: dateOnlyFormat(date),
    })
  }, [date])

  return (
    <CardItem
      total={bookingStore.totalDashboard}
      title={`Created Bookings ${isTodayDate(date) ? 'Today' : ''}`}
      icon="schedule"
    />
  )
}

export default observer(TotalBooking)
