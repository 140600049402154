export default (theme) => ({
  // https://material-ui.com/components/data-grid/rendering/#customization-example
  root: {
    border: 0,
    fontSize: '0.8rem !important',
    color: `${theme.color.white} !important`,
    '&.MuiDataGrid-root': {
      borderStyle: 'none',
    },
    '& .MuiDataGrid-iconSeparator': {
      display: 'none',
    },
    '& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell': {
      borderBottom: `1px solid ${theme.color.gray}`,
    },
    '& .MuiDataGrid-cell': {
      color: theme.palette.text.secondary,
      lineHeight: 'unset !important',
      maxHeight: 'none !important',
      whiteSpace: 'normal',
      display: 'flex !important',
      alignItems: 'center',
    },
    '& .MuiDataGrid-colCellTitle': {
      fontWeight: 600,
    },
    '& .MuiPaginationItem-root': {
      borderRadius: 0,
    },
    '& .MuiDataGrid-row': {
      backgroundColor: theme.color.white,
      fontWeight: 600,
      '&:nth-of-type(even)': {
        backgroundColor: theme.palette.action.hover,
      },
      maxHeight: 'none !important',
    },
    '& .MuiDataGrid-columnHeaders': {
      backgroundColor: theme.palette.primary.main,
    },
    '& .Mui-selected': {
      backgroundColor: `${theme.color.wheat} !important`,
    },
    '& .MuiDataGrid-columnHeaderTitle': {
      overflow: 'visible !important',
      fontWeight: `${600} !important`,
    },
    '& .MuiDataGrid-footerContainer': {
      borderStyle: 'none',
    },
  },
  wrapper: {
    flexGrow: 1,
    '& .customer-create': {
      backgroundColor: theme.color.pippin,
    },
  },
  layout: {
    height: '100vh',
    width: '100%',
  },
  flex: {
    display: 'flex',
    height: '100%',
  },
  label: {
    marginLeft: theme.spacing(1),
  },
  circularProgress: {
    position: 'absolute',
  },
  gridOverlay: {
    [theme.breakpoints.down('md')]: {
      position: 'relative',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: '40%',
    },
  },
})
