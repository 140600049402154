import React, { useState } from 'react'
import {
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
  MenuItem,
  MenuList,
  ListItemText,
} from '@mui/material'

import { BOOKING_STATUS } from 'admin/config'
import TextField from 'admin/components/textfield'

const MenuListComposition = ({
  anchorRef,
  open,
  onStatusUpdate,
  onClose,
}) => {
  const [isFail, setIsFail] = useState(false)
  const [otherReason, setOtherReason] = useState('')
  const [isOther, setIsOther] = useState(false)

  const handleClose = () => {
    setIsOther(false)
    setIsFail(false)
    setOtherReason('')
    onClose()
  }

  const handleUpdateStatus = (status) => () => {
    if (status === BOOKING_STATUS.FAILED) {
      setIsFail(true)
    } else {
      onStatusUpdate(status)
      handleClose()
    }
  }

  const handleKeyPress = (event) => {
    if (event.keyCode === 13) {
      onStatusUpdate(BOOKING_STATUS.FAILED, otherReason)
      handleClose()
    }
  }

  const handleChangeReason = (e) => {
    setOtherReason(e.target.value)
  }

  const handleUpdateReason = (reason) => () => {
    if (reason === 'other') {
      setIsOther(true)
      return
    }
    onStatusUpdate(BOOKING_STATUS.FAILED, reason)
    setIsFail(false)
  }

  const renderReasonMenu = () => {
    if (!isFail) return false
    return (
      <div>
        <MenuItem onClick={handleUpdateReason('Not at home')}>
          <ListItemText inset>Not at home</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleUpdateReason('Reject pricing')}>
          <ListItemText inset>Reject pricing</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleUpdateReason('Item unable to wash')}>
          <ListItemText inset>Item unable to wash</ListItemText>
        </MenuItem>
        {!isOther ? (
          <MenuItem onClick={handleUpdateReason('other')}>
            <ListItemText inset>Other</ListItemText>
          </MenuItem>
        ) : null}
      </div>
    )
  }

  return (
    <Popper
      open={Boolean(open)}
      anchorEl={anchorRef?.current}
      role={undefined}
      placement="bottom-start"
      transition
      disablePortal
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin:
                  placement === 'bottom-start' ? 'left top' : 'left bottom',
          }}
        >
          <Paper sx={{ maxWidth: 320 }}>
            <ClickAwayListener onClickAway={handleClose}>
              <div>
                <MenuList
                  dense
                  autoFocusItem={open}
                  id="composition-menu"
                  aria-labelledby="composition-button"
                  onKeyDown={(event) => {
                    event.preventDefault()
                    event.stopPropagation()
                  }}
                >
                  <MenuItem onClick={handleUpdateStatus(BOOKING_STATUS.SCHEDULED)}>
                    <ListItemText>Scheduled</ListItemText>
                  </MenuItem>
                  <MenuItem onClick={handleUpdateStatus(BOOKING_STATUS.COMPLETED)}>
                    <ListItemText>Completed</ListItemText>
                  </MenuItem>
                  <MenuItem onClick={handleUpdateStatus(BOOKING_STATUS.FAILED)}>
                    <ListItemText>Failed</ListItemText>
                  </MenuItem>
                  {renderReasonMenu()}
                </MenuList>
                {isOther ? (
                  <TextField
                    name="label"
                    type="text"
                    variant="outlined"
                    size="small"
                    onKeyDown={handleKeyPress}
                    placeholder="Enter other reason"
                    value={otherReason}
                    onChange={handleChangeReason}
                  />
                ) : null}
              </div>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  )
}

export default MenuListComposition
