import { makeAutoObservable, flow } from 'mobx'

import { TODAY_MOMENT } from 'admin/utils/helper'
import * as srv from 'admin/services'
import handleError from 'admin/services/error-handler'

export default class DriverStore {
  loading = false
  processing = false

  dialog = {
    form: false,
    delete: false,
    upload: false,
    report: false,
  }

  selected = null
  list = []
  total = 0
  query = {
    name: '',
    code: '',
    phoneNumber: '',
    columnIndex: -1,
    sortBy: '',
    sortDirection: '',
    day: 'default',
    date: TODAY_MOMENT.format(),
  }
  currentPage = 0
  rowsPerPage = 10

  DEFAULT = {
    name: '',
    code: '',
    phoneNumber: '',
    type: 'homePickupDriver',
    gender: 'male',
    imageUrl: '',
    status: 'active',
    prestoId: '',
    taggingColor: '',
    canCancelBooking: false,
  }

  workDays = {
    mon: false,
    tue: false,
    wed: false,
    thu: false,
    fri: false,
    sat: false,
    sun: false,
  }

  constructor(rootStore) {
    makeAutoObservable(this, {
      reload: flow,
      fetch: flow,
      fetchDriverSlot: flow,
      searchDriverHoliday: flow,
    })
    this.rootStore = rootStore
  }

  setOpenDialog = (selected, type) => {
    this.dialog[type] = true
    if (selected) {
      this.selected = { ...this.DEFAULT, ...selected }
    } else {
      this.selected = { ...this.DEFAULT }
    }
  }

  setCloseDialog = (type, reset = true) => {
    this.dialog[type] = false
    if (reset) setTimeout(this.resetSelected, 50)
  }

  select = (id) => {
    this.selected = this.list.find((item) => item.id === id)
  }

  resetSelected = () => {
    this.selected = { ...this.DEFAULT }
  }

  updatePage = (page) => {
    this.currentPage = page
  }

  updateRowsPerPage = (perPage) => {
    this.rowsPerPage = perPage
  }

  updateQuery = (key, value, resetPage = false) => {
    this.query[key] = value
    if (resetPage) this.currentPage = 0
  }

  resetQuery = () => {
    this.query = {
      name: '',
      contactNumber: '',
      email: '',
      columnIndex: -1,
      sortBy: '',
      sortDirection: '',
      day: 'default',
    }
    this.currentPage = 0
  }

  get queryParams() {
    const params = {
      limit: this.rowsPerPage,
      offset: this.currentPage * this.rowsPerPage,
    }
    if (this.query.sortBy) params.sortBy = this.query.sortBy
    if (this.query.sortDirection) params.sortDirection = this.query.sortDirection
    if (this.query.name) params.name = this.query.name
    if (this.query.code) params.code = this.query.code
    if (this.query.phoneNumber) params.phoneNumber = this.query.phoneNumber
    if (this.query.day) params.day = this.query.day
    if (this.query.date) params.date = this.query.date
    return params
  }

  * reload(reset = false) {
    yield this.fetch()
    if (reset) {
      this.selected = { ...this.DEFAULT }
      this.rootStore.infoSectionStore.reset()
    } else if (this.rootStore.infoSectionStore.selected?.id) {
      // reselect after fetch
      this.select(this.rootStore.infoSectionStore.selected.id)

      // update the selected object in infoSectionStore
      this.rootStore.infoSectionStore.replace(this.selected)
    }
  }

  * fetch(params) {
    try {
      this.loading = true
      const response = yield srv.fetchDriver({
        ...this.queryParams,
        ...params,
      })
      if (response.ok) {
        this.list = response.data.list
        this.total = response.data.total
        return response.data
      }
      throw handleError(response)
    } catch (error) {
      return null
    } finally {
      this.loading = false
    }
  }

  * fetchDriverSlot(params) {
    if (this.processing) return null
    this.processing = true
    try {
      const response = yield srv.fetchDriverSlot(params)
      if (response.ok) return response.data.list
      throw handleError(response)
    } finally {
      this.processing = false
    }
  }

  * searchDriverHoliday(params) {
    try {
      this.loading = true
      const response = yield srv.searchDriverHoliday(params)
      if (response.ok) return response.data
      throw handleError(response)
    } catch (error) {
      return null
    } finally {
      this.loading = false
    }
  }
}
