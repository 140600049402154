import React, {
  useEffect,
  useState,
} from 'react'
import { observer } from 'mobx-react'

import {
  Box,
  LinearProgress,
  Typography,
  Tabs,
  Tab,
  Stack,
} from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import makeStyles from '@mui/styles/makeStyles'

import { useStores, useApiRequest } from 'admin/hooks'
import * as srv from 'admin/services'
import { BOOKING_STATUS } from 'admin/config'
import {
  dateOnlyFormat,
  isTodayDate,
  yesterday,
} from 'admin/utils/helper'

import Link from 'admin/components/link'

import Table from './table'
import styles from './styles'

const useStyles = makeStyles(styles)

const Header = () => {
  const classes = useStyles()

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      className={classes.bottomTitle}
    >
      <Stack direction="row">
        <SearchIcon className={classes.icon} />
        <Typography variant="h3">
          Activity Logs
        </Typography>
      </Stack>
      <Link
        to="/booking-list"
        className={classes.link}
      >
        View more
      </Link>
    </Stack>
  )
}

const BookingToday = ({ date }) => {
  const classes = useStyles()
  const { bookingStore, notificationStore } = useStores()
  const [value, setValue] = useState(0)
  const [list, setList] = useState([])
  const rebook = bookingStore.rebookDialog

  const {
    request: fetchBooking,
    isLoading,
  } = useApiRequest(srv.fetchBooking, { blocking: false })

  useEffect(() => {
    const fetchBookingList = async () => {
      try {
        const params = {
          date: dateOnlyFormat(date),
          limit: 15,
        }
        if (value === 0) {
          params.date = dateOnlyFormat(yesterday(date))
          params.status = BOOKING_STATUS.FAILED
          params.limit = 100
        } else if (value === 2) {
          params.status = BOOKING_STATUS.COMPLETED
        }
        const response = await fetchBooking(params)
        if (response?.data) setList(response.data.list)
      } catch (error) {
        notificationStore.setError(error)
      }
    }
    fetchBookingList()
  }, [
    date,
    value,
    rebook,
  ])

  useEffect(() => {
    bookingStore.fetchBookingCounts({
      date: dateOnlyFormat(date),
      yesterdayDate: dateOnlyFormat(yesterday(date)),
    })
  }, [date, rebook])

  const handleChange = (_, newValue) => {
    setValue(newValue)
  }

  return (
    <>
      <Header />
      <Tabs
        value={value}
        onChange={handleChange}
        className={classes.rootTabs}
        classes={{
          indicator: classes.indicator,
          flexContainer: classes.flexContainer,
        }}
      >
        <Tab
          classes={{
            root: classes.root,
            selected: classes.selected,
            wrapper: classes.wrapper,
          }}
          icon={(
            <Typography
              variant="h1"
              className={classes.iconText}
            >
              {bookingStore.counts?.failedCount}
            </Typography>
          )}
          label={(
            <Stack>
              <Typography align="left">
                Failed
              </Typography>
              {isTodayDate(date) && (
                <Typography align="left">Yesterday</Typography>
              )}
            </Stack>
          )}
        />
        <Tab
          key="Total Bookings Today"
          classes={{
            root: classes.root,
            selected: classes.selected,
            wrapper: classes.wrapper,
          }}
          icon={(
            <Typography
              variant="h1"
              className={classes.iconText}
            >
              {bookingStore.counts?.count}
            </Typography>
          )}
          label={(
            <Stack>
              <Typography>
                Total Bookings
              </Typography>
              {isTodayDate(date) && (
                <Typography align="left">Today</Typography>
              )}
            </Stack>
          )}
        />
        <Tab
          classes={{
            root: classes.root,
            selected: classes.selected,
            wrapper: classes.wrapper,
          }}
          icon={(
            <Typography
              variant="h1"
              className={classes.iconText}
            >
              {bookingStore.counts?.completedCount}
            </Typography>
          )}
          label={(
            <Stack>
              <Typography>
                Completed
              </Typography>
              {isTodayDate(date) && (
                <Typography align="left">Today</Typography>
              )}
            </Stack>
          )}
        />
      </Tabs>
      {isLoading ? (
        <Box sx={{ width: '100%' }}>
          <LinearProgress />
        </Box>
      ) : (
        <Table
          list={list}
          tabFailed={value === 0}
        />
      )}
    </>
  )
}

export default observer(BookingToday)
