import React, { useCallback, useState } from 'react'
import { observer } from 'mobx-react'
import { Button } from '@mui/material'
import clxs from 'clsx'

import makeStyles from '@mui/styles/makeStyles'
import { useLocation } from 'react-router-dom'

import { useStores } from 'admin/hooks'
import CustomizedTabs from 'admin/components/tabs'

import InfoSection from 'admin/components/info-section'
import HeadBar from 'admin/components/head-bar'
import LogTable from 'admin/components/log-table'
import Information from './information'

import styles from './styles'

const useStyles = makeStyles(styles)

const Log = ({
  id,
}) => {
  const params = {
    objectId: id,
    object: 'invoicePayment',
    limit: 20,
  }

  return (
    <LogTable requestParams={params} />
  )
}

const EditDeleteMenu = observer(() => {
  const classes = useStyles()

  const {
    infoSectionStore,
    invoicePaymentStore,
  } = useStores()

  const { selected: payment } = infoSectionStore

  const openUpdatePaymentDialog = useCallback(() => {
    invoicePaymentStore.setOpenDialog(payment, 'form')
  }, [payment])

  const openDeletePaymentDialog = useCallback(() => {
    invoicePaymentStore.setOpenDialog(payment, 'delete')
  }, [payment])

  return (
    <div
      className={clxs(
        classes.padding,
        classes.leftMargin,
      )}
    >
      <Button
        variant="contained"
        color="secondary"
        size="small"
        onClick={openUpdatePaymentDialog}
        className={classes.editButton}
      >
        Edit
      </Button>
      <Button
        variant="outlined"
        color="secondary"
        size="small"
        onClick={openDeletePaymentDialog}
      >
        Delete
      </Button>
    </div>
  )
})

const InvoicePaymentInfo = () => {
  const location = useLocation()
  const { infoSectionStore } = useStores()
  const [activeTab, setActiveTab] = useState(0)
  const classes = useStyles()

  const { selected: payment } = infoSectionStore
  const handleChangeTab = useCallback((event, value) => {
    setActiveTab(value)
  }, [])

  return (
    <InfoSection
      title="Payment"
    >
      <div className={classes.parentContainer}>
        {payment && (
          <>
            <div style={{ display: 'flex' }}>
              <div>
                <HeadBar
                  data={[
                    {
                      label: 'Payment Ref',
                      value: payment.referenceNumber,
                    },
                    {
                      label: 'Invoice Payment Status',
                      value: payment.invoice.paymentStatus,
                      capitalize: true,
                      textRed: payment.invoice.paymentStatus === 'unpaid',
                      textBrown: payment.invoice.paymentStatus === 'paid',
                      textOrange: payment.invoice.paymentStatus === 'partial',
                      textGreen: payment.invoice.paymentStatus === 'received',
                    },
                  ]}
                />
              </div>
              {location.pathname === '/invoice-payment' && <EditDeleteMenu />}
            </div>
            <CustomizedTabs
              activeTab={activeTab}
              handleChangeTab={handleChangeTab}
              labels={[
                'Information',
                'Log',
              ]}
            />
            <div className={classes.infoContainer}>
              {activeTab === 0 && <Information key={`info-${payment.id}`} />}
              {activeTab === 1 && <Log key={`permission-${payment.id}`} id={payment.id} />}
            </div>
          </>
        )}
      </div>
    </InfoSection>
  )
}

export default observer(InvoicePaymentInfo)
