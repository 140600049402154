export default () => ({
  backdrop: {
    position: 'fixed',
    zIndex: 2000,
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
  },
  container: {
    position: 'fixed',
    zIndex: 2100,
    bottom: 0,
    left: 0,
    right: 0,
  },
})
