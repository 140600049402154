import React, { useCallback } from 'react'
import { observer } from 'mobx-react'
import {
  Button,
  Table,
  TableRow,
  TableCell,
  TableBody,
} from '@mui/material'

import DeleteDialog from 'admin/components/dialog'
import Protected from 'admin/components/protected'
import Setting from 'admin/components/setting'
import { useApiRequest, useStores } from 'admin/hooks'
import * as srv from 'admin/services'

import PriceCategoryForm from './form'

const TablePriceCategory = observer(() => {
  const { priceCategoryStore } = useStores()

  if (priceCategoryStore.list.length === 0) return null
  return (
    <Table size="small">
      <TableBody>
        {priceCategoryStore.list?.map((row) => (
          <TableRow
            key={row.id}
            sx={{
              '&:last-child td, &:last-child th': {
                border: 0,
              },
            }}
          >
            <TableCell component="th" scope="row">
              {row.label}
            </TableCell>
            <TableCell align="right" scope="row">
              <Button
                variant="text"
                color="secondary"
                size="small"
                onClick={() => {
                  priceCategoryStore.setSelected(row)
                  priceCategoryStore.setOpenDialog(row, 'form')
                }}
              >
                Edit
              </Button>
              <Button
                variant="text"
                color="secondary"
                size="small"
                onClick={() => {
                  priceCategoryStore.setSelected(row)
                  priceCategoryStore.setOpenDialog(row, 'delete')
                }}
              >
                Delete
              </Button>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
})

const PriceCategorySetting = () => {
  const { notificationStore, priceCategoryStore } = useStores()

  const {
    request: deletePriceCategory,
  } = useApiRequest(srv.deletePriceCategory, { blocking: true })

  const onDelete = useCallback(async () => {
    if (
      !priceCategoryStore.selected ||
      !priceCategoryStore.selected.id
    ) return
    const response = await deletePriceCategory({ id: priceCategoryStore.selected.id })
    if (!response) return
    notificationStore.setSuccess('Delete price category success')
    priceCategoryStore.setCloseDialog('delete')
    priceCategoryStore.reload(true)
  }, [priceCategoryStore.selected])

  const handleOpenAll = () => {
    if (priceCategoryStore.list.length <= 0) {
      notificationStore.setError('Please insert new data')
      return
    }
    const objAllPriceCategory = {
      allPriceCategory: priceCategoryStore.list,
    }
    priceCategoryStore.setOpenDialog(objAllPriceCategory, 'form')
  }

  return (
    <Setting
      title="Price List"
      action={(
        <>
          <Protected
            level="update"
            category="priceList"
          >
            <div>
              <Button
                variant="text"
                style={{ margin: '0 1rem' }}
                color="secondary"
                onClick={handleOpenAll}
              >
                Open All
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => {
                  priceCategoryStore.setSelected(null)
                  priceCategoryStore.setOpenDialog(null, 'form')
                }}
              >
                Create
              </Button>
            </div>
            <PriceCategoryForm />
          </Protected>
          <Protected
            level="delete"
            category="priceList"
          >
            <DeleteDialog
              open={priceCategoryStore.dialog.delete}
              title={`Do you want to delete ${priceCategoryStore.selected?.label} price category?`}
              content="Price category data that deleted cannot be recovered. Make sure you know what you are doing."
              primaryActionLabel="Delete"
              onAccept={onDelete}
              onClose={() => { priceCategoryStore.setCloseDialog('delete') }}
            />
          </Protected>
        </>
      )}
      table={<TablePriceCategory />}
    />
  )
}

export default observer(PriceCategorySetting)
