import React from 'react'
import { useHistory } from 'react-router-dom'
import { observer } from 'mobx-react'
import makeStyles from '@mui/styles/makeStyles'
import {
  Button,
  FormControl,
  Typography,
  Card,
  CardContent,
  Stack,
} from '@mui/material'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import { useStores } from 'admin/hooks'
import Link from 'admin/components/link'
import TextField from 'admin/components/textfield'
import schema from './schema'

import styles from './styles'

const useStyles = makeStyles(styles)

const Login = () => {
  const classes = useStyles()
  const history = useHistory()
  const { adminStore } = useStores()
  const { control, handleSubmit: handleSubmitForm } = useForm({
    resolver: yupResolver(schema),
  })

  const handleSubmit = async (value) => {
    try {
      const res = await adminStore.login(value)
      if (res) {
        await adminStore.current()
        if (adminStore.isCounterStaff) {
          history.replace('/booking-list')
        } else {
          history.replace('/')
        }
      }
    } catch (error) {
      console.log('Error on login', error)
      // do nothing
    }
  }

  return (
    <div className={classes.wrapper}>
      <Card className={classes.formContainer}>
        <CardContent>
          <Typography
            variant="h1"
            className={classes.header}
          >
            Admin
          </Typography>
          <form onSubmit={handleSubmitForm(handleSubmit)}>
            <FormControl fullWidth className={classes.formField}>
              <Controller
                control={control}
                name="username"
                render={({
                  field,
                  fieldState: { error },
                }) => (
                  <TextField
                    label="Username"
                    autoFocus
                    type="text"
                    error={error?.message}
                    helperText={error?.message}
                    {...field}
                  />
                )}
              />
            </FormControl>
            <FormControl fullWidth className={classes.formField}>
              <Controller
                control={control}
                name="password"
                render={({
                  field,
                  fieldState: { error },
                }) => (
                  <TextField
                    fullWidth
                    autoComplete="current-password"
                    label="Password"
                    type="password"
                    error={error?.message}
                    helperText={error?.message}
                    {...field}
                  />
                )}
              />
            </FormControl>
            <Stack alignItems="flex-end">
              <Link
                to="/forget-password"
                className={classes.link}
              >
                Forgot your password?
              </Link>
            </Stack>
            <Button
              fullWidth
              className={classes.actionButton}
              type="submit"
              color="primary"
              variant="contained"
              size="large"
              disabled={adminStore.processing}
            >
              Login
            </Button>
          </form>
        </CardContent>
      </Card>
    </div>
  )
}

export default observer(Login)
