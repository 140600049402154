import { api } from './api'

export const fetchPOSCustomer = (query) => api.get('/pos/customer', query)
export const fetchPOSDriver = (query) => api.get('/pos/driver', query)
export const fetchPOSInvoice = (query) => api.get('/pos/order', query)
export const fetchPOSInvoiceItem = (query) => api.get('/pos/order-item', query)
export const fetchPOSItemAccessory = (query) => api.get('/pos/item-accessory', query)

export const syncPOSInvoice = (id) => api.put(`/pos/sync/order/${id}`)
export const syncPOSCustomer = (mobileNo) => api.put(`/pos/sync/customer/${mobileNo}`)
