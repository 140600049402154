import React, { useEffect, useCallback, useState } from 'react'
import { observer } from 'mobx-react'
import moment from 'moment'

import {
  Typography,
  Stack,
  IconButton,
} from '@mui/material'

import { useStores } from 'admin/hooks'
import DateRangeIcon from '@mui/icons-material/DateRange'
import DateRangePicker from 'admin/components/date-range-picker'

import CustomDataGrid from 'admin/components/data-grid'
import { formatDateHour, getTags, formatDate, aWeekAgo, aWeekAhead } from 'admin/utils/helper'
import ServiceIcon from 'admin/components/service-icon'

const statusColor = (value) => {
  if (value === 'completed') return '#00A424'
  if (value === 'on the way') return '#E3AE24'
  return null
}

const columns = [
  {
    field: 'schedule',
    headerName: 'Date Created',
    width: 150,
    renderCell: (params) => (
      <Stack direction="column">
        <Typography variant="caption">
          {formatDateHour(params.row.createdAt)}
        </Typography>
      </Stack>
    ),
  },
  {
    field: 'services',
    headerName: 'Services',
    flex: 2,
    renderCell: (params) => {
      const {
        pickup,
        delivery,
      } = getTags(params.row.bookingTags)
      return (
        <Stack
          direction="column"
          style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
        >
          {pickup.length > 0 && (
            <ServiceIcon
              iconName="pickup"
              data={pickup}
            />
          )}
          {delivery.length > 0 && (
            <ServiceIcon
              iconName="delivery"
              data={delivery}
            />
          )}
        </Stack>
      )
    },
  },
  {
    field: 'status',
    headerName: 'Status',
    flex: 1,
    renderCell: (params) => (
      <Typography
        color="secondary"
        variant="caption"
        style={{
          textTransform: 'capitalize',
          color: statusColor(params.value),
        }}
      >
        {params.value}
      </Typography>
    ),
  },
]

const OrderDataGrid = () => {
  const { bookingStore, infoSectionStore } = useStores()

  const [anchorEl, setAnchorEl] = useState(null)

  const [date, setDate] = useState({
    key: 'selection',
    startDate: aWeekAgo,
    endDate: aWeekAhead,
  })

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleSelect = value => {
    const { startDate, endDate } = value.selection
    setDate({ ...date, startDate, endDate })
  }

  useEffect(() => {
    bookingStore.fetch({
      customerId: infoSectionStore.selected.id,
      startCreatedAt: moment(date.startDate).startOf('day').format(),
      endCreatedAt: moment(date.endDate).endOf('day').format(),
    })
  }, [bookingStore.queryParams, date])

  const handlePageChange = useCallback((params) => {
    bookingStore.updatePage(params)
  }, [])

  const handlePageSizeChange = useCallback((param) => {
    bookingStore.updateRowsPerPage(param)
  }, [])

  useEffect(() => {
    return () => {
      bookingStore.resetQuery()
    }
  }, [])

  return (
    <>
      <div>
        <IconButton onClick={handleClick} size="large">
          <DateRangeIcon />
        </IconButton>
        <span>
          {formatDate(date.startDate)} - {formatDate(date.endDate)}
        </span>
      </div>
      <DateRangePicker
        anchorEl={anchorEl}
        handleClose={handleClose}
        handleSelect={handleSelect}
        selectionRange={date}
      />
      <CustomDataGrid
        rows={bookingStore.list}
        columns={columns}
        handlePageChange={handlePageChange}
        rowCount={bookingStore.total}
        pageSize={bookingStore.rowsPerPage}
        page={bookingStore.currentPage}
        handlePageSizeChange={handlePageSizeChange}
        loading={bookingStore.loading}
        rowHeight={160}
      />
    </>
  )
}

export default observer(OrderDataGrid)
