import { startOfDay, endOfDay, addDays, subDays } from 'date-fns'
import { makeAutoObservable, flow } from 'mobx'
import moment from 'moment'

import { TODAY } from 'admin/utils/helper'
import * as srv from 'admin/services'
import handleError from 'admin/services/error-handler'

export default class InvoiceStore {
  loading = false
  processing = false
  dialog = {
    filter: false,
    export: false,
    form: false,
  }
  selected = null
  list = []
  total = 0
  query = {
    prestoId: '',
    paymentStatus: '',
    customerId: '',
    paymentMethod: '',
    columnIndex: -1,
    sortBy: 'updatedAt',
    sortDirection: 'desc',
  }
  currentPage = 0
  rowsPerPage = 10
  startDate = startOfDay(subDays(TODAY(), 7))
  endDate = endOfDay(addDays(TODAY(), 7))
  fileName = ''
  DEFAULT = {
    driverId: 0,
    customerId: '',
    paymentStatus: 'unpaid',
    paymentMethod: '',
    prestoId: '',
    originalResponse: {},
  }

  isDateRangeActive = true
  defaultDatePickerSelectionRange = {
    key: 'selection',
    startDate: this.startDate,
    endDate: this.endDate,
  }
  datePickerSelectionRange = this.defaultDatePickerSelectionRange

  constructor(rootStore) {
    makeAutoObservable(this, {
      reload: flow,
      fetch: flow,
    })
    this.rootStore = rootStore
  }

  updateDatePickerSelectionRange = (startDate, endDate) => {
    this.datePickerSelectionRange = {
      key: 'selection',
      startDate,
      endDate,
    }
  }

  select = (id) => {
    this.selected = this.list.find((item) => item.id === id)
  }

  setSelected = (value) => {
    this.selected = value
  }

  resetSelected = () => {
    this.selected = { ...this.DEFAULT }
  }

  updatePage = (page) => {
    this.currentPage = page
  }

  setFileName = (fileName) => {
    this.fileName = fileName
  }

  updateRowsPerPage = (perPage) => {
    this.rowsPerPage = perPage
  }

  updateQuery = (key, value, resetPage = false) => {
    this.query[key] = value
    if (resetPage) this.currentPage = 0
  }

  setOpenDialog = (selected, type) => {
    this.dialog[type] = true
    if (selected) {
      this.selected = { ...this.DEFAULT, ...selected }
    } else {
      this.selected = { ...this.DEFAULT }
    }
  }

  setCloseDialog = (type, reset = true) => {
    this.dialog[type] = false
    if (reset) setTimeout(this.resetSelected, 50)
  }

  activateDateRange = (value, reset = false) => {
    this.isDateRangeActive = value
    if (reset) this.datePickerSelectionRange = this.defaultDatePickerSelectionRange
  }

  // open or close dialog without changing this.selected value
  setDialog = (key, value) => {
    this.dialog[key] = value
  }

  resetQuery = () => {
    this.query = {
      prestoId: '',
      paymentStatus: '',
      customerId: '',
      paymentMethod: '',
      columnIndex: -1,
      sortBy: 'updatedAt',
      sortDirection: 'desc',
    }
    this.currentPage = 0
    this.rowsPerPage = 10
    this.datePickerSelectionRange = this.defaultDatePickerSelectionRange
    this.isDateRangeActive = true
  }

  get queryParams() {
    const params = {
      sortBy: this.query.sortBy,
      sortDirection: this.query.sortDirection,
      limit: this.rowsPerPage,
      offset: this.currentPage * this.rowsPerPage,
    }
    if (this.isDateRangeActive) {
      const sDate = moment(this.datePickerSelectionRange.startDate)
        .startOf('day')
        .toISOString()
      const eDate = moment(this.datePickerSelectionRange.endDate)
        .endOf('day')
        .toISOString()
      params.startDate = sDate
      params.endDate = eDate
    }
    if (this.query.prestoId) params.prestoId = this.query.prestoId
    if (this.query.paymentStatus) params.paymentStatus = this.query.paymentStatus
    if (this.query.customerId) params.customerId = this.query.customerId
    if (this.query.driverId) params.driverId = this.query.driverId
    if (this.query.paymentMethod) params.paymentMethod = this.query.paymentMethod
    return params
  }

  * reload(reset = false) {
    yield this.fetch()
    if (reset) {
      this.selected = { ...this.DEFAULT }
      this.rootStore.infoSectionStore.reset()
    } else if (this.rootStore.infoSectionStore.selected?.id) {
      // reselect after fetch
      this.select(this.rootStore.infoSectionStore.selected.id)

      // update the selected object in infoSectionStore
      this.rootStore.infoSectionStore.replace(this.selected)
    }
  }

  * fetch(params) {
    try {
      this.loading = true
      const response = yield srv.fetchInvoice({
        ...this.queryParams,
        ...params,
      })
      if (response.ok) {
        this.list = response.data.list
        this.total = response.data.total
        return response.data
      }
      throw handleError(response)
    } catch (error) {
      return null
    } finally {
      this.loading = false
    }
  }
}
