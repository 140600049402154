export default function styles(theme) {
  return {
    filter: {
      marginTop: '0.875rem',
      marginBottom: '0.875rem',
      alignItems: 'center',
    },
    today: {
      fontWeight: '600',
      marginLeft: '1rem',
    },
    calendar: {
      width: '18.61rem',
    },
    items: {
      width: '23rem',
    },
    driver: ({ bgColor }) => {
      return {
        backgroundColor: bgColor || theme.color.cinnabar,
        border: `${bgColor === '#FFFFFF' ? 'thin solid black' : undefined}`,
        padding: '0.44rem 0.56rem',
        position: 'sticky',
        top: 0,
        zIndex: 10,
      }
    },
    text: {
      marginTop: '5rem',
    },
    container: {
      backgroundColor: theme.color.white,
    },
    timeslot: {
      borderRightStyle: 'solid',
      borderBottomStyle: 'solid',
      borderWidth: '1px',
      borderColor: theme.color.pearlBush,
    },
    slotText: {
      marginTop: '0.5rem',
      color: theme.color.lightBlack,
    },
    slot: {
      backgroundColor: theme.color.pearlBush,
    },
    available: {
      padding: '0.5rem',
      color: theme.color.lightBlack,
    },
    map: {
      border: `1px solid ${theme.color.paarl}`,
      '&:hover': {
        backgroundColor: theme.color.paarl,
      },
    },
    mapDisabled: {
      border: '1px solid rgba(0, 0, 0, 0.26)',
    },
    button: {
      padding: '7px',
      minWidth: '0px',
      backgroundColor: theme.color.white,
    },
    iconHover: {
      color: theme.color.white,
    },
    icon: {
      color: theme.color.paarl,
    },
    customer: {
      padding: '0.5rem',
      '&:hover': {
        backgroundColor: theme.color.floralWhite,
      },
    },
    remarks: {
      marginBottom: '1.25rem',
    },
    mobileNo: {
      marginBottom: '0.63rem',
    },
    sheetsContainer: {
      display: 'flex',
      overflow: 'auto',
      flexDirection: 'row',
      alignItems: 'flex-start',
      flexGrow: 1,
    },
    tagColor: {
      color: theme.color.paarl,
    },
    todayButton: {
      marginLeft: '0.75rem',
    },
    review: {
      backgroundColor: theme.color.pippin,
    },
    textField: {
      width: '15rem',
      marginRight: '1rem',
    },
  }
}
