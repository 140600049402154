import React, { useState, useCallback } from 'react'
import { observer } from 'mobx-react'
import {
  Container,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  Button,
  IconButton,
} from '@mui/material'

import {
  Visibility,
  VisibilityOff,
} from '@mui/icons-material'

import makeStyles from '@mui/styles/makeStyles'

import * as srv from 'admin/services'
import { useStores, useApiRequest } from 'admin/hooks'
import Protected from 'admin/components/protected'

import styles from './styles'

const useStyles = makeStyles(styles)

const LoginAccess = observer(() => {
  const classes = useStyles()
  const { infoSectionStore, notificationStore } = useStores()
  const [password, setPassword] = useState('')
  const [showPassword, setShowPassword] = useState(false)

  const {
    request: updateDriverPassword,
    isLoading: updateProcessing,
  } = useApiRequest(srv.updateDriverPassword, { blocking: true })

  const handleChangePassword = useCallback((event) => {
    setPassword(event.target.value)
  }, [])

  const handleShowPassword = useCallback((event) => {
    event.preventDefault()
    event.stopPropagation()
    setShowPassword(!showPassword)
  }, [showPassword])

  const handleSubmit = useCallback(async (event) => {
    event.preventDefault()
    await updateDriverPassword(infoSectionStore.selected, password)
    notificationStore.setSuccess('Driver password has been updated successfully')
    setPassword('')
    setShowPassword(false)
  }, [infoSectionStore.selected, password])

  // block events from being triggered and bubble up
  const blockEvent = useCallback((event) => {
    event.preventDefault()
    event.stopPropagation()
  }, [])

  return (
    <Container>
      <Protected
        category="driver"
        level="update"
      >
        <form
          noValidate
          onSubmit={handleSubmit}
          autoComplete="off"
        >
          <FormControl
            fullWidth
            margin="normal"
            variant="outlined"
          >
            <InputLabel htmlFor="input-password">
              Update Password
            </InputLabel>
            <OutlinedInput
              label="input-password"
              type={showPassword ? 'text' : 'password'}
              value={password}
              onChange={handleChangePassword}
              required
              labelWidth={144}
              endAdornment={(
                <InputAdornment position="end">
                  <IconButton
                    onClick={handleShowPassword}
                    // to block textfield onfocus
                    onMouseDown={blockEvent}
                    // to block textfield onfocus
                    onMouseUp={blockEvent}
                    edge="end"
                    size="large"
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              )}
            />
          </FormControl>
          <div className={classes.btnWrapper}>
            <Button
              variant="contained"
              color="secondary"
              type="submit"
              disabled={updateProcessing}
              className={classes.btn}
            >
              Save
            </Button>
          </div>
        </form>
      </Protected>
    </Container>
  )
})

export default LoginAccess
