import React, { useCallback, useState, useEffect } from 'react'
import { observer } from 'mobx-react'
import {
  Dialog,
  Button,
  DialogContent,
  Grid,
  FormControlLabel,
  Checkbox,
  Tooltip,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

import AddIcon from '@mui/icons-material/Add'
import CloseIcon from '@mui/icons-material/Close'
import DoneIcon from '@mui/icons-material/Done'
import DeleteIcon from '@mui/icons-material/Delete'

import Protected from 'admin/components/protected'
import CustomizedDialogTitle from 'admin/components/dialog-title'
import TextField from 'admin/components/textfield'

import { useStores, useApiRequest } from 'admin/hooks'
import * as srv from 'admin/services'

import styles from '../styles'

const useStyles = makeStyles(styles)

const BookingTagItemForm = observer((props) => {
  const classes = useStyles()
  const { bookingTagStore, notificationStore } = useStores()
  const [isEdit, setIsEdit] = useState(false)
  const [tag, setTag] = useState({
    name: '',
    weightage: 0,
    priority: 0,
    isPickupTag: false,
    isDeliveryTag: false,
    minimumSpend: 0,
    enabled: false,
  })

  const {
    request: addTag,
    isLoading: addProcessing,
  } = useApiRequest(srv.addTag, { blocking: true })
  const {
    request: updateTag,
    isLoading: updateProcessing,
  } = useApiRequest(srv.updateTag, { blocking: true })

  const handleChange = useCallback((key, value) => {
    setTag({
      ...tag,
      [key]: value,
    })
  }, [tag])

  const resetTag = useCallback(() => {
    setTag({
      name: '',
      weightage: 0,
      priority: 0,
      isPickupTag: false,
      isDeliveryTag: false,
      minimumSpend: 0,
      enabled: false,
    })
  }, [])

  useEffect(() => {
    if (!props.item) return
    setTag(props.item)
    setIsEdit(!!props.item.id)
  }, [props.item])

  const handleSubmit = useCallback(async (event) => {
    event.preventDefault()
    if (addProcessing || updateProcessing) return

    const request = (isEdit) ? updateTag : addTag
    const response = await request(tag)
    if (!response) return

    if (isEdit) {
      notificationStore.setSuccess('Update tag success')
    } else {
      resetTag() // reset
      notificationStore.setSuccess('Save tag success')
    }

    bookingTagStore.reload(true)
  }, [tag])

  const openDeleteDialog = useCallback(() => {
    bookingTagStore.setOpenDialog(tag, 'delete')
  }, [tag])

  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      spacing={1}
      className={classes.grid}
      columns={14}
    >
      <Grid
        item
        md={3}
      >
        <TextField
          fullWidth
          label="Tag"
          name="name"
          value={tag.name}
          onChange={(event) => {
            handleChange(event.target.name, event.target.value)
          }}
          size="small"
        />
      </Grid>
      <Grid
        item
        md={2}
        sm={5}
      >
        <TextField
          fullWidth
          label="Min. Spend"
          name="minimumSpend"
          size="small"
          type="number"
          inputProps={{
            min: 0,
          }}
          value={tag.minimumSpend}
          onChange={(event) => {
            handleChange(event.target.name, event.target.value)
          }}
          color="secondary"
        />
      </Grid>
      <Grid
        item
        md={2}
        sm={2}
      >
        <TextField
          fullWidth
          label="Weightage"
          name="weightage"
          size="small"
          type="number"
          inputProps={{
            min: 0,
          }}
          value={tag.weightage}
          onChange={(event) => {
            handleChange(event.target.name, event.target.value)
          }}
          color="secondary"
        />
      </Grid>
      <Grid
        item
        md={2}
      >
        <TextField
          fullWidth
          label="Priority"
          name="priority"
          size="small"
          type="number"
          inputProps={{
            min: 0,
          }}
          value={tag.priority}
          onChange={(event) => {
            handleChange(event.target.name, event.target.value)
          }}
          color="secondary"
        />
      </Grid>
      <Grid
        item
        md={1}
        sm={1}
      >
        <FormControlLabel
          classes={{
            root: classes.formControlLabelRoot,
            label: classes.formControlLabel,
          }}
          control={(
            <Checkbox
              checked={tag.isPickupTag}
              onChange={(event) => {
                handleChange('isPickupTag', event.target.checked)
              }}
              color="primary"
            />
          )}
          label="Pick-up"
          labelPlacement="top"
        />
      </Grid>
      <Grid
        item
        md={1}
        xs={1}
      >
        <FormControlLabel
          classes={{
            root: classes.formControlLabelRoot,
            label: classes.formControlLabel,
          }}
          control={(
            <Checkbox
              checked={tag.isDeliveryTag}
              onChange={(event) => {
                handleChange('isDeliveryTag', event.target.checked)
              }}
              color="primary"
            />
          )}
          label="Delivery"
          labelPlacement="top"
        />
      </Grid>
      <Grid
        item
        md={1}
        xs={1}
      >
        <FormControlLabel
          classes={{
            root: classes.formControlLabelRoot,
            label: classes.formControlLabel,
          }}
          control={(
            <Checkbox
              checked={tag.enabled}
              onChange={(event) => {
                handleChange('enabled', event.target.checked)
              }}
              color="primary"
            />
          )}
          label="Enabled"
          labelPlacement="top"
        />
      </Grid>
      <Grid
        item
        xs={1}
      >
        <Tooltip title="Save">
          <Button
            variant="outlined"
            color="secondary"
            className={classes.button}
            onClick={handleSubmit}
            disabled={addProcessing || updateProcessing}
          >
            {isEdit ? <DoneIcon /> : <AddIcon />}
          </Button>
        </Tooltip>
      </Grid>
      <Grid
        xs={1}
        item
        className={classes.gridDelete}
      >
        {isEdit ? (
          <Protected
            level="delete"
            category="tag"
          >
            <Tooltip title="Delete">
              <Button
                variant="outlined"
                color="secondary"
                className={classes.button}
                onClick={openDeleteDialog}
              >
                <DeleteIcon />
              </Button>
            </Tooltip>
          </Protected>
        ) : (
          <Tooltip title="Cancel">
            <Button
              variant="outlined"
              color="secondary"
              className={classes.button}
              onClick={resetTag}
            >
              <CloseIcon />
            </Button>
          </Tooltip>
        )}
      </Grid>
    </Grid>
  )
})

const BookingTagForm = () => {
  const classes = useStyles()
  const { bookingTagStore } = useStores()

  const handleClose = useCallback(() => {
    bookingTagStore.setCloseDialog('form')
  }, [])

  return (
    <Dialog
      open={bookingTagStore.dialog.form}
      onClose={handleClose}
      maxWidth="lg"
      classes={{
        paper: classes.dialog,
      }}
      fullWidth
    >
      <CustomizedDialogTitle onClose={handleClose}>
        Booking Tag
      </CustomizedDialogTitle>
      <DialogContent className={classes.dialogContent}>
        <BookingTagItemForm item={null} />
        {(bookingTagStore.list.length !== 0) && bookingTagStore.list.map((tag) => (
          <BookingTagItemForm
            key={tag.id}
            item={tag}
          />
        ))}
      </DialogContent>
    </Dialog>
  )
}

export default observer(BookingTagForm)
