import React from 'react'
import {
  Button,
} from '@mui/material'
import {
  ContentCopy as ContentCopyIcon,
} from '@mui/icons-material'

import { CopyToClipboard } from 'react-copy-to-clipboard'
import { useStores } from 'admin/hooks'

const CopyToClipboardButton = ({
  text,
}) => {
  const {
    notificationStore,
  } = useStores()

  const handleCopy = () => {
    notificationStore.setSuccess('Copied.')
  }

  return (
    <CopyToClipboard
      text={text}
      onCopy={handleCopy}
    >
      <Button
        variant="outlined"
        color="secondary"
        startIcon={<ContentCopyIcon />}
      >
        Copy
      </Button>
    </CopyToClipboard>
  )
}

export default CopyToClipboardButton
