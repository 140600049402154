export const STATUS_TYPE = [
  {
    id: 1,
    name: 'New',
  },
  {
    id: 2,
    name: 'Cancelled',
  },
  {
    id: 3,
    name: 'Delivery To Factory',
  },
  {
    id: 4,
    name: 'Processing',
  },
  {
    id: 5,
    name: 'Awaiting Delivery',
  },
  {
    id: 6,
    name: 'Delivery',
  },
  {
    id: 7,
    name: 'Ready',
  },
  {
    id: 8,
    name: 'Wrong Location',
  },
  {
    id: 9,
    name: 'Rejected',
  },
  {
    id: 10,
    name: 'Payment Pending',
  },
  {
    id: 11,
    name: 'Completed',
  },
  {
    id: 12,
    name: 'Incomplete',
  },
  {
    id: 13,
    name: 'Late',
  },
  {
    id: 14,
    name: 'Rejected By Presto',
  },
  {
    id: 15,
    name: 'Sorting',
  },
  {
    id: 16,
    name: 'Sorted',
  },
  {
    id: 17,
    name: 'Folded',
  },
  {
    id: 18,
    name: 'Proceed',
  },
  {
    id: 19,
    name: 'Archived',
  },
]
