import React, { useCallback } from 'react'
import { observer } from 'mobx-react'

import { useStores } from 'admin/hooks'
import CustomDataGrid from 'admin/components/data-grid'

import { formatDate } from 'admin/utils/helper'

const columns = [
  {
    field: 'title',
    headerName: 'Title',
    width: 300,
    renderCell: (params) => params.row.title || '-',
  },
  {
    field: 'slashtag',
    headerName: 'Slashtag',
    width: 300,
  },
  {
    field: 'destination',
    headerName: 'Destination',
    width: 300,
  },
  {
    field: 'expiredAt',
    headerName: 'Expired At',
    width: 300,
    renderCell: (params) => {
      return params.row.expiredAt ? formatDate(params.row.expiredAt) : '-'
    },
  },
]

const LinkDataGrid = () => {
  const { shortLinkStore } = useStores()

  const handlePageChange = useCallback((params) => {
    shortLinkStore.updatePage(params)
  }, [])

  const handlePageSizeChange = useCallback((param) => {
    shortLinkStore.updateRowsPerPage(param)
  }, [])

  return (
    <CustomDataGrid
      rows={shortLinkStore.list}
      columns={columns}
      handlePageChange={handlePageChange}
      rowCount={shortLinkStore.total}
      pageSize={shortLinkStore.rowsPerPage}
      page={shortLinkStore.currentPage}
      handlePageSizeChange={handlePageSizeChange}
    />
  )
}

export default observer(LinkDataGrid)
