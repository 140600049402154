export default () => ({
  page: ({ lockScroll }) => {
    return {
      flexGrow: 1,
      padding: '1rem',
      display: 'flex',
      flexDirection: 'column',
      overflow: lockScroll ? 'hidden' : 'auto',
    }
  },
})
