import React, { useState, useEffect, useCallback } from 'react'
import { computed } from 'mobx'

import {
  ImageList,
  ImageListItem,
} from '@mui/material'

import { useStores, useApiRequest } from 'admin/hooks'
import * as srv from 'admin/services'
import Protected from 'admin/components/protected'

import UploadImg from './upload-image'
import Img from './image'

const BookingImages = ({
  type = 'proof_of_delivery',
  title = 'Upload booking image, e.g.: proof of delivery image',
}) => {
  const {
    infoSectionStore,
    bookingStore,
    notificationStore,
    adminStore,
  } = useStores()

  const { selected: booking } = infoSectionStore
  const [images, setImages] = useState([])

  const {
    request: addBookingImage,
    isLoading: addProcessing,
  } = useApiRequest(srv.addBookingImage, { blocking: true })

  const {
    request: updateBookingImage,
    isLoading: updateProcessing,
  } = useApiRequest(srv.updateBookingImage, { blocking: true })

  const {
    request: fetchBookingImage,
  } = useApiRequest(srv.fetchBookingImage, { blocking: true })

  const handleUpload = useCallback(async (img) => {
    if (!img.file) return false
    if (addProcessing || updateProcessing) return false

    await srv.uploadfileAWSS3(img.preSignedUrl, img.file)
    const request = (img.id) ? updateBookingImage : addBookingImage
    const result = await request({
      id: img.id,
      bookingId: booking.id,
      type,
      url: img.fileUrl,
    })
    notificationStore.setSuccess('Booking image uploaded successfully')
    infoSectionStore.replace({ ...booking, images: [...booking.images, result.data] })
    return true
  })

  useEffect(() => {
    async function reloadBookingParent() {
      if (!booking.images) return
      const getImage = await fetchBookingImage({
        bookingId: booking.id,
      })
      bookingStore.setImageList(booking.id, getImage.data.list)
      const imgs = booking.images.filter(img => img.type === type)
      setImages(imgs)
    }
    reloadBookingParent()
    return () => {
      reloadBookingParent()
    }
  }, [booking])

  const isAccessible = computed(() => {
    const { isAdmin, isOfficeUser } = adminStore

    return isAdmin || isOfficeUser
  }).get()

  return (
    <>
      {
        isAccessible &&
           (
             <Protected
               level="update"
               category="booking"
             >
               <UploadImg
                 title={title}
                 onUpload={handleUpload}
               />
             </Protected>
           )
      }
      <ImageList cols={3} gap={16}>
        {images && images.map((image) => (
          <ImageListItem
            key={image.url}
            cols={1}
          >
            <Img
              img={image.url}
              title={image.type}
              imgId={image.id}
              bookingId={booking.id}
              onClick={() => {
                bookingStore.setSelectedImg(image.url)
                bookingStore.setImageDialog(true)
              }}
            />
          </ImageListItem>
        ))}
      </ImageList>
    </>
  )
}

export default BookingImages
