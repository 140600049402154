import 'core-js/stable'
import 'regenerator-runtime/runtime'
import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles'
import { CssBaseline } from '@mui/material'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers'

// Error logging
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'

import { appRoot } from './utils/helper'
import { rootStore } from './stores'
import { SENTRY_DSN, SENTRY_ENV } from './config'
import theme from './theme'
import App from './application'

Sentry.init({
  dsn: SENTRY_DSN,
  integrations: [new Integrations.BrowserTracing()],
  environment: SENTRY_ENV,
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
})

const render = () => {
  ReactDOM.render(
    // https://next.material-ui.com/guides/interoperability/#css-injection-order
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <CssBaseline />
          <Router>
            <App />
          </Router>
        </LocalizationProvider>
      </ThemeProvider>
    </StyledEngineProvider>,
    appRoot,
  )
}

const main = async () => {
  const { adminStore } = rootStore
  try {
    // no access token = not yet logged in
    if (adminStore.accessToken) {
      // fetch the user info using the access token
      await adminStore.current()
    }
  } catch (e) {
    console.log(e)
  }
  render()
}

main()
