import React, { useEffect, useCallback } from 'react'
import { observer } from 'mobx-react'
import {
  Typography,
  Stack,
  Button,
} from '@mui/material'

import { useStores } from 'admin/hooks'
import { formatDate, formatTimeOnly } from 'admin/utils/helper'
import CustomDataGrid from 'admin/components/data-grid'

const SyncButton = observer(({ mobileNo }) => {
  const { posStore } = useStores()

  const sync = useCallback((event) => {
    event.preventDefault()
    event.stopPropagation()
    if (mobileNo) posStore.syncPOSCustomer(mobileNo)
  }, [mobileNo])

  return (
    <Button
      variant="outlined"
      color="primary"
      size="small"
      onClick={sync}
    >
      Sync
    </Button>
  )
})

const columns = [
  {
    field: 'id',
    headerName: 'ID',
    width: 160,
  },
  {
    field: 'name',
    headerName: 'Name',
    flex: 1,
  },
  {
    field: 'mobileNo',
    headerName: 'Phone Number',
    width: 160,
  },
  {
    field: 'address',
    headerName: 'Address',
    flex: 2,
  },
  {
    field: 'createdTime',
    headerName: 'Created at',
    width: 145,
    renderCell: (params) => (
      <Stack direction="column">
        <Typography variant="h5">
          {formatDate(params.row.createdTime)}
        </Typography>
        <Typography variant="h5">
          {formatTimeOnly(params.row.createdTime)}
        </Typography>
      </Stack>
    ),
  },
  {
    field: 'lastUpdatedTime',
    headerName: 'Last updated at',
    width: 160,
    renderCell: (params) => (
      <Stack direction="column">
        <Typography variant="h5">
          {formatDate(params.row.lastUpdatedTime)}
        </Typography>
        <Typography variant="h5">
          {formatTimeOnly(params.row.lastUpdatedTime)}
        </Typography>
      </Stack>
    ),
  },
  {
    field: 'sync',
    headerName: 'Sync',
    width: 90,
    renderCell: (params) => (
      <SyncButton mobileNo={params.row.mobileNo} />
    ),
  },
]

const PosDatagridCustomer = () => {
  const { posStore, infoSectionStore } = useStores()

  const handlePageChange = useCallback((param) => {
    posStore.updatePage(param)
  }, [])

  const handlePageSizeChange = useCallback((param) => {
    posStore.updateRowsPerPage(param)
  }, [])

  const onSortModelChange = (param) => {
    if (param.length === 1) {
      posStore.updateQuery('sortBy', param[0].field, true)
      posStore.updateQuery('sortDirection', param[0].sort, true)
      return
    }
    posStore.resetQuery()
  }

  const handleRowClick = useCallback((rowData) => {
    infoSectionStore.select(rowData.row)
  }, [])

  useEffect(() => {
    posStore.fetchCustomer()
  }, [posStore.queryParams])

  return (
    <CustomDataGrid
      rows={posStore.list}
      columns={columns}
      handleRowClick={handleRowClick}
      handlePageChange={handlePageChange}
      rowCount={posStore.total}
      pageSize={posStore.rowsPerPage}
      page={posStore.currentPage}
      handlePageSizeChange={handlePageSizeChange}
      onSortModelChange={onSortModelChange}
      loading={posStore.loading}
      rowsPerPageOptions={[20]}
    />
  )
}

export default observer(PosDatagridCustomer)
