import React, {
  useEffect,
  useRef,
  useState,
} from 'react'
import moment from 'moment'
import { useHistory, useParams } from 'react-router-dom'
import { computed } from 'mobx'
import { observer } from 'mobx-react'
import {
  Step,
  Stepper,
  StepButton,
  StepLabel,
  Button,
  StepConnector,
  Typography,
} from '@mui/material'
import {
  Face,
  ListAlt,
  Schedule,
  AssignmentTurnedIn,
} from '@mui/icons-material'
import makeStyles from '@mui/styles/makeStyles'
import withStyles from '@mui/styles/withStyles'
import clsx from 'clsx'

import {
  validpostalCode,
  formatMonthDate,
  getSelectedDate,
  dateOnlyFormat,
  slotDisplay,
  getTags,
} from 'admin/utils/helper'
import { useStores, useApiRequest } from 'admin/hooks'
import * as srv from 'admin/services'

import Page from 'admin/components/page'
import ServiceIcon from 'admin/components/service-icon'
import CustomerTab from './customer'
import ServicesTab from './services'
import ScheduleTab from './schedule'
import SummaryTab from './summary'
import WarningEditCustomer from './warning-edit-customer'

import styles from './styles'

const useStyles = makeStyles(styles)

const QontoConnector = withStyles({
  alternativeLabel: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  active: {
    '& $line': {
      borderColor: '#E3AC24',
    },
  },
  completed: {
    '& $line': {
      borderColor: '#06A616',
    },
  },
  line: {
    borderColor: '#eaeaf0',
    borderTopWidth: 3,
    borderRadius: 1,
  },
})(StepConnector)

const StepIcon = (props) => {
  const classes = useStyles()
  const { active, completed } = props

  const icons = {
    1: <Face />,
    2: <ListAlt />,
    3: <Schedule />,
    4: <AssignmentTurnedIn />,
  }

  return (
    <div
      className={clsx(classes.iconRoot, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  )
}

const Booking = observer(() => {
  const classes = useStyles()
  const route = useHistory()
  const { id } = useParams()
  const {
    bookingStore,
    notificationStore,
    infoSectionStore,
    holidayStore,
  } = useStores()
  const containerRef = useRef()
  const [containerWidth, setContainerWidth] = useState(null)

  const { activeStep } = bookingStore

  const selectedSchedule = computed(() => {
    if (!bookingStore.selected?.timeslotId) return null
    const month = formatMonthDate(bookingStore.selected?.date)
    const slot = slotDisplay(bookingStore.selected?.slot?.start, bookingStore.selected?.slot?.end)
    return `${month}, ${slot}`
  }).get()

  useEffect(() => {
    const params = {
      // limit holiday query
      // admin's only allowed to select date between today and 30 days ahead
      startDate: dateOnlyFormat(moment()),
      endDate: dateOnlyFormat(moment().add(30, 'days')),
      limit: 31,
    }
    holidayStore.fetch(params)
  }, [])

  const selectedTag = computed(() => {
    const {
      pickup,
      delivery,
    } = getTags(bookingStore.selected?.bookingTags)
    return {
      pickup,
      delivery,
    }
  }).get()

  const steps = [
    {
      label: 'Customer',
      description: (
        <div className={classes.stepDescription}>
          <Typography className={classes.desc}>
            {bookingStore.selected?.customer?.name}
          </Typography>
          <Typography className={classes.desc}>
            {bookingStore.selected?.customer?.phoneNumber}
          </Typography>
        </div>
      ),
    },
    {
      label: 'Services',
      description: (
        <div className={classes.stepDescription}>
          {!!selectedTag.pickup.length > 0 && (
            <ServiceIcon
              iconName="pickup"
              data={selectedTag.pickup}
              className={classes.desc}
            />
          )}
          {!!selectedTag.delivery.length > 0 && (
            <ServiceIcon
              iconName="delivery"
              data={selectedTag.delivery}
              className={classes.desc}
            />
          )}
        </div>
      ),
    },
    {
      label: 'Schedule',
      description: (
        <div className={classes.stepDescription}>
          <Typography className={classes.desc}>
            {bookingStore.selected?.driver?.name}
          </Typography>
          {selectedSchedule && (
            <Typography className={classes.desc}>
              {selectedSchedule}
            </Typography>
          )}
        </div>
      ),
    },
    {
      label: 'Summary',
    },
  ]

  const {
    request: addBooking,
    isLoading: addProcessing,
  } = useApiRequest(srv.addBooking, { blocking: true })
  const {
    request: updateBooking,
    isLoading: updateProcessing,
  } = useApiRequest(srv.updateBooking, { blocking: true })
  const {
    request: fetchBookingInfo,
    error: errorFetchBookingInfo,
  } = useApiRequest(srv.fetchBookingInfo, { blocking: false })

  const handleStep = (step) => () => {
    if (activeStep !== 0 && step === 0) {
      bookingStore.setOpenWarningDialog(true)
    } else {
      bookingStore.updateActiveStep(step)
    }
  }

  const handleNext = async () => {
    try {
      const {
        selected,
        newBooking,
        checkedTags,
        customerSearchResult,
      } = bookingStore

      // new booking
      if (activeStep === 0) {
        // set selected date before going to select schedule screen
        const date = getSelectedDate({
          dayToSkip: 1,
          holidayStoreList: holidayStore.list,
        })

        bookingStore.setSelected({
          ...bookingStore.DEFAULT,
          customerId: customerSearchResult?.id,
          customer: customerSearchResult,
          date,
        })
        bookingStore.setNewBooking(true)
      }

      // select tag and invoice
      if (activeStep === 1) {
        if (newBooking) bookingStore.updateSelected('address', selected.customer?.address)
        bookingStore.updateSelected('bookingTags', checkedTags)
        if (!Array.isArray(checkedTags) || !checkedTags.length) {
          throw new Error('Please select service')
        }
      }

      if (activeStep === 2) {
        if (!selected.driverId) {
          throw new Error('Driver is required')
        }
        if (!selected.timeslotId) {
          throw new Error('Timeslot is required')
        }
        if (!selected.address) {
          throw new Error('Please insert an address before continue')
        }
        const postalCode = validpostalCode(selected.address) || selected.customer?.postalCode
        if (!postalCode) throw new Error('Please insert a postal code before continue')
      }

      // last screen
      if (activeStep === steps.length - 1) {
        if (!selected.channelId) {
          throw new Error('Channel is required')
        }

        const request = (newBooking) ? addBooking : updateBooking
        const response = await request(selected)
        if (!response) return
        // omit code because updateBooking always returns a new booking code
        const { code, ...restResponseData } = response.data
        let rest = null
        if (!newBooking) rest = restResponseData
        if (newBooking) rest = response.data
        infoSectionStore.replace({
          ...bookingStore.selected,
          ...rest,
        })
        if (newBooking) {
          notificationStore.setSuccess('Create new booking success')
        } else {
          notificationStore.setSuccess('Update booking success')
        }
        route.push('/booking-list')
        return
      }
      bookingStore.updateActiveStep(activeStep + 1)
    } catch (err) {
      notificationStore.setError(err)
    }
  }

  const handleBack = async () => {
    if (activeStep === 1) {
      if (id) {
        route.goBack()
      } else {
        bookingStore.setOpenWarningDialog(true)
      }
    } else {
      bookingStore.updateActiveStep(activeStep - 1)
    }
  }

  const buttonLabel = () => {
    const { newBooking } = bookingStore
    if (activeStep === steps.length - 1 && newBooking) return 'Confirm'
    if (activeStep === steps.length - 1 && !newBooking) return 'Update Booking'
    return 'Next'
  }

  useEffect(() => {
    if (containerRef.current) {
      setContainerWidth(containerRef.current.getBoundingClientRect().width)
    }
    if (id) {
      const fetchBookingData = async () => {
        try {
          bookingStore.setServiceLoad(true)
          const response = await fetchBookingInfo(id)
          if (response?.data) bookingStore.setSelected(response.data)
        } catch (error) {
          throw new Error(error)
        } finally {
          bookingStore.setServiceLoad(false)
          bookingStore.setNewBooking(false)
          bookingStore.updateActiveStep(1)
        }
      }
      fetchBookingData()
    }
  }, [id])

  useEffect(() => {
    // exit from create/edit booking flow
    return () => {
      bookingStore.resetBookingFlow()
    }
  }, [])

  if (
    id &&
    errorFetchBookingInfo &&
    errorFetchBookingInfo.message
  ) {
    return (
      <Typography className={classes.root}>
        {errorFetchBookingInfo.code === 404 ? (
          'Sorry, can\'t find that'
        ) : errorFetchBookingInfo.message}
      </Typography>
    )
  }

  return (
    <Page>
      <div className={classes.infoContainer}>
        {activeStep === 0 && (
          <CustomerTab
            actions={(
              <Button
                onClick={handleNext}
                classes={{ root: classes.customerBtn }}
                variant="contained"
                disabled={!bookingStore.customerSearchResult || bookingStore.loading}
              >
                Create
              </Button>
            )}
          />
        )}
        {activeStep === 1 && <ServicesTab />}
        {activeStep === 2 && <ScheduleTab containerWidth={containerWidth} />}
        {activeStep === 3 && <SummaryTab />}
      </div>
      {/* DONT show button wrapper for the first step */}
      <div ref={containerRef} className={classes.stepButtonWrapper}>
        <div style={{ flexBasis: '80%' }}>
          <Stepper
            activeStep={activeStep}
            connector={<QontoConnector />}
          >
            {steps.map((step, index) => (
              <Step key={step.label}>
                <StepButton
                  onClick={handleStep(index)}
                  style={{
                    textAlign: 'left',
                    margin: 0,
                    padding: 0,
                  }}
                >
                  <StepLabel StepIconComponent={StepIcon}>
                    <Typography className={classes.stepName}>
                      {step.label}
                    </Typography>
                    {step.description}
                  </StepLabel>
                </StepButton>
              </Step>
            ))}
          </Stepper>
        </div>
        {activeStep > 0 && (
          <div className={classes.buttonWrapper}>
            <Button
              variant="outlined"
              classes={{ root: classes.backBtn }}
              disabled={activeStep === 0 || bookingStore.loading}
              onClick={handleBack}
              className={classes.button}
            >
              Back
            </Button>
            <Button
              onClick={handleNext}
              variant="contained"
              classes={{ root: classes.customerBtn }}
              disabled={
                updateProcessing ||
                addProcessing ||
                !bookingStore.selected ||
                bookingStore.loading
              }
            >
              {buttonLabel()}
            </Button>
          </div>
        )}
      </div>
      <WarningEditCustomer />
    </Page>
  )
})

export default Booking
