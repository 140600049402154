export default function styles(theme) {
  return {
    rootContainer: {
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh',
      backgroundColor: theme.color.seashell,
    },
    contentContainer: {
      display: 'flex',
      flexDirection: 'row',
      flex: 1,
      position: 'relative',
    },
    appContent: {
      display: 'flex',
      flexDirection: 'column',
      flex: '1 1 auto',
      position: 'relative',
      maxHeight: '100vh',
      overflow: 'auto',
      padding: '0',
    },
    menuOpen: {
      marginLeft: theme.global.menuWidth,
    },
  }
}
