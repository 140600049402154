import React from 'react'
import { observer } from 'mobx-react'

import { useStores } from 'admin/hooks'

import CardItem from 'admin/components/dashboard-card/upper-content/overview'

const DriverHighest = () => {
  const { bookingStore } = useStores()
  const { countHighestDriver } = bookingStore

  return (
    <CardItem
      title="Highest No. Pick Up"
      driverName={countHighestDriver?.name || '-'}
      total={countHighestDriver?.total || 0}
      isDriver
    />
  )
}

export default observer(DriverHighest)
