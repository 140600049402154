import React, { useEffect } from 'react'
import { observer } from 'mobx-react'
import {
  Divider,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@mui/material'
import CancelIcon from '@mui/icons-material/Cancel'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { green, red } from '@mui/material/colors'

import makeStyles from '@mui/styles/makeStyles'
import { useStores } from 'admin/hooks'
import { formatDateTimeSeconds } from 'admin/utils/helper'

import HeadBar from 'admin/components/head-bar'

import styles from './styles'

const useStyles = makeStyles(styles)

const LogData = ({ data }) => {
  const classes = useStyles()
  const createdAt = formatDateTimeSeconds(data.createdAt)
  return (
    <>
      <ListItem>
        {data.status === 'succeeded' ? (
          <CheckCircleIcon sx={{ color: green[500] }} />
        ) : (
          <CancelIcon sx={{ color: red[500] }} />
        )}
        <ListItemText
          primary={data.status}
          secondary={createdAt}
          className={classes.listItemTextPadding}
        />
      </ListItem>
      <HeadBar
        data={[
          {
            label: 'Amount',
            value: Number(data.amount).toFixed(2),
          },
          {
            label: 'Fee',
            value: Number(data.fee).toFixed(2),
          },
          {
            label: 'Net',
            value: Number(data.net).toFixed(2),
          },
          {
            label: 'Invoices',
            value: data.invoicePrestoIds,
          },
          {
            label: 'Reference Number',
            value: data.referenceNumber,
          },
          {
            label: 'Fail reason',
            value: data.declineCode,
          },
        ]}
      />
      <Divider component="li" />
    </>
  )
}

const LogDataList = observer(() => {
  const { bookingStore } = useStores()

  return (
    <div>
      {bookingStore.payments.map((row) => (
        <LogData key={row.id} data={row} />
      ))}
    </div>
  )
})

const APPInfo = () => {
  const {
    infoSectionStore,
    bookingStore,
  } = useStores()
  const { selected: booking } = infoSectionStore

  useEffect(() => {
    const paramsPayments = {
      id: booking.id,
    }
    bookingStore.fetchPayments(paramsPayments)
  }, [booking])

  if (!bookingStore.payments.length) return null

  return (
    <List
      sx={{
        width: '100%',
      }}
    >
      <Typography
        sx={{ mt: 0.5, ml: 2 }}
        variant="h6"
      >
        Latest Status:
      </Typography>
      <LogDataList />
    </List>

  )
}

export default observer(APPInfo)
