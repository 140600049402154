import React, { useCallback } from 'react'
import { observer } from 'mobx-react'
import { DatePicker } from '@mui/x-date-pickers'
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  FormControl,
  TextField as MuiTextField,
} from '@mui/material'
import { useForm, Controller } from 'react-hook-form'

import { useStores, useApiRequest } from 'admin/hooks'
import * as srv from 'admin/services'

import TextField from 'admin/components/textfield'
import CustomizedDialogTitle from 'admin/components/dialog-title'

const ShortLinkForm = () => {
  const { shortLinkStore, notificationStore } = useStores()

  const { control, handleSubmit: handleSubmitForm, reset } = useForm({
    defaultValues: { ...shortLinkStore.DEFAULT },
  })

  const {
    request: createShortLink,
    isLoading: createProcessing,
  } = useApiRequest(srv.createShortLink, { blocking: true })

  const handleClose = useCallback(() => {
    reset({ ...shortLinkStore.DEFAULT })
    shortLinkStore.setCloseDialog(false)
  }, [])

  const handleSubmit = useCallback(async (value) => {
    try {
      const res = await createShortLink(value)
      if (!res) return

      reset({ ...shortLinkStore.DEFAULT })
      notificationStore.setSuccess('Create short link success')
      shortLinkStore.setCloseDialog(false)
      shortLinkStore.reload()
    } catch (error) {
      notificationStore.setError(error)
    }
  }, [])

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={shortLinkStore.dialog}
      aria-labelledby="Short Link Form"
      onClose={handleClose}
    >
      <CustomizedDialogTitle>
        Create Short Link
      </CustomizedDialogTitle>
      <form
        noValidate
        onSubmit={handleSubmitForm(handleSubmit)}
        autoComplete="off"
      >
        <DialogContent>
          <FormControl
            fullWidth
            margin="normal"
          >
            <Controller
              control={control}
              name="title"
              render={({
                field: { ref, ...fieldProps },
                fieldState: { error },
              }) => (
                <TextField
                  label="Title"
                  InputLabelProps={{ shrink: true }}
                  error={!!error?.message}
                  helperText={error?.message}
                  inputRef={ref}
                  {...fieldProps}
                />
              )}
            />
          </FormControl>
          <FormControl
            fullWidth
            margin="normal"
          >
            <Controller
              control={control}
              name="slashtag"
              render={({
                field: { ref, ...fieldProps },
                fieldState: { error },
              }) => (
                <TextField
                  label="Slashtag"
                  InputLabelProps={{ shrink: true }}
                  error={!!error?.message}
                  helperText={error?.message}
                  inputRef={ref}
                  {...fieldProps}
                />
              )}
            />
          </FormControl>
          <FormControl
            fullWidth
            margin="normal"
          >
            <Controller
              control={control}
              name="destination"
              render={({
                field: { ref, ...fieldProps },
                fieldState: { error },
              }) => (
                <TextField
                  label="Destination"
                  InputLabelProps={{ shrink: true }}
                  error={!!error?.message}
                  helperText={error?.message}
                  inputRef={ref}
                  {...fieldProps}
                />
              )}
            />
          </FormControl>
          <FormControl
            fullWidth
            margin="normal"
          >
            <Controller
              name="expiredAt"
              control={control}
              render={({ field }) => {
                return (
                  <DatePicker
                    {...field}
                    label="Expired At"
                    mask="__/__/____"
                    inputFormat="dd/MM/yyyy"
                    onChange={(e) => field.onChange(e)}
                    selected={field.value}
                    renderInput={(params) => {
                      return (
                        <MuiTextField
                          {...params}
                          size="small"
                          helperText={null}
                        />
                      )
                    }}
                  />
                )
              }}
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            color="primary"
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={createProcessing}
          >
            Create
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default observer(ShortLinkForm)
