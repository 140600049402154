export default function styles(theme) {
  return {
    wrapperBalance: {
      padding: '1rem',
      backgroundColor: theme.color.seashell,
    },
    balanceLog: {
      maxHeight: '100vh',
      overflow: 'auto',
      padding: '1rem',
    },
    titleLogLabel: {
      marginBottom: '0.5rem',
    },
    infoLog: {
      padding: '0.2rem 0 0.2rem 0',
    },
    infoTransaction: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    greenTypelabel: {
      color: theme.color.limeGreen,
    },
    orangeTypelabel: {
      color: theme.color.orange,
    },
    timeLabel: {
      color: theme.color.dustyGray,
      fontSize: '14px',
    },
  }
}
