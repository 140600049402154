import { api } from './api'

export const fetchDriver = (query) => api.get('/driver', query)
export const fetchDriverById = (id, day) => api.get(`/driver/${id}`, { day })
export const fetchDriverSlot = (query) => api.get(`/driver/${query.id}/slots`, query)
export const fetchDriverHoliday = (query) => api.get(`/driver/${query.id}/holiday`, query)
export const searchDriverHoliday = (query) => api.get(`/driver/${query.id}/holiday/search`, query)
export const getBookingCount = (query) => api.get(`/driver/${query.id}/booking/count`, query)

export const downloadReport = (query) => api.get('/driver/download-report', query)

export const addDriver = (driver) => api.post('/driver', driver)
export const bulkAddDriverHoliday = (holiday) => api.post(`/driver/${holiday.driverId}/holiday/bulk-add`, holiday)

export const updateDriver = (driver) => api.put(`/driver/${driver.id}`, driver)
export const updateDriverSlots = (id, slots, day) => api.put(`/driver/${id}/slots`, { slots, day })
export const updateDriverImage = (driver, imageUrl) => api.put(`/driver/${driver.id}/image`, { imageUrl })
export const updateDriverHoliday = (holiday) => api.put(`/driver/${holiday.driverId}/holiday/${holiday.id}`, holiday)
export const updateDriverPassword = (driver, password) => api.put(`/driver/${driver.id}/password`, { password })

export const addDriverWorkDays = (driver, workDays) => api.post(`/driver/${driver.id}/workdays`, workDays)
export const updateDriverWorkDays = (driver, workDays) => api.put(`/driver/${driver.id}/workdays`, workDays)

export const deleteDriver = (id) => api.delete(`/driver/${id}`)
export const deleteDriverHoliday = (driver) => api.delete(`/driver/${driver.id}/holiday/${driver.holidayId}`)
export const bulkDeleteDriverHoliday = (holiday) => api.post(`/driver/${holiday.driverId}/holiday/bulk-delete`, holiday)
