import * as Yup from 'yup'
import { phoneRegex } from 'admin/utils/regex'

const ProfileSchema = Yup.object().shape({
  name: Yup.string()
    .required('Name is required.'),
  username: Yup.string()
    .required('Username is required.'),
  email: Yup.string()
    .required('Email is required.')
    .email('Email is invalid.'),
  phoneNumber: Yup.string()
    .required('Phone number is required.')
    .matches(phoneRegex, 'Phone number is invalid.'),
})

export default ProfileSchema
