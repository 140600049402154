import React, { useCallback, useEffect, useState, useRef } from 'react'
import { observer } from 'mobx-react'
import { computed } from 'mobx'
import { useHistory } from 'react-router-dom'
import moment from 'moment'
import makeStyles from '@mui/styles/makeStyles'
import {
  Button,
  IconButton,
  Typography,
  Stack,
} from '@mui/material'
import {
  Info as InfoIcon,
  Edit as EditIcon,
  WhatsApp as WhatsAppIcon,
  Cached as CachedIcon,
  CheckBoxOutlineBlank as CheckBoxOutline,
  CheckBox as CheckBoxIcon,
} from '@mui/icons-material'

import { useStores } from 'admin/hooks'
import { BOOKING_STATUS, CUSTOMER_CREATED } from 'admin/config'
import {
  getTags,
  formatDayName,
  formatDateHourAmPm,
  slotDisplay,
  priority,
  joinText,
  getFormattedTime,
  formatMonthShort,
  formatTimeOnly,
} from 'admin/utils/helper'
import whatsappMessage from 'admin/utils/whatsapp-message'
import Protected from 'admin/components/protected'

import ServiceIcon from 'admin/components/service-icon'
import Status from 'admin/components/status'
import CopyToClipboardButton from 'admin/components/copy-to-clipboard'
import MenuOption from './menu-popper'
import ConfirmDialog from './confirm-dialog'
import RemarksDialog from './remarks-dialog'
import ReviewedDialog from './reviewed-dialog'

import BookingImages from '../booking-image'

import styles from './styles'

const useStyles = makeStyles(styles)

const PHONE_CODE = 65
const getWhatsAppLink = (option) => {
  const messageText = encodeURIComponent(whatsappMessage(
    option.name,
    option.phoneNumber,
    option.address,
    option.bookingDate,
    option.slot,
    option.tagPriority,
    option.bookingCreatedAt,
    option.pickupTemplate,
  ))
  return `https://api.whatsapp.com/send/?phone=${option.phoneNumber}&text=${messageText}`
}

const EditDeleteMenu = observer(() => {
  const classes = useStyles()
  const route = useHistory()
  const {
    bookingStore,
    infoSectionStore,
  } = useStores()

  const { selected: booking } = infoSectionStore

  const editBooking = useCallback(() => {
    if (booking.id) {
      route.push(`/booking/${booking.id}`)
    }
  }, [booking?.id])

  const {
    pickup,
  } = getTags(booking.bookingTags)

  const sendWhatsApp = useCallback(() => {
    const { customer, ...otherData } = booking
    const custumerData = {
      name: customer.name,
      address: booking.address || customer.address,
      phoneNumber: `${PHONE_CODE}${customer.phoneNumber}`,
      bookingDate: formatMonthShort(otherData.date),
      slot: `${getFormattedTime(otherData.slot.start)} - ${getFormattedTime(otherData.slot.end)}`,
      tagPriority: priority(otherData.bookingTags),
      bookingCreatedAt: otherData.createdAt,
      pickupTemplate: pickup.length > 0,
    }

    const url = getWhatsAppLink(custumerData)
    window.open(url, '_blank')
  }, [booking, pickup])

  const openDeleteBookingDialog = useCallback(() => {
    bookingStore.setOpenDialog(booking, 'delete')
  }, [booking])

  if (booking.status === BOOKING_STATUS.FAILED) return null

  return (
    <Stack
      direction="row"
      justifyContent="center"
    >
      <Protected
        level="update"
        category="booking"
      >
        <Button
          variant="contained"
          color="whatsapp"
          onClick={sendWhatsApp}
          startIcon={<WhatsAppIcon />}
          className={classes.whatsappButton}
        >
          Send Whatsapp
        </Button>
      </Protected>
      <Protected
        level="update"
        category="booking"
      >
        <Button
          variant="contained"
          color="secondary"
          onClick={editBooking}
          className={classes.detailButton}
        >
          Edit
        </Button>
      </Protected>
      <Protected
        level="delete"
        category="booking"
      >
        <Button
          variant="outlined"
          color="secondary"
          onClick={openDeleteBookingDialog}
          className={classes.detailButton}
        >
          Delete
        </Button>
      </Protected>
    </Stack>
  )
})

const Information = () => {
  const classes = useStyles()
  const { infoSectionStore, bookingStore, adminStore } = useStores()
  const anchorRefPopper = useRef(null)
  const [open, setOpen] = useState(false)
  const prevOpen = useRef(open)
  const { query } = bookingStore

  const { selected: booking } = infoSectionStore

  const isAccessible = computed(() => {
    const { isAdmin, admin } = adminStore

    return isAdmin || admin.jobRole?.name === 'Office User'
  }).get()

  const {
    pickup,
    delivery,
  } = getTags(booking.bookingTags)

  const bookingInfo = computed(() => {
    if (!booking) return null
    let appPaymentMethod = null
    if (
      booking.payments &&
      Array.isArray(booking.payments) &&
      booking.payments.length
    ) {
      appPaymentMethod = booking.payments[0].paymentMethod
    }
    return [
      {
        label: 'Channel',
        value: booking.channel?.name,
        select: booking.hasReviewed,
      },
      {
        label: 'Driver',
        value: booking.driver ? `${booking.driver.name} (${booking.driver.code})` : '-',
      },
      {
        label: 'Booking Address',
        value: booking.address || booking.customer?.address,
      },
      {
        label: 'Created By',
        value: booking.createdBy,
      },
      {
        label: 'Deleted By',
        value: booking.deletedBy,
      },
      {
        label: 'Deleted At',
        value: booking.deletedAt ? formatDateHourAmPm(booking.deletedAt) : null,
      },
      {
        label: 'Payment Method',
        value: appPaymentMethod,
      },
    ]
  }).get()

  const textToCopy = computed(() => {
    if (!booking) return ''
    if (pickup.length === 0 && delivery.length === 0) return ''
    const name = booking.customer?.name || '-'
    const phoneNumber = booking.customer?.phoneNumber || '-'
    const address = booking.address || booking.customer?.address
    const date = moment(booking.date).format('DD MMM')
    const day = moment(booking.date).format('ddd')
    const time = slotDisplay(booking.slot?.start, booking.slot?.end)
    const scheduled = `*Scheduled on ${date} (${day}) ${time}*`
    const remarks = booking.remarks ? `\n*Remarks: ${booking.remarks}*` : ''

    if (pickup.length > 0) {
      const pickupTags = joinText(pickup)
      return `${name}\n${phoneNumber}\n${address}\n${pickupTags}\n${scheduled}${remarks}`
    }
    const deliveryTags = joinText(delivery)
    return `*Delivery: ${deliveryTags}*\n\n${name}\n${phoneNumber}\n${address}\n${scheduled}${remarks}`
  }).get()

  const handleClose = () => {
    setOpen(false)
  }

  const handleUpdateStatus = useCallback(async (status, reason) => {
    bookingStore.setConfirmStatus({ status, reason })
    handleClose()
  }, [booking])

  const handleToggle = () => {
    setOpen((revOpen) => !revOpen)
  }

  const handleEditRemarks = () => {
    bookingStore.setOpenDialog(booking, 'remarks')
  }

  const handleReviewed = () => {
    bookingStore.setOpenDialog(booking, 'hasReviewed')
  }

  const handleShowRebook = () => {
    bookingStore.setSelected(booking)
    bookingStore.setRebookDialog(true)
  }

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRefPopper.current.focus()
    }

    prevOpen.current = open
  }, [open])

  return (
    <>
      <div className={classes.titleSection}>
        <div className={classes.infoItem}>
          <Stack
            direction="row"
            justifyContent="space-between"
          >
            <div>
              <Typography variant="h6">
                Booking Date & Time
              </Typography>
              <Typography>
                {formatDayName(booking.date)}, {slotDisplay(booking.slot?.start, booking.slot?.end)}
              </Typography>
            </div>
            {!!booking && (
              <CopyToClipboardButton text={textToCopy} />
            )}
          </Stack>
        </div>
        <div className={classes.infoItem}>
          <Typography variant="h6">
            Date Created
          </Typography>
          <Typography>
            {formatDayName(booking.createdAt)}, {formatTimeOnly(booking.createdAt)}
          </Typography>
        </div>
        <div className={classes.infoItem}>
          <Stack
            direction="row"
            alignItems="center"
          >
            <Typography variant="h6">
              Booking Status
            </Typography>
            { !query.showDelete &&
              isAccessible &&
                (
                  <IconButton
                    onClick={handleToggle}
                    ref={anchorRefPopper}
                    size="small"
                    color="secondary"
                    variant="contained"
                    className={classes.iconButton}
                  >
                    <EditIcon size="small" className={classes.iconEdit} />
                  </IconButton>
                )}
          </Stack>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Stack
              direction="row"
              alignItems="center"
            >
              <Status
                variant="h5"
                status={booking.status}
              />
              { !query.showDelete &&
                booking.status === 'failed' && (
                <Stack
                  direction="row"
                  alignItems="center"
                  className={classes.failedReason}
                >
                  <InfoIcon className={classes.infoIcon} />
                  <Typography
                    variant="h4"
                    className={classes.reasonLabel}
                  >
                    {booking.reason}
                  </Typography>
                </Stack>
              )}
              { booking.completedTime &&
                booking.status === 'completed' && (
                <Typography
                  variant="h4"
                  className={classes.completeTime}
                >
                  {formatDateHourAmPm(booking.completedTime)}
                </Typography>
              )}
            </Stack>
            {
              booking.status === 'failed' && (
                <Button
                  variant="text"
                  color="error"
                  startIcon={<CachedIcon />}
                  onClick={handleShowRebook}
                >
                  Rebook
                </Button>
              )
            }
          </Stack>
        </div>
        <div className={classes.infoItem}>
          <Typography
            variant="h6"
            className={classes.label}
          >
            Service
          </Typography>
          <div>
            {pickup.length > 0 && (
              <ServiceIcon
                iconName="pickup"
                data={pickup}
                className={classes.tagColor}
              />
            )}
            {delivery.length > 0 && (
              <ServiceIcon
                iconName="delivery"
                data={delivery}
                className={classes.tagColor}
              />
            )}
          </div>
        </div>
        <div className={classes.infoItem}>
          <Stack
            direction="row"
            alignItems="center"
          >
            <Typography variant="h6">
              Booking Remarks
            </Typography>
            {!query.showDelete && (
              <IconButton
                onClick={handleEditRemarks}
                size="small"
                color="secondary"
                variant="contained"
                className={classes.iconButton}
              >
                <EditIcon size="small" className={classes.iconEdit} />
              </IconButton>
            )}
          </Stack>
          <Typography>
            {booking.remarks}
          </Typography>
        </div>
        {bookingInfo && bookingInfo.map((item) => {
          if ((booking.status !== 'deleted') && (item.label === 'Deleted At')) return null
          if ((booking.status !== 'deleted') && (item.label === 'Deleted By')) return null
          if ((booking.userType !== 'customer') && (item.label === 'Payment Method')) return null
          return (
            <Stack
              direction="row"
              justifyContent="space-between"
              key={item.label}
              className={classes.infoItem}
            >
              <div>
                <Typography variant="h6">
                  {item.label}
                </Typography>
                <Typography>
                  {item.value}
                </Typography>
              </div>
              {CUSTOMER_CREATED.includes(item.value) && (
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={handleReviewed}
                  startIcon={item.select ? <CheckBoxIcon /> : <CheckBoxOutline />}
                >
                  Reviewed
                </Button>
              )}
            </Stack>
          )
        })}
        {!query.showDelete && (
          <BookingImages
            type="proof_of_delivery"
            title="Upload booking image, e.g.: proof of delivery image"
          />
        )}
      </div>
      {!query.showDelete && (
        <EditDeleteMenu />
      )}
      <MenuOption
        anchorRef={anchorRefPopper}
        open={open}
        onClose={handleClose}
        onStatusUpdate={handleUpdateStatus}
      />
      <ConfirmDialog />
      <RemarksDialog />
      <ReviewedDialog />
    </>
  )
}

export default observer(Information)
