import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react'
import makeStyles from '@mui/styles/makeStyles'

import { useStores, useDebounce } from 'admin/hooks'
import { replaceToNumbers } from 'admin/utils/helper'

import Page from 'admin/components/page'
import TextField from 'admin/components/textfield'

import CustomerDataGrid from './datagrid'
import DriverInfo from './info'
import SyncButton from './sync'

import styles from './styles'

const useStyles = makeStyles(styles)

const FilterAndAction = () => {
  const classes = useStyles()
  const { customerStore } = useStores()

  const [phoneNumber, setPhoneNumber] = useState('')

  const debounceCustomerNumber = useDebounce(phoneNumber, 300)

  useEffect(() => {
    customerStore.updateQuery('phoneNumber', debounceCustomerNumber.trim(), true)
  }, [debounceCustomerNumber])

  return (
    <div className={classes.upperSection}>
      <TextField
        name="phoneNumber"
        type="text"
        variant="outlined"
        size="small"
        fullWidth
        className={classes.field}
        label="Search by Phone Number"
        value={phoneNumber}
        onChange={(e) => {
          setPhoneNumber(replaceToNumbers(e.target.value))
        }}
      />
      <SyncButton />
    </div>
  )
}

const Customer = () => (
  <Page>
    <FilterAndAction />
    <CustomerDataGrid />
    <DriverInfo />
  </Page>
)

export default observer(Customer)
