import React, { useEffect } from 'react'
import { observer } from 'mobx-react'
import makeStyles from '@mui/styles/makeStyles'
import { TextField } from '@mui/material'
import Keyboard from 'react-simple-keyboard'
import 'react-simple-keyboard/build/css/index.css'

import { useStores } from 'admin/hooks'

import styles from './styles'

const useStyles = makeStyles(styles)

const VirtualKeyboard = () => {
  const classes = useStyles()
  const { keyboardStore } = useStores()
  const [input, setInput] = React.useState('')
  const [layout, setLayout] = React.useState('default')
  const keyboard = React.useRef(null)

  const onInputChange = (value) => {
    setInput(value)
    keyboard.current.setInput(value)
  }

  const preventEventBubble = (e) => {
    e.preventDefault()
    e.stopPropagation()
  }

  const handleClose = (e) => {
    e.preventDefault()
    e.stopPropagation()
    keyboardStore.setInput('')
    keyboardStore.setOnChange(null)
    onInputChange('')
  }

  const onChange = (data) => {
    onInputChange(data)
    keyboardStore.onChange(data)
  }

  const handleShift = () => {
    const newLayoutName = layout === 'default' ? 'shift' : 'default'
    setLayout(newLayoutName)
  }

  const onKeyPress = (button) => {
    if (
      button === '{shift}' ||
      button === '{lock}'
    ) {
      handleShift()
    }
    if (button === '{enter}') keyboardStore.setOnChange(null)
  }

  useEffect(() => {
    if (!keyboardStore.onChange) return
    onInputChange(keyboardStore.input)
  }, [keyboardStore.input, keyboardStore.onChange])

  return (
    <div style={{ display: keyboardStore.onChange ? 'block' : 'none' }}>
      <div
        className={classes.backdrop}
        onClick={handleClose}
        onMouseDown={preventEventBubble}
      />
      <div className={classes.container}>
        <TextField
          fullWidth
          value={input}
          style={{ background: 'white' }}
          readOnly
        />
        <Keyboard
          keyboardRef={(r) => { keyboard.current = r }}
          layoutName={layout}
          onChange={onChange}
          onKeyPress={onKeyPress}
        />
      </div>
    </div>
  )
}

export default observer(VirtualKeyboard)
