import React, { useState, useCallback } from 'react'
import { observer } from 'mobx-react'
import makeStyles from '@mui/styles/makeStyles'

import { useStores } from 'admin/hooks'

import InfoSection from 'admin/components/info-section'
import HeadBar from 'admin/components/head-bar'
import CustomizedTabs from 'admin/components/tabs'
import Information from './information'
import Password from './password'

import styles from './styles'

const useStyles = makeStyles(styles)

const AdminInfo = () => {
  const classes = useStyles()
  const [activeTab, setActiveTab] = useState(0)

  const {
    infoSectionStore,
  } = useStores()

  const { selected: admin } = infoSectionStore

  const handleChangeTab = useCallback((event, value) => {
    setActiveTab(value)
  }, [])

  return (
    <InfoSection
      title="Admin"
    >
      <div className={classes.parentContainer}>
        {admin && (
          <>
            <HeadBar
              data={[{
                label: 'Username',
                value: admin.username,
              }, {
                label: 'Email',
                value: admin.email,
              }]}
            />
            <CustomizedTabs
              activeTab={activeTab}
              handleChangeTab={handleChangeTab}
              labels={[
                'Information',
                'Password',
              ]}
            />
            <div className={classes.infoContainer}>
              {activeTab === 0 && <Information key={`info-${admin.id}`} />}
              {activeTab === 1 && <Password key={`password-${admin.id}`} />}
            </div>
          </>
        )}
      </div>
    </InfoSection>
  )
}

export default observer(AdminInfo)
