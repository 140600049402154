import { makeAutoObservable, flow } from 'mobx'
import moment from 'moment'

import { TODAY } from 'admin/utils/helper'
import * as srv from 'admin/services'
import handleError from 'admin/services/error-handler'

export default class HolidayStore {
  loading = false

  dialog = {
    form: false,
    delete: false,
  }

  selected = null
  list = []
  total = 0
  query = {
    year: moment().format('YYYY').toString(),
    sortBy: 'date',
    sortDirection: 'asc',
  }
  currentPage = 0
  rowsPerPage = 10

  DEFAULT = {
    date: TODAY(),
    remarks: '',
    type: 'holiday',
  }

  constructor() {
    makeAutoObservable(this, {
      reload: flow,
      fetch: flow,
      searchHoliday: flow,
    })
  }

  setOpenDialog = (selected, type) => {
    this.dialog[type] = true
    if (selected) {
      this.selected = { ...this.DEFAULT, ...selected }
    } else {
      this.selected = { ...this.DEFAULT }
    }
  }

  setCloseDialog = (type, reset = true) => {
    this.dialog[type] = false
    if (reset) setTimeout(this.resetSelected, 50)
  }

  resetSelected = () => {
    this.selected = { ...this.DEFAULT }
  }

  updateSelected = (key, value) => {
    this.selected = { ...this.selected, [key]: value }
  }

  updatePage = (page) => {
    this.currentPage = page
  }

  updateRowsPerPage = (perPage) => {
    this.rowsPerPage = perPage
  }

  updateQuery = (key, value, resetPage = false) => {
    this.query[key] = value
    if (resetPage) this.currentPage = 0
  }

  resetQuery = (valYear) => {
    this.query = {
      year: valYear || moment().format('YYYY').toString(),
      sortBy: 'date',
      sortDirection: 'asc',
    }
    this.currentPage = 0
    this.rowsPerPage = 10
  }

  resetList = () => {
    this.list = []
    this.total = 0
    this.resetQuery()
  }

  * reload(params, reset = false) {
    yield this.fetch(params)
    if (reset) {
      this.selected = { ...this.DEFAULT }
    }
  }

  get queryParams() {
    const params = {
      sortBy: this.query.sortBy,
      sortDirection: this.query.sortDirection,
      limit: this.rowsPerPage,
      offset: this.currentPage * this.rowsPerPage,
    }
    if (this.query.year) params.year = this.query.year
    return params
  }

  * fetch(params) {
    try {
      this.loading = true
      const response = yield srv.fetchHoliday({
        ...this.queryParams,
        ...params,
      })
      if (response.ok) {
        this.list = response.data.list
        this.total = response.data.total
        return response.data
      }
      throw handleError(response)
    } catch (error) {
      return null
    } finally {
      this.loading = false
    }
  }

  * searchHoliday(params) {
    try {
      this.loading = true
      const response = yield srv.searchHoliday(params)
      if (response.ok) return response.data
      throw handleError(response)
    } catch (error) {
      return null
    } finally {
      this.loading = false
    }
  }
}
