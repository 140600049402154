export default function styles(theme) {
  return {
    upperTitle: {
      marginBottom: '0.75rem',
    },
    icon: {
      marginRight: '0.7rem',
    },
    today: {
      marginRight: '1rem',
    },
    todayButton: {
      marginRight: '0.75rem',
    },
    tab: {
      textTransform: 'none',
      color: theme.palette.secondary.main,
      fontWeight: 600,
    },
  }
}
