import React, { useEffect, useCallback } from 'react'
import { observer } from 'mobx-react'

import { useStores } from 'admin/hooks'
import CustomDataGrid from 'admin/components/data-grid'

const columns = [
  {
    field: 'id',
    headerName: 'ID',
    width: 160,
  },
  {
    field: 'name',
    headerName: 'Name',
    width: 160,
  },
  {
    field: 'tel',
    headerName: 'Phone Number',
    width: 160,
  },
  {
    field: 'initial',
    headerName: 'Code',
    width: 120,
  },
  {
    field: 'pin',
    headerName: 'PIN',
    width: 120,
  },
  {
    field: 'branches',
    headerName: 'Branches',
    width: 160,
  },
  {
    field: 'homeCollectionBranchId',
    headerName: 'Home Collection',
    width: 160,
  },
]

const PosDatagridDriver = () => {
  const { posStore, infoSectionStore } = useStores()

  const handlePageChange = useCallback((param) => {
    posStore.updatePage(param)
  }, [])

  const handlePageSizeChange = useCallback((param) => {
    posStore.updateRowsPerPage(param)
  }, [])

  const onSortModelChange = (param) => {
    if (param.length === 1) {
      posStore.updateQuery('sortBy', param[0].field, true)
      posStore.updateQuery('sortDirection', param[0].sort, true)
      return
    }
    posStore.resetQuery()
  }

  const handleRowClick = useCallback((rowData) => {
    infoSectionStore.select(rowData.row)
  }, [])

  useEffect(() => {
    posStore.fetchDriver()
  }, [posStore.queryParams])

  return (
    <CustomDataGrid
      rows={posStore.list}
      columns={columns}
      handleRowClick={handleRowClick}
      handlePageChange={handlePageChange}
      rowCount={posStore.total}
      pageSize={posStore.rowsPerPage}
      page={posStore.currentPage}
      handlePageSizeChange={handlePageSizeChange}
      onSortModelChange={onSortModelChange}
      loading={posStore.loading}
      rowsPerPageOptions={[20]}
    />
  )
}

export default observer(PosDatagridDriver)
