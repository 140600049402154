import React from 'react'
import { observer } from 'mobx-react'
import { useStores } from 'admin/hooks'
import makeStyles from '@mui/styles/makeStyles'

import {
  Divider,
  Typography,
} from '@mui/material'

import { formatDateHourAmPm } from 'admin/utils/helper'

import styles from './styles'

const useStyles = makeStyles(styles)

const InfoItem = ({ value }) => {
  const classes = useStyles()

  return value && value.map((data) => (
    <div key={data.label} className={classes.sectionContainer}>
      <Typography variant="h6" className={classes.label}>
        {data.label}
      </Typography>
      <Typography variant="h6" className={classes.description}>
        {data.description}
      </Typography>
    </div>
  ))
}

const SmsLogInfo = () => {
  const classes = useStyles()
  const { infoSectionStore } = useStores()

  const { selected: actionLog } = infoSectionStore

  const { value } = actionLog
  const data = Object.entries(value).filter(item => Array.isArray(item[1]) && item[1].length > 0)

  if (Array.isArray(data) && !data.length) {
    return (
      <div className={classes.bookingContainer}>
        <Typography variant="h6">
          No reminders
        </Typography>
      </div>
    )
  }

  return actionLog && data.map(item => (
    <div key={item} className={classes.log}>
      <div className={classes.bookingContainer}>
        <Typography variant="h6" className={classes.bookingTitle}>
          Booking created date
        </Typography>
        <Typography variant="h6" className={classes.bookingSubtitle}>
          {formatDateHourAmPm(new Date(item[0]))}
        </Typography>
      </div>
      {item[1].map(info => (
        <div key={info.code} className={classes.infoItem}>
          <InfoItem
            value={[
              {
                label: 'Phone Number',
                description: info.to,
              },
              {
                label: 'Code',
                description: info.code,
              },
              {
                label: 'Day Reminder',
                description: info.dayOrder,
              },
            ]}
          />
          {info.message && (
            <Typography variant="h6" className={classes.message}>
              {info.message}
            </Typography>
          )}
          <Divider className={classes.divider} />
        </div>
      ))}
    </div>
  ))
}

export default observer(SmsLogInfo)
