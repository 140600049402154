import React, {
  Suspense,
  useCallback,
  useState,
  useEffect,
} from 'react'
import { observer } from 'mobx-react'
import { computed } from 'mobx'
import moment from 'moment'

import makeStyles from '@mui/styles/makeStyles'
import {
  Box,
  Button,
  IconButton,
  Stack,
  Tab,
  Tabs,
  Typography,
} from '@mui/material'
import {
  FilterList,
  DateRange,
} from '@mui/icons-material/'

import { useStores } from 'admin/hooks'
import {
  formatDayName,
  lastSunday,
  lastSaturday,
} from 'admin/utils/helper'

import Page from 'admin/components/page'
import Dialog from 'admin/components/dialog'
import DateRangePicker from 'admin/components/date-range-picker'

import FilterDialog from './filter'
import Analytics from './analytics'
import Listing from './listing'

import styles from './styles'

const useStyles = makeStyles(styles)

const VIEW_MODE = [
  'Listing',
  'Analytics',
]

const DefectImageDialog = observer(() => {
  const { invoiceItemStore } = useStores()

  return (
    <Dialog
      size="lg"
      open={invoiceItemStore.defectImageDialog}
      title={invoiceItemStore.selectedDefect.name}
      content={(
        <img
          src={invoiceItemStore.selectedDefect.url}
          alt="Defect"
        />
      )}
      noButton
      onClose={() => {
        invoiceItemStore.openDefectImageDialog(null, false)
      }}
    />
  )
})

const DateRangeDisplay = observer(() => {
  const {
    invoiceItemStore: {
      startDate,
      endDate,
    },
  } = useStores()
  return (
    <Stack direction="column">
      {(
        moment(startDate).isSame(lastSunday) &&
        moment(endDate).isSame(lastSaturday)
      ) && (
        <Typography variant="h6">
          Last Week (Invoice Created Date)
        </Typography>
      )}
      <Typography>
        {formatDayName(startDate)} - {formatDayName(endDate)}
      </Typography>
    </Stack>
  )
})

const SecondaryTabAndFilterBox = observer(() => {
  const classes = useStyles()

  const { invoiceItemStore } = useStores()

  const onChangeTab = useCallback((event, value) => {
    invoiceItemStore.setActiveTab('sub', value)
    invoiceItemStore.resetQuery(invoiceItemStore.startDate, invoiceItemStore.endDate)
  }, [
    invoiceItemStore.startDate,
    invoiceItemStore.endDate,
  ])

  const filterLength = computed(() => {
    const object = {
      customerId: invoiceItemStore.query.customerId,
      status: invoiceItemStore.query.status,
      barcode: invoiceItemStore.query.barcode,
      prestoId: invoiceItemStore.query.prestoId,
      adminId: invoiceItemStore.query.adminId,
      defect1: invoiceItemStore.query.defect1,
      defect2: invoiceItemStore.query.defect2,
    }
    const objArr = Object.values(object)
    const filterArr = objArr.filter(item => item)
    return filterArr.length
  }).get()

  const tabs = computed(() => {
    if (invoiceItemStore.activeTab.main === 0) return VIEW_MODE
    return [VIEW_MODE[1]]
  }).get()

  const renderIcon = () => {
    return (
      <>
        {filterLength > 0 ? (
          <div className={classes.filterNumber}>
            {filterLength}
          </div>
        ) : (
          <FilterList />
        )}
        <span>
          Filter
        </span>
      </>
    )
  }

  return (
    <>
      <div className={classes.filterWrapper}>
        <div className={classes.searchField}>
          <div>
            <Tabs
              classes={{
                root: classes.tabsRoot,
                indicator: classes.tabsIndicator,
              }}
              value={invoiceItemStore.activeTab.sub}
              onChange={onChangeTab}
            >
              {tabs.map((item) => (
                <Tab
                  key={item}
                  label={item}
                  classes={{
                    root: classes.tabListing,
                    selected: classes.tabSelected,
                  }}
                />
              ))}
            </Tabs>
          </div>
          {invoiceItemStore.activeTab.main === 0 && (
            <>
              <Button
                onClick={() => {
                  invoiceItemStore.setOpenFilterDialog(true)
                }}
                variant="outlined"
                color="secondary"
              >
                {renderIcon()}
              </Button>
              <div className={classes.info}>
                <Typography variant="h6">
                  Counter Staff
                </Typography>
                <Typography>
                  {invoiceItemStore.selectedAdmin?.name || 'All'}
                </Typography>
              </div>
            </>
          )}
        </div>
      </div>
      {invoiceItemStore.activeTab.main === 0 && invoiceItemStore.activeTab.sub === 0 && <Listing />}
      {(invoiceItemStore.activeTab.sub === 1 || invoiceItemStore.activeTab.main === 1) && <Analytics />}
    </>
  )
})

const Defect = () => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState(null)

  const {
    invoiceItemStore,
  } = useStores()

  const [date, setDate] = useState({
    key: 'selection',
    startDate: invoiceItemStore.startDate,
    endDate: invoiceItemStore.endDate,
  })

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleCloseCalendar = () => {
    setAnchorEl(null)
  }

  const onChangeTab = useCallback((event, value) => {
    invoiceItemStore.setActiveTab('main', value)
    invoiceItemStore.setActiveTab('sub', 0)
    invoiceItemStore.resetQuery(invoiceItemStore.startDate, invoiceItemStore.endDate)
  }, [
    invoiceItemStore.startDate,
    invoiceItemStore.endDate,
  ])

  const handleSelect = useCallback(value => {
    const { startDate, endDate } = value.selection
    setDate(prev => ({
      ...prev,
      startDate,
      endDate,
    }))
    invoiceItemStore.updateStartDate(startDate)
    invoiceItemStore.updateEndDate(endDate)
  }, [])

  useEffect(() => {
    return () => {
      invoiceItemStore.resetQuery()
      invoiceItemStore.resetActiveTab()
    }
  }, [])

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Page>
        <Box sx={{
          borderBottom: 1,
          borderColor: 'divider',
          display: 'flex',
          alignItems: 'center',
        }}
        >
          <Tabs
            value={invoiceItemStore.activeTab.main}
            onChange={onChangeTab}
            textColor="secondary"
            indicatorColor="secondary"
          >
            {['Staff Level',
              'All Missed Defects',
            ].map((day) => (
              <Tab
                key={day}
                label={day}
                classes={{
                  root: classes.tab,
                }}
              />
            ))}
          </Tabs>
          <div className={classes.datePickerStart}>
            <IconButton onClick={handleClick} size="large">
              <DateRange />
            </IconButton>
            <DateRangeDisplay />
          </div>
          <DateRangePicker
            anchorEl={anchorEl}
            handleClose={handleCloseCalendar}
            handleSelect={handleSelect}
            selectionRange={date}
          />
        </Box>
        <SecondaryTabAndFilterBox />
        <FilterDialog />
        <DefectImageDialog />
      </Page>
    </Suspense>
  )
}

export default observer(Defect)
