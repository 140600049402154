import { api } from './api'

export const currentAdmin = () => api.get('/admin/session')
export const loginAdmin = ({ username, password }) => api.post('/admin/session', { username, password })
export const logoutAdmin = () => api.delete('/admin/session')
export const forgetPasswordAdmin = ({ username }) => api.post('/admin/session/forget-password', { username })
export const resetPasswordAdmin = ({ password, resetToken }) => api.post('/admin/session/reset-password', { password, resetToken })
export const fetchAdmin = (query) => api.get('/admin', query)
export const fetchAdminName = (query) => api.get('/admin/name', query)
export const addAdmin = (admin) => api.post('/admin', admin)
export const updateAdmin = (admin) => api.put(`/admin/${admin.id}`, admin)
export const updateAdminPassword = (admin, password) => api.put(`/admin/${admin.id}/password`, { password })
export const deleteAdmin = (id) => api.delete(`/admin/${id}`)
