export default (theme) => ({
  title: {
    padding: '1rem',
    backgroundColor: theme.color.seashell,
  },
  parentContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  infoContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1',
    overflow: 'auto',
  },
})
