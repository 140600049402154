import React, { useCallback, useState, useEffect, Suspense } from 'react'
import { observer } from 'mobx-react'
import { computed } from 'mobx'
import makeStyles from '@mui/styles/makeStyles'
import {
  Box,
  Button,
  IconButton,
  Tab,
  Tabs,
} from '@mui/material'
import { formatDate } from 'admin/utils/helper'

import DateRangePicker from 'admin/components/date-range-picker'

import DateRangeIcon from '@mui/icons-material/DateRange'
import FilterListIcon from '@mui/icons-material/FilterList'

import { useStores } from 'admin/hooks'
import Page from 'admin/components/page'

import InvoiceInfo from './info'
import InvoiceDataGrid from './datagrid'
import styles from './styles'
import FilterInsideDialog from './filter'
import SyncButton from './sync'

const useStyles = makeStyles(styles)

const FilterOutsideDialog = observer(() => {
  const classes = useStyles()
  const { invoiceStore } = useStores()
  const [anchorEl, setAnchorEl] = useState(null)
  const {
    isDateRangeActive,
    datePickerSelectionRange,
  } = invoiceStore

  const filterLength = computed(() => {
    const object = {
      prestoId: invoiceStore.query.prestoId,
      paymentStatus: invoiceStore.query.paymentStatus,
      customerId: invoiceStore.query.customerId,
      driverId: invoiceStore.query.driverId,
      paymentMethod: invoiceStore.query.paymentMethod,
    }
    const objArr = Object.values(object)
    const filterArr = objArr.filter(item => item)
    return filterArr.length
  }).get()

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleSelect = useCallback(value => {
    const { startDate, endDate } = value.selection
    invoiceStore.updateDatePickerSelectionRange(startDate, endDate)
  }, [])

  const renderIcon = () => {
    return (
      <>
        {filterLength > 0 ? (
          <div className={classes.filterNumber}>
            {filterLength}
          </div>
        ) : (
          <FilterListIcon />
        )}
        <span>
          Filter
        </span>
      </>
    )
  }

  return (
    <Box sx={{
      borderBottom: 1,
      borderColor: 'divider',
      display: 'flex',
      alignItems: 'center',
      marginBottom: '1rem',
    }}
    >
      <div className={classes.filterWrapper}>
        <div className={classes.searchField}>
          <Button
            onClick={() => {
              invoiceStore.setOpenDialog(null, 'filter')
            }}
            variant="outlined"
            color="secondary"
          >
            {renderIcon()}
          </Button>
        </div>
        {isDateRangeActive && (
          <>
            <div className={classes.datePickerStartEnd}>
              <IconButton onClick={handleClick} size="large">
                <DateRangeIcon />
              </IconButton>
              <span>
                {formatDate(datePickerSelectionRange.startDate)} - {formatDate(datePickerSelectionRange.endDate)}
              </span>
            </div>
            <DateRangePicker
              anchorEl={anchorEl}
              handleClose={handleClose}
              handleSelect={handleSelect}
              selectionRange={invoiceStore.datePickerSelectionRange}
            />
          </>
        )}
        <SyncButton className={classes.syncButton} />
      </div>
    </Box>
  )
})

const Invoice = observer(() => {
  const classes = useStyles()
  const [activeTab, setActiveTab] = useState(0)
  const {
    invoiceStore,
  } = useStores()

  useEffect(() => {
    let params = {}
    if (activeTab === 0) params = { defaultDriver: true }
    invoiceStore.fetch(params)
  }, [invoiceStore.queryParams, activeTab])

  useEffect(() => {
    return () => {
      invoiceStore.resetQuery()
    }
  }, [])

  const handleChangeTab = useCallback((event, value) => {
    setActiveTab(value)
  }, [])

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Page>
        <FilterOutsideDialog />
        <FilterInsideDialog />
        <Box sx={{
          borderBottom: 1,
          borderColor: 'divider',
          display: 'flex',
          alignItems: 'center',
          marginBottom: '1rem',
        }}
        >
          <Tabs
            value={activeTab}
            onChange={handleChangeTab}
            textColor="secondary"
            indicatorColor="secondary"
            classes={{ indicator: classes.indicator }}
          >
            {['Driver Invoices',
              'All Invoices',
            ].map((tab) => (
              <Tab
                key={tab}
                label={tab}
                classes={{
                  root: classes.tab,
                }}
              />
            ))}
          </Tabs>
        </Box>
        <InvoiceDataGrid />
        <InvoiceInfo />
      </Page>
    </Suspense>
  )
})

export default Invoice
